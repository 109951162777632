import { Link, useLocation } from 'react-router-dom'
import PatchEducationLogo from '../../../assets/patch-education-white.png'
import PatchLogoIconWhite from '../../../assets/patch-logo-icon-white.png'
import { useAuthentication } from "../../../contexts/authentication"

const HeaderDesktop = ({ open }) => {
    const { pathname } = useLocation()
    const { logUserOut, currentUser } = useAuthentication()

    return (
        <div className='bg-slate-800 h-16 flex flex-row items-center shadow-sm border-b'>
            <div className='flex flex-row justify-between items-center w-full px-4 md:px-8'>
                <div className='flex flex-row items-center'>
                    <div className='flex flex-row items-center justify-center pb-4 pt-4'>
                        <Link to='/course' className='hidden md:inline-block'><img src={PatchEducationLogo} className='h-8' /></Link>
                        <Link to='/course' className='inline-block md:hidden'><img src={PatchLogoIconWhite} className='h-8' /></Link>
                    </div>
                    <div className='md:ml-8'>
                        <Link to='/course' className={`${pathname.includes('/course') ? 'text-white font-bold' : 'font-medium text-slate-300'} ml-4 md:ml-8 text-sm hover:text-white-500`}>Read</Link>
                        <Link to='/listen' className={`${pathname.includes('/listen') ? 'text-white font-bold' : 'font-medium text-slate-300'} ml-4 md:ml-8 text-sm hover:text-white-500`}>Listen</Link>
                        <Link to='/tests' className={`${pathname.includes('/tests') ? 'text-white font-bold' : 'font-medium text-slate-300'} ml-4 md:ml-8 text-sm hover:text-blue-500`}>Test</Link>
                        <Link to='/search' className={`${pathname.includes('/search') ? 'text-white font-bold' : 'font-medium text-slate-300'} ml-4 md:ml-8 text-sm hover:text-blue-500`}>Search</Link>
                        <Link to='/ask' className={`${pathname.includes('/ask') ? 'text-white font-bold' : 'font-medium text-slate-300'} ml-4 md:ml-8 text-sm hover:text-blue-500`}>Ask AI</Link>
                    </div>
                </div>
                <div className='flex flex-row hidden md:inline-block'>
                    {/* <p className='mr-2 text-white text-sm'>Welcome {currentUser?.fullName}</p> */}
                    <p><button className='text-white text-sm' onClick={() => logUserOut()}>Logout</button></p>
                </div>
            </div>
        </div>
    );
}

export default HeaderDesktop