export const SMALL_ANIMAL_DRUGS = [
  {
    "name": "Amoxicillin",
    "class": "Antibiotic",
    "category": "Penicillin antibiotic",
    "side_effects": "Allergic reactions (rash, itching, swelling), gastrointestinal upset (vomiting, diarrhea)"
  },
  {
    "name": "Atropine",
    "class": "Antimuscarinic",
    "category": "Anticholinergic drug",
    "side_effects": "Increased heart rate, dry mouth, dilated pupils, constipation"
  },
  {
    "name": "Acepromazine",
    "class": "Phenothiazine",
    "category": "Sedative and tranquilizer",
    "side_effects": "Low blood pressure, mild hypothermia, reduced respiratory rate"
  },
  {
    "name": "Aspirin",
    "class": "Non-Steroidal Anti-Inflammatory Drug - NSAID",
    "category": "NSAID, anti-inflammatory, analgesic, antipyretic",
    "side_effects": "Gastrointestinal ulcers, kidney impairment, bleeding disorders (in high doses)"
  },
  {
    "name": "Allopurinol",
    "class": "Xanthine Oxidase Inhibitor",
    "category": "Antigout medication",
    "side_effects": "Allergic reactions, liver dysfunction"
  },
  {
    "name": "Amitriptyline",
    "class": "Tricyclic Antidepressant",
    "category": "Antidepressant",
    "side_effects": "Sedation, dry mouth, constipation, increased heart rate"
  },
  {
    "name": "Azithromycin",
    "class": "Antibiotic",
    "category": "Macrolide antibiotic",
    "side_effects": "Gastrointestinal upset, allergic reactions"
  },
  {
    "name": "Amlodipine",
    "class": "Calcium Channel Blocker",
    "category": "Antihypertensive",
    "side_effects": "Hypotension, dizziness, edema"
  },
  {
    "name": "Advantage",
    "class": "Insecticide",
    "category": "Topical insecticide (imidacloprid)",
    "side_effects": "Skin irritation, hair loss at application site (rare)"
  },
  {
    "name": "Atenolol",
    "class": "Beta-Blocker",
    "category": "Antihypertensive, antiarrhythmic",
    "side_effects": "Bradycardia, hypotension, bronchoconstriction"
  },
  {
    "name": "Amikacin",
    "class": "Aminoglycoside Antibiotic",
    "category": "Antibiotic",
    "side_effects": "Nephrotoxicity, ototoxicity"
  },
  {
    "name": "Apoquel",
    "class": "Janus Kinase Inhibitor",
    "category": "Anti-inflammatory for allergies",
    "side_effects": "Vomiting, diarrhea, decreased appetite"
  },
  {
    "name": "Antisedan",
    "class": "Alpha-2 Adrenergic Receptor Antagonist",
    "category": "Reversal agent for alpha-2 agonists",
    "side_effects": "Hypertension, salivation, vomiting"
  },
  {
    "name": "Atipamezole",
    "class": "Alpha-2 Adrenergic Receptor Antagonist",
    "category": "Reversal agent for alpha-2 agonists",
    "side_effects": "Transient excitement, tremors"
  },
  {
    "name": "Anafen",
    "class": "Non-Steroidal Anti-Inflammatory Drug - NSAID",
    "category": "NSAID",
    "side_effects": "Gastrointestinal upset, renal impairment"
  },
  {
    "name": "Amantadine",
    "class": "Antiviral",
    "category": "Antiviral and analgesic",
    "side_effects": "Insomnia, GI upset"
  },
  {
    "name": "Apomorphine",
    "class": "Emetic",
    "category": "Emetic",
    "side_effects": "Vomiting, excitement"
  },
  {
    "name": "Acaricides",
    "class": "Parasiticides",
    "category": "Various (e.g., pyrethroids, organophosphates)",
    "side_effects": "Skin irritation, neurological signs (rare)"
  },
  {
    "name": "Anesthesia Induction Agents (e.g., Propofol, Alfaxalone)",
    "class": "Anesthetic induction agents",
    "category": "e.g., Propofol, Alfaxalone",
    "side_effects": "Respiratory depression, cardiovascular depression"
  },
  {
    "name": "Analgesics",
    "class": "Pain control medications",
    "category": "e.g., Tramadol, Buprenorphine",
    "side_effects": "Sedation, gastrointestinal upset"
  },
  {
    "name": "Ammonium Chloride",
    "class": "Acidifying Agent",
    "category": "Urinary acidifier",
    "side_effects": "Metabolic acidosis"
  },
  {
    "name": "Antacids (e.g., Famotidine, Ranitidine)",
    "class": "Gastric acid reducers",
    "category": "e.g., Famotidine, Ranitidine",
    "side_effects": "Rare, may include vomiting or diarrhea"
  },
  {
    "name": "Anti-Convulsants (e.g., Phenobarbital, Gabapentin)",
    "class": "Medications to control seizures",
    "category": "e.g., Phenobarbital, Gabapentin",
    "side_effects": "Sedation, liver enzyme elevation (in some)"
  },
  {
    "name": "Anabolic Steroids (e.g., Stanozolol)",
    "class": "Anabolic agent",
    "category": "e.g., Stanozolol",
    "side_effects": "Liver toxicity, behavioral changes"
  },
  {
    "name": "Artificial Tears",
    "class": "Ophthalmic Lubricant",
    "category": "Ophthalmic lubricant",
    "side_effects": "Rare, may include mild eye irritation"
  },
  {
    "name": "Baytril (Enrofloxacin)",
    "class": "Antibiotic",
    "category": "Fluoroquinolone antibiotic",
    "side_effects": "Gastrointestinal upset, joint cartilage abnormalities (in young animals)"
  },
  {
    "name": "Benadryl (Diphenhydramine)",
    "class": "Antihistamine",
    "category": "H1 receptor antagonist",
    "side_effects": "Sedation, dry mouth, urinary retention"
  },
  {
    "name": "Buprenorphine",
    "class": "Opioid Analgesic",
    "category": "Opioid analgesic",
    "side_effects": "Sedation, respiratory depression, constipation"
  },
  {
    "name": "Butorphanol",
    "class": "Opioid Analgesic",
    "category": "Opioid analgesic",
    "side_effects": "Sedation, respiratory depression, ataxia"
  },
  {
    "name": "Bravecto (Fluralaner)",
    "class": "Ectoparasiticide",
    "category": "Isoxazoline insecticide",
    "side_effects": "Vomiting, diarrhea, lethargy"
  },
  {
    "name": "Baycox (Toltrazuril)",
    "class": "Antiprotozoal",
    "category": "Coccidiostat",
    "side_effects": "Rare, may include gastrointestinal upset"
  },
  {
    "name": "Betamethasone",
    "class": "Corticosteroid",
    "category": "Glucocorticoid",
    "side_effects": "Increased thirst and urination, potential for immunosuppression"
  },
  {
    "name": "Buspirone",
    "class": "Anxiolytic",
    "category": "Serotonin receptor agonist",
    "side_effects": "Sedation, gastrointestinal upset"
  },
  {
    "name": "Baclofen",
    "class": "Muscle Relaxant",
    "category": "GABA-B receptor agonist",
    "side_effects": "Sedation, weakness"
  },
  {
    "name": "Brimonidine",
    "class": "Ophthalmic Alpha-2 Agonist",
    "category": "Alpha-2 adrenergic agonist",
    "side_effects": "Conjunctival hyperemia, ocular irritation"
  },
  {
    "name": "Betadine (Povidone-Iodine)",
    "class": "Antiseptic",
    "category": "Antiseptic",
    "side_effects": "Rare, may include skin irritation"
  },
  {
    "name": "Budesonide",
    "class": "Corticosteroid",
    "category": "Glucocorticoid",
    "side_effects": "Similar to other corticosteroids; may include increased thirst and urination"
  },
  {
    "name": "Bolus Therapy (Calcium Borogluconate)",
    "class": "Calcium Supplement",
    "category": "Calcium supplement",
    "side_effects": "Rare, may include local tissue irritation"
  },
  {
    "name": "Biotin",
    "class": "Vitamin Supplement",
    "category": "Vitamin B supplement",
    "side_effects": "Generally well-tolerated"
  },
  {
    "name": "Bismuth Subsalicylate",
    "class": "Gastrointestinal Protectant",
    "category": "Gastrointestinal protectant",
    "side_effects": "Rare, may include darkening of the tongue and stools"
  },
  {
    "name": "Boldenone",
    "class": "Anabolic Steroid",
    "category": "Anabolic agent",
    "side_effects": "Similar to other anabolic steroids; may include liver toxicity"
  },
  {
    "name": "Brevail (Lignans)",
    "class": "Nutraceutical",
    "category": "Nutraceutical",
    "side_effects": "Generally well-tolerated"
  },
  {
    "name": "Boric Acid",
    "class": "Ophthalmic Irrigant",
    "category": "Ophthalmic irrigant",
    "side_effects": "Rare, may include eye irritation"
  },
  {
    "name": "Bovine Colostrum",
    "class": "Nutritional Supplement",
    "category": "Nutritional supplement",
    "side_effects": "Generally well-tolerated"
  },
  {
    "name": "Bethanechol",
    "class": "Cholinergic Agent",
    "category": "Muscarinic agonist",
    "side_effects": "Increased salivation, gastrointestinal upset"
  },
  {
    "name": "Burow's Solution (Aluminum Acetate)",
    "class": "Dermatologic Solution",
    "category": "Dermatologic solution",
    "side_effects": "Rare, may include skin irritation"
  },
  {
    "name": "Benzoyl Peroxide",
    "class": "Dermatologic Agent",
    "category": "Dermatologic agent",
    "side_effects": "Rare, may include skin dryness or irritation"
  },
  {
    "name": "Bromides",
    "class": "Antiepileptic",
    "category": "Antiepileptic",
    "side_effects": "Polydipsia, polyuria, sedation"
  },
  {
    "name": "Cephalexin",
    "class": "Antibiotic",
    "category": "Cephalosporin antibiotic",
    "side_effects": "Potential for gastrointestinal upset, rare instances of allergic reactions"
  },
  {
    "name": "Clavamox (Amoxicillin/Clavulanic Acid)",
    "class": "Antibiotic",
    "category": "Penicillin antibiotic",
    "side_effects": "Potential for gastrointestinal upset, rare instances of allergic reactions"
  },
  {
    "name": "Carprofen",
    "class": "Nonsteroidal Anti-Inflammatory Drug",
    "category": "NSAID",
    "side_effects": "Potential for gastrointestinal upset, renal toxicity, and hepatotoxicity"
  },
  {
    "name": "Cerenia (Maropitant Citrate)",
    "class": "Antiemetic",
    "category": "Neurokinin-1 receptor antagonist",
    "side_effects": "Potential for mild sedation, rare instances of hypersensitivity reactions"
  },
  {
    "name": "Convenia (Cefovecin)",
    "class": "Antibiotic",
    "category": "Cephalosporin antibiotic",
    "side_effects": "Potential for gastrointestinal upset, rare instances of allergic reactions"
  },
  {
    "name": "Ciprofloxacin",
    "class": "Antibiotic",
    "category": "Fluoroquinolone antibiotic",
    "side_effects": "Potential for gastrointestinal upset, rare instances of musculoskeletal effects"
  },
  {
    "name": "Corticosteroids (e.g., Prednisone, Dexamethasone)",
    "class": "Corticosteroid",
    "category": "Glucocorticoid",
    "side_effects": "Potential for immunosuppression, polyuria, polydipsia, and gastrointestinal upset"
  },
  {
    "name": "Cytopoint (Lokivetmab)",
    "class": "Monoclonal Antibody",
    "category": "IL-31 antagonist",
    "side_effects": "Generally well-tolerated, rare instances of hypersensitivity reactions"
  },
  {
    "name": "Clindamycin",
    "class": "Antibiotic",
    "category": "Lincosamide antibiotic",
    "side_effects": "Potential for gastrointestinal upset, rare instances of allergic reactions"
  },
  {
    "name": "Capstar (Nitenpyram)",
    "class": "Insecticide",
    "category": "Neonicotinoid insecticide",
    "side_effects": "Generally well-tolerated, rare instances of mild gastrointestinal upset"
  },
  {
    "name": "Cisapride",
    "class": "Gastrointestinal Prokinetic Agent",
    "category": "Gastrointestinal prokinetic agent",
    "side_effects": "Potential for mild gastrointestinal upset, rare instances of cardiovascular effects"
  },
  {
    "name": "Cyclosporine",
    "class": "Immunosuppressant",
    "category": "Immunosuppressant",
    "side_effects": "Potential for renal toxicity, hepatotoxicity, and gastrointestinal upset"
  },
  {
    "name": "Chloramphenicol",
    "class": "Antibiotic",
    "category": "Amphenicol antibiotic",
    "side_effects": "Potential for bone marrow suppression, rare instances of allergic reactions"
  },
  {
    "name": "Clotrimazole",
    "class": "Antifungal",
    "category": "Antifungal",
    "side_effects": "Rare, potential for local irritation"
  },
  {
    "name": "Capromorelin",
    "class": "Ghrelin Receptor Agonist",
    "category": "Ghrelin receptor agonist",
    "side_effects": "Potential for gastrointestinal upset, rare instances of hypersensitivity reactions"
  },
  {
    "name": "Cyproheptadine",
    "class": "Antihistamine and Serotonin Receptor Antagonist",
    "category": "Antihistamine and serotonin receptor antagonist",
    "side_effects": "Potential for sedation, gastrointestinal upset"
  },
  {
    "name": "Cefpodoxime",
    "class": "Antibiotic",
    "category": "Cephalosporin antibiotic",
    "side_effects": "Potential for gastrointestinal upset, rare instances of allergic reactions"
  },
  {
    "name": "Comfortis (Spinosad)",
    "class": "Insecticide",
    "category": "Avermectin insecticide",
    "side_effects": "Generally well-tolerated, rare instances of vomiting and lethargy"
  },
  {
    "name": "Cerenia (Aprepitant)",
    "class": "Antiemetic",
    "category": "Neurokinin-1 receptor antagonist",
    "side_effects": "Potential for mild sedation, rare instances of hypersensitivity reactions"
  },
  {
    "name": "Cystaid (N-Acetyl Glucosamine)",
    "class": "Nutritional Supplement",
    "category": "Nutritional supplement",
    "side_effects": "Generally well-tolerated, rare instances of gastrointestinal upset"
  },
  {
    "name": "Cefazolin",
    "class": "Antibiotic",
    "category": "Cephalosporin antibiotic",
    "side_effects": "Potential for gastrointestinal upset, rare instances of allergic reactions"
  },
  {
    "name": "Chlorpheniramine",
    "class": "Antihistamine",
    "category": "Antihistamine",
    "side_effects": "Potential for sedation, gastrointestinal upset"
  },
  {
    "name": "Clomipramine",
    "class": "Tricyclic Antidepressant",
    "category": "Tricyclic antidepressant",
    "side_effects": "Potential for sedation, gastrointestinal upset"
  },
  {
    "name": "CitraVet (Potassium Citrate)",
    "class": "Urinary Alkalinizing Agent",
    "category": "Urinary alkalinizing agent",
    "side_effects": "Generally well-tolerated, rare instances of gastrointestinal upset"
  },
  {
    "name": "Ceftriaxone",
    "class": "Antibiotic",
    "category": "Cephalosporin antibiotic",
    "side_effects": "Potential for gastrointestinal upset, rare instances of allergic reaction"
  },
  {
    "name": "Dexamethasone",
    "class": "Corticosteroid",
    "category": "Glucocorticoid",
    "side_effects": "Increased thirst and urination, potential for immunosuppression"
  },
  {
    "name": "Diphenhydramine",
    "class": "Antihistamine",
    "category": "H1 receptor antagonist",
    "side_effects": "Sedation, dry mouth, urinary retention"
  },
  {
    "name": "Doxapram",
    "class": "Respiratory Stimulant",
    "category": "Respiratory stimulant",
    "side_effects": "Excitement, tremors, increased heart rate"
  },
  {
    "name": "Deslorelin",
    "class": "Gonadotropin-Releasing Hormone Agonist",
    "category": "GnRH agonist",
    "side_effects": "Transient infertility, potential for adrenal gland suppression"
  },
  {
    "name": "Doxorubicin",
    "class": "Chemotherapeutic Agent",
    "category": "Anthracycline antibiotic",
    "side_effects": "Cardiotoxicity, bone marrow suppression"
  },
  {
    "name": "Darbepoetin Alfa",
    "class": "Erythropoiesis-Stimulating Agent",
    "category": "Erythropoiesis-stimulating agent",
    "side_effects": "Hypertension, potential for pure red cell aplasia"
  },
  {
    "name": "Dinoprost Tromethamine",
    "class": "Prostaglandin Analogue",
    "category": "Prostaglandin analogue",
    "side_effects": "Vomiting, diarrhea, increased uterine tone"
  },
  {
    "name": "Diazoxide",
    "class": "Antihypertensive",
    "category": "Antihypertensive",
    "side_effects": "Polyuria, polydipsia, potential for hypotension"
  },
  {
    "name": "Dimenhydrinate",
    "class": "Antiemetic",
    "category": "Antihistamine",
    "side_effects": "Sedation, dry mouth"
  },
  {
    "name": "Desmopressin",
    "class": "Vasopressin Analogue",
    "category": "Vasopressin analogue",
    "side_effects": "Water intoxication, hyponatremia"
  },
  {
    "name": "Diethylstilbestrol (DES)",
    "class": "Estrogen",
    "category": "Estrogen",
    "side_effects": "Bone marrow suppression, mammary gland enlargement"
  },
  {
    "name": "Dipivefrin",
    "class": "Ophthalmic Agent",
    "category": "Prodrug of epinephrine",
    "side_effects": "Ocular irritation, increased intraocular pressure"
  },
  {
    "name": "Diazoxide",
    "class": "Antihypertensive",
    "category": "Antihypertensive",
    "side_effects": "Polyuria, polydipsia, potential for hypotension"
  },
  {
    "name": "Diltiazem",
    "class": "Calcium Channel Blocker",
    "category": "Calcium channel blocker",
    "side_effects": "Hypotension"
  },
  {
    "name": "Enalapril",
    "class": "Angiotensin-Converting Enzyme (ACE) Inhibitor",
    "category": "ACE inhibitor",
    "side_effects": "Hypotension, renal impairment"
  },
  {
    "name": "Epinephrine",
    "class": "Sympathomimetic Agent",
    "category": "Sympathomimetic agent",
    "side_effects": "Tachycardia, hypertension, potential for arrhythmias"
  },
  {
    "name": "Esomeprazole",
    "class": "Proton Pump Inhibitor",
    "category": "Proton pump inhibitor",
    "side_effects": "Gastrointestinal upset, potential for electrolyte imbalances"
  },
  {
    "name": "Enrofloxacin",
    "class": "Antibiotic",
    "category": "Fluoroquinolone antibiotic",
    "side_effects": "Gastrointestinal upset, joint cartilage abnormalities (in young animals)"
  },
  {
    "name": "Erythromycin",
    "class": "Antibiotic",
    "category": "Macrolide antibiotic",
    "side_effects": "Gastrointestinal upset, potential for diarrhea"
  },
  {
    "name": "Ezetimibe",
    "class": "Cholesterol Absorption Inhibitor",
    "category": "Cholesterol absorption inhibitor",
    "side_effects": "Gastrointestinal upset, potential for liver enzyme elevation"
  },
  {
    "name": "Ephedrine",
    "class": "Sympathomimetic Agent",
    "category": "Sympathomimetic agent",
    "side_effects": "Increased heart rate, hypertension, potential for excitability"
  },
  {
    "name": "Estradiol",
    "class": "Estrogen",
    "category": "Estrogen",
    "side_effects": "Mammary gland enlargement, potential for bone marrow suppression"
  },
  {
    "name": "Ethinyl Estradiol",
    "class": "Estrogen",
    "category": "Estrogen",
    "side_effects": "Mammary gland enlargement, potential for bone marrow suppression"
  },
  {
    "name": "Esmolol",
    "class": "Beta-1 Adrenergic Blocker",
    "category": "Beta-1 adrenergic blocker",
    "side_effects": "Hypotension, bradycardia"
  },
  {
    "name": "Epomediol",
    "class": "Diuretic",
    "category": "Diuretic",
    "side_effects": "Polyuria, potential for electrolyte imbalances"
  },
  {
    "name": "Ethylenediamine Dihydroiodide",
    "class": "Iodine Supplement",
    "category": "Iodine supplement",
    "side_effects": "Rare, potential for iodine toxicity"
  },
  {
    "name": "Edrophonium",
    "class": "Acetylcholinesterase Inhibitor",
    "category": "Acetylcholinesterase inhibitor",
    "side_effects": "Potential for cholinergic effects"
  },
  {
    "name": "Epirubicin",
    "class": "Chemotherapeutic Agent",
    "category": "Anthracycline antibiotic",
    "side_effects": "Cardiotoxicity, bone marrow suppression"
  },
  {
    "name": "Erythropoietin",
    "class": "Erythropoiesis-Stimulating Agent",
    "category": "Erythropoiesis-stimulating agent",
    "side_effects": "Hypertension, potential for pure red cell aplasia"
  },
  {
    "name": "Famotidine",
    "class": "H2 Receptor Antagonist",
    "category": "H2 receptor antagonist",
    "side_effects": "Rare, may include gastrointestinal upset"
  },
  {
    "name": "Fluoxetine",
    "class": "Selective Serotonin Reuptake Inhibitor (SSRI)",
    "category": "SSRI (antidepressant)",
    "side_effects": "Anorexia, sedation, gastrointestinal upset"
  },
  {
    "name": "Fipronil",
    "class": "Ectoparasiticide",
    "category": "Phenylpyrazole insecticide",
    "side_effects": "Rare, may include skin irritation"
  },
  {
    "name": "Fentanyl",
    "class": "Opioid Analgesic",
    "category": "Opioid analgesic",
    "side_effects": "Respiratory depression, sedation, bradycardia"
  },
  {
    "name": "Florfenicol",
    "class": "Antibiotic",
    "category": "Amphenicol antibiotic",
    "side_effects": "Gastrointestinal upset, potential for bone marrow suppression"
  },
  {
    "name": "Furosemide",
    "class": "Loop Diuretic",
    "category": "Loop diuretic",
    "side_effects": "Dehydration, electrolyte imbalances"
  },
  {
    "name": "Firocoxib",
    "class": "Non-Steroidal Anti-Inflammatory Drug (NSAID)",
    "category": "NSAID",
    "side_effects": "Gastrointestinal upset, renal impairment, liver enzyme elevation"
  },
  {
    "name": "Frontline (Fipronil)",
    "class": "Ectoparasiticide",
    "category": "Phenylpyrazole insecticide",
    "side_effects": "Rare, may include skin irritation"
  },
  {
    "name": "Fluconazole",
    "class": "Antifungal",
    "category": "Antifungal (azole)",
    "side_effects": "Gastrointestinal upset, potential for hepatotoxicity"
  },
  {
    "name": "Felodipine",
    "class": "Calcium Channel Blocker",
    "category": "Calcium channel blocker",
    "side_effects": "Hypotension, dizziness"
  },
  {
    "name": "Flumazenil",
    "class": "Benzodiazepine Receptor Antagonist",
    "category": "Benzodiazepine receptor antagonist",
    "side_effects": "Rare, may include seizures (in benzodiazepine-dependent animals)"
  },
  {
    "name": "Fludrocortisone",
    "class": "Mineralocorticoid",
    "category": "Mineralocorticoid",
    "side_effects": "Polyuria, polydipsia, potential for sodium retention"
  },
  {
    "name": "Febantel",
    "class": "Anthelmintic",
    "category": "Anthelmintic (for intestinal parasites)",
    "side_effects": "Rare, may include gastrointestinal upset"
  },
  {
    "name": "Fenbendazole",
    "class": "Anthelmintic",
    "category": "Anthelmintic (for intestinal parasites)",
    "side_effects": "Rare, may include gastrointestinal upset"
  },
  {
    "name": "Fluniximin Meglumine",
    "class": "Non-Steroidal Anti-Inflammatory Drug (NSAID)",
    "category": "NSAID",
    "side_effects": "Gastrointestinal upset, potential for renal impairment"
  },
  {
    "name": "Fomepizole",
    "class": "Alcohol Dehydrogenase Inhibitor",
    "category": "Alcohol dehydrogenase inhibitor (used in toxic alcohol exposure)",
    "side_effects": "Rare, may include gastrointestinal upset"
  },
  {
    "name": "Fish Oil (Omega-3 Fatty Acids)",
    "class": "Nutraceutical",
    "category": "Nutraceutical",
    "side_effects": "Generally well-tolerated"
  },
  {
    "name": "Ferric Hydroxide Sucrose Complex",
    "class": "Iron Supplement",
    "category": "Iron supplement",
    "side_effects": "Rare, may include hypersensitivity reactions"
  },
  {
    "name": "Filgrastim",
    "class": "Granulocyte Colony-Stimulating Factor (G-CSF)",
    "category": "G-CSF (used to stimulate white blood cell production)",
    "side_effects": "Bone pain, potential for splenic enlargement"
  },
  {
    "name": "Fipronil/Permethrin",
    "class": "Ectoparasiticide",
    "category": "Phenylpyrazole and pyrethroid insecticides",
    "side_effects": "Rare, may include skin irritation"
  },
  {
    "name": "Flecainide",
    "class": "Antiarrhythmic",
    "category": "Antiarrhythmic",
    "side_effects": "Rare, may include proarrhythmia"
  },
  {
    "name": "Follicle-Stimulating Hormone (FSH)",
    "class": "Hormone",
    "category": "Hormone",
    "side_effects": "Rare, may include ovarian hyperstimulation"
  },
  {
    "name": "Fenoldopam",
    "class": "Vasodilator",
    "category": "Vasodilator",
    "side_effects": "Hypotension, tachycardia"
  },
  {
    "name": "Folic Acid",
    "class": "Vitamin Supplement",
    "category": "Vitamin supplement",
    "side_effects": "Generally well-tolerated"
  },
  {
    "name": "Floxuridine",
    "class": "Chemotherapeutic Agent",
    "category": "Antimetabolite (pyrimidine analog)",
    "side_effects": "Bone marrow suppression, gastrointestinal upset"
  },
  {
    "name": "Gabapentin",
    "class": "Anticonvulsant/Analgesic",
    "category": "Anticonvulsant",
    "side_effects": "Sedation, ataxia, potential for gastrointestinal upset"
  },
  {
    "name": "Gentamicin",
    "class": "Antibiotic",
    "category": "Aminoglycoside antibiotic",
    "side_effects": "Nephrotoxicity, ototoxicity"
  },
  {
    "name": "Griseofulvin",
    "class": "Antifungal",
    "category": "Antifungal (antimicrotubule agent)",
    "side_effects": "Gastrointestinal upset, hepatotoxicity"
  },
  {
    "name": "Glipizide",
    "class": "Sulfonylurea (Antidiabetic)",
    "category": "Sulfonylurea",
    "side_effects": "Hypoglycemia, gastrointestinal upset"
  },
  {
    "name": "Guaifenesin",
    "class": "Expectorant",
    "category": "Expectorant",
    "side_effects": "Rare, may include gastrointestinal upset"
  },
  {
    "name": "Glycopyrrolate",
    "class": "Anticholinergic",
    "category": "Anticholinergic",
    "side_effects": "Dry mouth, tachycardia, potential for ileus"
  },
  {
    "name": "Galliprant (Grapiprant)",
    "class": "Non-Steroidal Anti-Inflammatory Drug (NSAID)",
    "category": "NSAID (EP4 receptor antagonist)",
    "side_effects": "Gastrointestinal upset, renal impairment, liver enzyme elevation"
  },
  {
    "name": "Gleptoferron",
    "class": "Iron Supplement",
    "category": "Iron supplement",
    "side_effects": "Rare, may include hypersensitivity reactions"
  },
  {
    "name": "Glyburide",
    "class": "Sulfonylurea (Antidiabetic)",
    "category": "Sulfonylurea",
    "side_effects": "Hypoglycemia, gastrointestinal upset"
  },
  {
    "name": "Gonadorelin",
    "class": "Gonadotropin-Releasing Hormone (GnRH) Agonist",
    "category": "GnRH agonist",
    "side_effects": "May induce estrus, rare allergic reactions"
  },
  {
    "name": "Gentamicin/Betamethasone/Clotrimazole (Gentizol)",
    "class": "Antibiotic/Corticosteroid/Antifungal",
    "category": "Combination medication",
    "side_effects": "Vary depending on the component used"
  },
  {
    "name": "Gemcitabine",
    "class": "Chemotherapeutic Agent",
    "category": "Antimetabolite (nucleoside analog)",
    "side_effects": "Bone marrow suppression, gastrointestinal upset"
  },
  {
    "name": "Grapiprant",
    "class": "Non-Steroidal Anti-Inflammatory Drug (NSAID)",
    "category": "NSAID (EP4 receptor antagonist)",
    "side_effects": "Gastrointestinal upset, renal impairment, liver enzyme elevation"
  },
  {
    "name": "Guaifenesin/Ketamine/Xylazine (GKX)",
    "class": "Anesthetic Combination",
    "category": "Combination anesthetic",
    "side_effects": "Vary depending on the component used"
  },
  {
    "name": "Guaifenesin/Theophylline/Ephedrine (GTE)",
    "class": "Expectorant/Bronchodilator",
    "category": "Combination medication",
    "side_effects": "Vary depending on the component used"
  },
  {
    "name": "Glycopyrrolate/Atropine",
    "class": "Anticholinergic",
    "category": "Anticholinergic",
    "side_effects": "Vary depending on the component used"
  },
  {
    "name": "Gentamicin/Betamethasone (GentaCort)",
    "class": "Antibiotic/Corticosteroid",
    "category": "Combination medication",
    "side_effects": "Vary depending on the component used"
  },
  {
    "name": "Guaifenesin/Codeine (Guiatuss AC)",
    "class": "Expectorant/Opioid Antitussive",
    "category": "Combination medication",
    "side_effects": "Vary depending on the component used"
  },
  {
    "name": "Guaifenesin/Codeine/Pseudoephedrine (Guaiatussin AC)",
    "class": "Expectorant/Opioid Antitussive/Decongestant",
    "category": "Combination medication",
    "side_effects": "Vary depending on the component used"
  },
  {
    "name": "Granisetron",
    "class": "5-HT3 Receptor Antagonist",
    "category": "5-HT3 receptor antagonist (antiemetic)",
    "side_effects": "Rare, may include constipation, headache"
  },
  {
    "name": "Glycerin",
    "class": "Osmotic Laxative",
    "category": "Osmotic laxative",
    "side_effects": "Rare, may include dehydration"
  },
  {
    "name": "Guaifenesin/Dextromethorphan (GG/DM)",
    "class": "Expectorant/Antitussive",
    "category": "Combination medication",
    "side_effects": "Vary depending on the component used"
  },
  {
    "name": "Guaifenesin/Phenylephrine (GlycoFlex Plus)",
    "class": "Expectorant/Decongestant",
    "category": "Combination medication",
    "side_effects": "Vary depending on the component used"
  },
  {
    "name": "Guaifenesin/Dextromethorphan/Phenylephrine (GG/D/PE)",
    "class": "Expectorant/Antitussive/Decongestant",
    "category": "Combination medication",
    "side_effects": "Vary depending on the component used"
  },
  {
    "name": "Hydroxyzine",
    "class": "Antihistamine/Sedative",
    "category": "H1 receptor antagonist",
    "side_effects": "Sedation, dry mouth, urinary retention"
  },
  {
    "name": "Heparin",
    "class": "Anticoagulant",
    "category": "Anticoagulant",
    "side_effects": "Bleeding, thrombocytopenia"
  },
  {
    "name": "Hypertonic Saline",
    "class": "Fluid Supplement",
    "category": "Fluid supplement",
    "side_effects": "Dehydration, electrolyte imbalances"
  },
  {
    "name": "Hydrochlorothiazide",
    "class": "Thiazide Diuretic",
    "category": "Thiazide diuretic",
    "side_effects": "Dehydration, electrolyte imbalances"
  },
  {
    "name": "Hydrocodone/Acetaminophen",
    "class": "Opioid Analgesic/Non-Steroidal Anti-Inflammatory Drug (NSAID)",
    "category": "Opioid analgesic/NSAID combination",
    "side_effects": "Sedation, gastrointestinal upset, potential for constipation"
  },
  {
    "name": "Hydrocortisone",
    "class": "Corticosteroid",
    "category": "Corticosteroid",
    "side_effects": "Polyuria, polydipsia, potential for adrenal suppression"
  },
  {
    "name": "Halothane",
    "class": "Inhalation Anesthetic",
    "category": "Inhalation anesthetic",
    "side_effects": "Respiratory depression, potential for hepatotoxicity"
  },
  {
    "name": "Hematologic Support",
    "class": "Blood Product",
    "category": "Blood product (e.g., packed red blood cells)",
    "side_effects": "Rare, may include transfusion reactions"
  },
  {
    "name": "Histamine2 (H2) Blockers",
    "class": "Gastrointestinal Medication",
    "category": "H2 receptor antagonist",
    "side_effects": "Rare, may include gastrointestinal upset"
  },
  {
    "name": "Hydromorphone",
    "class": "Opioid Analgesic",
    "category": "Opioid analgesic",
    "side_effects": "Sedation, respiratory depression, potential for constipation"
  },
  {
    "name": "Homatropine",
    "class": "Anticholinergic",
    "category": "Anticholinergic",
    "side_effects": "Dry mouth, tachycardia, potential for ileus"
  },
  {
    "name": "Hypromellose",
    "class": "Ophthalmic Lubricant",
    "category": "Ophthalmic lubricant",
    "side_effects": "Rare, may include eye irritation"
  },
  {
    "name": "Hydroxyurea",
    "class": "Chemotherapeutic Agent",
    "category": "Antimetabolite (urea derivative)",
    "side_effects": "Bone marrow suppression, gastrointestinal upset"
  },
  {
    "name": "Hawthorn",
    "class": "Herbal Supplement",
    "category": "Herbal supplement",
    "side_effects": "Generally well-tolerated"
  },
  {
    "name": "Hydralazine",
    "class": "Vasodilator",
    "category": "Vasodilator",
    "side_effects": "Hypotension, tachycardia"
  },
  {
    "name": "H-2 Blockers (Ranitidine)",
    "class": "Gastrointestinal Medication",
    "category": "H2 receptor antagonist",
    "side_effects": "Rare, may include gastrointestinal upset"
  },
  {
    "name": "Hyaluronidase",
    "class": "Enzyme",
    "category": "Enzyme",
    "side_effects": "Rare, may include hypersensitivity reactions"
  },
  {
    "name": "Homatropine/Methscopolamine/Prednisolone (HMP)",
    "class": "Ophthalmic Combination",
    "category": "Combination medication",
    "side_effects": "Vary depending on the component used"
  },
  {
    "name": "Hydroxyzine/Prednisolone",
    "class": "Antihistamine/Corticosteroid",
    "category": "Combination medication",
    "side_effects": "Vary depending on the component used"
  },
  {
    "name": "Hyaluronic Acid",
    "class": "Joint Supplement",
    "category": "Joint supplement",
    "side_effects": "Rare, may include joint swelling"
  },
  {
    "name": "Hydrocodone",
    "class": "Opioid Analgesic",
    "category": "Opioid analgesic",
    "side_effects": "Sedation, respiratory depression, potential for constipation"
  },
  {
    "name": "Human Chorionic Gonadotropin (hCG)",
    "class": "Hormone",
    "category": "Hormone",
    "side_effects": "May induce estrus, rare allergic reactions"
  },
  {
    "name": "Hydroxocobalamin",
    "class": "Vitamin B12 Supplement",
    "category": "Vitamin B12 supplement",
    "side_effects": "Rare, may include hypersensitivity reactions"
  },
  {
    "name": "H-2 Blockers (Cimetidine)",
    "class": "Gastrointestinal Medication",
    "category": "H2 receptor antagonist",
    "side_effects": "Rare, may include gastrointestinal upset"
  },
  {
    "name": "Hydroxyzine/Pseudoephedrine",
    "class": "Antihistamine/Decongestant",
    "category": "Combination medication",
    "side_effects": "Vary depending on the component used"
  },
  {
    "name": "Ivermectin",
    "class": "Antiparasitic",
    "category": "Macrocyclic lactone",
    "side_effects": "Neurological signs (in high doses in certain breeds), gastrointestinal upset"
  },
  {
    "name": "Imidacloprid",
    "class": "Ectoparasiticide",
    "category": "Neonicotinoid",
    "side_effects": "Rare, may include skin irritation"
  },
  {
    "name": "Interferon Alpha",
    "class": "Immunomodulator",
    "category": "Cytokine immunomodulator",
    "side_effects": "Rare, may include flu-like symptoms"
  },
  {
    "name": "Isoflurane",
    "class": "Inhalation Anesthetic",
    "category": "Inhalation anesthetic",
    "side_effects": "Respiratory depression, potential for hepatotoxicity"
  },
  {
    "name": "Isosorbide Dinitrate",
    "class": "Vasodilator",
    "category": "Vasodilator",
    "side_effects": "Hypotension, tachycardia"
  },
  {
    "name": "Ibuprofen",
    "class": "Non-Steroidal Anti-Inflammatory Drug (NSAID)",
    "category": "NSAID",
    "side_effects": "Gastrointestinal upset, renal impairment, liver enzyme elevation"
  },
  {
    "name": "Idoxuridine",
    "class": "Antiviral",
    "category": "Nucleoside analog",
    "side_effects": "Ocular irritation"
  },
  {
    "name": "Imiquimod",
    "class": "Immunomodulator",
    "category": "Toll-like receptor agonist",
    "side_effects": "Local skin reactions"
  },
  {
    "name": "Indomethacin",
    "class": "Non-Steroidal Anti-Inflammatory Drug (NSAID)",
    "category": "NSAID",
    "side_effects": "Gastrointestinal upset, renal impairment, liver enzyme elevation"
  },
  {
    "name": "Injectable Anesthetics (e.g., Ketamine, Xylazine, Telazol)",
    "class": "Anesthetic",
    "category": "Various",
    "side_effects": "Vary depending on the specific anesthetic used"
  },
  {
    "name": "Intravenous Lipid Emulsion",
    "class": "Detoxification Agent",
    "category": "Fat emulsion",
    "side_effects": "Generally well-tolerated"
  },
  {
    "name": "Interleukin-2 (IL-2)",
    "class": "Immunomodulator",
    "category": "Cytokine immunomodulator",
    "side_effects": "Capillary leak syndrome, flu-like symptoms"
  },
  {
    "name": "Ipratropium Bromide/Albuterol Sulfate",
    "class": "Bronchodilator",
    "category": "Combination bronchodilator",
    "side_effects": "Vary depending on the component used"
  },
  {
    "name": "Itraconazole",
    "class": "Antifungal",
    "category": "Antifungal (azole)",
    "side_effects": "Gastrointestinal upset, potential for hepatotoxicity"
  },
  {
    "name": "Isosorbide Mononitrate",
    "class": "Vasodilator",
    "category": "Vasodilator",
    "side_effects": "Hypotension, tachycardia"
  },
  {
    "name": "Interleukin-10 (IL-10)",
    "class": "Immunomodulator",
    "category": "Cytokine immunomodulator",
    "side_effects": "Generally well-tolerated"
  },
  {
    "name": "Isoxsuprine",
    "class": "Vasodilator",
    "category": "Vasodilator",
    "side_effects": "Hypotension, colic (in horses)"
  },
  {
    "name": "Injectable Opioids (e.g., Morphine, Hydromorphone)",
    "class": "Opioid Analgesic",
    "category": "Opioid analgesic",
    "side_effects": "Sedation, respiratory depression, potential for constipation"
  },
  {
    "name": "Ibuprofen/Famotidine",
    "class": "Non-Steroidal Anti-Inflammatory Drug (NSAID)/Gastric Protectant",
    "category": "Combination medication",
    "side_effects": "Vary depending on the component used"
  },
  {
    "name": "Isosorbide Dinitrate/Hydralazine",
    "class": "Vasodilator",
    "category": "Combination vasodilator",
    "side_effects": "Hypotension, tachycardia"
  },
  {
    "name": "Itraconazole/Fluconazole",
    "class": "Antifungal",
    "category": "Combination antifungal",
    "side_effects": "Vary depending on the component used"
  },
  {
    "name": "Insulin",
    "class": "Hormone",
    "category": "Hormone",
    "side_effects": "Hypoglycemia, insulin resistance"
  },
  {
    "name": "Isosorbide Dinitrate/Phenylephrine",
    "class": "Vasodilator/Decongestant",
    "category": "Combination medication",
    "side_effects": "Hypotension, tachycardia"
  },
  {
    "name": "Itraconazole/Ketoconazole",
    "class": "Antifungal",
    "category": "Combination antifungal",
    "side_effects": "Vary depending on the component used"
  },
  {
    "name": "Ivermectin/Pyrantel",
    "class": "Antiparasitic/Anthelmintic",
    "category": "Combination antiparasitic/anthelmintic",
    "side_effects": "Vary depending on the component used"
  },
  {
    "name": "Ketamine",
    "class": "Injectable Anesthetic",
    "category": "Dissociative anesthetic",
    "side_effects": "Emergence delirium, increased heart rate, respiratory depression"
  },
  {
    "name": "Ketoconazole",
    "class": "Antifungal",
    "category": "Azole antifungal",
    "side_effects": "Gastrointestinal upset, potential for hepatotoxicity"
  },
  {
    "name": "Ketorolac",
    "class": "Non-Steroidal Anti-Inflammatory Drug (NSAID)",
    "category": "NSAID",
    "side_effects": "Gastrointestinal upset, renal impairment, potential for gastrointestinal ulceration"
  },
  {
    "name": "Ketoprofen",
    "class": "Non-Steroidal Anti-Inflammatory Drug (NSAID)",
    "category": "NSAID",
    "side_effects": "Gastrointestinal upset, renal impairment, potential for gastrointestinal ulceration"
  },
  {
    "name": "Ketocid (Ketoprofen/Lidocaine)",
    "class": "NSAID/Local Anesthetic Combination",
    "category": "Combination medication",
    "side_effects": "Vary depending on the component used"
  },
  {
    "name": "Ketamine/Midazolam",
    "class": "Injectable Anesthetic",
    "category": "Combination anesthetic",
    "side_effects": "Vary depending on the component used"
  },
  {
    "name": "Ketoconazole/Chlorhexidine (Malaseb)",
    "class": "Antifungal/Antibacterial",
    "category": "Combination antifungal/antibacterial",
    "side_effects": "Vary depending on the component used"
  },
  {
    "name": "Ketamine/Xylazine (Ketazil)",
    "class": "Injectable Anesthetic",
    "category": "Combination anesthetic",
    "side_effects": "Vary depending on the component used"
  },
  {
    "name": "Ketamine/Diazepam",
    "class": "Injectable Anesthetic",
    "category": "Combination anesthetic",
    "side_effects": "Vary depending on the component used"
  },
  {
    "name": "Ketamine/Valium (KetaVed)",
    "class": "Injectable Anesthetic",
    "category": "Combination anesthetic",
    "side_effects": "Vary depending on the component used"
  },
  {
    "name": "Ketamine/Diazepam/Butorphanol (KDB)",
    "class": "Injectable Anesthetic/Analgesic Combination",
    "category": "Combination medication",
    "side_effects": "Vary depending on the component used"
  },
  {
    "name": "Ketoprofen/Butorphanol (Torbugesic-SA)",
    "class": "NSAID/Analgesic Combination",
    "category": "Combination medication",
    "side_effects": "Vary depending on the component used"
  },
  {
    "name": "Ketamine/Medetomidine",
    "class": "Injectable Anesthetic",
    "category": "Combination anesthetic",
    "side_effects": "Vary depending on the component used"
  },
  {
    "name": "Ketamine/Bupivacaine (KB)",
    "class": "Injectable Anesthetic/Analgesic Combination",
    "category": "Combination medication",
    "side_effects": "Vary depending on the component used"
  },
  {
    "name": "Ketoprofen/Omeprazole (KetoGard)",
    "class": "NSAID/Gastric Protectant",
    "category": "Combination medication",
    "side_effects": "Vary depending on the component used"
  },
  {
    "name": "Ketoprofen/Phenylbutazone (Equioxx)",
    "class": "NSAID",
    "category": "NSAID",
    "side_effects": "Gastrointestinal upset, renal impairment, potential for gastrointestinal ulceration"
  },
  {
    "name": "Ketorolac/Ondansetron (Torbugesic-SA)",
    "class": "NSAID/Antiemetic Combination",
    "category": "Combination medication",
    "side_effects": "Vary depending on the component used"
  },
  {
    "name": "Ketamine/Medetomidine/Butorphanol (KMB)",
    "class": "Injectable Anesthetic/Analgesic Combination",
    "category": "Combination medication",
    "side_effects": "Vary depending on the component used"
  },
  {
    "name": "Ketorolac/Morphine",
    "class": "NSAID/Opioid Analgesic Combination",
    "category": "Combination medication",
    "side_effects": "Vary depending on the component used"
  },
  {
    "name": "Ketamine/Valium/Morphine (KVM)",
    "class": "Injectable Anesthetic/Analgesic Combination",
    "category": "Combination medication",
    "side_effects": "Vary depending on the component used"
  },
  {
    "name": "Lactulose",
    "class": "Laxative",
    "category": "Osmotic laxative",
    "side_effects": "Diarrhea, flatulence"
  },
  {
    "name": "Lactated Ringer's Solution",
    "class": "Fluid Supplement",
    "category": "Crystalloid solution",
    "side_effects": "Generally well-tolerated"
  },
  {
    "name": "Levamisole",
    "class": "Anthelmintic/Immunomodulator",
    "category": "Anthelmintic/Immunomodulator",
    "side_effects": "Gastrointestinal upset, hypersensitivity reactions"
  },
  {
    "name": "Levothyroxine",
    "class": "Thyroid Hormone Supplement",
    "category": "Thyroid hormone supplement",
    "side_effects": "Hyperthyroidism if overdosed"
  },
  {
    "name": "Lidocaine",
    "class": "Local Anesthetic/Antiarrhythmic",
    "category": "Amide local anesthetic",
    "side_effects": "Local irritation, potential for systemic toxicity"
  },
  {
    "name": "Lincomycin",
    "class": "Antibiotic",
    "category": "Lincosamide antibiotic",
    "side_effects": "Gastrointestinal upset, potential for antibiotic-associated colitis"
  },
  {
    "name": "Lufenuron",
    "class": "Insect Growth Regulator",
    "category": "Chitin synthesis inhibitor",
    "side_effects": "Rare, may include gastrointestinal upset"
  },
  {
    "name": "Lysine",
    "class": "Nutritional Supplement",
    "category": "Amino acid supplement",
    "side_effects": "Generally well-tolerated"
  },
  {
    "name": "Lactobacillus",
    "class": "Probiotic",
    "category": "Probiotic",
    "side_effects": "Generally well-tolerated"
  },
  {
    "name": "Lomustine",
    "class": "Chemotherapeutic Agent",
    "category": "Alkylating agent",
    "side_effects": "Bone marrow suppression, gastrointestinal upset"
  },
  {
    "name": "Lubricant Eye Drops (e.g., Lacri-Lube)",
    "class": "Ophthalmic Lubricant",
    "category": "Ophthalmic lubricant",
    "side_effects": "Rare, may include eye irritation"
  },
  {
    "name": "Levetiracetam",
    "class": "Anticonvulsant",
    "category": "Anticonvulsant",
    "side_effects": "Sedation, ataxia"
  },
  {
    "name": "Lidocaine/Prilocaine (EMLA Cream)",
    "class": "Topical Anesthetic",
    "category": "Topical anesthetic",
    "side_effects": "Local skin reactions"
  },
  {
    "name": "Lisinopril",
    "class": "Angiotensin-Converting Enzyme (ACE) Inhibitor",
    "category": "ACE inhibitor",
    "side_effects": "Hypotension, renal impairment"
  },
  {
    "name": "Lactulose/Mannitol",
    "class": "Osmotic Diuretic/Laxative",
    "category": "Combination medication",
    "side_effects": "Diarrhea, flatulence, diuresis"
  },
  {
    "name": "Levamisole/Praziquantel",
    "class": "Anthelmintic Combination",
    "category": "Combination anthelmintic",
    "side_effects": "Gastrointestinal upset, hypersensitivity reactions"
  },
  {
    "name": "Lidocaine/Bupivacaine",
    "class": "Local Anesthetic",
    "category": "Combination local anesthetic",
    "side_effects": "Local irritation, potential for systemic toxicity"
  },
  {
    "name": "Lubricating Jelly (e.g., K-Y Jelly)",
    "class": "Lubricant",
    "category": "Lubricant",
    "side_effects": "Generally well-tolerated"
  },
  {
    "name": "Loratadine",
    "class": "Antihistamine",
    "category": "H1 receptor antagonist",
    "side_effects": "Sedation, dry mouth"
  },
  {
    "name": "Lysine/Hydrochloride",
    "class": "Nutritional Supplement",
    "category": "Amino acid supplement",
    "side_effects": "Generally well-tolerated"
  },
  {
    "name": "Levothyroxine/Liothyronine",
    "class": "Thyroid Hormone Supplement",
    "category": "Combination thyroid hormone supplement",
    "side_effects": "Hyperthyroidism if overdosed"
  },
  {
    "name": "Lactase Enzyme",
    "class": "Digestive Supplement",
    "category": "Digestive supplement",
    "side_effects": "Generally well-tolerated"
  },
  {
    "name": "Liothyronine",
    "class": "Thyroid Hormone Supplement",
    "category": "Thyroid hormone supplement",
    "side_effects": "Hyperthyroidism if overdosed"
  },
  {
    "name": "Levetiracetam Extended-Release",
    "class": "Anticonvulsant",
    "category": "Anticonvulsant",
    "side_effects": "Sedation, ataxia"
  },
  {
    "name": "Leflunomide",
    "class": "Immunosuppressant",
    "category": "Immunosuppressant",
    "side_effects": "Gastrointestinal upset, potential for bone marrow suppression"
  },
  {
    "name": "Meloxicam",
    "class": "Non-Steroidal Anti-Inflammatory Drug (NSAID)",
    "category": "NSAID",
    "side_effects": "Gastrointestinal upset, renal impairment, potential for gastrointestinal ulceration"
  },
  {
    "name": "Methocarbamol",
    "class": "Muscle Relaxant",
    "category": "Muscle relaxant",
    "side_effects": "Sedation, ataxia"
  },
  {
    "name": "Metronidazole",
    "class": "Antibiotic/Antiprotozoal",
    "category": "Nitroimidazole antibiotic",
    "side_effects": "Gastrointestinal upset, neurotoxicity (in high doses)"
  },
  {
    "name": "Mirtazapine",
    "class": "Appetite Stimulant/Antidepressant",
    "category": "Noradrenergic and specific serotonergic antidepressant (NaSSA)",
    "side_effects": "Sedation, increased appetite"
  },
  {
    "name": "Milbemycin Oxime",
    "class": "Antiparasitic",
    "category": "Macrocyclic lactone",
    "side_effects": "Gastrointestinal upset, neurotoxicity (in high doses)"
  },
  {
    "name": "Methotrexate",
    "class": "Chemotherapeutic Agent/Immunosuppressant",
    "category": "Antimetabolite",
    "side_effects": "Bone marrow suppression, gastrointestinal upset"
  },
  {
    "name": "Maropitant",
    "class": "Antiemetic",
    "category": "Neurokinin-1 (NK1) receptor antagonist",
    "side_effects": "Decreased appetite, vomiting"
  },
  {
    "name": "Miconazole",
    "class": "Antifungal",
    "category": "Imidazole antifungal",
    "side_effects": "Rare, may include skin irritation"
  },
  {
    "name": "Mitotane",
    "class": "Adrenocortical Suppressant",
    "category": "Adrenocortical suppressant",
    "side_effects": "Gastrointestinal upset, lethargy"
  },
  {
    "name": "Moxidectin",
    "class": "Antiparasitic",
    "category": "Macrocyclic lactone",
    "side_effects": "Gastrointestinal upset, neurotoxicity (in high doses)"
  },
  {
    "name": "Melatonin",
    "class": "Hormone/Supplement",
    "category": "Hormone/supplement",
    "side_effects": "Rare, may include mild sedation"
  },
  {
    "name": "Methadone",
    "class": "Opioid Analgesic",
    "category": "Opioid analgesic",
    "side_effects": "Sedation, respiratory depression"
  },
  {
    "name": "Methimazole",
    "class": "Antithyroid Agent",
    "category": "Antithyroid agent",
    "side_effects": "Gastrointestinal upset, potential for bone marrow suppression"
  },
  {
    "name": "Metoclopramide",
    "class": "Gastrointestinal Prokinetic",
    "category": "Dopamine receptor antagonist",
    "side_effects": "Restlessness, gastrointestinal upset"
  },
  {
    "name": "Monoamine Oxidase Inhibitors (e.g., Selegiline)",
    "class": "Neurotransmitter Modulator",
    "category": "Monoamine oxidase inhibitor",
    "side_effects": "Restlessness, gastrointestinal upset"
  },
  {
    "name": "Mupirocin",
    "class": "Antibiotic",
    "category": "Topical antibiotic",
    "side_effects": "Rare, may include skin irritation"
  },
  {
    "name": "Monoclonal Antibodies (e.g., Atopica)",
    "class": "Immunomodulator",
    "category": "Immunomodulator",
    "side_effects": "Vary depending on the specific antibody used"
  },
  {
    "name": "Mepivacaine",
    "class": "Local Anesthetic",
    "category": "Amide local anesthetic",
    "side_effects": "Local irritation, potential for systemic toxicity"
  },
  {
    "name": "Methazolamide",
    "class": "Carbonic Anhydrase Inhibitor",
    "category": "Carbonic anhydrase inhibitor",
    "side_effects": "Gastrointestinal upset, polyuria"
  },
  {
    "name": "Mitoxantrone",
    "class": "Chemotherapeutic Agent",
    "category": "Anthracenedione antineoplastic",
    "side_effects": "Bone marrow suppression, gastrointestinal upset"
  },
  {
    "name": "Melarsomine",
    "class": "Adulticidal Heartworm Treatment",
    "category": "Organic arsenical",
    "side_effects": "Pain at injection site, potential for thromboembolism"
  },
  {
    "name": "Methylnaltrexone",
    "class": "Opioid Receptor Antagonist",
    "category": "Opioid receptor antagonist",
    "side_effects": "Rare, may include abdominal pain"
  },
  {
    "name": "Mepolizumab",
    "class": "Monoclonal Antibody",
    "category": "Monoclonal antibody",
    "side_effects": "Vary depending on the specific antibody used"
  },
  {
    "name": "Mesalamine",
    "class": "Anti-Inflammatory",
    "category": "Aminosalicylate anti-inflammatory",
    "side_effects": "Gastrointestinal upset, potential for bone marrow suppression"
  },
  {
    "name": "Moxidectin/Imidacloprid (Advantage Multi)",
    "class": "Antiparasitic/Insecticide Combination",
    "category": "Combination antiparasitic/insecticide",
    "side_effects": "Vary depending on the component used"
  },
  {
    "name": "Naproxen",
    "class": "Non-Steroidal Anti-Inflammatory Drug (NSAID)",
    "category": "NSAID",
    "side_effects": "Gastrointestinal upset, renal impairment, potential for gastrointestinal ulceration"
  },
  {
    "name": "Neomycin",
    "class": "Antibiotic",
    "category": "Aminoglycoside antibiotic",
    "side_effects": "Nephrotoxicity, ototoxicity"
  },
  {
    "name": "Nitenpyram",
    "class": "Insecticide",
    "category": "Neonicotinoid insecticide",
    "side_effects": "Rare, may include gastrointestinal upset"
  },
  {
    "name": "Nitrofurantoin",
    "class": "Antibiotic",
    "category": "Nitrofuran antibiotic",
    "side_effects": "Gastrointestinal upset, potential for hepatotoxicity"
  },
  {
    "name": "Nystatin",
    "class": "Antifungal",
    "category": "Polyene antifungal",
    "side_effects": "Rare, may include gastrointestinal upset"
  },
  {
    "name": "Nifedipine",
    "class": "Calcium Channel Blocker",
    "category": "Dihydropyridine calcium channel blocker",
    "side_effects": "Hypotension, potential for reflex tachycardia"
  },
  {
    "name": "Naloxone",
    "class": "Opioid Receptor Antagonist",
    "category": "Opioid receptor antagonist",
    "side_effects": "Rare, may include increased heart rate, hypertension"
  },
  {
    "name": "Nandrolone",
    "class": "Anabolic Steroid",
    "category": "Anabolic steroid",
    "side_effects": "Hepatotoxicity, potential for endocrine disturbances"
  },
  {
    "name": "Naproxen/Esomeprazole (Vimovo)",
    "class": "NSAID/Proton Pump Inhibitor Combination",
    "category": "Combination medication",
    "side_effects": "Vary depending on the component used"
  },
  {
    "name": "Nifedipine/Atenolol (Nitrocard)",
    "class": "Calcium Channel Blocker/Beta-Blocker Combination",
    "category": "Combination medication",
    "side_effects": "Vary depending on the component used"
  },
  {
    "name": "Nitenpyram/Lufenuron (Program)",
    "class": "Insecticide/Insect Growth Regulator Combination",
    "category": "Combination medication",
    "side_effects": "Vary depending on the component used"
  },
  {
    "name": "Naproxen/Sumatriptan (Treximet)",
    "class": "NSAID/Triptan Combination",
    "category": "Combination medication",
    "side_effects": "Vary depending on the component used"
  },
  {
    "name": "Naproxen/Pseudoephedrine (Treximet)",
    "class": "NSAID/Decongestant Combination",
    "category": "Combination medication",
    "side_effects": "Vary depending on the component used"
  },
  {
    "name": "Nitenpyram/Pramoxine (Comfortis Plus)",
    "class": "Insecticide/Local Anesthetic Combination",
    "category": "Combination medication",
    "side_effects": "Vary depending on the component used"
  },
  {
    "name": "Naphazoline/Pheniramine (Naphcon-A)",
    "class": "Ophthalmic Decongestant/Antihistamine Combination",
    "category": "Combination medication",
    "side_effects": "Vary depending on the component used"
  },
  {
    "name": "Nepafenac",
    "class": "Non-Steroidal Anti-Inflammatory Drug (NSAID)",
    "category": "NSAID",
    "side_effects": "Ocular irritation, potential for corneal effects"
  },
  {
    "name": "Nandrolone/Phenylpropionate (Durabolin)",
    "class": "Anabolic Steroid",
    "category": "Anabolic steroid",
    "side_effects": "Hepatotoxicity, potential for endocrine disturbances"
  },
  {
    "name": "Naratriptan",
    "class": "Triptan",
    "category": "Triptan",
    "side_effects": "Chest pain, dizziness"
  },
  {
    "name": "Naphazoline/Phenylephrine (Naphcon Forte)",
    "class": "Ophthalmic Decongestant/Mydriatic Combination",
    "category": "Combination medication",
    "side_effects": "Vary depending on the component used"
  },
  {
    "name": "Nifedipine/Enalapril (Enacard)",
    "class": "Calcium Channel Blocker/Angiotensin-Converting Enzyme (ACE) Inhibitor Combination",
    "category": "Combination medication",
    "side_effects": "Vary depending on the component used"
  },
  {
    "name": "Nitroglycerin",
    "class": "Vasodilator",
    "category": "Nitrate vasodilator",
    "side_effects": "Hypotension, reflex tachycardia"
  },
  {
    "name": "Naphazoline/Chlorpheniramine (Naphcon-A)",
    "class": "Ophthalmic Decongestant/Antihistamine Combination",
    "category": "Combination medication",
    "side_effects": "Vary depending on the component used"
  },
  {
    "name": "Naproxen/Diphenhydramine (Aleve PM)",
    "class": "NSAID/Antihistamine Combination",
    "category": "Combination medication",
    "side_effects": "Vary depending on the component used"
  },
  {
    "name": "Nalbuphine",
    "class": "Opioid Analgesic",
    "category": "Opioid analgesic",
    "side_effects": "Sedation, respiratory depression"
  },
  {
    "name": "Nabumetone",
    "class": "Non-Steroidal Anti-Inflammatory Drug (NSAID)",
    "category": "NSAID",
    "side_effects": "Gastrointestinal upset, renal impairment, potential for gastrointestinal ulceration"
  },
  {
    "name": "Omeprazole",
    "class": "Proton Pump Inhibitor",
    "category": "Proton pump inhibitor",
    "side_effects": "Rare, may include gastrointestinal upset"
  },
  {
    "name": "Orbifloxacin",
    "class": "Antibiotic (Fluoroquinolone)",
    "category": "Fluoroquinolone antibiotic",
    "side_effects": "Gastrointestinal upset, potential for joint cartilage damage in young animals"
  },
  {
    "name": "Oclacitinib",
    "class": "Janus Kinase (JAK) Inhibitor",
    "category": "JAK inhibitor",
    "side_effects": "Diarrhea, vomiting"
  },
  {
    "name": "Oseltamivir",
    "class": "Antiviral (Neuraminidase Inhibitor)",
    "category": "Neuraminidase inhibitor",
    "side_effects": "Gastrointestinal upset, potential for neuropsychiatric events (in humans)"
  },
  {
    "name": "Oxytetracycline",
    "class": "Antibiotic (Tetracycline)",
    "category": "Tetracycline antibiotic",
    "side_effects": "Gastrointestinal upset, potential for tooth discoloration in young animals"
  },
  {
    "name": "Ondansetron",
    "class": "Antiemetic (5-HT3 Receptor Antagonist)",
    "category": "5-HT3 receptor antagonist",
    "side_effects": "Constipation, headache"
  },
  {
    "name": "Oral Contraceptives (e.g., Megestrol Acetate)",
    "class": "Hormonal Contraceptive",
    "category": "Hormonal contraceptive",
    "side_effects": "Vary depending on the specific contraceptive used"
  },
  {
    "name": "Oxymorphone",
    "class": "Opioid Analgesic",
    "category": "Opioid analgesic",
    "side_effects": "Sedation, respiratory depression"
  },
  {
    "name": "Oxazepam",
    "class": "Benzodiazepine (Anxiolytic)",
    "category": "Benzodiazepine anxiolytic",
    "side_effects": "Sedation, potential for paradoxical reactions"
  },
  {
    "name": "Olopatadine",
    "class": "Ophthalmic Antihistamine/Mast Cell Stabilizer",
    "category": "Ophthalmic antihistamine/mast cell stabilizer",
    "side_effects": "Ocular irritation, headache"
  },
  {
    "name": "Oseltamivir/Peramivir (Rapivab)",
    "class": "Antiviral (Neuraminidase Inhibitor) Combination",
    "category": "Combination antiviral",
    "side_effects": "Vary depending on the component used"
  },
  {
    "name": "Oseltamivir/Zanamivir (Relenza)",
    "class": "Antiviral (Neuraminidase Inhibitor) Combination",
    "category": "Combination antiviral",
    "side_effects": "Vary depending on the component used"
  },
  {
    "name": "Oxacillin",
    "class": "Antibiotic (Beta-Lactam)",
    "category": "Beta-lactam antibiotic",
    "side_effects": "Hypersensitivity reactions, gastrointestinal upset"
  },
  {
    "name": "Oxymetazoline",
    "class": "Nasal Decongestant",
    "category": "Alpha-adrenergic agonist",
    "side_effects": "Rebound congestion, nasal irritation"
  },
  {
    "name": "Oxymetazoline/Antazoline (Opti-Clear)",
    "class": "Ophthalmic Decongestant/Antihistamine Combination",
    "category": "Combination medication",
    "side_effects": "Vary depending on the component used"
  },
  {
    "name": "Oxytetracycline/Prednisolone (Terramycin with Polymyxin B)",
    "class": "Antibiotic/Corticosteroid Combination",
    "category": "Combination medication",
    "side_effects": "Vary depending on the component used"
  },
  {
    "name": "Oxymetazoline/Dexpanthenol (Clear Eyes Complete)",
    "class": "Ophthalmic Decongestant/Emollient Combination",
    "category": "Combination medication",
    "side_effects": "Vary depending on the component used"
  },
  {
    "name": "Oxycodone",
    "class": "Opioid Analgesic",
    "category": "Opioid analgesic",
    "side_effects": "Sedation, respiratory depression"
  },
  {
    "name": "Oxymetazoline/Antihistamine (Zicam)",
    "class": "Nasal Decongestant/Antihistamine Combination",
    "category": "Combination medication",
    "side_effects": "Vary depending on the component used"
  },
  {
    "name": "Olopatadine/Hydrochloride",
    "brand": "Pataday",
    "class": "Ophthalmic Antihistamine/Mast Cell Stabilizer Combination",
    "category": "Combination medication",
    "side_effects": "Vary depending on the component used"
  },
  {
    "name": "Oxymorphone/Atipamezole",
    "brand": "OxyDex",
    "class": "Opioid Analgesic/Alpha-2 Adrenergic Antagonist Combination",
    "category": "Combination medication",
    "side_effects": "Vary depending on the component used"
  },
  {
    "name": "Oxytetracycline/Flunixin",
    "brand": "Naxcel",
    "class": "Antibiotic/Non-Steroidal Anti-Inflammatory Drug (NSAID) Combination",
    "category": "Combination medication",
    "side_effects": "Vary depending on the component used"
  },
  {
    "name": "Oxytetracycline/Neomycin",
    "brand": "Terramycin",
    "class": "Antibiotic Combination",
    "category": "Combination medication",
    "side_effects": "Vary depending on the component used"
  },
  {
    "name": "Ofloxacin",
    "class": "Antibiotic (Fluoroquinolone)",
    "category": "Fluoroquinolone antibiotic",
    "side_effects": "Gastrointestinal upset, potential for joint cartilage damage in young animals"
  },
  {
    "name": "Oseltamivir/Zanamivir",
    "brand": "Tamiflu",
    "class": "Antiviral (Neuraminidase Inhibitor) Combination",
    "category": "Combination antiviral",
    "side_effects": "Vary depending on the component used"
  },
  {
    "name": "Prednisone",
    "class": "Corticosteroid",
    "category": "Corticosteroid",
    "side_effects": "Increased thirst and urination, gastrointestinal upset, potential for immunosuppression"
  },
  {
    "name": "Phenobarbital",
    "class": "Barbiturate (Anticonvulsant)",
    "category": "Barbiturate anticonvulsant",
    "side_effects": "Sedation, potential for liver enzyme induction"
  },
  {
    "name": "Pimobendan",
    "class": "Inodilator (Phosphodiesterase III Inhibitor)",
    "category": "Phosphodiesterase III inhibitor",
    "side_effects": "Increased heart rate, gastrointestinal upset"
  },
  {
    "name": "Piroxicam",
    "class": "Non-Steroidal Anti-Inflammatory Drug (NSAID)",
    "category": "NSAID",
    "side_effects": "Gastrointestinal upset, renal impairment, potential for gastrointestinal ulceration"
  },
  {
    "name": "Propranolol",
    "class": "Non-Selective Beta-Blocker",
    "category": "Non-selective beta-blocker",
    "side_effects": "Bradycardia, potential for bronchoconstriction"
  },
  {
    "name": "Pradofloxacin",
    "class": "Antibiotic (Fluoroquinolone)",
    "category": "Fluoroquinolone antibiotic",
    "side_effects": "Gastrointestinal upset, potential for joint cartilage damage in young animals"
  },
  {
    "name": "Phenylpropanolamine",
    "class": "Alpha-Adrenergic Agonist",
    "category": "Alpha-adrenergic agonist",
    "side_effects": "Restlessness, potential for increased heart rate"
  },
  {
    "name": "Pentobarbital",
    "class": "Barbiturate (Euthanasia Solution)",
    "category": "Barbiturate",
    "side_effects": "Rapid induction of anesthesia leading to respiratory depression and cardiac arrest (used for euthanasia)"
  },
  {
    "name": "Polymyxin B",
    "class": "Antibiotic (Polypeptide)",
    "category": "Polypeptide antibiotic",
    "side_effects": "Nephrotoxicity, potential for neurotoxicity"
  },
  {
    "name": "Penicillin",
    "class": "Antibiotic (Beta-Lactam)",
    "category": "Beta-lactam antibiotic",
    "side_effects": "Hypersensitivity reactions, gastrointestinal upset"
  },
  {
    "name": "Praziquantel",
    "class": "Antiparasitic (Anthelmintic)",
    "category": "Anthelmintic",
    "side_effects": "Vomiting, lethargy"
  },
  {
    "name": "Propofol",
    "class": "Intravenous Anesthetic",
    "category": "General anesthetic",
    "side_effects": "Respiratory depression, potential for hypotension"
  },
  {
    "name": "Pancuronium",
    "class": "Neuromuscular Blocking Agent",
    "category": "Non-depolarizing neuromuscular blocker",
    "side_effects": "Respiratory paralysis, potential for prolonged neuromuscular blockade"
  },
  {
    "name": "Pentosan Polysulfate Sodium",
    "class": "Disease-Modifying Osteoarthritis Drug (DMOAD)",
    "category": "DMOAD",
    "side_effects": "Gastrointestinal upset, bleeding disorders (rare)"
  },
  {
    "name": "Phenytoin",
    "class": "Anticonvulsant (Hydantoin)",
    "category": "Hydantoin anticonvulsant",
    "side_effects": "Sedation, potential for gingival hyperplasia"
  },
  {
    "name": "Procaine Penicillin",
    "class": "Antibiotic (Beta-Lactam)",
    "category": "Beta-lactam antibiotic",
    "side_effects": "Hypersensitivity reactions, gastrointestinal upset"
  },
  {
    "name": "Permethrin",
    "class": "Insecticide",
    "category": "Pyrethroid insecticide",
    "side_effects": "Rare, may include skin irritation"
  },
  {
    "name": "Pipobroman",
    "class": "Chemotherapeutic Agent (Alkylating Agent)",
    "category": "Alkylating agent",
    "side_effects": "Bone marrow suppression, gastrointestinal upset"
  },
  {
    "name": "Pentoxifylline",
    "class": "Hemorheologic Agent",
    "category": "Hemorheologic agent",
    "side_effects": "Gastrointestinal upset, potential for bleeding disorders"
  },
  {
    "name": "Piroxicam/Misoprostol",
    "brand": "Feldene",
    "class": "NSAID/Prostaglandin E1 Analog Combination",
    "category": "Combination medication",
    "side_effects": "Vary depending on the component used"
  },
  {
    "name": "Pentazocine",
    "class": "Opioid Analgesic",
    "category": "Opioid analgesic",
    "side_effects": "Sedation, potential for respiratory depression"
  },
  {
    "name": "Prazosin",
    "class": "Alpha-1 Adrenergic Antagonist",
    "category": "Alpha-1 adrenergic antagonist",
    "side_effects": "Hypotension, potential for reflex tachycardia"
  },
  {
    "name": "Pentoxifylline/Alpha-Tocopherol",
    "brand": "Pentoflux",
    "class": "Hemorheologic Agent/Antioxidant Combination",
    "category": "Combination medication",
    "side_effects": "Vary depending on the component used"
  },
  {
    "name": "Pirlimycin",
    "class": "Antibiotic (Lincosamide)",
    "category": "Lincosamide antibiotic",
    "side_effects": "Gastrointestinal upset, potential for allergic reactions"
  },
  {
    "name": "Pipemidic Acid",
    "class": "Antibiotic (Quinolone)",
    "category": "Quinolone antibiotic",
    "side_effects": "Gastrointestinal upset, potential for joint cartilage damage in young animals"
  },
  {
    "name": "Quinidine",
    "class": "Antiarrhythmic",
    "category": "Class I antiarrhythmic",
    "side_effects": "Cinchonism (nausea, vomiting, tinnitus), potential for proarrhythmia"
  },
  {
    "name": "Quinacrine",
    "class": "Antiparasitic (Aminoacridine)",
    "category": "Aminoacridine antiparasitic",
    "side_effects": "Gastrointestinal upset, potential for skin reactions"
  },
  {
    "name": "Quetiapine",
    "class": "Atypical Antipsychotic",
    "category": "Atypical antipsychotic",
    "side_effects": "Sedation, weight gain"
  },
  {
    "name": "Quinupristin/Dalfopristin",
    "class": "Antibiotic (Streptogramin)",
    "category": "Streptogramin antibiotic",
    "side_effects": "Pain at the injection site, potential for infusion-related reactions"
  },
  {
    "name": "Quinolones (e.g. Enrofloxacin)",
    "class": "Antibiotic (Quinolone)",
    "category": "Quinolone antibiotic",
    "side_effects": "Gastrointestinal upset, potential for joint cartilage damage in young animals"
  },
  {
    "name": "Quazepam",
    "class": "Benzodiazepine (Sedative-Hypnotic)",
    "category": "Benzodiazepine sedative-hypnotic",
    "side_effects": "Sedation, potential for paradoxical reactions"
  },
  {
    "name": "Quinacrine",
    "brand": "Atabrine",
    "class": "Antiparasitic (Aminoacridine)",
    "category": "Aminoacridine antiparasitic",
    "side_effects": "Gastrointestinal upset, potential for skin reactions"
  },
  {
    "name": "Quinapril",
    "class": "Angiotensin-Converting Enzyme (ACE) Inhibitor",
    "category": "ACE inhibitor",
    "side_effects": "Hypotension, potential for renal dysfunction"
  },
  {
    "name": "Quetiapine",
    "brand": "Seroquel",
    "class": "Atypical Antipsychotic",
    "category": "Atypical antipsychotic",
    "side_effects": "Sedation, weight gain"
  },
  {
    "name": "Qinghaosu",
    "alternative_name": "Artemisinin",
    "class": "Antiparasitic (Sesquiterpene Lactone)",
    "category": "Sesquiterpene lactone antiparasitic",
    "side_effects": "Vomiting, potential for neurotoxicity"
  },
  {
    "name": "Quinaldine",
    "class": "Antiseptic",
    "category": "Antiseptic",
    "side_effects": "Irritation, potential for skin sensitization"
  },
  {
    "name": "Quipazine",
    "class": "Serotonin Receptor Agonist",
    "category": "Serotonin receptor agonist",
    "side_effects": "Behavioral changes, potential for gastrointestinal upset"
  },
  {
    "name": "Q-Vax",
    "class": "Vaccine (Q Fever Vaccine)",
    "category": "Vaccine",
    "side_effects": "Vary depending on the individual and vaccination response"
  },
  {
    "name": "Quinidine Sulfate",
    "brand": "Quinaglute",
    "class": "Antiarrhythmic",
    "category": "Class I antiarrhythmic",
    "side_effects": "Cinchonism (nausea, vomiting, tinnitus), potential for proarrhythmia"
  },
  {
    "name": "Quinestrol",
    "class": "Hormonal Supplement (Estrogen)",
    "category": "Estrogen hormonal supplement",
    "side_effects": "Vary depending on the individual and hormonal balance"
  },
  {
    "name": "Quazepam",
    "brand": "Doral",
    "class": "Benzodiazepine (Sedative-Hypnotic)",
    "category": "Benzodiazepine sedative-hypnotic",
    "side_effects": "Sedation, potential for paradoxical reactions"
  },
  {
    "name": "Quinapril",
    "brand": "Accupril",
    "class": "Angiotensin-Converting Enzyme (ACE) Inhibitor",
    "category": "ACE inhibitor",
    "side_effects": "Hypotension, potential for renal dysfunction"
  },
  {
    "name": "Quinine",
    "class": "Antiparasitic (Alkaloid)",
    "category": "Alkaloid antiparasitic",
    "side_effects": "Cinchonism (nausea, vomiting, tinnitus), potential for hypoglycemia"
  },
  {
    "name": "Quinidine Gluconate",
    "class": "Antiarrhythmic",
    "category": "Class I antiarrhythmic",
    "side_effects": "Cinchonism (nausea, vomiting, tinnitus), potential for proarrhythmia"
  },
  {
    "name": "Quinestrol",
    "brand": "Estrovis",
    "class": "Hormonal Supplement (Estrogen)",
    "category": "Estrogen hormonal supplement",
    "side_effects": "Vary depending on the individual and hormonal balance"
  },    {
        "name": "Rimadyl (Carprofen)",
        "class": "Non-Steroidal Anti-Inflammatory Drug (NSAID)",
        "category": "NSAID",
        "side_effects": "Gastrointestinal upset, potential for renal and hepatic issues"
    },
    {
        "name": "Rimifon (Isoniazid)",
        "class": "Antibiotic (Antitubercular)",
        "category": "Antitubercular antibiotic",
        "side_effects": "Hepatotoxicity, peripheral neuropathy"
    },
    {
        "name": "Robenacoxib (Onsior)",
        "class": "Non-Steroidal Anti-Inflammatory Drug (NSAID)",
        "category": "NSAID",
        "side_effects": "Gastrointestinal upset, potential for renal and hepatic issues"
    },
    {
        "name": "Revolution (Selamectin)",
        "class": "Antiparasitic (Macrocyclic Lactone)",
        "category": "Macrocyclic lactone antiparasitic",
        "side_effects": "Potential for skin irritation, vomiting"
    },
    {
        "name": "Rifampin",
        "class": "Antibiotic (Rifamycin)",
        "category": "Rifamycin antibiotic",
        "side_effects": "Gastrointestinal upset, hepatotoxicity"
    },
    {
        "name": "Ranitidine",
        "class": "Histamine H2 Receptor Antagonist",
        "category": "H2 receptor antagonist",
        "side_effects": "Rare, potential for gastrointestinal upset"
    },
    {
        "name": "Rapamycin (Sirolimus)",
        "class": "Immunosuppressant",
        "category": "Immunosuppressant",
        "side_effects": "Potential for immunosuppression, gastrointestinal upset"
    },
    {
        "name": "Ritodrine",
        "class": "Beta-2 Adrenergic Agonist",
        "category": "Beta-2 adrenergic agonist",
        "side_effects": "Tachycardia, potential for hyperglycemia"
    },
    {
        "name": "Robenacoxib (Simbadol)",
        "class": "Non-Steroidal Anti-Inflammatory Drug (NSAID)",
        "category": "NSAID",
        "side_effects": "Gastrointestinal upset, potential for renal and hepatic issues"
    },
    {
        "name": "Rifampin/Pyrazinamide (Rifater)",
        "class": "Antibiotic Combination (Rifamycin/Pyrazinamide)",
        "category": "Antibiotic combination",
        "side_effects": "Gastrointestinal upset, hepatotoxicity, peripheral neuropathy"
    },
    {
        "name": "Reserpine",
        "class": "Sympatholytic",
        "category": "Sympatholytic",
        "side_effects": "Sedation, potential for hypotension"
    },
    {
        "name": "Ribavirin",
        "class": "Antiviral (Nucleoside Analog)",
        "category": "Nucleoside analog antiviral",
        "side_effects": "Anemia, potential for gastrointestinal upset"
    },
    {
        "name": "Ringer's Solution",
        "class": "Crystalloid Fluid",
        "category": "Crystalloid fluid",
        "side_effects": "Overhydration if administered excessively"
    },
    {
        "name": "Rifampin/Isoniazid (Rifamate)",
        "class": "Antibiotic Combination (Rifamycin/Isoniazid)",
        "category": "Antibiotic combination",
        "side_effects": "Gastrointestinal upset, hepatotoxicity, peripheral neuropathy"
    },
    {
        "name": "Ranitidine (Zantac)",
        "class": "Histamine H2 Receptor Antagonist",
        "category": "H2 receptor antagonist",
        "side_effects": "Rare, potential for gastrointestinal upset"
    },
    {
        "name": "Rocuronium",
        "class": "Neuromuscular Blocking Agent",
        "category": "Non-depolarizing neuromuscular blocker",
        "side_effects": "Respiratory paralysis, potential for prolonged neuromuscular blockade"
    },
    {
        "name": "Rifampin (Rifadin)",
        "class": "Antibiotic (Rifamycin)",
        "category": "Rifamycin antibiotic",
        "side_effects": "Gastrointestinal upset, hepatotoxicity"
    },
    {
        "name": "Ringer's Lactate Solution",
        "class": "Crystalloid Fluid",
        "category": "Crystalloid fluid",
        "side_effects": "Overhydration if administered excessively"
    },
    {
        "name": "Rimadyl (Rimadyl)",
        "class": "Non-Steroidal Anti-Inflammatory Drug (NSAID)",
        "category": "NSAID",
        "side_effects": "Gastrointestinal upset, potential for renal and hepatic issues"
    },
    {
        "name": "Raloxifene",
        "class": "Selective Estrogen Receptor Modulator (SERM)",
        "category": "SERM",
        "side_effects": "Rare, potential for gastrointestinal upset"
    },
    {
        "name": "Simparica (Sarolaner)",
        "class": "Antiparasitic (Isoxazoline)",
        "category": "Isoxazoline antiparasitic",
        "side_effects": "Vomiting, diarrhea"
    },
    {
        "name": "Sulfamethoxazole/Trimethoprim (SMZ-TMP)",
        "class": "Antibiotic (Sulfonamide)",
        "category": "Sulfonamide antibiotic",
        "side_effects": "Hypersensitivity reactions, gastrointestinal upset"
    },
    {
        "name": "Sileo (Dexmedetomidine)",
        "class": "Alpha-2 Adrenergic Agonist",
        "category": "Alpha-2 adrenergic agonist",
        "side_effects": "Sedation, bradycardia"
    },
    {
        "name": "Selegiline",
        "class": "Monoamine Oxidase Inhibitor (MAOI)",
        "category": "MAOI",
        "side_effects": "Gastrointestinal upset, potential for serotonin syndrome"
    },
    {
        "name": "Soloxine (Levothyroxine)",
        "class": "Thyroid Hormone Supplement",
        "category": "Thyroid hormone supplement",
        "side_effects": "Overmedication can lead to hyperthyroidism symptoms"
    },
    {
        "name": "Sentinel (Lufenuron/Milbemycin Oxime)",
        "class": "Antiparasitic (Insect Growth Regulator/Milbemycin)",
        "category": "Insect growth regulator/milbemycin antiparasitic",
        "side_effects": "Vomiting, lethargy"
    },
    {
        "name": "Surolan (Miconazole/Polymyxin B/Prednisolone)",
        "class": "Antifungal/Antibacterial/Corticosteroid Combination",
        "category": "Combination medication",
        "side_effects": "Rare, potential for skin irritation"
    },
    {
        "name": "Spinosad (Comfortis)",
        "class": "Antiparasitic (Spinosyn)",
        "category": "Spinosyn antiparasitic",
        "side_effects": "Vomiting, lethargy"
    },
    {
        "name": "Sucralfate",
        "class": "Gastrointestinal Protectant",
        "category": "Gastrointestinal protectant",
        "side_effects": "Constipation, potential for aluminum toxicity"
    },
    {
        "name": "Stanozolol",
        "class": "Anabolic Steroid",
        "category": "Anabolic steroid",
        "side_effects": "Hepatotoxicity, potential for masculinization"
    },
    {
        "name": "Simplicef (Cefpodoxime Proxetil)",
        "class": "Antibiotic (Cephalosporin)",
        "category": "Cephalosporin antibiotic",
        "side_effects": "Gastrointestinal upset, potential for hypersensitivity reactions"
    },
    {
        "name": "Silver Sulfadiazine",
        "class": "Antibiotic (Sulfonamide)",
        "category": "Sulfonamide antibiotic",
        "side_effects": "Rare, potential for skin reactions"
    },
    {
        "name": "Sotalol",
        "class": "Antiarrhythmic",
        "category": "Class III antiarrhythmic",
        "side_effects": "Bradycardia, potential for proarrhythmia"
    },
    {
        "name": "Selamectin (Revolution)",
        "class": "Antiparasitic (Macrocyclic Lactone)",
        "category": "Macrocyclic lactone antiparasitic",
        "side_effects": "Potential for skin irritation, vomiting"
    },
    {
        "name": "Sarafloxacin",
        "class": "Antibiotic (Fluoroquinolone)",
        "category": "Fluoroquinolone antibiotic",
        "side_effects": "Gastrointestinal upset, potential for joint cartilage damage in young animals"
    },
    {
        "name": "Sucralfate (Carafate)",
        "class": "Gastrointestinal Protectant",
        "category": "Gastrointestinal protectant",
        "side_effects": "Constipation, potential for aluminum toxicity"
    },
    {
        "name": "Selamectin (Stronghold/Revolution)",
        "class": "Antiparasitic (Macrocyclic Lactone)",
        "category": "Macrocyclic lactone antiparasitic",
        "side_effects": "Potential for skin irritation, vomiting"
    },
    {
        "name": "Selegiline (Anipryl)",
        "class": "Monoamine Oxidase Inhibitor (MAOI)",
        "category": "MAOI",
        "side_effects": "Gastrointestinal upset, potential for serotonin syndrome"
    },
    {
        "name": "Sulfasalazine",
        "class": "Anti-Inflammatory (Sulfonamide)",
        "category": "Sulfonamide anti-inflammatory",
        "side_effects": "Gastrointestinal upset, potential for blood dyscrasias"
    },
    {
        "name": "Sodium Chloride Solution",
        "class": "Crystalloid Fluid",
        "category": "Crystalloid fluid",
        "side_effects": "Overhydration if administered excessively"
    },
    {
        "name": "Sevoflurane",
        "class": "Inhalation Anesthetic",
        "category": "Inhalation anesthetic",
        "side_effects": "Respiratory depression, potential for hypotension"
    },
    {
        "name": "Sulfadiazine/Pyrimethamine",
        "class": "Antibiotic Combination (Sulfonamide/Biguanide)",
        "category": "Antibiotic combination",
        "side_effects": "Bone marrow suppression, gastrointestinal upset"
    },
    {
        "name": "Sildenafil",
        "class": "Phosphodiesterase Type 5 Inhibitor",
        "category": "PDE5 inhibitor",
        "side_effects": "Hypotension, potential for gastrointestinal upset"
    },
    {
        "name": "Sertraline",
        "class": "Selective Serotonin Reuptake Inhibitor (SSRI)",
        "category": "SSRI",
        "side_effects": "Gastrointestinal upset, potential for serotonin syndrome"
    },
    {
        "name": "Sotalol (Betapace)",
        "class": "Antiarrhythmic",
        "category": "Class III antiarrhythmic",
        "side_effects": "Bradycardia, potential for proarrhythmia"
    },
    {
        "name": "Trifexis (Spinosad/Milbemycin Oxime)",
        "class": "Antiparasitic (Spinosyn/Milbemycin)",
        "category": "Spinosyn/milbemycin antiparasitic",
        "side_effects": "Vomiting, lethargy"
    },
    {
        "name": "Tetracycline",
        "class": "Antibiotic (Tetracycline)",
        "category": "Tetracycline antibiotic",
        "side_effects": "Gastrointestinal upset, potential for dental staining in young animals"
    },
    {
        "name": "Tramadol",
        "class": "Opioid Analgesic",
        "category": "Opioid analgesic",
        "side_effects": "Sedation, potential for gastrointestinal upset"
    },
    {
        "name": "Torbugesic (Butorphanol)",
        "class": "Opioid Analgesic",
        "category": "Opioid analgesic",
        "side_effects": "Sedation, potential for respiratory depression"
    },
    {
        "name": "Temaril-P (Trimeprazine/Prednisolone)",
        "class": "Antihistamine/Corticosteroid Combination",
        "category": "Combination medication",
        "side_effects": "Polydipsia, potential for immunosuppression"
    },
    {
        "name": "Tolfedine (Tolfenamic Acid)",
        "class": "Non-Steroidal Anti-Inflammatory Drug (NSAID)",
        "category": "NSAID",
        "side_effects": "Gastrointestinal upset, potential for renal and hepatic issues"
    },
    {
        "name": "Telazol (Tiletamine/Zolazepam)",
        "class": "Injectable Anesthetic",
        "category": "Injectable anesthetic",
        "side_effects": "Respiratory depression, potential for cardiovascular depression"
    },
    {
        "name": "Trilostane (Vetoryl)",
        "class": "Adrenal Steroid Synthesis Inhibitor",
        "category": "Adrenal steroid synthesis inhibitor",
        "side_effects": "Polyuria, polydipsia"
    },
    {
        "name": "Theophylline",
        "class": "Bronchodilator",
        "category": "Methylxanthine bronchodilator",
        "side_effects": "Vomiting, potential for gastrointestinal upset"
    },
    {
        "name": "Trazodone",
        "class": "Serotonin Receptor Antagonist and Reuptake Inhibitor (SARI)",
        "category": "SARI",
        "side_effects": "Sedation, potential for gastrointestinal upset"
    },
  {
    "name": "Rimadyl (Carprofen)",
    "class": "Non-Steroidal Anti-Inflammatory Drug (NSAID)",
    "category": "NSAID",
    "side_effects": "Gastrointestinal upset, potential for renal and hepatic issues"
  },
  {
    "name": "Rimifon (Isoniazid)",
    "class": "Antibiotic (Antitubercular)",
    "category": "Antitubercular antibiotic",
    "side_effects": "Hepatotoxicity, peripheral neuropathy"
  },
  {
    "name": "Robenacoxib (Onsior)",
    "class": "Non-Steroidal Anti-Inflammatory Drug (NSAID)",
    "category": "NSAID",
    "side_effects": "Gastrointestinal upset, potential for renal and hepatic issues"
  },
  {
    "name": "Revolution (Selamectin)",
    "class": "Antiparasitic (Macrocyclic Lactone)",
    "category": "Macrocyclic lactone antiparasitic",
    "side_effects": "Potential for skin irritation, vomiting"
  },
  {
    "name": "Rifampin",
    "class": "Antibiotic (Rifamycin)",
    "category": "Rifamycin antibiotic",
    "side_effects": "Gastrointestinal upset, hepatotoxicity"
  },
  {
    "name": "Ranitidine",
    "class": "Histamine H2 Receptor Antagonist",
    "category": "H2 receptor antagonist",
    "side_effects": "Rare, potential for gastrointestinal upset"
  },
  {
    "name": "Rapamycin (Sirolimus)",
    "class": "Immunosuppressant",
    "category": "Immunosuppressant",
    "side_effects": "Potential for immunosuppression, gastrointestinal upset"
  },
  {
    "name": "Ritodrine",
    "class": "Beta-2 Adrenergic Agonist",
    "category": "Beta-2 adrenergic agonist",
    "side_effects": "Tachycardia, potential for hyperglycemia"
  },
  {
    "name": "Robenacoxib (Simbadol)",
    "class": "Non-Steroidal Anti-Inflammatory Drug (NSAID)",
    "category": "NSAID",
    "side_effects": "Gastrointestinal upset, potential for renal and hepatic issues"
  },
  {
    "name": "Rifampin/Pyrazinamide (Rifater)",
    "class": "Antibiotic Combination (Rifamycin/Pyrazinamide)",
    "category": "Antibiotic combination",
    "side_effects": "Gastrointestinal upset, hepatotoxicity, peripheral neuropathy"
  },
  {
    "name": "Reserpine",
    "class": "Sympatholytic",
    "category": "Sympatholytic",
    "side_effects": "Sedation, potential for hypotension"
  },
  {
    "name": "Ribavirin",
    "class": "Antiviral (Nucleoside Analog)",
    "category": "Nucleoside analog antiviral",
    "side_effects": "Anemia, potential for gastrointestinal upset"
  },
  {
    "name": "Ringer's Solution",
    "class": "Crystalloid Fluid",
    "category": "Crystalloid fluid",
    "side_effects": "Overhydration if administered excessively"
  },
  {
    "name": "Rifampin/Isoniazid (Rifamate)",
    "class": "Antibiotic Combination (Rifamycin/Isoniazid)",
    "category": "Antibiotic combination",
    "side_effects": "Gastrointestinal upset, hepatotoxicity, peripheral neuropathy"
  },
  {
    "name": "Ranitidine (Zantac)",
    "class": "Histamine H2 Receptor Antagonist",
    "category": "H2 receptor antagonist",
    "side_effects": "Rare, potential for gastrointestinal upset"
  },
  {
    "name": "Rocuronium",
    "class": "Neuromuscular Blocking Agent",
    "category": "Non-depolarizing neuromuscular blocker",
    "side_effects": "Respiratory paralysis, potential for prolonged neuromuscular blockade"
  },
  {
    "name": "Rifampin (Rifadin)",
    "class": "Antibiotic (Rifamycin)",
    "category": "Rifamycin antibiotic",
    "side_effects": "Gastrointestinal upset, hepatotoxicity"
  },
  {
    "name": "Ringer's Lactate Solution",
    "class": "Crystalloid Fluid",
    "category": "Crystalloid fluid",
    "side_effects": "Overhydration if administered excessively"
  },
  {
    "name": "Rimadyl (Rimadyl)",
    "class": "Non-Steroidal Anti-Inflammatory Drug (NSAID)",
    "category": "NSAID",
    "side_effects": "Gastrointestinal upset, potential for renal and hepatic issues"
  },
  {
    "name": "Raloxifene",
    "class": "Selective Estrogen Receptor Modulator (SERM)",
    "category": "SERM",
    "side_effects": "Rare, potential for gastrointestinal upset"
  },
  {
    "name": "Simparica (Sarolaner)",
    "class": "Antiparasitic (Isoxazoline)",
    "category": "Isoxazoline antiparasitic",
    "side_effects": "Vomiting, diarrhea"
  },
  {
    "name": "Sulfamethoxazole/Trimethoprim (SMZ-TMP)",
    "class": "Antibiotic (Sulfonamide)",
    "category": "Sulfonamide antibiotic",
    "side_effects": "Hypersensitivity reactions, gastrointestinal upset"
  },
  {
    "name": "Sileo (Dexmedetomidine)",
    "class": "Alpha-2 Adrenergic Agonist",
    "category": "Alpha-2 adrenergic agonist",
    "side_effects": "Sedation, bradycardia"
  },
  {
    "name": "Selegiline",
    "class": "Monoamine Oxidase Inhibitor (MAOI)",
    "category": "MAOI",
    "side_effects": "Gastrointestinal upset, potential for serotonin syndrome"
  },
  {
    "name": "Soloxine (Levothyroxine)",
    "class": "Thyroid Hormone Supplement",
    "category": "Thyroid hormone supplement",
    "side_effects": "Overmedication can lead to hyperthyroidism symptoms"
  },
  {
    "name": "Sentinel (Lufenuron/Milbemycin Oxime)",
    "class": "Antiparasitic (Insect Growth Regulator/Milbemycin)",
    "category": "Insect growth regulator/milbemycin antiparasitic",
    "side_effects": "Vomiting, lethargy"
  },
  {
    "name": "Surolan (Miconazole/Polymyxin B/Prednisolone)",
    "class": "Antifungal/Antibacterial/Corticosteroid Combination",
    "category": "Combination medication",
    "side_effects": "Rare, potential for skin irritation"
  },
  {
    "name": "Spinosad (Comfortis)",
    "class": "Antiparasitic (Spinosyn)",
    "category": "Spinosyn antiparasitic",
    "side_effects": "Vomiting, lethargy"
  },
  {
    "name": "Sucralfate",
    "class": "Gastrointestinal Protectant",
    "category": "Gastrointestinal protectant",
    "side_effects": "Constipation, potential for aluminum toxicity"
  },
  {
    "name": "Stanozolol",
    "class": "Anabolic Steroid",
    "category": "Anabolic steroid",
    "side_effects": "Hepatotoxicity, potential for masculinization"
  },
  {
    "name": "Simplicef (Cefpodoxime Proxetil)",
    "class": "Antibiotic (Cephalosporin)",
    "category": "Cephalosporin antibiotic",
    "side_effects": "Gastrointestinal upset, potential for hypersensitivity reactions"
  },
  {
    "name": "Silver Sulfadiazine",
    "class": "Antibiotic (Sulfonamide)",
    "category": "Sulfonamide antibiotic",
    "side_effects": "Rare, potential for skin reactions"
  },
  {
    "name": "Sotalol",
    "class": "Antiarrhythmic",
    "category": "Class III antiarrhythmic",
    "side_effects": "Bradycardia, potential for proarrhythmia"
  },
  {
    "name": "Selamectin (Revolution)",
    "class": "Antiparasitic (Macrocyclic Lactone)",
    "category": "Macrocyclic lactone antiparasitic",
    "side_effects": "Potential for skin irritation, vomiting"
  },
  {
    "name": "Sarafloxacin",
    "class": "Antibiotic (Fluoroquinolone)",
    "category": "Fluoroquinolone antibiotic",
    "side_effects": "Gastrointestinal upset, potential for joint cartilage damage in young animals"
  },
  {
    "name": "Sucralfate (Carafate)",
    "class": "Gastrointestinal Protectant",
    "category": "Gastrointestinal protectant",
    "side_effects": "Constipation, potential for aluminum toxicity"
  },
  {
    "name": "Selamectin (Stronghold/Revolution)",
    "class": "Antiparasitic (Macrocyclic Lactone)",
    "category": "Macrocyclic lactone antiparasitic",
    "side_effects": "Potential for skin irritation, vomiting"
  },
  {
    "name": "Selegiline (Anipryl)",
    "class": "Monoamine Oxidase Inhibitor (MAOI)",
    "category": "MAOI",
    "side_effects": "Gastrointestinal upset, potential for serotonin syndrome"
  },
  {
    "name": "Sulfasalazine",
    "class": "Anti-Inflammatory (Sulfonamide)",
    "category": "Sulfonamide anti-inflammatory",
    "side_effects": "Gastrointestinal upset, potential for blood dyscrasias"
  },
  {
    "name": "Sodium Chloride Solution",
    "class": "Crystalloid Fluid",
    "category": "Crystalloid fluid",
    "side_effects": "Overhydration if administered excessively"
  },
  {
    "name": "Sevoflurane",
    "class": "Inhalation Anesthetic",
    "category": "Inhalation anesthetic",
    "side_effects": "Respiratory depression, potential for hypotension"
  },
  {
    "name": "Sulfadiazine/Pyrimethamine",
    "class": "Antibiotic Combination (Sulfonamide/Biguanide)",
    "category": "Antibiotic combination",
    "side_effects": "Bone marrow suppression, gastrointestinal upset"
  },
  {
    "name": "Sildenafil",
    "class": "Phosphodiesterase Type 5 Inhibitor",
    "category": "PDE5 inhibitor",
    "side_effects": "Hypotension, potential for gastrointestinal upset"
  },
  {
    "name": "Sertraline",
    "class": "Selective Serotonin Reuptake Inhibitor (SSRI)",
    "category": "SSRI",
    "side_effects": "Gastrointestinal upset, potential for serotonin syndrome"
  },
  {
    "name": "Sotalol (Betapace)",
    "class": "Antiarrhythmic",
    "category": "Class III antiarrhythmic",
    "side_effects": "Bradycardia, potential for proarrhythmia"
  },
  {
    "name": "Trifexis (Spinosad/Milbemycin Oxime)",
    "class": "Antiparasitic (Spinosyn/Milbemycin)",
    "category": "Spinosyn/milbemycin antiparasitic",
    "side_effects": "Vomiting, lethargy"
  },
  {
    "name": "Tetracycline",
    "class": "Antibiotic (Tetracycline)",
    "category": "Tetracycline antibiotic",
    "side_effects": "Gastrointestinal upset, potential for dental staining in young animals"
  },
  {
    "name": "Tramadol",
    "class": "Opioid Analgesic",
    "category": "Opioid analgesic",
    "side_effects": "Sedation, potential for gastrointestinal upset"
  },
  {
    "name": "Torbugesic (Butorphanol)",
    "class": "Opioid Analgesic",
    "category": "Opioid analgesic",
    "side_effects": "Sedation, potential for respiratory depression"
  },
  {
    "name": "Temaril-P (Trimeprazine/Prednisolone)",
    "class": "Antihistamine/Corticosteroid Combination",
    "category": "Combination medication",
    "side_effects": "Polydipsia, potential for immunosuppression"
  },
  {
    "name": "Tolfedine (Tolfenamic Acid)",
    "class": "Non-Steroidal Anti-Inflammatory Drug (NSAID)",
    "category": "NSAID",
    "side_effects": "Gastrointestinal upset, potential for renal and hepatic issues"
  },
  {
    "name": "Telazol (Tiletamine/Zolazepam)",
    "class": "Injectable Anesthetic",
    "category": "Injectable anesthetic",
    "side_effects": "Respiratory depression, potential for cardiovascular depression"
  },
  {
    "name": "Trilostane (Vetoryl)",
    "class": "Adrenal Steroid Synthesis Inhibitor",
    "category": "Adrenal steroid synthesis inhibitor",
    "side_effects": "Polyuria, polydipsia"
  },
  {
    "name": "Theophylline",
    "class": "Bronchodilator",
    "category": "Methylxanthine bronchodilator",
    "side_effects": "Vomiting, potential for gastrointestinal upset"
  },
  {
    "name": "Trazodone",
    "class": "Serotonin Receptor Antagonist and Reuptake Inhibitor (SARI)",
    "category": "SARI",
    "side_effects": "Sedation, potential for gastrointestinal upset"
  },
  {
    "name": "Thiopental",
    "class": "Injectable Anesthetic",
    "category": "Barbiturate injectable anesthetic",
    "side_effects": "Respiratory depression, potential for cardiovascular depression"
  },
  {
    "name": "Tobramycin",
    "class": "Antibiotic (Aminoglycoside)",
    "category": "Aminoglycoside antibiotic",
    "side_effects": "Nephrotoxicity, potential for ototoxicity"
  },
  {
    "name": "Tiamulin",
    "class": "Antibiotic (Diterpene)",
    "category": "Diterpene antibiotic",
    "side_effects": "Gastrointestinal upset, potential for injection site reactions"
  },
  {
    "name": "Taurine",
    "class": "Nutritional Supplement",
    "category": "Nutritional supplement",
    "side_effects": "Generally well-tolerated, potential for gastrointestinal upset in excessive amounts"
  },
  {
    "name": "Trilostane",
    "class": "Adrenal Steroid Synthesis Inhibitor",
    "category": "Adrenal steroid synthesis inhibitor",
    "side_effects": "Polyuria, polydipsia",
    "brand": "Modrenal"
  },
  {
    "name": "Trazodone",
    "class": "Serotonin Receptor Antagonist and Reuptake Inhibitor (SARI)",
    "category": "SARI",
    "side_effects": "Sedation, potential for gastrointestinal upset",
    "brand": "Desyrel"
  },
  {
    "name": "Tacrolimus",
    "class": "Calcineurin Inhibitor",
    "category": "Calcineurin inhibitor",
    "side_effects": "Potential for immunosuppression, gastrointestinal upset",
    "brand": "Prograf"
  },
  {
    "name": "Tramadol",
    "class": "Opioid Analgesic",
    "category": "Opioid analgesic",
    "side_effects": "Sedation, potential for gastrointestinal upset",
    "brand": "Ultram"
  },
  {
    "name": "Tobramycin",
    "class": "Antibiotic (Aminoglycoside)",
    "category": "Aminoglycoside antibiotic",
    "side_effects": "Nephrotoxicity, potential for ototoxicity",
    "brand": "Nebcin"
  },
  {
    "name": "Tetracycline",
    "class": "Antibiotic (Tetracycline)",
    "category": "Tetracycline antibiotic",
    "side_effects": "Gastrointestinal upset, potential for dental staining in young animals",
    "brand": "Achromycin"
  },
  {
    "name": "Ursodiol",
    "class": "Bile Acid",
    "category": "Bile acid",
    "side_effects": "Generally well-tolerated, potential for gastrointestinal upset"
  },
  {
    "name": "Unasyn",
    "class": "Antibiotic (Penicillin/Beta-Lactamase Inhibitor)",
    "category": "Penicillin/beta-lactamase inhibitor antibiotic",
    "side_effects": "Hypersensitivity reactions, potential for gastrointestinal upset",
    "brand": "Ampicillin/Sulbactam"
  },
  {
    "name": "U-40 Insulin",
    "class": "Insulin",
    "category": "Insulin",
    "side_effects": "Hypoglycemia, potential for insulin resistance"
  },
  {
    "name": "UltiCare VetRx U-40 Syringes",
    "class": "Insulin Syringe",
    "category": "Insulin syringe",
    "side_effects": "None (device for insulin administration)"
  },
  {
    "name": "Ursodeoxycholic Acid",
    "class": "Bile Acid",
    "category": "Bile acid",
    "side_effects": "Generally well-tolerated, potential for gastrointestinal upset"
  },
  {
    "name": "Ursodiol",
    "class": "Bile Acid",
    "category": "Bile acid",
    "side_effects": "Generally well-tolerated, potential for gastrointestinal upset",
    "brand": "Actigall"
  },
  {
    "name": "U-100 Insulin",
    "class": "Insulin",
    "category": "Insulin",
    "side_effects": "Hypoglycemia, potential for insulin resistance"
  },
  {
    "name": "Ursodiol",
    "class": "Bile Acid",
    "category": "Bile acid",
    "side_effects": "Generally well-tolerated, potential for gastrointestinal upset",
    "brand": "Urso"
  },
  {
    "name": "Vetmedin",
    "class": "Inodilator",
    "category": "Inodilator",
    "side_effects": "Anorexia, potential for arrhythmias",
    "brand": "Pimobendan"
  },
  {
    "name": "Vitamin K1",
    "class": "Fat-Soluble Vitamin",
    "category": "Fat-soluble vitamin",
    "side_effects": "Rare, potential for hypersensitivity reactions",
    "brand": "Phytonadione"
  },
  {
    "name": "Vetoryl",
    "class": "Adrenal Steroid Synthesis Inhibitor",
    "category": "Adrenal steroid synthesis inhibitor",
    "side_effects": "Polyuria, polydipsia",
    "brand": "Trilostane"
  },
  {
    "name": "Vetalog",
    "class": "Corticosteroid (Glucocorticoid)",
    "category": "Glucocorticoid",
    "side_effects": "Polydipsia, potential for immunosuppression",
    "brand": "Triamcinolone"
  },
  {
    "name": "Vetprofen",
    "class": "Non-Steroidal Anti-Inflammatory Drug (NSAID)",
    "category": "NSAID",
    "side_effects": "Gastrointestinal upset, potential for renal and hepatic issues",
    "brand": "Carprofen"
  },
  {
    "name": "Vetulicol",
    "class": "Nutritional Supplement",
    "category": "Nutritional supplement",
    "side_effects": "Generally well-tolerated, potential for hypersensitivity reactions",
    "brand": "Ethylenediamine Dihydroiodide"
  },
  {
    "name": "VetJet",
    "class": "Injectable Anesthetic",
    "category": "Injectable anesthetic",
    "side_effects": "Respiratory depression, potential for cardiovascular depression",
    "brand": "Ketamine"
  },
  {
    "name": "Vetame",
    "class": "Phenothiazine Sedative/Tranquilizer",
    "category": "Phenothiazine sedative/tranquilizer",
    "side_effects": "Hypotension, potential for prolonged sedation",
    "brand": "Acepromazine"
  },
  {
    "name": "Vetropolycin",
    "class": "Ophthalmic Antibiotic Combination",
    "category": "Ophthalmic antibiotic combination",
    "side_effects": "Rare, potential for local irritation",
    "brand": "Neomycin/Polymyxin B/Bacitracin"
  },
  {
    "name": "Vetadryl",
    "class": "Antihistamine",
    "category": "Antihistamine",
    "side_effects": "Sedation, potential for anticholinergic effects",
    "brand": "Diphenhydramine"
  },
  {
    "name": "Ventipulmin",
    "class": "Beta-2 Adrenergic Agonist",
    "category": "Beta-2 adrenergic agonist",
    "side_effects": "Tachycardia, potential for tremors",
    "brand": "Clenbuterol"
  },
  {
    "name": "Vecuronium",
    "class": "Neuromuscular Blocking Agent",
    "category": "Neuromuscular blocking agent",
    "side_effects": "Respiratory depression, potential for prolonged muscle paralysis"
  },
  {
    "name": "Vinblastine",
    "class": "Antineoplastic Agent (Vinca Alkaloid)",
    "category": "Vinca alkaloid antineoplastic agent",
    "side_effects": "Myelosuppression, potential for gastrointestinal upset"
  },
  {
    "name": "Vetrazin",
    "class": "Antiparasitic (Anthelmintic)",
    "category": "Anthelmintic antiparasitic",
    "side_effects": "Rare, potential for gastrointestinal upset",
    "brand": "Pyrantel Pamoate"
  },
  {
    "name": "Vivitonin",
    "class": "Cerebral and Peripheral Vasodilator",
    "category": "Cerebral and peripheral vasodilator",
    "side_effects": "Gastrointestinal upset, potential for CNS stimulation",
    "brand": "Propentofylline"
  },
  {
    "name": "Vitamin B Complex",
    "class": "Nutritional Supplement",
    "category": "Nutritional supplement",
    "side_effects": "Generally well-tolerated"
  },
  {
    "name": "Verapamil",
    "class": "Calcium Channel Blocker",
    "category": "Calcium channel blocker",
    "side_effects": "Hypotension, potential for bradycardia"
  },
  {
    "name": "Vitamin E",
    "class": "Fat-Soluble Vitamin",
    "category": "Fat-soluble vitamin",
    "side_effects": "Rare, potential for hypersensitivity reactions"
  },
  {
    "name": "Vetricyn",
    "class": "Wound Care Product",
    "category": "Wound care product",
    "side_effects": "Generally well-tolerated, potential for local irritation",
    "brand": "Hypochlorous Acid"
  },
  {
    "name": "Veta-K1",
    "class": "Fat-Soluble Vitamin",
    "category": "Fat-soluble vitamin",
    "side_effects": "Rare, potential for hypersensitivity reactions",
    "brand": "Phytonadione"
  },
  {
    "name": "Vitamin B12",
    "class": "Water-Soluble Vitamin",
    "category": "Water-soluble vitamin",
    "side_effects": "Generally well-tolerated",
    "brand": "Cyanocobalamin"
  },
  {
    "name": "Winstrol V (Stanozolol)",
    "class": "Anabolic Steroid",
    "category": "Anabolic steroid",
    "side_effects": "Hepatotoxicity, potential for masculinization"
  },
  {
    "name": "Warfarin",
    "class": "Anticoagulant",
    "category": "Anticoagulant",
    "side_effects": "Hemorrhage, potential for hypersensitivity reactions"
  },
  {
    "name": "Worm-X (Pyrantel Pamoate)",
    "class": "Antiparasitic (Anthelmintic)",
    "category": "Anthelmintic antiparasitic",
    "side_effects": "Rare, potential for gastrointestinal upset"
  },
  {
    "name": "Watson 385 (Hydrocodone/Acetaminophen)",
    "class": "Opioid Analgesic/Non-Opioid Analgesic Combination",
    "category": "Opioid analgesic/non-opioid analgesic combination",
    "side_effects": "Sedation, potential for hepatotoxicity (due to acetaminophen)"
  },
  {
    "name": "Winstrol (Stanozolol)",
    "class": "Anabolic Steroid",
    "category": "Anabolic steroid",
    "side_effects": "Hepatotoxicity, potential for masculinization"
  },
  {
    "name": "Wartex (Podophyllin)",
    "class": "Cytotoxic Agent",
    "category": "Cytotoxic agent",
    "side_effects": "Local irritation, potential for systemic toxicity"
  },
  {
    "name": "Westhroid (Thyroid Extract)",
    "class": "Thyroid Hormone Replacement",
    "category": "Thyroid hormone replacement",
    "side_effects": "Overdose symptoms, potential for hyperthyroidism"
  },
  {
    "name": "Westcort (Hydrocortisone)",
    "class": "Corticosteroid (Glucocorticoid)",
    "category": "Glucocorticoid",
    "side_effects": "Polydipsia, potential for immunosuppression"
  },
  {
    "name": "Womectin (Ivermectin)",
    "class": "Antiparasitic (Macrocyclic Lactone)",
    "category": "Macrocyclic lactone antiparasitic",
    "side_effects": "Neurological signs, potential for gastrointestinal upset"
  },
  {
    "name": "Wound Gard (Chlorhexidine)",
    "class": "Antiseptic",
    "category": "Antiseptic",
    "side_effects": "Rare, potential for local irritation"
  },
  {
    "name": "Worm Protector 2X (Pyrantel Pamoate)",
    "class": "Antiparasitic (Anthelmintic)",
    "category": "Anthelmintic antiparasitic",
    "side_effects": "Rare, potential for gastrointestinal upset"
  },
  {
    "name": "Wormeze (Pyrantel Pamoate)",
    "class": "Antiparasitic (Anthelmintic)",
    "category": "Anthelmintic antiparasitic",
    "side_effects": "Rare, potential for gastrointestinal upset"
  },
  {
    "name": "Wound Kote (Gentian Violet)",
    "class": "Antiseptic",
    "category": "Antiseptic",
    "side_effects": "Rare, potential for local irritation"
  },
  {
    "name": "Xylazine",
    "class": "Alpha-2 Adrenergic Agonist",
    "category": "Alpha-2 adrenergic agonist",
    "side_effects": "Sedation, potential for bradycardia"
  },
  {
    "name": "Xenazine (Tetrabenazine)",
    "class": "Dopamine Depleter",
    "category": "Dopamine depleter",
    "side_effects": "Sedation, potential for parkinsonian-like symptoms"
  },
  {
    "name": "Xylocaine (Lidocaine)",
    "class": "Local Anesthetic",
    "category": "Local anesthetic",
    "side_effects": "Local irritation, potential for systemic toxicity"
  },
  {
    "name": "Xylitol",
    "class": "Sugar Substitute",
    "category": "Sugar substitute",
    "side_effects": "Can be toxic to dogs, potential for hypoglycemia"
  },
  {
    "name": "Xenodine (Povidone-Iodine)",
    "class": "Antiseptic",
    "category": "Antiseptic",
    "side_effects": "Rare, potential for local irritation"
  },
  {
    "name": "Xenaderm (Dexpanthenol)",
    "class": "Topical Skin Protectant",
    "category": "Topical skin protectant",
    "side_effects": "Rare, potential for local irritation"
  },
  {
    "name": "Xalatan (Latanoprost)",
    "class": "Ophthalmic Prostaglandin Analog",
    "category": "Ophthalmic prostaglandin analog",
    "side_effects": "Ocular irritation, potential for increased iris pigmentation"
  },
  {
    "name": "Yobine (Yohimbine)",
    "class": "Alpha-2 Adrenergic Receptor Antagonist",
    "category": "Alpha-2 adrenergic receptor antagonist",
    "side_effects": "Potential for tachycardia, hypertension"
  },
  {
    "name": "Yunnan Baiyao",
    "class": "Hemostatic Agent",
    "category": "Hemostatic agent",
    "side_effects": "Rare, potential for hypersensitivity reactions"
  },
  {
    "name": "Y-Tex Brute Pour-On (Permethrin)",
    "class": "Insecticide and Repellent",
    "category": "Insecticide and repellent",
    "side_effects": "Rare, potential for local irritation"
  },
  {
    "name": "Zeniquin (Marbofloxacin)",
    "class": "Fluoroquinolone Antibiotic",
    "category": "Fluoroquinolone antibiotic",
    "side_effects": "Potential for gastrointestinal upset, rare instances of central nervous system effects"
  },
  {
    "name": "Zycortal (Desoxycorticosterone Pivalate)",
    "class": "Mineralocorticoid",
    "category": "Mineralocorticoid",
    "side_effects": "Polyuria, polydipsia, potential for electrolyte imbalances"
  },
  {
    "name": "ZydaClin (Clindamycin)",
    "class": "Lincosamide Antibiotic",
    "category": "Lincosamide antibiotic",
    "side_effects": "Potential for gastrointestinal upset, rare instances of allergic reactions"
  },
  {
    "name": "Zyban (Bupropion)",
    "class": "Antidepressant (Norepinephrine-Dopamine Reuptake Inhibitor)",
    "category": "Antidepressant (norepinephrine-dopamine reuptake inhibitor)",
    "side_effects": "Potential for seizures, gastrointestinal upset"
  },
  {
    "name": "Zonisamide",
    "class": "Anticonvulsant",
    "category": "Anticonvulsant",
    "side_effects": "Potential for sedation, ataxia, rare instances of idiosyncratic reactions"
  },
  {
    "name": "Zylkene (Alpha-Casozepine)",
    "class": "Nutritional Supplement (Alpha-S1 Casein Tryptic Hydrolysate)",
    "category": "Nutritional supplement (alpha-S1 casein tryptic hydrolysate)",
    "side_effects": "Generally well-tolerated, rare instances of gastrointestinal upset"
  },
  {
    "name": "Zymox Otic Enzymatic Solution (Hydrocortisone, Lysozyme, Lactoferrin)",
    "class": "Otic Solution",
    "category": "Otic solution",
    "side_effects": "Rare, potential for local irritation"
  },
  {
    "name": "Zyloric (Allopurinol)",
    "class": "Xanthine Oxidase Inhibitor",
    "category": "Xanthine oxidase inhibitor",
    "side_effects": "Potential for gastrointestinal upset, rare instances of hypersensitivity reactions"
  }
]