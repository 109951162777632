import { gql } from "@apollo/client"

export const GET_AUDIO_ARTICLES_QUERY = gql`
  query GetAudioArticles($category: String!) {
    audioArticles(category: $category) {
      id
      title
      audio
      audioDuration

      category {
        id
        title
      }

      subcategory {
        id
        title
      }
    }
  }
`;

export const GET_ARTICLE_AUDIO_QUERY = gql`
  query GetArticleAudio($id: ID!) {
    articleAudio(id: $id)
  }
`;