import { gql } from "@apollo/client"

export const GET_TEST_QUERY = gql`
  query GetTest($testId: ID!) {
    test(testId: $testId) {
      id
      startedAt
      duration
      completedAt
      createdAt
      numberQuestions
      numberCorrectAnswers
      scorePercent
      finishedDuration
      completedTestCategoryStats

      testQuestions {
        id
        correct
        answer
        question {
          id
          question
          answers
          correctAnswer
          studyHint
        }
      }
    }
  }
`;
