

const StatsTab = ({ test }) => {

  return (
    <div>
      {
        test.completedTestCategoryStats.map(category => (
          <div className='mt-8'>
            <p className='font-semibold text-lg'>{category.title}</p>
            <div className='w-96 flex flex-row items-center mb-4'>
              <div className="w-full bg-gray-200 rounded-full dark:bg-gray-700">
                <div className="bg-blue-600 text-xs font-medium text-blue-100 text-center p-0.5 leading-none rounded-full" style={{ width: category.number_questions == 0 ? 0 : `${parseInt(parseFloat(category.number_correct) / category.number_questions * 100)}%` }}>{category.number_correct > 0 && `${parseInt(parseFloat(category.number_correct) / category.number_questions * 100)}%`}</div>
              </div>
              <p className='text-xs font-medium w-24 ml-2'>{category.number_correct} / {category.number_questions}</p>
            </div>
            <div className='mb-8'>
              {category.subcategories.map(subcategory => (
                <div className='flex flex-row items-center justify-start'>
                  <p className='text-sm w-64'>{subcategory.title}</p>
                  <div className='w-96 flex flex-row items-center'>
                    <div className="w-full bg-gray-200 rounded-full dark:bg-gray-700">
                      <div className="bg-blue-600 h-2 text-xs font-medium text-blue-100 text-center p-0.5 leading-none rounded-full" style={{ width: subcategory.number_questions == 0 ? 0 : `${parseInt(parseFloat(subcategory.number_correct) / subcategory.number_questions * 100)}%` }}></div> {/* {subcategory.number_correct > 0 && `${parseInt(parseFloat(subcategory.number_correct) / subcategory.number_questions * 100)}%`}*/}
                    </div>
                    <p className='text-xs font-medium w-24 ml-2'>{subcategory.number_correct} / {subcategory.number_questions}</p>
                  </div>
                </div>
              ))}
            </div>
          </div>
        ))
      }
    </div>
  )
}

export default StatsTab