import { gql } from "@apollo/client";


export const GENERATE_ASSISTANT_RESPONSE = gql`
  mutation GenerateAssistantResponse(
    $question: String!
  ) {
    generateAssistantResponse(
      question: $question
    ) {
      response
    }
  }
`
export const GENERATE_ASSISTANT_QUICK_RESPONSE = gql`
  mutation GenerateAssistantQuickResponse(
    $question: String!
  ) {
    generateAssistantQuickResponse(
      question: $question
    ) {
      response
    }
  }
`

export const GET_ASSISTANT_QUERIES_QUERY = gql`
  query AssistantQueries {
    assistantQueries {
      id
      query
      response
    }
  }
`