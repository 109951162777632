import { gql } from "@apollo/client"


export const GET_CURRENT_TEST_QUESTION_QUERY = gql`
  query GetCurrentTestQuestion($testId: ID!) {
    currentTestQuestion(testId: $testId) {
      id
      question
      correctAnswer
      answers
    }
  }
`;

export const SHOW_TEST_QUESTION_ANSWER_MUTATION = gql`
mutation ShowTestQuestionAnswer($testId: ID!, $testQuestionId: ID!, $submittedAnswer: String) {
    showTestQuestionAnswer(testId: $testId, testQuestionId: $testQuestionId, submittedAnswer: $submittedAnswer) {
      testQuestion {
        id
        answeredAt
        correct
        question {
          id
          correctAnswer
        }
      }
    }
  }
`;

export const PROCEED_TO_NEXT_QUESTION_MUTATION = gql`
mutation ProceedToNextQuestion($testId: ID!, $testQuestionId: ID!, $submittedAnswer: String) {
    proceedToNextQuestion(testId: $testId, testQuestionId: $testQuestionId, submittedAnswer: $submittedAnswer) {
      success
    }
  }
`;