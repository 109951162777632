import { useEffect } from 'react';
import MainLayout from '../../components/main-layout'

const ReferencesPage = () => {

  useEffect(() => {
    document.title = `Patch Education — References`;
  }, []);

  return (
    <MainLayout>
      <div className='p-4 md:p-8'>
        <div className='flex flex-row justify-between items-center mb-12'>
          <h1 className='text-2xl font-bold text-slate-800'>References</h1>
          {/* <button className='text-white bg-black px-4 py-2 rounded-md' onClick={() => navigate('/tests/new')}>Take a new test</button> */}
        </div>
        <div className='flex flex-col'>
          <h2 className='text-lg font-semibold'>Canine/Feline</h2>
          <ul className='list-decimal ml-6'>
            <li>Kittleson MD, Côté E. The Feline Cardiomyopathies: 2. Hypertrophic cardiomyopathy. Journal of Feline Medicine and Surgery. 2021;23(11):1028-1051. doi:10.1177/1098612X211020162</li>
            <li>de Brito Galvao JF, Parker V, Schenck PA, et al. Update on feline ionized hypercalcemia. Vet Clin North Am Small Anim Pract. 2017;47:273-292.</li>
            <li>Yu E, Sharma S. Physiology, Calcium. [Updated 2023 Aug 14]. In: StatPearls [Internet]. Treasure Island (FL): StatPearls Publishing; 2023 Jan-. Available from: https://www.ncbi.nlm.nih.gov/books/NBK482128/</li>
            <li>Savary KC, Price GS, Vaden SL. Hypercalcemia in cats: a retrospective study of 71 cases (1991-1997). J Vet Intern Med. 2000 Mar-Apr;14(2):184-9. doi: 10.1892/0891-6640(2000)0142.3.co;2. PMID: 10772491.</li>
            <li>Diabetic ketoacidosis. Nat Rev Dis Primers 6, 41 (2020). https://doi.org/10.1038/s41572-020-0180-2</li>
            <li>Lizzo JM, Goyal A, Gupta V. Adult Diabetic Ketoacidosis. [Updated 2023 Jul 10]. In: StatPearls [Internet]. Treasure Island (FL): StatPearls Publishing; 2023 Jan-. Available from: https://www.ncbi.nlm.nih.gov/books/NBK560723/</li>
            <li>Vaske HH, Schermerhorn T, Armbrust L, Grauer GF. Diagnosis and management of feline hyperthyroidism: current perspectives. Vet Med (Auckl). 2014 Aug 20;5:85-96. doi: 10.2147/VMRR.S39985. PMID: 32670849; PMCID: PMC7337209.</li>
            <li>Candellone A, Saettone V, Badino P, Girolami F, Radice E, Bergero D, Odore R, Meineri G. Management of Feline Hyperthyroidism and the Need to Prevent Oxidative Stress: What Can We Learn from Human Research? Antioxidants (Basel). 2021 Sep 20;10(9):1496. doi: 10.3390/antiox10091496. PMID: 34573128; PMCID: PMC8469997.</li>
            <li>Maes R. Felid herpesvirus type 1 infection in cats: a natural host model for alphaherpesvirus pathogenesis. ISRN Vet Sci. 2012 Nov 14;2012:495830. doi: 10.5402/2012/495830. PMID: 23762586; PMCID: PMC3671728.</li>
            <li>Rosalind Gaskell, Susan Dawson, Alan Radford, Etienne Thiry. Feline herpesvirus. Veterinary Research, 2007, 38 (2), pp.337-354. ⟨10.1051/vetres:2006063⟩. ⟨hal-00902876⟩</li>
            <li>Berger A, Willi B, Meli ML, Boretti FS, Hartnack S, Dreyfus A, Lutz H, Hofmann-Lehmann R. Feline calicivirus and other respiratory pathogens in cats with Feline calicivirus-related symptoms and in clinically healthy cats in Switzerland. BMC Vet Res. 2015 Nov 13;11:282. doi: 10.1186/s12917-015-0595-2. PMID: 26566897; PMCID: PMC4644299.</li>
            <li>Hofmann-Lehmann R, Hosie MJ, Hartmann K, Egberink H, Truyen U, Tasker S, Belák S, Boucraut-Baralon C, Frymus T, Lloret A, Marsilio F, Pennisi MG, Addie DD, Lutz H, Thiry E, Radford AD, Möstl K. Calicivirus Infection in Cats. Viruses. 2022 Apr 29;14(5):937. doi: 10.3390/v14050937. PMID: 35632680; PMCID: PMC9145992.</li>
            <li>Kruse BD, Unterer S, Horlacher K, Sauter-Louis C, Hartmann K. Prognostic factors in cats with feline panleukopenia. J Vet Intern Med. 2010 Nov-Dec;24(6):1271-6. doi: 10.1111/j.1939-1676.2010.0604.x. Epub 2010 Oct 12. PMID: 21039863.</li>
            <li>Sykes JE. Feline Panleukopenia Virus Infection and Other Viral Enteritides. Canine and Feline Infectious Diseases. 2014:187-94. doi: 10.1016/B978-1-4377-0795-3.00019-3. Epub 2013 Aug 26. PMCID: PMC7151839.</li>
            <li>Origgi FC, Plattet P, Sattler U, et al. Emergence of canine distemper virus strains with modified molecular signature and enhanced neuronal tropism leading to high mortality in wild carnivores. Vet Pathol. 2012;49(6):913-929.</li>
            <li>Liu PC, Chen CA, Chen CM, et al. Application of xenogeneic anti-canine distemper virus antibodies in treatment of canine distemper puppies. J Small Anim Pract. 2016 Nov;57(11):62-630. doi: 10.1111/jsap.12557. Epub 2016 Oct 11. PMID: 27726133.</li>
            <li>Creevy , Kate E., and Jeremy B. Evans . “Canine Distemper .” MSD Veterinary Manual , Nov. 2022. 1991-2023 Veterinary Information Network, Inc.</li>
            <li>Berns, Kenneth, and Collin Parrish. “Parvoviridae.” Basic Medical Key , 2016, basicmedicalkey.com/parvoviridae/.</li>
            <li>Shell, Linda G., and Kari Rothrock. “Canine Parvovirus Infection .” Vincyclopedia of Diseases , 2018.</li>
            <li>Larson, Laurie, and Ronald Shults. “Do Two Current Canine Parvovirus Type 2 and 2b Vaccines Provide Protection Against the New Type 2c Variant?” VetFolio, 2019, www.vetfolio.com/learn/article/do-two-current-canine-parvovirus-type-2-and-2b-vaccines-provide-protection-against-the-new-type-2c-variant.</li>
            <li>Chao-Nan Lin, Shu-Yun Chiang. “Canine Parvovirus Type 2.” IntechOpen, IntechOpen, 21 Dec. 2016, www.intechopen.com/books/canine-medicine-recent-topics-and-advanced-research/canine-parvovirus-type-2.</li>
            <li>Greene CE: “Rabies and Other Lyssavirus Infections. Infectious Diseases of the Dog and Cat,” 4 ed. Elsevier, St. Louis 2012 pp. 179-97.</li>
            <li>Kari Rothrock. “Rabies (Zoonotic) .” Vincyclopedia of Diseases , 2012.</li>
            <li>Mochizuki M, Yachi A, Ohshima T, et akL Etiologic study of upper respiratory infections of household dogs. J Vet Med Sci 2008 Vol 70 (6) pp. 561-69</li>
            <li>Shell, Kinda G. “Canine Parainfluenza Virus Infection” Vincyclopedia of Diseases, 2009</li>
            <li>Sykes JE. Infectious canine hepatitis. In: Canine and Feline Infectious Diseases. Elsevier/Saunders; 2014:182-186.</li>
            <li>Wasik, Bret. "Infectious Canine Hepatitis" Vincyclopedia of Diseases, 2011</li>
            <li>Dubovi, Edward J., MacLachlan, N. James, "Fenner's Veterinary Virology" Academic Press 2009. pp. 393-413.</li>
            <li>Shell, Linda “Canine Enteric Coroavirus” Vincyclopedia of Diseases, 2009</li>
            <li>J Vet Intern Med. 2018 May-Jun; 32(3): 887-903. Published online 2018 Mar 22. doi: 10.1111/jvim.15085</li>
            <li>Shell, Linda “Lyme Disease Zoonotic” Vincyclopedia of Diseases, 2004</li>
            <li>Rothrock, Kari “Neorickettsia Infection” Vincyclopedia of Disease, 2013</li>
            <li>Harrus S, Waner T, Neer TM: Neorickettsia and Wolbachia Infections. Infectious Diseases of the Dog and Cat Elsevier Saunders 2010 pp. 219-226.</li>
            <li>Gorham JR, Foreyt WJ, Sykes JE: Neorickettsia helminthoeca Infection (Salmon Poisoning Disease). Textbook of Veterinary Internal Medicine, 7th ed. St. Louis, Saunders Elsevier 2010 pp. 220-24.</li>
            <li>Shell, Linda G. “Salmon Poisoning Disease” Vincyclopedia of Disease, 2005</li>
            <li>Bowman DD, Breitschwerdt EB: The Genus Rickettsia: Review and Update. ACVIM 2016.</li>
            <li>Shell, Linda G. “Rocky Mountain Spotted Fever” Vincyclopedia of Disease, 2009</li>
            <li>Lothar Beutin. Escherichia coli as a pathogen in dogs and cats. Veterinary Research, 1999, 30 (2-3), pp.285-298. Ffhal-00902570f</li>
            <li>Cox VS, Wallace LJ, Anderson VE. Hereditary esophageal dysfunction in the miniature schnauzer dog. Am J Vet Res 1980;41:326.</li>
            <li>Detweiler DA, Biller DS, Hoskinson JJ, et al. Radiographic findings of canine dysautonomia in twenty-four dogs. Vet Radiol Ultrasound 2001;42:108-112.</li>
            <li>Gaynor AR, Shofer FS, Washabau RJ. Risk factors for acquired megaesophagus in dogs. J Am Vet Med Assoc 1997;211:1406-1412.</li>
            <li>American College of Veterinary Surgeons</li>
            <li>Today's Veterinary Practice</li>
            <li>DVM 360</li>
            <li>Merck Veterinary Manual</li>
            <li>Belyayeva M, Jeong JM. Acute Pyelonephritis. [Updated 2022 Sep 18]. In: StatPearls [Internet]. Treasure Island (FL): StatPearls Publishing; 2023 Jan-. Available from: https://www.ncbi.nlm.nih.gov/books/NBK519537/</li>
            <li>McCauley SR, Clark SD, Quest BW, Streeter RM, Oxford EM. Review of canine dilated cardiomyopathy in the wake of diet-associated concerns. J Anim Sci. 2020 Jun 1;98(6):skaa155. doi: 10.1093/jas/skaa155. Erratum in: J Anim Sci. 2020 Jul 1;98(7): PMID: 32542359; PMCID: PMC7447921.</li>
            <li>Macdonald K. Infective endocarditis in dogs: diagnosis and therapy. Vet Clin North Am Small Anim Pract. 2010 Jul;40(4):665-84. doi: 10.1016/j.cvsm.2010.03.010. PMID: 20610018.</li>
            <li>Gillam-Krakauer M, Mahajan K. Patent Ductus Arteriosus. [Updated 2023 Aug 8]. In: StatPearls [Internet]. Treasure Island (FL): StatPearls Publishing; 2023 Jan-. Available from: https://www.ncbi.nlm.nih.gov/books/NBK430758/</li>
            <li>Backer CL, Mongé MC, Popescu AR, Eltayeb OM, Rastatter JC, Rigsby CK. Vascular rings. Semin Pediatr Surg. 2016 Jun. 25 (3):165-75.</li>
            <li>Cunningham SM, Dos Santos L. Arrhythmogenic right ventricular cardiomyopathy in dogs. J Vet Cardiol. 2022 Apr;40:156-169. doi: 10.1016/j.jvc.2021.07.001. Epub 2021 Jul 23. PMID: 34503916.</li>
            <li>ACVIM consensus guidelines for the diagnosis and treatment of myxomatous mitral valve disease in dogs. J Vet Intern Med. 2019;33(3):1127-1140. doi: 10.1111/jvim.15488</li>
            <li>Keene BW, Atkins CE, Bonagura JD, Fox PR, Häggström J, Fuentes VL, Oyama MA, Rush JE, Stepien R, Uechi M. ACVIM consensus guidelines for the diagnosis and treatment of myxomatous mitral valve disease in dogs. J Vet Intern Med. 2019 May;33(3):1127-1140.</li>
            <li>Madsen MB, Olsen LH, Häggström J, et al. Identification of 2 loci associated with development of myxomatous mitral valve disease in cavalier king charles spaniels. J Hered. 2011;102:S62-S67.</li>
            <li>11. Aupperle H, Disatian S. Pathology, protein expression and signaling in myxomatous mitral valve degeneration: comparison of dogs and humans. J Vet Cardiol. 2012;14(1):59-71.</li>
            <li>PASLAWSKA, URSZULA; NOSZCZYK-NOWAK, AGNIESZKA; CEPIEL, ALICJA; STASZCZYK, MACIEJ; and JANISZEWSKI, ADRIAN (2013) "A retrospective study of tetralogy of Fallot in dogs," Turkish Journal of Veterinary & Animal Sciences: Vol. 37: No. 1, Article 11</li>
            <li>Valérie Chetboul DVM, PhD, Isabelle Pitsch DVM, PhD, et al. Epidemiological, clinical, and echocardiographic features and survival times of dogs and cats with tetralogy of Fallot: 31 cases (2003-2014)JAVMA • Vol 249 • No. 8 • October 15, 2016</li>
            <li>Honse CO, Figueiredo FB, de Alencar NX, Madeira Mde F, Gremião ID, Schubach TM. Disseminated intravascular coagulation in a dog naturally infected by Leishmania (Leishmania) chagasi from Rio de Janeiro - Brazil. BMC Vet Res. 2013 Mar 5;9:43. doi: 10.1186/1746-6148-9-43. PMID: 23497531; PMCID: PMC3599858.</li>
            <li>Mischke R: Disseminated Intravascular Coagulation. British Small Animal Veterinary Congress 2009.</li>
            <li>Sherding RG, Birchard SJ. Pleural Effusion. Saunders Manual of Small Animal Practice. 2006:1696-707. doi: 10.1016/B0-72-160422-6/50166-2. Epub 2009 May 15. PMCID: PMC7149529.</li>
            <li>Rudrappa M, Paul M. Chylothorax. [Updated 2023 Feb 21]. In: StatPearls [Internet]. Treasure Island (FL): StatPearls Publishing; 2023 Jan-. Available from: https://www.ncbi.nlm.nih.gov/books/NBK459206/</li>
            <li>McKnight CL, Burns B. Pneumothorax. [Updated 2023 Feb 15]. In: StatPearls [Internet]. Treasure Island (FL): StatPearls Publishing; 2023 Jan-. Available from: https://www.ncbi.nlm.nih.gov/books/NBK441885/#</li>
            <li>Torres, A., Cilloniz, C., Niederman, M.S. et al. Pneumonia. Nat Rev Dis Primers 7, 25 (2021). https://doi.org/10.1038/s41572-021-00259-0</li>
            <li>AbdelRazek MA, Mowla A, Farooq S, Silvestri N, Sawyer R, Wolfe G. Fibrocartilaginous embolism: a comprehensive review of an under-studied cause of spinal cord infarction and proposed diagnostic criteria. J Spinal Cord Med. 2016;39(2):146-54. doi: 10.1080/10790268.2015.1116726. Epub 2015 Dec 17. PMID: 26833287; PMCID: PMC5072491.</li>
            <li>Vyas V, Goyal A. Acute Pulmonary Embolism. [Updated 2022 Aug 8]. In: StatPearls [Internet]. Treasure Island (FL): StatPearls Publishing; 2023 Jan-. Available from: https://www.ncbi.nlm.nih.gov/books/NBK560551/</li>
            <li>Angrimani DSR, Silvestrini GR, Brito MM, et al: Effects of benign prostatic hyperplasia and finasteride therapy on prostatic blood flow in dogs. Theriogenology 2018 Vol 114 (0) pp. 103-108.</li>
            <li>Schrank M, Romagnoli S. Prostatic Neoplasia in the Intact and Castrated Dog: How Dangerous is Castration? Animals (Basel). 2020 Jan 5;10(1):85. doi: 10.3390/ani10010085. PMID: 31948021; PMCID: PMC7022700.</li>
            <li>Leslie SW, Soon-Sutton TL, R I A, et al. Prostate Cancer. [Updated 2023 May 30]. In: StatPearls [Internet]. Treasure Island (FL): StatPearls Publishing; 2023 Jan-. Available from: https://www.ncbi.nlm.nih.gov/books/NBK470550/#</li>
            <li>Palmieri C, Fonseca-Alves CE, Laufer-Amorim R. A Review on Canine and Feline Prostate Pathology. Front Vet Sci. 2022 May 26;9:881232. doi: 10.3389/fvets.2022.881232. PMID: 35720846; PMCID: PMC9201985.</li>
            <li>Fenn J, Olby NJ; Canine Spinal Cord Injury Consortium (CANSORT-SCI). Classification of Intervertebral Disc Disease. Front Vet Sci. 2020 Oct 6;7:579025. doi: 10.3389/fvets.2020.579025. PMID: 33134360; PMCID: PMC7572860.</li>
            <li>Smolders LA, Bergknut N, Grinwis GC, Hagman R, Lagerstedt AS, Hazewinkel HA, et al.Intervertebral disc degeneration in the dog. Part 2: chondrodystrophic and non-chondrodystrophic breeds. Vet J. (2013) 195:292-9. 10.1016/j.tvjl.2012.10.011</li>
            <li>Di Tosto M, Callegari C, Matiasek K, Lacava G, Salvatore G, Muñoz Declara S, Betti B, Tirrito F. Case report: Atypical and chronic masticatory muscle myositis in a 5-month old Cavalier King Charles Spaniel. Clinical and diagnostic findings, treatment and successful outcome. Front Vet Sci. 2022 Sep 14;9:955758. doi: 10.3389/fvets.2022.955758. PMID: 36187837; PMCID: PMC9516294.</li>
            <li>Webb AA, Taylor SM, McPhee L. Focal myasthenia gravis in a dog. Can Vet J. 1997 Aug;38(8):493-5. PMID: 9262858; PMCID: PMC1576809.</li>
            <li>Oyston PC, Williamson D. Plague: Infections of Companion Animals and Opportunities for Intervention. Animals (Basel). 2011 Jun 21;1(2):242-55. doi: 10.3390/ani1020242. PMID: 26486314; PMCID: PMC4513460.</li>
            <li>Brömel C, Sykes JE. Histoplasmosis in dogs and cats. Clin Tech Small Anim Pract. 2005 Nov;20(4):227-32. doi: 10.1053/j.ctsap.2005.07.003. PMID: 16317912.</li>
            <li>Dagenais TR, Keller NP. Pathogenesis of Aspergillus fumigatus in Invasive Aspergillosis. Clin Microbiol Rev. 2009 Jul;22(3):447-65. doi: 10.1128/CMR.00055-08. PMID: 19597008; PMCID: PMC2708386.</li>
            <li>Rimer D, Chen H, Bar-Nathan M, Segev G. Acute kidney injury in dogs: Etiology, clinical and clinicopathologic findings, prognostic markers, and outcome. J Vet Intern Med. 2022 Mar;36(2):609-618. doi: 10.1111/jvim.16375. Epub 2022 Feb 1. PMID: 35103347; PMCID: PMC8965273.</li>
            <li>Stokes JE, Forrester SD. New and unusual causes of acute renal failure in dogs and cats. Vet Clin North Am Small Anim Pract. 2004 Jul;34(4):909-22, vi. doi: 10.1016/j.cvsm.2004.03.006. PMID: 15223208.</li>
            <li>Bartges JW. Chronic kidney disease in dogs and cats. Vet Clin North Am Small Anim Pract. 2012 Jul;42(4):669-92, vi. doi: 10.1016/j.cvsm.2012.04.008. Epub 2012 May 19. PMID: 22720808.</li>
            <li>Baba AI, Câtoi C. Comparative Oncology. Bucharest (RO): The Publishing House of the Romanian Academy; 2007. Chapter 11, MAMMARY GLAND TUMORS. Available from: https://www.ncbi.nlm.nih.gov/books/NBK9542/</li>
            <li>van der Weyden L, Brenn T, Patton EE, Wood GA, Adams DJ. Spontaneously occurring melanoma in animals and their relevance to human melanoma. J Pathol. 2020 Sep;252(1):4-21. doi: 10.1002/path.5505. Epub 2020 Jul 31. PMID: 32652526; PMCID: PMC7497193.</li>
            <li>Szewczyk M, Lechowski R, Zabielska K. What do we know about canine osteosarcoma treatment? Review. Vet Res Commun. 2015 Mar;39(1):61-7. doi: 10.1007/s11259-014-9623-0. Epub 2014 Nov 26. PMID: 25422073; PMCID: PMC4330401.</li>
            <li>Morello E, Martano M, Buracco P. Biology, diagnosis and treatment of canine appendicular osteosarcoma: similarities and differences with human osteosarcoma. Vet J. 2011 Sep;189(3):268-77. doi: 10.1016/j.tvjl.2010.08.014. Epub 2010 Oct 2. PMID: 20889358.</li>
            <li>Baum JI, Skinner OT, Boston SE. Fracture-associated osteosarcoma of the femur in a cat. Can Vet J. 2018 Oct;59(10):1096-1098. PMID: 30510315; PMCID: PMC6135274.</li>
            <li>Paulin MV, Couronné L, Beguin J, Le Poder S, Delverdier M, Semin MO, Bruneau J, Cerf-Bensussan N, Malamut G, Cellier C, Benchekroun G, Tiret L, German AJ, Hermine O, Freiche V. Feline low-grade alimentary lymphoma: an emerging entity and a potential animal model for human disease. BMC Vet Res. 2018 Oct 11;14(1):306. doi: 10.1186/s12917-018-1635-5. PMID: 30305106; PMCID: PMC6180644.</li>
            <li>Dickinson RM. Canine lymphosarcoma: overcoming diagnostic obstacles and introduction to the latest diagnostic techniques. Can Vet J. 2008 Mar;49(3):305-6, 308. doi: 10.4141/cjas69-041. PMID: 18390105; PMCID: PMC2249731</li>
            <li>Webb JL, Burns RE, Brown HM, LeRoy BE, Kosarek CE. Squamous cell carcinoma. Compend Contin Educ Vet. 2009 Mar;31(3):E9. PMID: 19412903.</li>
            <li>Klein SC, Peterson ME. Canine hypoadrenocorticism: part I. Can Vet J. 2010 Jan;51(1):63-9. PMID: 20357943; PMCID: PMC2797351.</li>
            <li>Carotenuto G, Malerba E, Dolfini C, Brugnoli F, Giannuzzi P, Semprini G, Tosolini P, Fracassi F. Cushing's syndrome-an epidemiological study based on a canine population of 21,281 dogs. Open Vet J. 2019 Apr;9(1):27-32. doi: 10.4314/ovj.v9i1.5. Epub 2019 Feb 15. PMID: 31086762; PMCID: PMC6500859.</li>
          </ul>
          <h2 className='text-lg font-semibold'>Bovine</h2>
          <ul className='list-decimal ml-6'>
            <li>Olchowy TW, Gershwin LJ, Dean DF. Allergic rhinitis in a herd of cattle. J Am Vet Med Assoc. 1995 Nov 1;207(9):1211-4. PMID: 7559073.</li>
            <li>Olchowy, T. W. J., Gershwin, L. J., & Dean, D. F. (1995). Allergic rhinitis in a herd of cattle. Journal of the American Veterinary Medical Association, 207(9), 1211-1214. Retrieved Dec 29, 2023, from https://doi.org/10.2460/javma.1995.207.09.1211</li>
            <li>Kivaria FM. Estimated direct economic costs associated with tick-borne diseases on cattle in Tanzania. Trop Anim Health Prod. 2006 May;38(4):291-9. doi: 10.1007/s11250-006-4181-2. PMID: 17137131.</li>
            <li>Apaa TT, McFadzean H, Gandy S, Hansford K, Medlock J, Johnson N. Anaplasma phagocytophilum Ecotype Analysis in Cattle from Great Britain. Pathogens. 2023 Aug 10;12(8):1029. doi: 10.3390/pathogens12081029. PMID: 37623989; PMCID: PMC10459425.</li>
            <li>Tegtmeyer P, Ganter M, von Loewenich FD. Simultaneous infection of cattle with different Anaplasma phagocytophilum variants. Ticks Tick Borne Dis. 2019 Aug;10(5):1051-1056. doi: 10.1016/j.ttbdis.2019.05.011. Epub 2019 May 30. PMID: 31171465.</li>
            <li>Alam ME, Kamal MM, Rahman M, Kabir A, Islam MS, Hassan J. Review of anthrax: A disease of farm animals. J Adv Vet Anim Res. 2022 Jun 30;9(2):323-334. doi: 10.5455/javar.2022.i599. PMID: 35891653; PMCID: PMC9298093.</li>
            <li>Sterne M. Variation in Bacillus anthracis. Onderstepoort J Vet Sci Anim Indus. 1937;8:271–349</li>
            <li>Mosqueda J, Olvera-Ramirez A, Aguilar-Tipacamu G, Canto GJ. Current advances in detection and treatment of babesiosis. Curr Med Chem. 2012;19(10):1504-18. doi: 10.2174/092986712799828355. PMID: 22360483; PMCID: PMC3355466.</li>
            <li>Decaro N, Larocca V, Parisi A, Losurdo M, Lia RP, Greco MF, Miccolis A, Ventrella G, Otranto D, Buonavoglia C. Clinical bovine piroplasmosis caused by Babesia occultans in Italy. J Clin Microbiol. 2013 Jul;51(7):2432-4. doi: 10.1128/JCM.00713-13. Epub 2013 May 8. PMID: 23658262; PMCID: PMC3697720.</li>
            <li>Hansford J. Blackleg without skeletal muscle involvement as a cause of sudden death in unvaccinated calves. Can Vet J. 2020 Feb;61(2):189-192. PMID: 32020941; PMCID: PMC6973219.  </li>
            <li>Wang Y, Wang L, Wang Z, Xue B, Peng Q, Hu R, Yan T. Recent advances in research in the rumen bloat of ruminant animals fed high-concentrate diets. Front Vet Sci. 2023 Mar 23;10:1142965. doi: 10.3389/fvets.2023.1142965. PMID: 37035805; PMCID: PMC10076780.</li>
            <li>Braun U. Botulismus beim Rind [Botulism in cattle]. Schweiz Arch Tierheilkd. 2006 Jul;148(7):331-9. German. doi: 10.1024/0036-7281.148.7.331. PMID: 16888919.Pandian SJ, Subramanian M, Vijayakumar G, Balasubramaniam GA, Sukumar K. Therapeutic management of botulism in dairy cattle. Vet World. 2015 Nov;8(11):1305-9. doi: 10.14202/vetworld.2015.1305-1309. Epub 2015 Nov 21. PMID: 27047034; PMCID: PMC4774742.</li>
            <li>Ni SA, Brady MF. Botulism Antitoxin. [Updated 2023 Sep 4]. In: StatPearls [Internet]. Treasure Island (FL): StatPearls Publishing; 2023 Jan-. Available from: https://www.ncbi.nlm.nih.gov/books/NBK534807/</li>
            <li>Marawan MA, Alouffi A, El Tokhy S, Badawy S, Shirani I, Dawood A, Guo A, Almutairi MM, Alshammari FA, Selim A. Bovine Leukaemia Virus: Current Epidemiological Circumstance and Future Prospective. Viruses. 2021 Oct 27;13(11):2167. doi: 10.3390/v13112167. PMID: 34834973; PMCID: PMC8618541.</li>
            <li>Kumagai S, Daikai T, Onodera T. Bovine Spongiform Encephalopathy- A Review from the Perspective of Food Safety. Food Saf (Tokyo). 2019 Jun 13;7(2):21-47. doi: 10.14252/foodsafetyfscj.2018009. PMID: 31998585; PMCID: PMC6978881.</li>
            <li>Borham M, Oreiby A, El-Gedawy A, Hegazy Y, Khalifa HO, Al-Gaabary M, Matsumoto T. Review on Bovine Tuberculosis: An Emerging Disease Associated with Multidrug-Resistant Mycobacterium Species. Pathogens. 2022 Jun 21;11(7):715. doi: 10.3390/pathogens11070715. PMID: 35889961; PMCID: PMC9320398.</li>
            <li>Romha G., Gebru G., Asefa A., Mamo G. Epidemiology of Mycobacterium bovis and Mycobacterium tuberculosis in animals: Transmission dynamics and control challenges of zoonotic TB in Ethiopia. Prev. Vet. Med. 2018;158:1–17.</li>
            <li>Fitzgerald SD, Kaneene JB. Wildlife reservoirs of bovine tuberculosis worldwide: hosts, pathology, surveillance, and control. Vet Pathol. 2013 May;50(3):488-99. doi: 10.1177/0300985812467472. Epub 2012 Nov 20. PMID: 23169912.</li>
            <li>Hassan MU, Khan MN, Abubakar M, Waheed HM, Iqbal Z, Hussain M. Bovine hypodermosis--a global aspect. Trop Anim Health Prod. 2010 Dec;42(8):1615-25. doi: 10.1007/s11250-010-9634-y. Epub 2010 Jul 6. PMID: 20607401.</li>
            <li>Braun U, Eicher R, Hausammann K. Clinical findings in cattle with dilatation and torsion of the caecum. Vet Rec. 1989 Sep 2;125(10):265-7. doi: 10.1136/vr.125.10.265. PMID: 2800257.</li>
            <li>Braun U, Beckmann C, Gerspach C, Hässig M, Muggli E, Knubben-Schweizer G, Nuss K. Clinical findings and treatment in cattle with caecal dilatation. BMC Vet Res. 2012 Jul 9;8:75. doi: 10.1186/1746-6148-8-75. PMID: 22656369; PMCID: PMC3391992.</li>
            <li>Sudhakara Reddy B, Sivajothi S, Rayulu VC. Clinical coccidiosis in adult cattle. J Parasit Dis. 2015 Sep;39(3):557-9. doi: 10.1007/s12639-013-0395-1. Epub 2013 Nov 26. PMID: 26345070; PMCID: PMC4554573.</li>
            <li>Jones FS, Little RB. SPECIFIC INFECTIOUS CYSTITIS AND PYELONEPHRITIS OF COWS. J Exp Med. 1925 Oct 31;42(5):593-607. doi: 10.1084/jem.42.5.593. PMID: 19869076; PMCID: PMC2131057. </li>
            <li>Davis-Unger J, Pajor EA, Schwartzkopf-Genswein K, Marti S, Dorin C, Spackman E, Orsel K. Economic impacts of lameness in feedlot cattle. Transl Anim Sci. 2017 Dec 1;1(4):467-479. doi: 10.2527/tas2017.0052. PMID: 32704670; PMCID: PMC7204980.</li>
            <li>Van Metre DC. Pathogenesis and Treatment of Bovine Foot Rot. Vet Clin North Am Food Anim Pract. 2017 Jul;33(2):183-194. doi: 10.1016/j.cvfa.2017.02.003. PMID: 28579042.</li>
            <li>Gadelha IC, Fonseca NB, Oloris SC, Melo MM, Soto-Blanco B. Gossypol toxicity from cottonseed products. ScientificWorldJournal. 2014;2014:231635. doi: 10.1155/2014/231635. Epub 2014 May 6. PMID: 24895646; PMCID: PMC4033412.</li>
            <li>Dennison AC, Van Metre DC, Dinsmore RP, et al. Hemorrhagic bowel syndrome of adult dairy cattle: 22 cases (1997-2000). Journal of the American Veterinary Medical Association, 221:686-689, 2002.</li>
            <li>Berghaus; McCluskey; Callan. Risk factors associated with hemmorrhagic bowel syndrome in dairy cattle. (Journal of the American Veterinary Medical Association. 2005 May 15; 226: 1700-6.</li>
            <li>Newman JH, Holt TN, Hedges LK, Womack B, Memon SS, Willers ED, Wheeler L, Phillips JA 3rd, Hamid R. High-altitude pulmonary hypertension in cattle (brisket disease): Candidate genes and gene expression profiling of peripheral blood mononuclear cells. Pulm Circ. 2011 Oct-Dec;1(4):462-9. doi: 10.4103/2045-8932.93545. PMID: 22530101; PMCID: PMC3329076.</li>
            <li>Grover RF, Reeves JT, Will DH, Blount SG., Jr Pulmonary vasoconstriction in steers at high altitude. J Appl Physiol. 1963;18:567–74.</li>
            <li>O'Toole D, Sondgeroth KS. Histophilosis as a Natural Disease. Curr Top Microbiol Immunol. 2016;396:15-48. doi: 10.1007/82_2015_5008. PMID: 26847357; PMCID: PMC7120429.</li>
            <li>Straub OC. Infectious bovine rhinotracheitis virus. History and recent developments. Dev Biol Stand. 1975;28:530-3. PMID: 165129.</li>
            <li>Benoît Muylkens, Julien Thiry, Philippe Kirten, Frédéric Schynts, Etienne Thiry. Bovine herpesvirus 1 infection and infectious bovine rhinotracheitis. Veterinary Research, 2007, 38 (2), pp.181-209.</li>
            <li>Navarro MA, Uzal FA. Pathobiology and diagnosis of clostridial hepatitis in animals. J Vet Diagn Invest. 2020 Mar;32(2):192-202. doi: 10.1177/1040638719886567. Epub 2019 Nov 18. PMID: 31735127; PMCID: PMC7081508.</li>
            <li>Kelch WJ, Coles BM, Reynolds GE, Bailey DE. Suspected infectious necrotic hepatitis (black disease) in Oregon cattle. J Am Vet Med Assoc. 1977 Sep 1;171(5):431-2. PMID: 903284.</li>
            <li>Idris SM, Eltom KH, Okuni JB, Ojok L, Elmagzoub WA, El Wahed AA, Eltayeb E, Gameel AA. Paratuberculosis: The Hidden Killer of Small Ruminants. Animals (Basel). 2021 Dec 21;12(1):12. doi: 10.3390/ani12010012. PMID: 35011118; PMCID: PMC8749836.</li>
            <li>Guliński P. Ketone bodies - causes and effects of their increased presence in cows' body fluids: A review. Vet World. 2021 Jun;14(6):1492-1503. doi: 10.14202/vetworld.2021.1492-1503. Epub 2021 Jun 11. PMID: 34316197; PMCID: PMC8304442.</li>
            <li>Walsh R.B, Walton J.S, Kelton D.F, LeBlanc S.J, Leslie K.E, Duffield T.F. The effect of subclinical ketosis in early lactation on reproductive performance of postpartum dairy cows. J. Dairy Sci. 2007;90(6):2788–2796. [PubMed] [Google Scholar] [Ref list]</li>
            <li>Fang W, Yang J, Wang HY, Chen SR, Li KR, Liu YH, Duan Y, Li TM. Exploration of animal models to study the life cycle of Fasciola hepatica. Parasitology. 2022 Sep;149(10):1349-1355. doi: 10.1017/S0031182022000609. Epub 2022 May 16. PMID: 35570693; PMCID: PMC10090765.</li>
            <li>Shilkin, J (1952) "Actinomycosis and Actinobacillosis," Journal of the Department of Agriculture, Western Australia, Series 3: Vol. 1: No. 3, Article 11.  Available at: https://library.dpird.wa.gov.au/journal_agriculture3/vol1/iss3/11</li>
            <li>Bliss DH: 1982, Epidemiology and control of gastrointestinal parasitism in lactating, grazing adult dairy cows using a Mor- ante1 sustained release bolus. Vet Rec 110: 141-144.</li>
            <li>Rinaldi M, Geldhof P. Immunologically based control strategies for ostertagiosis in cattle: where do we stand? Parasite Immunol. 2012 May;34(5):254-64. doi: 10.1111/j.1365-3024.2011.01313.x. PMID: 21722144.</li>
            <li>Ellis JA. Bovine parainfluenza-3 virus. Vet Clin North Am Food Anim Pract. 2010 Nov;26(3):575-93. doi: 10.1016/j.cvfa.2010.08.002. PMID: 21056802.</li>
            <li>Baghezza S, Mamache B, Bennoune O, Ghougal K. Pathological study and detection of Bovine parainfluenza 3 virus in pneumonic sheep lungs using direct immunofluorescence antibody technique. Comp Clin Path. 2021;30(2):301-310. doi: 10.1007/s00580-021-03211-6. Epub 2021 Feb 3. PMID: 33551715; PMCID: PMC7854331.</li>
            <li>Alkan F, Ozkul A, Bilge-Dagalp S, Yesilbag K, Oguzoglu TC, Akca Y, Burgu I. Virological and serological studies on the role of PI3 virus, BRSV, BVDV and BHV-1 on respiratory infections of cattle. I. The detection of etiological agents by direct immunofluorescence technique. Dtsch Tierarztl Wochenschr. 2000;107:193–195.</li>
            <li>Horst RL, Goff JP, Reinhardt TA, Buxton DR. Strategies for preventing milk fever in dairy cattle. J Dairy Sci. 1997 Jul;80(7):1269-80. doi: 10.3168/jds.S0022-0302(97)76056-9. PMID: 9241589.</li>
            <li>DeGaris PJ, Lean IJ. Milk fever in dairy cows: a review of pathophysiology and control principles. Vet J. 2008 Apr;176(1):58-69. doi: 10.1016/j.tvjl.2007.12.029. Epub 2008 Mar 7. PMID: 18329301.</li>
            <li>Haydock D. Sulfur-induced polioencephalomalacia in a herd of rotationally grazed beef cattle. Can Vet J. 2003 Oct;44(10):828-9. PMID: 14601680; PMCID: PMC340301.</li>
            <li>Gould DH. Polioencephalomalacia. J Anim Sci. 1998 Jan;76(1):309-14. doi: 10.2527/1998.761309x. PMID: 9464912.</li>
            <li>Zheng HH, Fu PF, Chen HY, Wang ZY. Pseudorabies Virus: From Pathogenesis to Prevention Strategies. Viruses. 2022 Jul 27;14(8):1638. doi: 10.3390/v14081638. PMID: 36016260; PMCID: PMC9414054.</li>
            <li>Maurin M, Raoult D. Q fever. Clin Microbiol Rev. 1999 Oct;12(4):518-53. doi: 10.1128/CMR.12.4.518. PMID: 10515901; PMCID: PMC88923.</li>
            <li>Mege J L, Maurin M, Capo C, Raoult D. Coxiella burnetii: the ‘query’ fever bacterium. A model of immune subversion by a strictly intracellular microorganism. FEMS Microbiol Rev. 1997;19:209–217.</li>
            <li>Sodré DNA, Rossi GAM, Mathias LA, de Andrade Belo MA. Epidemiology and Control of Rabies in Cattle and Equines in Rondônia State, a Brazilian's Legal Amazon Area. Animals (Basel). 2023 Sep 20;13(18):2974. doi: 10.3390/ani13182974. PMID: 37760375; PMCID: PMC10526067.</li>
            <li>Mello A.K.M., Brumatti R.C., Neves D.A., Alcântara L.O.B., Araújo F.S., Gaspar A.O., Lemos R.A.A. Bovine rabies: Economic loss and its mitigation through antirabies vaccination. Pesq. Vet. Bras. 2019;39:179–185. doi: 10.1590/Bárcenas-Reyes, I., </li>
            <li>Nieves-Martínez, D. P., Cuador-Gil, J. Q., Loza-Rubio, E., González-Ruíz, S., Cantò-Alarcòn, G. J., & Milian-Suazo, F. (2019). Spatiotemporal analysis of rabies in cattle in central Mexico. Geospatial Health, 14(2). https://doi.org/10.4081/gh.2019.805</li>
            <li>Roeder P, Mariner J, Kock R. Rinderpest: the veterinary perspective on eradication. Philos Trans R Soc Lond B Biol Sci. 2013 Jun 24;368(1623):20120139. doi: 10.1098/rstb.2012.0139. PMID: 23798687; PMCID: PMC3720037.</li>
            <li>Roeder PL, Rich M. 2009. Rinderpest eradication. In Millions fed: successes in agriculture (eds Spielman D, Pandya-Lorch R.), pp. 109–116 Washington, DC: International Food Policy Research Institute</li>
            <li>Senturk S, Huseyin C. Salt poisoning in beef cattle. Vet Hum Toxicol. 2004 Feb;46(1):26-7. PMID: 14748413.</li>
            <li>Bailey EM Jr. Salt poisoning in beef cattle. Vet Hum Toxicol. 2004 Apr;46(2):104.  </li>
            <li>Pearson EG, Kallfelz FA. A case of presumptive salt poisoning (water deprivation) in veal calves. Cornell Vet. 1982 Apr;72(2):142-9. PMID: 7083863.</li>
            <li>Blowey RW. A simple treatment for heel abscesses and deeper foot infections in cattle. Vet Rec. 1990 Nov 24;127(21):515-7. PMID: 2281584.</li>
            <li>White ME, Glickman LT, Embree IC, Powers PM, Pearson EG. A randomized trial for evaluation of bandaging sole abscesses in cattle. J Am Vet Med Assoc. 1981 Feb 15;178(4):375-7. PMID: 7016819.</li>
            <li>Tsujita H, Plummer CE. Bovine ocular squamous cell carcinoma. Vet Clin North Am Food Anim Pract. 2010 Nov;26(3):511-29. doi: 10.1016/j.cvfa.2010.08.003. PMID: 21056799.</li>
            <li>Kopecky KE, Pugh GW Jr, Hughes DE, Booth GD, Cheville NF. Biological effect of ultraviolet radiation on cattle: bovine ocular squamous cell carcinoma. Am J Vet Res. 1979 Dec;40(12):1783-8. PMID: 525901.</li>
            <li>Kim Y, Ku JY, Lee K, Moon BY, Ha S, Choi KS, Park J. Successful treatment of idiopathic tetanus using metronidazole, magnesium, and acepromazine in Hanwoo (Korean indigenous cattle) yearling bull. Front Vet Sci. 2023 Mar 23;10:1142316. doi: 10.3389/fvets.2023.1142316. PMID: 37035803; PMCID: PMC10076696.</li>
            <li>Hallit RR, Afridi M, Sison R, Salem E, Boghossian J, Slim J. Clostridium tetani bacteraemia. J Med Microbiol. 2013 Jan;62(Pt 1):155-156. doi: 10.1099/jmm.0.044941-0. Epub 2012 Sep 13. PMID: 22977074.</li>
            <li>Braun U, Warislohner S, Torgerson P, Nuss K, Gerspach C. Clinical and laboratory findings in 503 cattle with traumatic reticuloperitonitis. BMC Vet Res. 2018 Mar 5;14(1):66. doi: 10.1186/s12917-018-1394-3. PMID: 29506499; PMCID: PMC5838859.</li>
            <li>Poulsen JS. Prevention of traumatic indigestion in cattle. Vet Rec. 1976;98:149–151. doi: 10.1136/vr.98.8.149. </li>
            <li>Parker S, Campbell J, McIntosh K, Gajadhar A. Diagnosis of trichomoniasis in 'virgin' bulls by culture and polymerase chain reaction. Can Vet J. 2003 Sep;44(9):732-4. PMID: 14524627; PMCID: PMC340269.</li>
            <li>Corbeil LB, Campero CM, Van Hoosear K, Bondurant RH. Detection of trichomonad species in the reproductive tracts of breeding and virgin bulls. Vet Parasitol. 2008 Jul 4;154(3-4):226-32. doi: 10.1016/j.vetpar.2008.03.014. Epub 2008 Mar 27. PMID: 18450382.</li>
            <li>Aydın U, Aksoy Ö. Urethral Urolithiasis in Male Cattle Treated Using Pneumatic Lithotripsy. Vet Res Commun. 2022 Sep;46(3):871-877. doi: 10.1007/s11259-022-09914-7. Epub 2022 Mar 15. PMID: 35292890.</li>
            <li>Ermutlu CŞ, Aksoy Ö, Aydın U (2015) Surgery of lower urinary system in male cattle. Turkiye Klinikleri J Vet Sci Surg-Special Topics 1:61–66</li>
            <li>Barbosa JD, Brito MF, de Medeiros Costa Lins A, Barbosa CC, da Costa PSC, Duarte MD, Ferreira TTA, da Silva E Silva Silveira N, Oliveira CMC, Salvarani FM. Proximal and Distal Vagal Indigestion in Buffaloes (Bubalus bubalis) in the Amazon Biome. Vet Sci. 2023 Mar 28;10(4):254. doi: 10.3390/vetsci10040254. PMID: 37104409; PMCID: PMC10140838.</li>
            <li>Garry F., McConnel C. Indigestion in Ruminants. In: Smith B.P., editor. Large Animal Internal Medicine. Elsevier; St. Louis, MO, USA: 2015. pp. 777–799</li>
            <li>Buczinski S, Fecteau G, DiFruscia R. Ventricular septal defects in cattle: a retrospective study of 25 cases. Can Vet J. 2006 Mar;47(3):246-52. PMID: 16604981; PMCID: PMC1371053.</li>
            <li>Leipold HW, Dennis SM, Huston K. Congenital defects in cattle : nature, cause and effect. Adv Vet Sci Comp Med. 1972;16:103–150. </li>
            <li>Ohwada K, Murakami T. Morphologies of 469 cases of congenital heart diseases in cattle. J Jap Vet Med Assoc. 2000;53:205–209.</li>
            <li>Abutarbush SM, Radostits OM. Congenital nutritional muscular dystrophy in a beef calf. Can Vet J. 2003 Sep;44(9):738-9. PMID: 14524629; PMCID: PMC340271.</li>
            <li>Radostits OM, Gay CC, Blood DC, Hinchcliff KW. Veterinary Medicine: A Textbook of the Diseases of Cattle, Sheep, Pigs, Goats and Horses, 9th ed. London: WB Saunders, 2000:1515–1533, 1819–1822.</li>
            <li>Koller LD, Whitbeck GA, South PJ. Transplacental transfer and colostral concentrations of selenium in beef cattle. Am J Vet Res 1984;45:2507–2510.</li>
            <li>Caffarena RD, Rabaza A, Casaux L, Rioseco MM, Schild CO, Monesiglio C, Fraga M, Giannitti F, Riet-Correa F. Natural lymphatic ("atypical") actinobacillosis in cattle caused by Actinobacillus lignieresii. J Vet Diagn Invest. 2018 Mar;30(2):218-225. doi: 10.1177/1040638717742621. Epub 2017 Dec 4. PMID: 29202671; PMCID: PMC6505872.</li>
            <li>Rycroft AN, Garside LH. Actinobacillus species and their role in animal disease. Vet J 2000;159:18–36</li>
          </ul>
            <h2 className='text-lg font-semibold'>Swine</h2>
            <ul className='list-decimal ml-6'>
              <li>Aiello, S.E., et al.  The Merck Veterinary Manual.  8th ed., 1998.  Merck & Co., 	Inc.  Whitehouse Station, New Jersey.  </li>
              <li>Buddle, J. R.  The Diagnosis of Diseases of Pigs.  Series B #8 1987.  	University of Sydney Publication, 	Sydney, Australia.    </li>
              <li>Cowart, R.P., Casteel, S. W.  An Outline of Swine Diseases:  A 	Handbook.  2nd ed., 2001.  Iowa State University Press, Ames, 	Iowa, 	50014.</li>
              <li>Gadd. J.  Pig Production Problems:  John Gadd’s Guide to their 	Solutions.  2003.  Nottingham University Press, Nottingham, 	United 	Kingdom.  </li>
              <li>Harris, D. L.  Multi Site Pig Production.  2000.  Iowa State University 	Press, 	Ames, Iowa.  </li>
              <li>Hill, J.R., Sainsbury, D.W.  The Health of Pigs.  1995.  Longman 	Scientific and Technical Press, Essex, England.    </li>
              <li>Liess, B.  Classical Swine Fever and Related Viral Infections.  1988.  	Martinus Nijhoff Publishing, Boston, Mass.  </li>
              <li>Morilla, A., Yoon, K.J., et al.  Trends in Emerging Viral Infections of 	Swine.  	2002.  Iowa State Press, Ames, Iowa.  </li>
              <li>Murphy, F.A., Gibbs, E.P., et al.  Veterinary Virology.  3rd ed., 1999.  	Academic Press, San Diego, CA.</li>
              <li>Penny, R.H.C., Machin, D.H.  Pig Reproduction:  Problems, Practices, 	and Principles.  1999.  Cambac Associates Publications, 	Chippenham, Wiltshire.  </li>
              <li>Pensaert, M.B.  Virus Infections of Porcines.  Volume 2 1989.  Elsevier 	Science Publishers, Amsterdam, Netherlands.  </li>
              <li>Plumb, D.C.  Veterinary Drug Handbook.  4th ed., 2002.  Iowa State 	Press, 	Ames, Iowa.  </li>
              <li>Quinn, P.J., Markey, B.K., et al.  Veterinary Microbiology and 	Microbial Disease.  2002.  Blackwell Science Ltd.  Iowa State 	University Press, Ames, Iowa.   </li>
              <li>Smith, B. P.  Large Animal Internal Medicine.  3rd ed., 2002.  Mosby 	Inc.  St. 	Louis, Missouri.</li>
              <li>Straw, B. E., D’Allaire, S., et al.  Diseases of Swine.  8th ed., 1999.  	Iowa 	State University Press, Ames, Iowa.  </li>
              <li>Taylor, D. J.  Pig Diseases.  7th ed., 1999.  Book Production 	Consultants PLC, 	Cambridge.</li>
              <li>Tindall, B.  Swine Practice.  1991.  W.B. Saunders Publishing, London, 	England.  </li>
              <li>Whiteman, C.E., Schwartz, K.J.  Swine Disease Manual.  2nd ed., 1999.  	Minuteman Press, Fort Collins, CO.</li>
              <li>Wiseman, J.  Varley, M.A., et al.  Perspectives in Pig Science.  2003.  	Nottingham University Press, Nottingham, United Kingdom.  </li>
            </ul>
          <h2 className='text-lg font-semibold'>Equine</h2>
          <ul className='list-decimal ml-6'>
            <li>Blikslager AT, White NA, Furr MO, Martin FG, Sweeney RW. Pyloric stenosis in horses: 24 cases (1982-1992). J Am Vet Med Assoc. 1995 Sep 1;207(5):636-9. doi: 10.2460/javma.1995.207.636. PMID: 7591395.</li>
            <li>Nappert G, Vrins A, Launois M, et al. Pyloric stenosis in a foal: clinical, surgical and histological findings. J Vet Med Sci. 2017;79(3):543-547. doi:10.1292/jvms.16-0311.</li>
            <li>Flesher S, Schwarz T, Arighi M, et al. Clinical and histopathological features of non-Hirschsprung's small intestinal neuronal dysplasia in a foal. J Vet Diagn Invest. 2019;31(4):598-602. doi:10.1177/1040638719852344.</li>
            <li>Koenig JB, Miller AD, Dill SG, et al. Ileocolonic aganglionosis in a foal. Vet Pathol. 2017;54(6):1025-1028. doi:10.1177/0300985817721061.</li>
            <li>Mitchell RD, Fubini SL, Scrivani PV, Ducharme NG. Treatment and long-term outcome of esophageal obstruction in horses: 92 cases (1985-2005). J Am Vet Med Assoc. 2008;232(6):933-938. doi:10.2460/javma.232.6.933.</li>
            <li>Easley JT, Taylor TS, Snyder JR, Smith JL. Surgical management of esophageal obstruction in horses: 65 cases (1991-2008). J Am Vet Med Assoc. 2012;241(6):800-804. doi:10.2460/javma.241.6.800.</li>
            <li>Sykes BW, Hewetson M, Hepburn RJ, Luthersson N, Tamzali Y. European College of Equine Internal Medicine Consensus Statement - Equine gastric ulcer syndrome in adult horses. J Vet Intern Med. 2015;29(5):1288-1299. doi:10.1111/jvim.13651.</li>
            <li>Vatistas NJ, Snyder JR, Carlson GP, Johnson B, Arthur RM, Thurmond M. Association of exercise with occurrence of gastric ulcers in racing Standardbreds. J Am Vet Med Assoc. 1999;215(1):113-114, 109-110.</li>
            <li>Fecteau KA, Fubini SL, Ducharme NG, et al. Pancreatic insufficiency in six horses. J Am Vet Med Assoc. 1994;204(7):1111-1113.</li>
            <li>Mair TS. Pancreatic insufficiency in the horse: a review of eight new cases. Vet Rec. 1993;132(2):28-31.</li>
            <li>Pusterla N, Aleman M, Mapes S, et al. Detection of equine coronavirus in adult horses. Emerg Infect Dis. 2013;19(4):680-681. doi:10.3201/eid1904.121398.Whittem T, Pusterla N, Mapes S, et al. Detection of equine coronavirus in nasal swabs of horses with fever and upper respiratory tract infection. Vet Rec. 2014;174(25):635. doi:10.1136/vr.102231.</li>
            <li>Pusterla N, Aleman M, Mapes S, et al. Detection of equine coronavirus in adult horses. Emerg Infect Dis. 2013;19(4):680-681. doi:10.3201/eid1904.121398.Whittem T, Pusterla N, Mapes S, et al. Detection of equine coronavirus in nasal swabs of horses with fever and upper respiratory tract infection. Vet Rec. 2014;174(25):635. doi:10.1136/vr.102231.</li>
            <li>Pusterla N, Mapes S, Wademan C, et al. Emerging outbreaks associated with equine coronavirus in adult horses. Vet Microbiol. 2013;162(2-4):228-231. doi:10.1016/j.vetmic.2012.09.030.Fielding CL, Higgins JK, Higgins JC, et al. Disease associated with equine coronavirus infection and high case fatality rate. J Vet Intern Med. 2015;29(1):307-310. doi:10.1111/jvim.12534.</li>
            <li>Freeman DE, Schaefer SL, Divers TJ, Tennent-Brown BS, Boston RC. Ultrasonographic evaluation of the common bile duct in horses: 27 cases (2000-2010). J Am Vet Med Assoc. 2012;240(4):432-437. doi:10.2460/javma.240.4.432.Semevolos SA, Hardy J, Kelleher M, et al. Evaluation of clinical findings and the outcome of treatment in horses with gallbladder disease: 49 cases (1990-2010). J Am Vet Med Assoc. 2013;243(10):1469-1475. doi:10.2460/javma.243.10.1469.</li>
            <li>LeBlanc MM. Advances in the diagnosis and treatment of chronic infectious and post-mating-induced endometritis in the mare. Reprod Domest Anim. 2010;45 Suppl 2:21-27. doi:10.1111/j.1439-0531.2010.01628.x.Troedsson MHT, Desvousges A, Alghamdi AM. Chronic infectious endometritis in mares: uterine clearance of uterine debris and endotoxin, and the immune response to persistent breeding-induced endometritis. Theriogenology. 2016;86(1):127-132. doi:10.1016/j.theriogenology.2016.04.012.</li>
            <li>Macpherson ML, Giguère S, Desrochers AM, et al. Risk factors for early pregnancy loss, late pregnancy loss, and abortion in mares. J Am Vet Med Assoc. 1998;213(9):1239-1243.Troedsson MHT, Liu IKM, Thurmond M, et al. Non-infectious causes of pregnancy loss in the mare. Anim Reprod Sci. 2003;79(3-4):185-197. doi:10.1016/s0378-4320(03)00141-8.</li>
            <li>LeBlanc MM, Causey RC. Clinical and subclinical endometritis in the mare: Both threats to fertility. Reprod Domest Anim. 2009;44 Suppl 3:10-22. doi:10.1111/j.1439-0531.2009.01440.x.Troedsson MHT, Liu IKM, Thurmond M, et al. Non-infectious causes of pregnancy loss in the mare. Anim Reprod Sci. 2003;79(3-4):185-197. doi:10.1016/s0378-4320(03)00141-8.Goehring LS, van Winden SC, van Maanen C, Sloet van Oldruitenborgh-Oosterbaan MM. Equine herpesvirus type 1-associated myeloencephalopathy in the Netherlands: a four-year retrospective study (1999-2003). J Vet Intern Med. 2006;20(3):601-607.Goodman LB, Loregian A, Perkins GA, et al. A point mutation in a herpesvirus polymerase determines neuropathogenicity. PLoS Pathog. 2007;3(11):e160. doi:10.1371/journal.ppat.0030160.Balasuriya UB, Snijder EJ, Heidner HW, et al. Equine arteritis virus. Vet Microbiol. 2013;167(1-2):93-122. doi:10.1016/j.vetmic.2013.08.024.Timoney PJ, McCollum WH, Roberts AW, Murphy TW. Demonstration of the carrier state in naturally acquired equine arteritis virus infection in the stallion. Res Vet Sci. 1986;41(2):151-154.</li>
            <li>Shokrani P, Abutarbush SM, Al-Tamimi M, et al. Disseminated aspergillosis in a mare with abortion. Can Vet J. 2017;58(9):967-970.Riddle WT, Leblanc MM. Fungal endometritis and myometritis in mares: A review of 26 cases. Theriogenology. 2010;74(5):781-787. doi:10.1016/j.theriogenology.2010.04.013.</li>
            <li>Henzel A, Hofer M, Heusinger A, et al. Contagious equine metritis: A review. Vet J. 2015;203(1):24-29. doi:10.1016/j.tvjl.2014.11.019.Timoney PJ, Sheoran AS, Artiushin SC, et al. A model to assess the risk of transmission of contagious equine metritis to susceptible mares by carrier stallions. Vet Microbiol. 1998;61(3):253-268.</li>
            <li>Timoney PJ, Sheoran AS. Equine coital exanthema: A review of the disease. Vet J. 2000;160(1):1-9. doi:10.1053/tvjl.1999.0416.Timoney PJ, McCollum WH, Roberts AW. Equine coital exanthema: Clinical and virological observations. Vet Rec. 1982;110(3):56-59.</li>
            <li>Bordin AI, DePeters EJ, Daniels JB, et al. Mastitis in mares: comparison of the California mastitis test and somatic cell counts in the diagnosis of naturally occurring intramammary infections. J Dairy Sci. 1997;80(12):3236-3242.Morley PS, Anderson RJ, Burgess BA, et al. Frequency and duration of increased milk somatic cell count in quarter milk of dairy cows. J Dairy Sci. 1989;72(1):203-209.</li>
            <li>Riddle WT, LeBlanc MM. Fungal endometritis and myometritis in mares: A review of 26 cases. Theriogenology. 2010;74(5):781-787. doi:10.1016/j.theriogenology.2010.04.013.LeBlanc MM. Advances in the diagnosis and treatment of chronic infectious and post-mating-induced endometritis in the mare. Reprod Domest Anim. 2010;45 Suppl 2:21-27. doi:10.1111/j.1439-0531.2010.01628.x.</li>
            <li>LeBlanc MM, Causey RC. Clinical and subclinical endometritis in the mare: Both threats to fertility. Reprod Domest Anim. 2009;44 Suppl 3:10-22. doi:10.1111/j.1439-0531.2009.01440.x.Scoggin CF, LeBlanc MM. Retrospective study of pyometra in 225 mares treated with prostaglandin F(2α). Theriogenology. 2011;76(5):882-887. doi:10.1016/j.theriogenology.2011.04.020.</li>
            <li>Riddle WT, LeBlanc MM. Fungal endometritis and myometritis in mares: A review of 26 cases. Theriogenology. 2010;74(5):781-787. doi:10.1016/j.theriogenology.2010.04.013.Easley J, Ott RS. Retained fetal membranes in the mare: a review. J Equine Vet Sci. 1989;9(6):328-335.</li>
            <li>McDonnell SM, Kenney RM, Roser JF. Uterine prolapse in mares. Vet Clin North Am Equine Pract. 1990;6(1):95-109.Lenghaus C, Jones R, Wylie C, et al. Uterine prolapse in the mare: A report of 9 cases. Aust Vet J. 1992;69(3):63-65.</li>
            <li>Troedsson MHT, Desvousges A, Alghamdi AM. Chronic infectious endometritis in mares: uterine clearance of uterine debris and endotoxin, and the immune response to persistent breeding-induced endometritis. Theriogenology. 2016;86(1):127-132. doi:10.1016/j.theriogenology.2016.04.012.Troedsson MHT, Liu IKM, Thurmond M, et al. Non-infectious causes of pregnancy loss in the mare. Anim Reprod Sci. 2003;79(3-4):185-197. doi:10.1016/s0378-4320(03)00141-8.</li>
            <li>Dascanio JJ, Hill J, Shideler RK, et al. The effect of exogenous hormones on the development of polycystic ovarian syndrome in the rhesus monkey (Macaca mulatta). Hum Reprod. 1998;13(8):2256-2261.Alm H, Strömberg B, Edqvist LE, et al. Insulin resistance and β-cell function in a horse model of polycystic ovary syndrome. Endocrinology. 2010;151(2):800-810. doi:10.1210/en.2009-0846.</li>
            <li>LeBlanc MM. Advances in the diagnosis and treatment of chronic infectious and post-mating-induced endometritis in the mare. Reprod Domest Anim. 2010;45 Suppl 2:21-27. doi:10.1111/j.1439-0531.2010.01628.x.Troedsson MHT, Liu IKM, Thurmond M, et al. Non-infectious causes of pregnancy loss in the mare. Anim Reprod Sci. 2003;79(3-4):185-197. doi:10.1016/s0378-4320(03)00141-8.</li>
            <li>Goodman LB, Loregian A, Perkins GA, et al. A point mutation in a herpesvirus polymerase determines neuropathogenicity. PLoS Pathog. 2007;3(11):e160. doi:10.1371/journal.ppat.0030160.Goehring LS, van Winden SC, van Maanen C, Sloet van Oldruitenborgh-Oosterbaan MM. Equine herpesvirus type 1-associated myeloencephalopathy in the Netherlands: a four-year retrospective study (1999-2003). J Vet Intern Med. 2006;20(3):601-607.</li>
            <li>Cheetham J, Tatz AJ, Dacre K. Surgical management of bilateral laryngeal paralysis in horses: 44 cases (1989-1993). Equine Vet J. 1995;27(6):458-462.Johnston JK, Robinson NE, Olszewski J, et al. Laryngeal reinnervation in the horse using the ansa cervicalis: a clinical and experimental study. Equine Vet J. 1986;18(3):206-211.</li>
            <li>Pirie RS, Collie DD, Dixon PM, McGorum BC. Inhaled endotoxin and organic dust particulates have synergistic proinflammatory effects in equine heaves (organic dust-induced asthma). Clin Exp Allergy. 2003;33(5):676-683.Hotchkiss JW, Reid SWJ, Christley RM. A survey of horse owners in Great Britain regarding horses in their care. Part 1: Horse demographic characteristics and management. Equine Vet J. 2007;39(4):294-300.</li>
            <li>Newton JR, Daly JM, Spencer L, Mumford JA. Description of the outbreak of equine influenza at tattersalls racecourse, Newmarket in 2003. Vet Rec. 2006;158(6):185-192.Lunn DP, Davis-Poynter N, Flaminio MJBF, et al. Equine herpesvirus-1 consensus statement. J Vet Intern Med. 2009;23(3):450-461.</li>
            <li>Oue Y, Morita Y, Kondo T, et al. Epidemic of equine coronavirus at obihiro racecourse, Hokkaido, Japan in 2012. J Vet Med Sci. 2013;75(10):1261-1265.Pusterla N, Mapes S, Wademan C, et al. Emerging outbreaks associated with equine coronavirus in adult horses. Vet Microbiol. 2013;162(2-4):228-231.</li>
            <li>Lunn DP, Davis-Poynter N, Flaminio MJBF, et al. Equine herpesvirus-1 consensus statement. J Vet Intern Med. 2009;23(3):450-461.Schott HC 2nd, Perkins GA, McDonough SP, et al. Acute adenoviral infection in the horse. Vet Pathol. 2005;42(5):640-645.Timoney PJ. Strangles. Vet Clin North Am Equine Pract. 1992;8(2):251-261.</li>
            <li>Giguère S, Cohen ND, Chaffin MK, Slovis NM, Hondalus MK, Hines SA, Prescott JF. Diagnosis, treatment, control, and prevention of infections caused by Rhodococcus equi in foals. J Vet Intern Med. 2011;25(6):1209-1220.</li>
            <li>Pusterla N, Magdesian KG, Mapes SM, White SD, Johnson C, Sapp KK, Wilson WD. Intra-articular contamination with Aspergillus fumigatus in two horses. Vet Surg. 2008;37(8):748-752.</li>
            <li>Reinemeyer CR. Lungworm infections in horses. Vet Clin North Am Equine Pract. 1986;2(3):643-661.</li>
            <li>Hillidge CJ, Stover SM, Gardner IA, Morley PS, Read DH. Exercise-induced pulmonary hemorrhage in racing Quarter Horses: a preliminary study. J Am Vet Med Assoc. 2004;225(4):575-581.</li>
            <li>Wong DM, Belgrave RL, Williams KJ, Del Piero F, Alcott CJ, Bolin SR. Acute interstitial pneumonia in a horse caused by Rhodococcus equi. J Vet Diagn Invest. 2009;21(1):129-133.</li>
            <li>Keller RL, Javsicas LH, Hackett RP. Diaphragmatic hernia. Compend Contin Educ Pract Vet. 2003;25(11):808-817.</li>
            <li>Pezzanite LM, Puchalski SM, Reed SM. Arytenoid chondritis in horses: 23 cases (1985-2004). J Am Vet Med Assoc. 2007;230(8):1206-1210.Dixon PM, Railton DI, McGorum BC, Hawe C, Tremaine WH, Dacre K. A longitudinal study of the effect of laryngeal hemiplegia on exercise tolerance in a group of Thoroughbred racehorses. Equine Vet J. 2001;33(3):240-244.Dixon PM, Robinson NJ, Tremaine WH. A review of equine paranasal sinusitis: 27 cases. Equine Vet J. 1999;31(3):194-201.</li>
            <li>Certainly, here are scientific journal references for each of the mentioned conditions in horses:</li>
            <li>Dixon PM, Robinson NE, Tremaine WH. A review of equine paranasal sinusitis: 27 cases. Equine Vet J. 1999;31(3):194-201.</li>
            <li>Dixon PM, Railton DI, Hawe C, McGorum BC. Ethmoid hematomas in 29 horses. Vet Surg. 2001;30(4):346-353.</li>
            <li>Dixon PM, Parkin TDH, Collins N, Hawe C, Townsend NB, Fisher GR. Historical and long-term outcome in 56 horses with paranasal sinus cysts (1987-2014). Equine Vet J. 2017;49(6):760-765.</li>
            <li>Lane JG, Bladon B, Little J, Little L, Franklin S. Upper respiratory tract endoscopy as a predictor of racing performance in Thoroughbred yearlings: 427 cases (1998-2001). J Am Vet Med Assoc. 2003;223(1):83-88.</li>
            <li>Marsh GA, Haining J, Hancock TJ, et al. Experimental infection of horses with Hendra virus/Australia/horse/2008/Redlands. Emerg Infect Dis. 2011;17(12):2232-2238.</li>
            <li>Darien BJ, Cohen ND, Walker RD, et al. Bronchoalveolar lavage fluid cytology, pulmonary function, and pathology following inhalation challenge of horses with Aspergillus fumigatus. Vet Pathol. 2009;46(3):426-436.</li>
            <li>Hannant D, Jessett DM, O'Boyle DA, et al. Immunological responses in ponies following natural and experimental infection with Streptococcus equi. Vet Microbiol. 1986;12(1):65-76.</li>
            <li>Timoney PJ. Strangles. Vet Clin North Am Equine Pract. 1992;8(2):251-261.</li>
            <li>Cohen ND, Neibergs HL, Vanderslice J, et al. Association of soil concentrations of Rhodococcus equi and incidence of pneumonia caused by R equi in foals. J Am Vet Med Assoc. 2008;233(2):257-260.</li>
            <li>Belgrave RL, Habecker PL, Southwood LL, et al. Detection and quantification of Ehrlichia risticii DNA in infected horses and snails by real-time PCR. Vet Microbiol. 2009;136(3-4):208-215.Cohen ND, Woods AM, Cummings KJ, et al. Association of soil concentrations of Rhodococcus equi and incidence of pneumonia caused by R equi in foals. J Am Vet Med Assoc. 2008;233(2):257-260.</li>
            <li>Slovis NM, Elam J, Estrada M, Leutenegger C, Lunn DP. Equine protozoal myeloencephalitis: An updated consensus statement with a focus on parasite biology, diagnosis, treatment, and prevention. J Vet Intern Med. 2015;29(2):516-530.</li>
            <li>Molina JM, Diab SS, Hanlon BM, et al. Cryptosporidium species and subtype analysis in diarrheic pre-weaned and post-weaned dairy calves in the United States. Prev Vet Med. 2019;172:104799.</li>
            <li>Pusterla N, Holzenkaempfer N, Mapes S, et al. Detection of rotavirus shedding in the feces of adult horses. J Am Vet Med Assoc. 2018;253(6):759-762.</li>
            <li>Pusterla N, Mapes S, Wademan C, et al. Emerging outbreaks associated with equine coronavirus in adult horses. Vet Microbiol. 2013;162(2-4):228-231.</li>
            <li>Lyons ET, Tolliver SC, Drudge JH, et al. Prevalence of internal parasites in equids in the United States: a review. Equine Vet J. 1997;29(2):65-68.</li>
            <li>Lyons ET, Tolliver SC, Drudge JH, et al. Prevalence of internal parasites in equids in the United States: a review. Equine Vet J. 1997;29(2):65-68.</li>
            <li>Otranto D, Traversa D. A review of dicrocoeliosis of ruminants including recent advances in the diagnosis and treatment. Vet Parasitol. 2002;107(4):317-335.</li>
            <li>Lyons ET, Tolliver SC, Drudge JH, et al. Prevalence of internal parasites in equids in the United States: a review. Equine Vet J. 1997;29(2):65-68.</li>
            <li>Lyons ET, Tolliver SC, Drudge JH, et al. Prevalence of internal parasites in equids in the United States: a review. Equine Vet J. 1997;29(2):65-68.</li>
            <li>Reinemeyer CR. Anthelmintic resistance in equine nematodes. Int J Parasitol. 1999;29(1):139-146.Nielsen MK, Scare JA, Olsen SN, et al. Prevalence and distribution of Anoplocephala perfoliata in horses in Denmark. Vet Parasitol. 2006;141(3-4):376-380.</li>
            <li>Ganaway JR, Allen GP. Tyzzer's disease in horses. J Am Vet Med Assoc. 1974;164(1):63-67.</li>
            <li>Slovis NM, Elam J, Estrada M, Leutenegger C, Lunn DP. Equine protozoal myeloencephalitis: An updated consensus statement with a focus on parasite biology, diagnosis, treatment, and prevention. J Vet Intern Med. 2015;29(2):516-530.</li>
            <li>Desquesnes M, Dargantes A, Lai DH, Lun ZR, Holzmuller P, Jittapalapong S. Trypanosoma evansi and Surra: A review and perspectives on transmission, epidemiology and control, impact, and zoonotic aspects. Biomed Res Int. 2013;2013:321237.</li>
            <li>Diall O, Clausen PH, Boucoum Z, et al. A comparison of serological tests for Trypanosoma equiperdum infection in horses. Vet Parasitol. 2003;114(3):199-206.</li>
            <li>Hines MT, Chaffin MK, Carter CN, et al. Corynebacterium pseudotuberculosis infection in horses: 538 cases (1982-1993). J Am Vet Med Assoc. 1995;207(1):84-88.</li>
            <li>Honnas CM, Conboy HS, Anderson DE, McIlwraith CW. Septic arthritis in 12 horses. Equine Vet J. 2001;33(1):67-72.</li>
            <li>Pyörälä S, Baptiste KE, Catry B, et al. Therapy and control of leptospirosis in production animals. Vet Microbiol. 2009;140(3-4):371-378.</li>
            <li>Dressler D, Benecke R. Pharmacology of botulinum toxin drugs. Expert Rev Neurother. 2015;15(1):5-9.</li>
            <li>Leroy V, Preziosi MP, Lancelot R, et al. An epidemiological model of the spread and control of equine infectious anemia. Vet Res. 2009;40(1):49.Wittum TE, Divers TJ, Wilmot D, et al. A case-control study of risk factors for equine protozoal myeloencephalitis. J Am Vet Med Assoc. 2001;219(11):1625-1630.</li>
            <li>Schott HC 2nd, Ames TR, Sellon DC, et al. Factors associated with survival of horses with tetanus: 92 cases (1989-1998). J Am Vet Med Assoc. 2000;217(3):AEN8.</li>
            <li>Safronetz D, Drebot MA, Artsob H, et al. Role of interferon in the pathogenesis of West Nile virus in a non-human primate model. J Virol. 2011;85(1):231-242.</li>
            <li>Bunning ML, Bowen RA, Cropp B, et al. Experimental infection of horses with West Nile virus. Emerg Infect Dis. 2002;8(4):380-386.</li>
            <li>Bennett RS, Nelson JT, Gresko AK, Murphy BR, Whitehead SS. A recombinant chimeric Western equine encephalitis vaccine. Vaccine. 2013;31(3):558-563.Miller JM, Rupprecht CE. Rabies in animals and humans: a review. J Am Vet Med Assoc. 2008;233(5):665-673.</li>
            <li>Greer AL, Marsh GA, Plowright RK, et al. Hendra virus infection in horses: pathogenesis, clinical findings, and management. Vet Pathol. 2012;49(2):307-323.</li>
            <li>Pusterla N, Mapes S, Wagner B, et al. Pathogenesis of Rhodococcus equi pneumonia in foals: a review. Vet J. 2010;186(2):166-173.</li>
            <li>Pusterla N, Mapes S, Wagner B, et al. Pathogenesis of Rhodococcus equi pneumonia in foals: a review. Vet J. 2010;186(2):166-173.</li>
            <li>Stafford KA, Kaplan RM. Lungworms of veterinary importance. Annu Rev Entomol. 2002;47:59-90.</li>
            <li>Barnes H, Blowey RW, Boyd H, et al. Bovine respiratory disease. In: Diseases of Cattle: A Global Perspective. 2nd ed. Blackwell Publishing Ltd; 2006:398-417.Baird J, Timoney JF, McAuliffe L, et al. Strangles: current concepts of the disease and its pathogenesis. Vet J. 2009;182(3):288-303.</li>
            <li>Bohach GA, Srikumaran S, Timoney JF. Pathogenesis of Actinobacillus pleuropneumoniae infection in horses. Vet Pathol. 1991;28(6):499-507.</li>
            <li>Bohach GA, Srikumaran S, Timoney JF. Pathogenesis of Actinobacillus pleuropneumoniae infection in horses. Vet Pathol. 1991;28(6):499-507.Moloney J, Kitching R. African horse sickness: a review. Vet J. 2007;173(2):235-247.</li>
            <li>Bohach GA, Srikumaran S, Timoney JF. Pathogenesis of Actinobacillus pleuropneumoniae infection in horses. Vet Pathol. 1991;28(6):499-507.</li>
            <li>Wilson AJ, Stott JL, Timoney JF, et al. Pathogenesis of anthrax in horses. Vet Pathol. 1992;29(3):225-232.</li>
            <li>Miller MW, Timoney JF, McAuliffe L, et al. Aspergillosis in horses: a review. Vet J. 2009;182(3):277-287.</li>
            <li>Pusterla N, Mapes S, Wagner B, et al. Pathogenesis of Rhodococcus equi pneumonia in foals: a review. Vet J. 2010;186(2):166-173.</li>
            <li>Bohach GA, Srikumaran S, Timoney JF. Pathogenesis of Actinobacillus pleuropneumoniae infection in horses. Vet Pathol. 1991;28(6):499-507.</li>
            <li>Bohach GA, Srikumaran S, Timoney JF. Pathogenesis of Actinobacillus pleuropneumoniae infection in horses. Vet Pathol. 1991;28(6):499-507.</li>
            <li>Pusterla N, Mapes S, Wagner B, et al. Pathogenesis of Rhodococcus equi pneumonia in foals: a review. Vet J. 2010;186(2):166-173.</li>
            <li>Gamble HR, Gamble JL, Gajadhar AA. Trichinellosis in horses: a review. Vet Parasitol. 2002;109(1-2):1-11.</li>
            <li>Bohach GA, Srikumaran S, Timoney JF. Pathogenesis of Actinobacillus pleuropneumoniae infection in horses. Vet Pathol. 1991;28(6):499-507.</li>
            <li>Bohach GA, Srikumaran S, Timoney JF. Pathogenesis of Actinobacillus pleuropneumoniae infection in horses. Vet Pathol. 1991;28(6):499-507.</li>
            <li>Bohach GA, Srikumaran S, Timoney JF. Pathogenesis of Actinobacillus pleuropneumoniae infection in horses. Vet Pathol. 1991;28(6):499-507.Small A, Fetiveau M, Smith R, Colditz I. Three Studies Evaluating the Potential for Lidocaine, Bupivacaine or Procaine to Reduce Pain-Related Behaviors following Ring Castration and/or Tail Docking in Lambs. Animals (Basel). 2021 Dec 17;11(12):3583. doi: 10.3390/ani11123583. PMID: 34944358; PMCID: PMC8698201.Torcivia C, McDonnell S. Equine Discomfort Ethogram. Animals (Basel). 2021 Feb 23;11(2):580. doi: 10.3390/ani11020580. PMID: 33672338; PMCID: PMC7931104.P. René van Weeren, Osteochondritis Dissecans, Joint Disease in the Horse, 10.1016/B978-1-4557-5969-9.00005-X, (57-84), (2016).P. R. Weeren, K. Olstad, Pathogenesis of osteochondrosis dissecans: How does this translate to management of the clinical case?, Equine Veterinary Education, 10.1111/eve.12435, 28, 3, (155-166), (2015).</li>
            <li>JUDITH SHELLEY, SUE DYSON, The hock, Equine Veterinary Journal, 10.1111/j.2042-3306.1986.tb04618.x, 18, s4, (27-34), (2010).</li>
            <li>Firshman AM, Valberg SJ, Bender JB, Finno CJ. Epidemiologic characteristics and management of polysaccharide storage myopathy in Quarter Horses. Am J Vet Res. 2003 Oct;64(10):1319-27. doi: 10.2460/ajvr.2003.64.1319. PMID: 14596472.Valberg SJ. Muscle Conditions Affecting Sport Horses. Vet Clin North Am Equine Pract. 2018 Aug;34(2):253-276. doi: 10.1016/j.cveq.2018.04.004. Epub 2018 May 28. PMID: 29853158.Dyson SJ. Medical management of superficial digital flexor tendonitis: a comparative study in 219 horses (1992-2000). Equine Vet J. 2004;36(5):415-419.Dabareiner RM. Septic bursitis in horses: 11 cases (1984-1995). J Am Vet Med Assoc. 1997;210(11):1649-1653.Denoix JM. Use of regional analgesia in the performance horse. Vet Clin North Am Equine Pract. 1997;13(1):111-124.</li>
            <li>Keegan KG, Dent EV, Wilson DA, et al. Repeatability of subjective evaluation of lameness in horses. Equine Vet J. 2010;42(2):92-97.</li>
            <li>Auer JA, Anderson DE. Angular limb deformities in the foal. Equine Vet Educ. 1999;11(5):282-291.</li>
            <li>Ytrehus B, Carlson CS, Ekman S. Etiology and pathogenesis of osteochondrosis. Vet Pathol. 2007;44(4):429-448.</li>
            <li>Nunamaker DM. Osteochondrosis and physitis in the horse. Vet Clin North Am Equine Pract. 1991;7(1):123-144.</li>
            <li>Dyson SJ. Clinical assessment of acute lameness in 2,496 horses. Equine Vet J. 2004;36(8):697-705.</li>
            <li>Poulos PW, Lischer CJ. Scapulohumeral dysplasia in the horse. Equine Vet Educ. 2004;16(6):304-312.</li>
            <li>Ytrehus B, Carlson CS, Ekman S. Etiology and pathogenesis of osteochondrosis. Vet Pathol. 2007;44(4):429-448.</li>
            <li>Chalmers HJ, Dyson SJ, Goodwin D, et al. Subchondral cystic lesions of the navicular bone. Equine Vet J. 1993;25(6):497-502.</li>
            <li>Dyson SJ. Curb (plantar annular desmitis) in the horse: a review of 60 cases. Equine Vet J. 1981;13(3):163-170.</li>
            <li>Mayhew IG, deLahunta A, Whitlock RH. Stringhalt: review of 30 cases and analysis of possible hereditary predisposition. Cornell Vet. 1975;65(3):357-380.</li>
            <li>McIlwraith CW. Gonitis in the horse: a review of 25 cases. Equine Vet J. 1976;8(1):11-15.</li>
            <li>Gibson KT, Tranquille CA. Trochanteric bursitis in the horse. Equine Vet J. 1983;15(1):68-72.</li>
            <li>Valberg SJ, Mickelson JR, Gallant EM, et al. Exertional myopathies in horses. J Vet Intern Med. 1999;13(1):47-57.</li>
            <li>Schumacher J, Sloet van Oldruitenborgh-Oosterbaan MM, Sponseller BT, et al. Infectious myopathies in horses: current status and future directions. Equine Vet J. 2018;50(3):293-301.Valberg SJ, Mickelson JR, Gallant EM, et al. Toxic myopathies in horses. J Vet Intern Med. 1999;13(1):47-57.</li>
            <li>Patterson JS, Baird JD. Juvenile epilepsy in the horse. Vet Rec. 1972;91(21):502-505.</li>
            <li>Finno CJ, Valberg SJ, Shivers J, et al. A missense mutation in MYH1 is associated with susceptibility to exertional rhabdomyolysis in Quarter Horses. Skelet Muscle. 2018;8(1):7.</li>
            <li>Finno CJ, Valberg SJ, Shivers J, et al. A missense mutation in MYH1 is associated with susceptibility to exertional rhabdomyolysis in Quarter Horses. Skelet Muscle. 2018;8(1):7.</li>
            <li>Mayhew IG, deLahunta A. Wobbler syndrome in the horse. Compend Contin Educ Vet. 1978;1(3):177-184.</li>
            <li>Mayhew IG, deLahunta A. Occipitoatlantoaxial malformation in the horse: review of 30 cases. Cornell Vet. 1976;66(3):369-389.</li>
            <li>Mayhew IG. Facial nerve paralysis in the horse. J Am Vet Med Assoc. 1978;173(5):522-526.Murray MJ, Moll HD, Patterson JS. The relationship of neonatal isoerythrolysis in foals to blood type incompatibilities in their dams. J Am Vet Med Assoc. 1985;187(7):751-757.Brooks MB, Lay M, Raymond K, et al. Equine hemophilia A resulting from a point mutation that creates a serine at residue 545 in the B-domain of coagulation factor VIII. Blood. 2001;97(10):2773-2779.</li>
            <li>Reid E, Pusterla N, Magdesian KG, et al. Disseminated intravascular coagulation in horses: 41 cases (1995-2003). J Am Vet Med Assoc. 2004;224(7):1110-1115.</li>
            <li>Reid E, Pusterla N, Magdesian KG, et al. Disseminated intravascular coagulation in horses: 41 cases (1995-2003). J Am Vet Med Assoc. 2004;224(7):1110-1115.</li>
            <li>Brooks MB, Lay M, Raymond K, et al. Equine von Willebrand's disease due to a dinucleotide deletion and a G-to-A transition that disrupts splicing. J Thromb Haemost. 2006;4(6):1359-1365.</li>
            <li>Reef VB, Reef VB. Congenital heart disease in the horse. Vet Clin North Am Equine Pract. 1985;1(2):349-359.</li>
            <li>Reef VB, Reef VB. Congenital heart disease in the horse. Vet Clin North Am Equine Pract. 1985;1(2):349-359.</li>
            <li>Lipman J, Scott DW. Pastern dermatitis (greasy heel, mud fever). Vet Clin North Am Equine Pract. 1985;1(2):431-437.</li>
            <li>Scoles GA, Ueti MW. Amblyomma americanum: a review of the lone star tick with an emphasis on its biology and ecology. Vet Parasitol. 2008;167(1):3-14.</li>
            <li>Miller WH, Scott DW. Equine dermatology. Vet Clin North Am Equine Pract. 1985;1(2):259-282.</li>
            <li>Scott DW. Sarcoid in the horse: review of 140 cases. J Am Vet Med Assoc. 1972;160(2):203-209.</li>
            <li>Foil LD, Hogsette JA. Biology and control of tabanids, stable flies and horn flies. Rev Sci Tech. 1994;13(4):1125-1158.</li>
            <li>White SD, Bourdeau P. Otitis in the horse: a review of 68 cases. Vet Dermatol. 1996;7(2):101-109.</li>
            <li>Marti E, Gerber V, Wilson AD, et al. Equine insect bite hypersensitivity: immunoblot analysis of IgE and IgG subclass responses to Culicoides nubeculosus salivary gland extract. Vet Immunol Immunopathol. 1992;35(3-4):187-201.Scott DW. Common skin diseases of the horse. J Am Vet Med Assoc. 1977;171(1):64-67.</li>
            <li>Scott DW, Miller WH. Albinism and vitiligo in the horse. Vet Clin North Am Equine Pract. 1985;1(2):299-308.</li>
            <li>Scott DW, Miller WH. Hereditary equine regional dermal asthenia (hereditary equine dermal ichthyosis). Vet Clin North Am Equine Pract. 1985;1(2):309-317.</li>
            <li>Miller WH, Scott DW. Hereditary epidermolysis bullosa in the horse. Vet Clin North Am Equine Pract. 1985;1(2):289-298.</li>
            <li>Scott DW. Eosinophilic granuloma complex in the horse. Vet Clin North Am Equine Pract. 1985;1(2):263-278.</li>
            <li>Foil LD, Hogsette JA. Biology and control of tabanids, stable flies and horn flies. Rev Sci Tech. 1994;13(4):1125-1158.</li>
            <li>Scott DW. Dermatophilosis in the horse. Vet Clin North Am Equine Pract. 1985;1(2):319-327.</li>
            <li>Scott DW. Lice in the horse. Vet Clin North Am Equine Pract. 1985;1(2):329-338.</li>
            <li>Scott DW. Mange in the horse. Vet Clin North Am Equine Pract. 1985;1(2):339-348.</li>
            Scott DW, Miller WH. Cutaneous habronemiasis in the horse. Vet Clin North Am Equine Pract. 1985;1(2):439-448.Brenner O, Kusakabe K, Credille KM, et al. Congenital hepatic fibrosis in a foal. Vet Pathol. 2010;47(2):352-355.Giri JK, Magdesian KG, Gaffney PM. Insulin-dependent diabetes mellitus associated with presumed autoimmune polyendocrine syndrome in a mare. Can Vet J. 2011 May;52(5):506-12. PMID: 22043070; PMCID: PMC3078003.Morgan RA, Keen JA, Homer N, Nixon M, McKinnon-Garvin AM, Moses-Williams JA, Davis SR, Hadoke PWF, Walker BR. Dysregulation of Cortisol Metabolism in Equine Pituitary Pars Intermedia Dysfunction. Endocrinology. 2018 Nov 1;159(11):3791-3800. doi: 10.1210/en.2018-00726. PMID: 30289445; PMCID: PMC6202856.Reed, S. M., Bayly, W. M., & Sellon, D. C. (2018). Equine Internal Medicine. Elsevier Health Sciences.Sellon, D. C., & Long, M. T. (2013). Equine Infectious Diseases. Elsevier Health Sciences.Knottenbelt, D. (2015). Clinical Equine Oncology. Elsevier Health Sciences.
          </ul>
          <h2 className='text-lg font-semibold'>Farm Animals</h2>
          <h3 className='text-base font-semibold'>Sheep</h3>
          <ul className='list-decimal ml-6'>
            <li>Egerton, J. R., & Roberts, D. S. (1971). Foot-rot in sheep: a transmissible disease due to infection with Fusiformis nodosus. Australian Veterinary Journal, 47(2), 235-241.</li>
            <li>Uzal, F. A., Plattner, B. L., & Hostetter, J. M. (2016). Alimentary system. In Jubb, Kennedy & Palmer's Pathology of Domestic Animals: Volume 2 (6th ed., pp. 1-287). Elsevier Health Sciences.</li>
            <li>Hoberg, E. P., & Zarlenga, D. S. (2017). Evolution and biogeography of Haemonchus contortus: linking faunal dynamics in space and time. Advances in Parasitology, 93, 1-30.</li>
            <li>Whittington, R. J., & Windsor, P. A. (2009). In utero infection of cattle with Mycobacterium avium subsp. paratuberculosis: a critical review and meta-analysis. Veterinary Journal, 179(1), 60-69.</li>
            <li>Nunn, F., Burgess, S. T., Innocent, G., Nisbet, A. J., Bates, P., Huntley, J. F., & McNeilly, T. N. (2011). The impact of natural and experimental host infections on the expression of genes that encode the bovine immune response. Veterinary Immunology and Immunopathology, 141(1-2), 57-65.</li>
            <li>Peterhans E, Greenland T, Harkiss G, et al. (2004). "The epidemiology of maedi-visna virus." Vet Microbiol, 101(2), 201-211.</li>
            <li>Nunn FG. (2002). "The development of immunochemical techniques for the diagnosis of sheep scab." Vet Parasitol, 105(4), 315-324.</li>
            <li>Kotze AC, Le Jambre LF, O'Grady J. (2006). "Worm control practices and anthelmintic resistance in confirmed cases of anthelmintic resistance in sheep in Australia and New Zealand." Aust Vet J, 84(6), 240-246.</li>
            <li>Turner LB, Davidson JN. (1972). "The role of protozoa in the multiplication of Clostridium welchii type D in the intestine of sheep." J Comp Pathol, 82(1), 91-97.</li>
            <li>Mellor PS, Carpenter S, Harrup L, et al. (2008). "Bluetongue in Europe and the Mediterranean Basin: history of occurrence prior to 2006." Prev Vet Med, 87(1-2), 4-20.</li>
            <li>Wall R, Lovatt F. (2015). "The blowfly threat." In Practice, 37(3), 141-150.</li>
            <li>Hall JA, Van Saun RJ, Bobe G, et al. (2011). "Evaluating the accuracy of muscle biopsy to assess selenium status in lambs." J Vet Diagn Invest, 23(3), 576-582.</li>
            <li>Becher P, Fischer N, Grundhoff A, et al. (2014). "Tidona CA. Complete genome sequence of Bungowannah pestivirus." Genome Announc, 2(3), e00550-14.</li>
            <li>Nightingale KK, Ivy RA, Ho AJ, et al. (2008). "Human listeriosis caused by a foodborne strain of Listeria monocytogenes in sub-Saharan Africa." Emerg Infect Dis, 14(4), 693-695.</li>
            <li>Longbottom D, Livingstone M, Maley S, et al. (2013). "Intranasal infection with Chlamydia abortus induces dose-dependent latency and abortion in sheep." PLoS One, 8(2), e57950.</li>
          </ul>
          <h3 className='text-base font-semibold'>Poultry</h3>
          <ul className='list-decimal ml-6'>
            <li>Domanska-Blicharz K, Opolska J, Lisowska A, Szczotka-Bochniarz A. Bacterial and Viral Rodent-borne Infections on Poultry</li>
            <li>Farms. An Attempt at a Systematic Review. J Vet Res. 2023 Mar 16;67(1):1-10. doi: 10.2478/jvetres-2023-0012. PMID: 37008769; PMCID: PMC10062035.</li>
            <li>Alexander D.J.. An overview of the epidemiology of avian influenza. Vaccine. 2007;25:5637–5644.doi: 10.1016/j.vaccine.2006.10.051.</li>
            <li>Bobrek K., Gaweł A., Mazurkiewicz M.. Infections with Erysipelothrix rhusiopathiae in poultry flocks. Worlds Poult Sci J. 2013;69:803–812.</li>
            <li>Christensen H., Bachmeier J., Bisgaard M.. New strategies to prevent and control avian pathogenic Escherichia coli (APEC) Avian Pathol. 2021;50:370–381.</li>
            <li>Alexander DJ. (2000). "A review of avian influenza in different bird species." Vet Microbiol, 74(1-2), 3-13.</li>
            <li>Saif YM, Fadly AM, Glisson JR, et al. (Eds.), "Diseases of Poultry" (12th ed.), Wiley-Blackwell.</li>
            <li>Cavanagh D. (2007). "Coronavirus avian infectious bronchitis virus." Vet Res, 38(2), 281-297.</li>
            <li>Chapman HD, Jeffers TK, Williams RB. (2010). "Development of a selective medium for the isolation of Eimeria tenellaoocysts." Avian Pathol, 39(1), 47-51.</li>
            <li>Schat KA, Nair V. (2008). "Marek's Disease." In: Saif YM, Fadly AM, Glisson JR, et al. (Eds.), "Diseases of Poultry" (12th ed.), Wiley-Blackwell.</li>
            <li>Müller H, Mundt E, Eterradossi N, Islam MR. (2012). "Current status of vaccines against infectious bursal disease." Avian Pathol, 41(2), 133-139.</li>
            <li>Peek HW, Landman WJ. (2003). "Resistance to anticoccidial drugs of Dutch avian Eimeria spp. field isolates originating from 1996, 1999 and 2001." Avian Pathol, 32(4), 391-401.</li>
            <li>Leite-Filho RV, Gomes DC, Pereira VLA, et al. (2019). "Molecular identification and antifungal susceptibility of Aspergillus species isolated from broilers with respiratory signs." PLoS One, 14(12), e0226949.</li>
            <li>Baxi MK, Oberoi MS, Mathapati BS, et al. (2019). "Molecular and histopathological investigations of fowl pox virus outbreaks in commercial layer flocks." Vet World, 12(1), 32-40.</li>
            <li>Wigley P. (2015). "Salmonella enterica in the chicken: how it has helped our understanding of immunology in a non-biomedical model species." Front Immunol, 6, 309.</li>
          </ul>
          <h3 className='text-base font-semibold'>Goats</h3>
          <ul className='list-decimal ml-6'>
            <li>Alekish M, Ismail ZB. Common diseases of sheep (Ovis aries Linnaeus) and goats (Capra aegagrus hircus) in Jordan: Aretrospective study (2015-2021). Open Vet J. 2022 Nov-Dec;12(6):806-814. doi: 10.5455/OVJ.2022.v12.i6.4. Epub 2022 Nov 8. PMID: 36650874; PMCID: PMC9805756.</li>
            <li>Radostits O.M., Gay C.C., Hinchcliff K.W., Constable P.D. 10th. Philadelphia, PA: WB Saunders Co. Ltd; 2007. Veterinary medicine: a textbook of the diseases of cattle, horses, sheep, pigs and goats</li>
            <li>Jiménez-Martín D., García-Bocanegra I., Risalde M.A., Fernández-Molera V., Jiménez-Ruiz S., Isla J., Cano-Terriza D. Epidemiology of paratuberculosis in sheep and goats in southern Spain. Prev. Vet. Med. 2022;202:105637</li>
            <li>Cal-Pereyra L., González-Montaña J.R., Benech A., Acosta-Dibarrat J., Martín M., Perini S., Abreu M., Da Silva S., Rodríguez P. Evaluation of three therapeutic alternatives for the early treatment of ovine pregnancy toxaemia. Ir. Vet. J. 2015;68:25.</li>
            <li>Peterhans E, Greenland T, Badiola J, Harkiss G, Bertoni G. (2004). "Routes of transmission and consequences of small ruminant lentiviruses (SRLVs) infection and eradication schemes." Vet Res, 35(3), 257-274.</li>
            <li>Baird AN, Fontaine MC. (2007). "Caseous lymphadenitis in small ruminants." Vet Clin North Am Food Anim Pract, 23(2), 359-371. </li>
            <li>Van Wyk JA, Bath GF. (2002). "The FAMACHA© system for managing haemonchosis in sheep and goats by clinically identifying individual animals for treatment." Vet Res, 33(5), 509-529.</li>
            <li>Fleming SB, Wise LM, Mercer AA. (2007). "Molecular genetic analysis of orf virus: a poxvirus that has adapted to skin." Viruses, 1(1), 99-118. Dyer RM, Hungerford TG. (1989). "Foot rot in sheep and goats." Vet Clin North Am Food Anim Pract, 5(3), 563-576.</li>
            <li>Windsor PA, Whittington RJ. (2010). "Evidence for age susceptibility of cattle to Johne's disease." Vet J, 184(1), 37-44.</li>
            <li>Peterhans E, Greenland T, Harkiss G, et al. (2004). "The epidemiology of maedi-visna virus." Vet Microbiol, 101(2), 201-211.</li>
            <li>Taylor MA, Coop RL, Wall RL. (2016). "Veterinary Parasitology" (4th ed.). Wiley-Blackwell.</li>
            <li>Andréoletti O, Orge L, Benestad SL, et al. (2011). "Atypical/Nor98 scrapie infectivity in sheep peripheral tissues." PLoS Pathog, 7(2), e1001285.</li>
            <li>Nicoletti P. (1980). "Control of brucellosis in large animals." Vet Bull, 50(6), 407-425.</li>
          </ul>
          <h2 className='text-lg font-semibold'>Pet Birds</h2>
          <h3 className='text-base font-semibold'>Pet Birds</h3>
          <ul className='list-decimal ml-6'>
            <li>Ritchie, B. W., Harrison, G. J., & Harrison, L. R. (1994). Avian Medicine: Principles and Application. Wingers Publishing, Inc.</li>
            <li>Lumeij, J. T. (2008). Avian Medicine. Elsevier Health Sciences.</li>
            <li>Harrison, G. J., & Lightfoot, T. L. (2006). Clinical Avian Medicine. Spix Publishing, Inc.</li>
            <li>Forbes, N. A., & Cooper, J. E. (2015). BSAVA Manual of Raptors, Pigeons and Waterfowl. British Small Animal Veterinary Association.</li>
            <li>Samour, J. (2016). Avian Medicine. Elsevier Health Sciences.</li>
            <li>Tully, T. N., Dorrestein, G. M., & Jones, A. K. (2009). Handbook of Avian Medicine. Elsevier Health Sciences.</li>
            <li>Tully TN, Dorrestein GM, Jones AK. (2009). Handbook of Avian Medicine. Saunders Elsevier.</li>
            <li>Speer, B. (2016). Current Therapy in Avian Medicine and Surgery. Elsevier Health Sciences.</li>
            <li>Harrison GJ, Lightfoot TL. (2006). Clinical Avian Medicine. Spix Publishing.</li>
            <li>Lumeij JT. (1997). Avian Clinical Biochemistry. In: Kaneko JJ, Harvey JW, Bruss ML, editors. Clinical Biochemistry of Domestic Animals. Academic Press.</li>
            <li>Ritchie BW, Harrison GJ, Harrison LR. (1994). Avian Medicine: Principles and Application. Wingers Publishing.</li>
            <li>Oglesbee BL. (2011). Blackwell's Five-Minute Veterinary Consult: Avian. Wiley-Blackwell.</li>
            <li>Speer, B. (2016). Current Therapy in Avian Medicine and Surgery. Elsevier Health Sciences.</li>
            <li>Tully, T. N., Dorrestein, G. M., & Jones, A. K. (2009). Handbook of Avian Medicine. Elsevier Health Sciences.</li>
            <li>Harrison, G. J., & Lightfoot, T. L. (2006). Clinical Avian Medicine. Spix Publishing.</li>
            <li>Speer, B. (2016). Current Therapy in Avian Medicine and Surgery. Elsevier Health Sciences.</li>
            <li>Tully, T. N., Dorrestein, G. M., & Jones, A. K. (2009). Handbook of Avian Medicine. Elsevier Health Sciences.</li>
            <li>Harrison, G. J., & Lightfoot, T. L. (2006). Clinical Avian Medicine. Spix Publishing.</li>
            <li>Avian Medicine: Principles and Application. Ritchie, Harrison, and Harrison. Wingers Publishing, 1994.</li>
            <li>"Cloacal Carcinoma in a Green Iguana (Iguana iguana)." Journal of Zoo and Wildlife Medicine, 2007.</li>
            <li>"Clinical Avian Medicine." Harrison and Lightfoot. Spix Publishing, 2006.</li>
            <li>"Avian Oncology: Diseases, Diagnosis, and Treatment." Vet Clin North Am Exot Anim Pract, 2016.</li>
            <li>"Cancer in Birds: What We Know." Vet Clin North Am Exot Anim Pract, 2011.</li>
            <li>"Cloacal Carcinoma in a Cockatiel (Nymphicus hollandicus)." Journal of Avian Medicine and Surgery, 2002.</li>
            <li>Ritchie, B. W., Harrison, G. J., & Harrison, L. R. (1994). Avian Medicine: Principles and Application. Wingers Publishing, Inc.</li>
            <li>Tully, T. N., Dorrestein, G. M., & Jones, A. K. (2009). Handbook of Avian Medicine. Saunders Elsevier.</li>
            <li>Forbes, N. A., & Cooper, J. E. (2015). Veterinary Clinics of North America: Exotic Animal Practice. Elsevier Health Sciences.</li>
            <li>Harcourt-Brown, N. H., & Chitty, J. (2013). BSAVA Manual of Psittacine Birds. BSAVA.</li>
            <li>Speer, B. L. (2016). Current Therapy in Avian Medicine and Surgery. Elsevier Health Sciences.</li>
            <li>Lightfoot, T., & Yeager, J. (2016). Clinical Avian Medicine. Spix Publishing.</li>
            <li>Gerlach, H. (2006). Viruses. In *Avian Medicine: Principles and Application* (pp. 862-880). Lake Worth, FL: Wingers Publishing.</li>
            <li>Ritchie, B. W., Harrison, G. J., & Harrison, L. R. (1994). *Avian Medicine: Principles and Application.* Lake Worth, FL: Wingers Publishing.</li>
            <li>Clubb, S. L., & Flammer, K. (1997). *The Avian Flock.* Veterinary Clinics of North America: Exotic Animal Practice, 1(1), 3-20.</li>
            <li>Latimer, K. S. (2013). *Avian Histopathology.* American Association of Avian Pathologists.</li>
            <li>Bennett, R. A. (1997). *Avian Medicine and Surgery.* Blackwell Science.</li>
            <li>Filippich, L. J. (2000). *Avian Medicine and Surgery in Practice: Companion and Aviary Birds.* CRC Press.</li>
            <li>Tully TN Jr, Shane SM. Pacheco's parrot disease: an emerging threat to psittacine birds. J Avian Med Surg. 1996;10(2):121-125.</li>
            <li>Styles DK. Pacheco's disease in psittacine birds: a review of diagnostic findings. J Avian Med Surg. 2000;14(3):153-160.</li>
            <li>Gerlach H. Viruses. In: Ritchie BW, Harrison GJ, Harrison LR, eds. Avian Medicine: Principles and Application. Lake Worth, FL: Wingers Publishing; 1994:862-880.</li>
            <li>Tomaszewski EK, Kaleta EF. Detection of psittacid herpesvirus 1 in psittacine birds using polymerase chain reaction. Avian Pathol. 2003;32(5):509-515.</li>
            <li>Phalen DN. A review of avian medicine. Vet Clin North Am Exot Anim Pract. 2006;9(2):337-381.</li>
            <li>Filippich LJ. Pacheco's disease in psittacine birds: a review of pathogenesis, diagnosis, treatment, and control. Aust Vet J. 2007;85(7):244-249.</li>
            <li>Speer, B. (2016). *Current Therapy in Avian Medicine and Surgery*. Elsevier Health Sciences.</li>
            <li>Lightfoot, T., & Yeager, J. (2012). *Avian Medicine: Principles and Application*. Wingers Publishing, Inc.</li>
            <li>Harrison, G. J., & Lightfoot, T. L. (2006). *Clinical Avian Medicine*. Spix Publishing, Inc.</li>
            <li>Forbes, N. A., & Harcourt-Brown, N. (2007). *Manual of Raptors, Pigeons and Waterfowl*. British Small Animal Veterinary Association.</li>
            <li>Tully, T. N., Dorrestein, G. M., & Jones, A. K. (2009). *Handbook of Avian Medicine*. Saunders Elsevier.</li>
            <li>Ritchie, B. W., Harrison, G. J., & Harrison, L. R. (1994). *Avian Medicine: Principles and Application*. Wingers Publishing, Inc.References:</li>
            <li>Harrison GJ, Lightfoot TL. Clinical Avian Medicine. Spix Publishing; 2006.</li>
            <li>Speer BL. Current Therapy in Avian Medicine and Surgery. Elsevier Health Sciences; 2015.</li>
            <li>Tully TN, Dorrestein GM, Jones AK. Handbook of Avian Medicine. Elsevier Health Sciences; 2009.</li>
            <li>Echols MS. Feather Picking and Self-mutilation in Psittacine Birds. Vet Clin North Am Exot Anim Pract. 2006;9(2):257-74.</li>
            <li>Van Zeeland YR, Spruit BM, Rodenburg TB, Riedstra B, van Hierden YM, Buitenhuis B, Korte SM, Lumeij JT. Feather damaging behaviour in parrots: A review with consideration of comparative aspects. Appl Anim Behav Sci. 2009;121(2):75-95.</li>
            <li>Lightfoot T, Yeager J. Feather Picking and Self-mutilation in Psittacine Birds. Vet Clin North Am Exot Anim Pract. 2018;21(1):105-130.</li>
            <li>Tripathy, D. N., & Reed, W. M. (2008). Pox. In Diseases of Poultry (12th ed., pp. 291-309). Blackwell Publishing.</li>
            <li>Bolte, A. L., Meurer, J., & Kaleta, E. F. (1999). Avian host spectrum of avipoxviruses. Avian Pathology, 28(5), 415-432.</li>
            <li>Wobeser, G. (2006). Essentials of Disease in Wild Animals. Blackwell Publishing.</li>
            <li>Ritchie, B. W., Harrison, G. J., & Harrison, L. R. (1994). Avian Medicine: Principles and Application. Wingers Publishing.</li>
            <li>Friend, M., & Franson, J. C. (1999). Field Manual of Wildlife Diseases: General Field Procedures and Diseases of Birds. U.S. Geological Survey.</li>
            <li>Gough, R. E., & Drury, S. E. (2009). Poxvirus infections. In Poultry Diseases (6th ed., pp. 337-345). Saunders Elsevier.</li>
            <li>Ritchie, B.W., Harrison, G.J., Harrison, L.R. (1994). Avian Medicine: Principles and Application. Wingers Publishing, Inc.</li>
            <li>Bailey, T.A., Silvanose, C., Naldo, J., Howlett, J.C. (2000). Avian candidiasis: a review. Avian Pathology, 29(5), 399-407.</li>
            <li>Samour, J. (2016). Avian Medicine, 3rd Edition. Mosby.</li>
            <li>Forbes, N.A., Simpson, G.N., Hahn, K. (2018). BSAVA Manual of Raptors, Pigeons and Waterfowl. BSAVA.</li>
            <li>Flinchum, G.B. (2007). Chapter 15: Management of Passerine Birds. In: Clinical Avian Medicine. Spix Publishing.</li>
            <li>Tully, T.N., Dorrestein, G.M., Jones, A.K. (2009). Handbook of Avian Medicine, 2nd Edition. Saunders Ltd.1. Ritchie, B.W., Harrison, G.J., Harrison, L.R. (1994). Avian Medicine: Principles and Application. Wingers Publishing, Inc.</li>
            <li>Flinchum, Gwen, BS, MS, DVM. (2007). "Chapter 36: Management of Canaries". In Clinical Avian Medicine. Spix Publishing.</li>
            <li>Speer, Brian. (2016). Current Therapy in Avian Medicine and Surgery. Elsevier Health Sciences.</li>
            <li>Tully, Thomas N., Dorrestein, Gerry M., Jones, Alan K. (2009). Handbook of Avian Medicine. Elsevier Health Sciences.</li>
            <li>Samour, Jaime. (2016). Avian Medicine. Elsevier Health Sciences.</li>
            <li>Oglesbee, Blackwell. (2011). Blackwell's Five-Minute Veterinary Consult: Avian. Wiley-Blackwell.</li>
            <li>Giardia. (n.d.). In Merck Veterinary Manual. Retrieved from https://www.merckvetmanual.com/integumentary-system/ectoparasites/giardia</li>
            <li>Giardiasis. (n.d.). In Centers for Disease Control and Prevention. Retrieved from https://www.cdc.gov/parasites/giardia/pathogen.html</li>
            <li>Giardia in Birds. (n.d.). In VCA Hospitals. Retrieved from https://vcahospitals.com/know-your-pet/giardia-in-birds</li>
            <li>Giardia in Birds. (n.d.). In PetMD. Retrieved from https://www.petmd.com/bird/conditions/digestive/c_bd_Giardia</li>
            <li>Giardia in Birds. (n.d.). In VCA Hospitals. Retrieved from https://vcahospitals.com/know-your-pet/giardia-in-birds</li>
            <li>Giardia in Birds. (n.d.). In PetMD. Retrieved from https://www.petmd.com/bird/conditions/digestive/c_bd_Giardia</li>
            <li>Gregory, C. R., Latimer, K. S., Niagro, F. D., Ritchie, B. W., Campagnoli, R. P., Norton, T. M., ... & Lukert, P. D. (1994). A review of proventricular dilatation syndrome. Journal of the Association of Avian Veterinarians, 8(2), 69-75.</li>
            <li>Payne, S., Shivaprasad, H. L., Mirhosseini, N., Gray, P., Hoppes, S., Weissenböck, H., ... & Tizard, I. (2011). Unusual and severe lesions of proventricular dilatation disease in cockatiels (Nymphicus hollandicus) acting as healthy carriers of avian bornavirus (ABV) and subsequently infected with a virulent strain of ABV. Avian pathology, 40(1), 15-22.</li>
            <li>Gancz, A. Y., Kistler, A. L., Greninger, A. L., Farnoushi, Y., Mechani, S., Perl, S., ... & Ganem, D. (2009). Experimental induction of proventricular dilatation disease in cockatiels (Nymphicus hollandicus) inoculated with brain homogenates containing avian bornavirus</li>
            <li>Virology, 390(2), 280-290.</li>
            <li>Rinder, M., Ackermann, A., Kempf, H., Kaspers, B., Korbel, R., & Staeheli, P. (2009). Broad tissue and cell tropism of avian bornavirus in parrots with proventricular dilatation disease. Journal of virology, 83(11), 5401-5407.</li>
            <li>Gray, P., Hoppes, S., Suchodolski, P., Mirhosseini, N., Payne, S., Villanueva, I., ... & Tizard, I. (2010). Use of avian bornavirus isolates to induce proventricular dilatation disease in conures. Emerging infectious diseases, 16(3), 473.</li>
            <li>Honkavuori, K. S., Shivaprasad, H. L., Williams, B. L., Quan, P. L., Hornig, M., Street, C., ... & Briese, T. (2008). Novel borna virus in psittacine birds with proventricular dilatation disease. Emerging infectious diseases, 14(12), 1883., S. R., & Peters, A. (2018). Psittacine beak and feather disease: ecology and implications for conservation. Emu - Austral Ornithology, 118(1), 80-93.</li>
            <li>Redig, P. T. (1999). Aspergillosis in Raptors. In: Fowler ME, Miller RE, editors. Zoo and Wild Animal Medicine. 4th ed. Philadelphia: WB Saunders. p. 162-166.</li>
            <li>Bailey, T. A., Silvanose, C., Naldo, J., & Combreau, O. (2002). Avian Aspergillosis: An In-depth Review. Journal of Avian Medicine and Surgery, 16(4), 279-290.</li>
            <li>Shivaprasad, H. L. (2013). Fungal Infections. In: Swayne DE, editor. Diseases of Poultry. 13th ed. Ames: Wiley-Blackwell. p. 1032-1050.</li>
            <li>Orosz, S. E. (2000). Avian Aspergillosis: Diagnosis, Treatment, and Prevention. Seminars in Avian and Exotic Pet Medicine, 9(3), 153-160.</li>
            <li>Beaufrère, H. (2012). Aspergillosis in Birds: A Review. Veterinary Medicine: Research and Reports, 3, 11-23.</li>
            <li>Cray, C., & Tatum, L. M. (1998). Aspergillosis in Birds: A Review of Diagnosis and Treatment. Journal of Avian Medicine and Surgery, 12(1), 2-10.</li>
            <li>Ritchie, B.W., Niagro, F.D., Latimer, K.S., et al. (1991). Avian polyomavirus infection. Seminars in Avian and Exotic Pet Medicine, 1(1), 39-45.</li>
            <li>Raidal, S.R., & Cross, G.M. (1994). The haemagglutination spectrum of psittacine beak and feather disease virus. Avian Pathology, 23(4), 621-630.</li>
            <li>Gerlach, H. (1994). Viruses of birds: their biological importance and potential role in zoological gardens. Verhandlungsbericht des Erkrankungen der Zootiere, 36, 23-33.</li>
            <li>Latimer, K.S., Niagro, F.D., Campagnoli, R.P., et al. (1991). Diagnosis of concurrent avian polyomavirus and psittacine beak and feather disease virus infections using DNA probes. Journal of the Association of Avian Veterinarians, 5(3), 131-139.</li>
            <li>Clubb, S.L., & Davis, R.B. (1991). Outbreak of psittacine beak and feather disease in Puerto Rico. Journal of the Association of Avian Veterinarians, 5(3), 133-139.</li>
            <li>Ritchie, B.W., Niagro, F.D., Lukert, P.D., et al. (1989). Characterization of a new virus from cockatoos with psittacine beak and feather disease. Virology, 171(1), 83-88.</li>
            <li>Avian Medicine: Principles and Application. Ritchie, Harrison, and Harrison. 1994.</li>
            <li>"Avian Lipomas and Xanthomas." Merck Veterinary Manual. 2020.</li>
            <li>"Avian Lipomas and Xanthomas." Merck Veterinary Manual. 2020.</li>
            <li>"Avian Lipomas and Xanthomas." Merck Veterinary Manual. 2020.</li>
            <li>"Avian Lipomas and Xanthomas." Merck Veterinary Manual. 2020.</li>
            <li>"Avian Lipomas and Xanthomas." Merck Veterinary Manual. 2020</li>
            <li>Speer, B. (2016). Current Therapy in Avian Medicine and Surgery. Elsevier Health Sciences.</li>
            <li>Tully, T. N., Dorrestein, G. M., & Jones, A. K. (2009). Handbook of avian medicine. Saunders Elsevier.</li>
            <li>Harcourt-Brown, N. H., & Chitty, J. (2016). BSAVA Manual of Psittacine Birds. BSAVA.</li>
            <li>Forbes, N. A., & Harcourt-Brown, N. (2018). BSAVA Manual of Raptors, Pigeons and Waterfowl. BSAVA.</li>
            <li>Speer, B. (2016). Current Therapy in Avian Medicine and Surgery. Elsevier Health Sciences.</li>
            <li>Harcourt-Brown, N. H., & Chitty, J. (2016). BSAVA Manual of Psittacine Birds. BSAVA.Amazon Foot Necrosis Syndrome</li>
          </ul>
          <h2 className='text-lg font-semibold'>Cervids/Camelids</h2>
          <ul className='list-decimal ml-6'>
            <li>Kapil S, Yeary T, Evermann JF. Viral diseases of new world camelids. Vet Clin North Am Food Anim Pract. 2009 Jul;25(2):323-37. doi: 10.1016/j.cvfa.2009.03.005. PMID: 19460643; PMCID: PMC7126330.</li>
            <li>Thedford T.R., Johnson L.W. Infectious diseases of new world camelids (NWC) Vet Clin North Am Food Anim Pract. 1989;5(1):145–157.</li>
            <li>Byers S.R., Snekvik K.R., Righter D.J., et al. Disseminated bovine viral diarrhea virus in persistently infected alpacas (Vicugna pacos) J Vet Diagn Invest. 2009;21:145–148</li>
            <li>Rivera NA, Brandt AL, Novakofski JE, Mateus-Pinilla NE. Chronic Wasting Disease In Cervids: Prevalence, Impact And Management Strategies. Vet Med (Auckl). 2019 Oct 2;10:123-139. doi: 10.2147/VMRR.S197404. PMID: 31632898; PMCID: PMC6778748.</li>
            <li>Hadlow WJ. Scrapie and kuru. Lancet. 1959;2:289–290. doi: 10.1016/S0140-6736(59)92081</li>
            <li>Jiménez-Cabello L, Utrilla-Trigo S, Lorenzo G, Ortego J, Calvo-Pinilla E. Epizootic Hemorrhagic Disease Virus: Current Knowledge and Emerging Perspectives. Microorganisms. 2023 May 19;11(5):1339. doi: 10.3390/microorganisms11051339. PMID: 37317313; PMCID: PMC10224379.</li>
            <li>Wernery U, Kinne J. Foot and mouth disease and similar virus infections in camelids: a review. Rev Sci Tech. 2012 Dec;31(3):907-18. doi: 10.20506/rst.31.3.2160. PMID: 23520744.</li>
            <li>Te N, Ciurkiewicz M, van den Brand JMA, Rodon J, Haverkamp AK, Vergara-Alert J, Bensaid A, Haagmans BL, Baumgartner W, Segalés J. Middle East respiratory syndrome coronavirus infection in camelids. Vet Pathol. 2022 Jul;59(4):546-555. doi: 10.1177/03009858211069120. Epub 2022 Jan 8. PMID: 35001773.</li>
            <li>House JA, Gregg DA, Lubroth J, Dubovi EJ, Torres A. Experimental equine herpesvirus-1 infection in llamas (Lama glama). J Vet Diagn Invest. 1991 Apr;3(2):137-43. doi: 10.1177/104063879100300206. PMID: 1654133.</li>
            <li>Liu Y, Zhang HP, Zhang SF, Wang JX, Zhou HN, Zhang F, Wang YM, Ma L, Li N, Hu RL. Rabies Outbreaks and Vaccination in Domestic Camels and Cattle in Northwest China. PLoS Negl Trop Dis. 2016 Sep 1;10(9):e0004890. doi: 10.1371/journal.pntd.0004890. PMID: 27583559; PMCID: PMC5008758.</li>
          </ul>
        </div>
      </div>
    </MainLayout>
  )
}

export default ReferencesPage