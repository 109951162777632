import { useEffect } from 'react'
import { useQuery } from '@apollo/client'
import { GET_SUBCATEGORY_QUIZ_QUERY } from './queries'
import IncompleteQuiz from './incomplete-quiz'
import CompleteQuiz from './complete-quiz'

const Quiz = ({ subcategoryId }) => {

  const { loading, error, data, refetch } = useQuery(GET_SUBCATEGORY_QUIZ_QUERY, {
    variables: {
      subcategoryId
    }
  })

  useEffect(() => {
    if (data) {
      document.title = `Patch Education — Quiz`;
    }
  }, [data]);

  if (!data) { return null }

  return (
    <div className='w-100 h-full bg-gray-100 p-8 rounded-md border'>
      {!data.subcategoryQuiz.completedAt && <IncompleteQuiz quiz={data.subcategoryQuiz} refetch={refetch} />}
      {data.subcategoryQuiz.completedAt && <CompleteQuiz quiz={data.subcategoryQuiz} />}
    </div>
  )

}

export default Quiz