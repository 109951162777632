import { useQuery } from '@apollo/client'
import { GET_ARTICLE_QUIZ_QUERY } from './queries'
import IncompleteQuiz from './incomplete-quiz'
import CompleteQuiz from './complete-quiz'

const ArticleQuiz = ({ articleId }) => {

  const { loading, error, data, refetch } = useQuery(GET_ARTICLE_QUIZ_QUERY, {
    variables: {
      articleId
    }
  })

  console.log('articleQuiz', data)
  console.log('articleQuiz error', error)

  if (!data) { return null }

  return (
    <div className='w-100 h-full bg-gray-100 p-4 md:p-8 rounded-md border my-8 md:my-0'>
      <h2 className='text-lg font-bold mb-8'>Test Your Understanding</h2>
      {!data.articleQuiz.completedAt && <IncompleteQuiz quiz={data.articleQuiz} refetch={refetch} />}
      {data.articleQuiz.completedAt && <CompleteQuiz quiz={data.articleQuiz} />}
    </div>
  )

}

export default ArticleQuiz