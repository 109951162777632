import { useState, useEffect } from 'react'
import MainLayout from "../../components/main-layout"
import { GET_AUDIO_ARTICLES_QUERY, GET_ARTICLE_AUDIO_QUERY } from './queries'
import { useQuery } from "@apollo/client"
import AudioPlayer from 'react-h5-audio-player';
import 'react-h5-audio-player/lib/styles.css';
import Markdown from 'react-markdown'

const minutesSeconds = (duration) => {
  return `${Math.floor(duration / 60)}:${parseInt(duration % 60).toString().padStart(2, '0') }`
}


const Audio = ({ articleId, onNext, onPrev }) => {
  const { data, error } = useQuery(GET_ARTICLE_AUDIO_QUERY, {
    variables: {
      id: articleId,
    }
  })

  return (
    data?.articleAudio && <AudioPlayer
      // autoPlay
      src={data.articleAudio}
      onPlay={e => console.log("onPlay")}
      showSkipControls
      onClickNext={onNext}
      onClickPrevious={onPrev}

    // other props here
    />
  )


}

const ListenPage = () => {

  const [currentCategory, setCurrentCategory] = useState('swine')
  const [files, setFiles] = useState([])
  const [currentFileIndex, setCurrentFileIndex] = useState(0)

  const categories = [
    { title: 'Swine', slug: 'swine' },
    { title: 'Equine', slug: 'equine' },
    { title: 'Canine/Feline', slug: 'canine-feline' },
    { title: 'Bovine', slug: 'bovine' },
    { title: 'Farm Animals', slug: 'farm-animals' },
    { title: 'Exotics', slug: 'exotics' },
    { title: 'Cervids/Camelids', slug: 'cervids-camelids' }
    // { link: '/course/ethics', title: 'Ethics (Placeholder)', photoUrl: 'https://patch-education-assets.s3.us-west-1.amazonaws.com/ethics.png' }
  ]


  const { loading, error, data, refetch } = useQuery(GET_AUDIO_ARTICLES_QUERY, {
    variables: {
      category: currentCategory
    },
    nextFetchPolicy: 'cache-first',
    onCompleted: (data) => {
      setFiles(data.audioArticles)
    }
  })

  useEffect(() => {
    document.title = `Patch Education — Listen`;
  }, []);

  const onChangeCategory = (slug) => {
    setCurrentCategory(slug)
    setCurrentFileIndex(0)
    refetch()
  }

  const onNext = () => {
    if (currentFileIndex < (files.length - 1)) {
      setCurrentFileIndex(prev => prev + 1)
    }
  }
  
  const onPrev = () => {
    if (currentFileIndex > 0) {
      setCurrentFileIndex(prev => prev - 1)
    }
  }

  return (
    <MainLayout>
      <div className='p-4 md:p-8'>
        <h1 className='text-2xl font-bold mb-4'>Listen</h1>
        <p>Listen to the audio form of this study guide in continuous form below.</p>

        <div className='fixed bottom-0 left-0 right-0'>
          {files.length > 0 && <Audio articleId={files[currentFileIndex].id} onNext={onNext} onPrev={onPrev} />}
        </div>

        <div className="sm:block overflow-x-scroll">
          <div className="border-b border-slate-200">
            <nav className="-mb-px flex space-x-8" aria-label="Tabs">
              {categories.map(({ title, slug }) => (
                <a onClick={() => onChangeCategory(slug)} className={`${currentCategory == slug ? 'border-indigo-500 text-indigo-600' : 'border-transparent text-slate-500 hover:border-slate-300 hover:text-slate-700'} whitespace-nowrap border-b-2 py-4 px-1 text-sm font-medium cursor-pointer`}>{title}</a>
              ))}
            </nav>
          </div>
        </div>

        <ul className='mb-20 mt-8 divide-y divide-slate-100 border rounded-lg pt-2'>
          {files.map((article, i) => (
            <>
              <li 
                onClick={() => setCurrentFileIndex(i)}
                className={`${i === currentFileIndex ? 'text-black font-semibold' : 'text-slate-800 cursor-pointer'} flex flex-row justify-between text-sm px-4 py-4`}
              >
                <span>[{article.category.title}] ({article.subcategory.title}) <Markdown components={{
                  p(props) {
                    const { node, ...rest } = props
                    return <span {...rest} />
                  },
                  em(props) {
                    const { node, ...rest } = props
                    return <i className='font-italic' {...rest} />
                  }
                }}>{article.title}
                </Markdown>
                </span>
                <span>{minutesSeconds(article.audioDuration)}</span>
              </li>
              {/* <li>{article.presignedAudioUrl}</li> */}
            </>
          ))}
        </ul>
      </div>
    </MainLayout>
  )
}

export default ListenPage