import { useEffect, useMemo, useState } from "react";
import MainLayout from "components/main-layout"
import { Link } from "react-router-dom"
import { SMALL_ANIMAL_DRUGS } from './small-animal-drugs'
import { LARGE_ANIMAL_DRUGS } from './large-animal-drugs'
import { shuffle } from 'underscore'

const Card = ({ drug, nextCard, showBack, setShowBack }) => {

  const onNextCard = () => {
    nextCard()
  }

  if (showBack) {
    return (
      <div onClick={onNextCard} className='cursor-pointer bg-white flex flex-col items-center justify-start shadow-lg shadow-xl rounded-lg py-8 px-4 w-64 h-96 border border-slate-200'>
        <div className='border-b-2 border-black mb-12 w-full'>
          <p className='text-center w-100 text-base pb-2 font-bold'>{drug.name}</p>
        </div>
        <p className='w-100 text-sm text-center font-bold'>Class</p>
        <p className='w-100 text-sm text-center text-slate-800 mb-8'>{drug.class}</p>
        <p className='w-100 text-sm text-center font-bold text-slate-800'>Side Effects</p>
        <p className='w-100 text-sm text-center text-slate-800'>{drug.side_effects}</p>
      </div>
    )
  } else {
    return (
      <div onClick={() => setShowBack(true)} className='cursor-pointer bg-white flex flex-col items-center justify-center shadow-lg shadow-xl rounded-md py-8 px-4 w-64 h-96 border border-slate-200'>
        <p className='text-center w-100 mb-12 text-xl font-bold'>{drug.name}</p>
      </div>
    )
  }

}

const Tab = ( { size, cardIndex, setCardIndex, showBack, setShowBack }) => {
  

  const DRUGS = useMemo(() => size == 'small' ? SMALL_ANIMAL_DRUGS : LARGE_ANIMAL_DRUGS, [size])

  const drugsByLetter = useMemo(() => {
    return DRUGS.reduce((acc, item) => {
      // Get the first character of the "name"
      const firstChar = item.name[0];

      // If the property doesn't exist, create it as an array
      if (!acc[firstChar]) {
        acc[firstChar] = [];
      }

      // Push the current item into the correct array
      acc[firstChar].push(item);

      return acc;
    }, {});
  }, [size])

  const shuffledDrugs = useMemo(() => {
    return shuffle(DRUGS)
  }, [size])

  const prevCard = () => {
    setShowBack(false)
    setCardIndex(prev => {
      if (prev == 0) {
        return prev
      } else {
        return prev - 1
      }
    })
  }

  const nextCard = () => {
    setShowBack(false)
    setCardIndex(prev => {
      if (prev == shuffledDrugs.length - 1) {
        return prev
      } else {
        return prev + 1
      }
    })
  }

  return (
    <>
    <div className='flex flex-col items-center justify-center w-full mt-12 bg-slate-100 py-20'>
      <Card showBack={showBack} drug={shuffledDrugs[cardIndex]} nextCard={nextCard} setShowBack={setShowBack}/>
      <p className='text-slate-500 text-sm mt-2'>
          {cardIndex === 0 ? <span className='select-none mr-4 cursor-pointer text-lg font-light align-middle leading-tight'>&nbsp;</span> : <span onClick={prevCard} className='select-none mr-2 cursor-pointer text-lg font-light align-middle leading-tight'>&larr;</span>}
          <span className='select-none leading-tight'>{cardIndex + 1} / {shuffledDrugs.length}</span>
          {cardIndex === shuffledDrugs.length - 1 ? <span className='select-none ml-4 cursor-pointer text-lg font-light align-middle leading-tight'>&nbsp;</span> : <span onClick={nextCard} className='select-none ml-2 cursor-pointer text-lg font-light align-middle leading-tight'>&rarr;</span>}
      </p>
    </div>
    <div className='md:p-8 pt-12'>
      {Object.keys(drugsByLetter).map(letter => (
        <div className='flex flex-col w-full mb-20'>
          <h3 className='text-2xl font-bold mb-2'>{letter.toUpperCase()}</h3>
          <div className='border-t border-slate-400 w-full'>
            <ul>
              {drugsByLetter[letter].map(drug => (
                <li className='mt-8'>
                  <p className='font-lg font-bold'>{drug.name} {drug.brand && `(${drug.brand})`}</p>
                  <p className='text-slate-800'>
                    <span className='font-medium text-black'>Class:&nbsp;</span>
                    {drug.class}
                  </p>
                  <p className='text-slate-700'>
                    <span className='font-medium text-black'>Side Effects:&nbsp;</span>
                    {drug.side_effects}
                  </p>
                </li>
              ))}
            </ul>
          </div>
        </div>
      ))}
    </div>
    </>
  )
}

const PharmacologyPage = () => {

  const [currentTab, setCurrentTab] = useState('small')
  const [cardIndex, setCardIndex] = useState(0)
  const [showBack, setShowBack] = useState(false)

  const changeTab = (newTab) => {
    setCardIndex(0)
    setShowBack(false)
    setCurrentTab(newTab)
  }
  

  useEffect(() => {
    document.title = `Patch Education — Pharmacology`;
  }, []);

  return (
    <MainLayout>
      <div>
        <div className='md:pt-8 p-2 flex flex-col lg:flex-row'>
          <div className='w-full'>
            <h1 className='md:px-8 text-2xl font-bold mt-4'>Pharmacology</h1>
            <div className="sm:block">
              <div className="border-b border-slate-200">
                <nav className="-mb-px flex space-x-8 md:px-8 px-2" aria-label="Tabs">
                  {/* <!-- Current: "border-indigo-500 text-indigo-600", Default: "border-transparent text-slate-500 hover:border-slate-300 hover:text-slate-700" --> */}
                  <a onClick={() => changeTab('small')} className={`${currentTab == 'small' ? 'border-indigo-500 text-indigo-600' : 'border-transparent text-slate-500 hover:border-slate-300 hover:text-slate-700'} whitespace-nowrap border-b-2 py-4 px-1 text-sm font-medium cursor-pointer`}>Small Animal</a>
                  <a onClick={() => changeTab('large')} className={`${currentTab == 'large' ? 'border-indigo-500 text-indigo-600' : 'border-transparent text-slate-500 hover:border-slate-300 hover:text-slate-700'} whitespace-nowrap border-b-2 py-4 px-1 text-sm font-medium cursor-pointer`}>Large Animal</a>
                </nav>
              </div>
            </div>
            <Tab size={currentTab} showBack={showBack} setShowBack={setShowBack} cardIndex={cardIndex} setCardIndex={setCardIndex} />
          </div>
        </div>
      </div>
    </MainLayout>
  )

}

export default PharmacologyPage