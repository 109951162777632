import { Navigate } from "react-router-dom";
import React, { createContext, useContext, useEffect, useState, useCallback } from 'react';
import { useQuery } from "@apollo/react-hooks";
import { GET_CURRENT_USER_QUERY } from './queries.js';
import { setLocalStorageEntry, removeLocalStorageEntry } from 'utils/local-storage'

const USER_LOCALSTORAGE_KEY = '_patch_education_user';

const AuthenticationContext = createContext({});

const AuthWrapper = ({ children }) => {
  const [currentUser, setCurrentUser] = useState(null);

  const { data, loading, error } = useQuery(GET_CURRENT_USER_QUERY, {
    onCompleted: (data) => {
      setCurrentUser(data?.currentUser)
    }
  })

  // console.log('AuthWrapper data', data)
  // console.log('AuthWrapper loading', loading)
  // console.log('AuthWrapper error', error)
  // console.log('AuthWrapper currentUser', currentUser)

  const logUserIn = useCallback((user) => {
    // console.log('logUserIn', user)
    setLocalStorageEntry(USER_LOCALSTORAGE_KEY, {
      authToken: user.authToken,
      id: user.id
    });
    // console.log('logUserInSuccess');
    setCurrentUser(user);
    // console.log('setCurrentUser success');
  }, []);


  const logUserOut = useCallback(() => {
    setCurrentUser(null);
    removeLocalStorageEntry(USER_LOCALSTORAGE_KEY);
    window.location = '/';
  }, []);

  return (
    <AuthenticationContext.Provider
      value={{
        currentUser,
        logUserIn,
        logUserOut,
        loading
        // refetch
      }}
    >
      {children}
    </AuthenticationContext.Provider>
  );
};


const useAuthentication = () => {
  const context = useContext(AuthenticationContext);

  if (context === undefined) {
    throw new Error('AuthWrapper is missing');
  }
  return context;
};

export const AuthenticatedRoute = ({ children }) => {
  const { currentUser, loading } = useAuthentication();

  // console.log('AuthenticatedRoute currentUser', currentUser)
  // console.log('AuthenticatedRoute loading', loading)
  if (!loading && !currentUser) {
    // console.log('user is not authenticated')
    // user is not authenticated
    return <Navigate to="/" />;
  }

  if (loading) {
    return null
  }
  
  return children;
};

export { AuthenticationContext, useAuthentication };
export default AuthWrapper;