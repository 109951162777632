import { gql } from "@apollo/client"

export const GET_TEST_QUERY = gql`
  query GetTest($testId: ID!) {
    test(testId: $testId) {
      id
      startedAt
      duration
      completedAt
      createdAt
      numberQuestions

      currentTestQuestion {
        id
        order

        question {
          id
          question
          answers
          correctAnswer
        }
      }
    }
  }
`;

export const EARLY_EXIT_MUTATION = gql`
mutation EarlyExit($testId: ID!) {
    earlyExit(testId: $testId) {
      success
    }
  }
`;
