import { useState, useEffect } from "react"
import MainLayout from "../../components/main-layout"
import { GET_TEST_QUERY, GET_CURRENT_TEST_QUESTION_QUERY, EARLY_EXIT_MUTATION } from "./queries"
import { useQuery, useMutation } from "@apollo/client"
import { Link, useNavigate, useParams } from "react-router-dom"
// import IncompleteTest from './incomplete-test'
// import CompleteTest from './complete-test'
import { format } from "date-fns"
import TestQuestion from "./test-question"
import TestCompleted from "./test-completed"
import { minutesSeconds } from '../../utils/time'

const Timer = ({ startedAt, duration, earlyExit }) => {

  const [timeRemaining, setTimeRemaining] = useState()

  useEffect(() => {
    var interval;
    if (startedAt && duration) {
      const secondsElapsed = (new Date().getTime() - Date.parse(startedAt)) / 1000
      const secondsRemaining = Math.max(duration * 60 - secondsElapsed, 0)
      setTimeRemaining(secondsRemaining)

      if (secondsRemaining > 0) {
        interval = setInterval(() => {
          const secondsElapsed = (new Date().getTime() - Date.parse(startedAt)) / 1000
          const secondsRemaining = Math.max(duration * 60 - secondsElapsed, 0)

          if (secondsRemaining <= 0) {
            console.log('earlyExit')
            earlyExit()
          }

          setTimeRemaining(secondsRemaining)
        }, 1000)
      }
    }

    return (() => {
      clearInterval(interval)
    })
  }, [startedAt, duration])

  return (
    <div className='flex flex-col items-center'>
      <p className='text-xl font-semibold'>{timeRemaining && minutesSeconds(timeRemaining)}</p>
      <p className='text-xs text-slate-400'>Time Remaining</p>
    </div>
  )
}

const TestPage = () => {
  const params = useParams()
  const navigate = useNavigate()

  useEffect(() => {
    document.title = `Patch Education — Test`;
  }, []);

  const { loading, error, data, refetch } = useQuery(GET_TEST_QUERY, {
    variables: {
      testId: params.testId
    }
  })

  const [earlyExit, _] = useMutation(EARLY_EXIT_MUTATION, {
    variables: {
      testId: params.testId,
    },
    errorPolicy: "all",
    onCompleted: (data) => {
      refetch()
    }
  })

  
  console.log('test error', error)
  
  if (!data) { return null }

  console.log('test data', data.test)

  if (data.test.startedAt && !data.test.completedAt) {
    return (
      <div>
        <div className='bg-black h-16 px-8 flex flex-row items-center justify-between'>
          <h1 className='text-white text-xl font-medium'>NAVLE Practice Test</h1>
          <button onClick={earlyExit} className='bg-white rounded-sm px-4 py-1 font-medium hover:bg-gray-200'>Exit Early</button>
        </div>
        <div className='p-8 bg-gray-100 h-screen'>
          <div className="flex flex-row items-center justify-end mb-24">
            {data?.test && <Timer startedAt={data.test.startedAt} duration={data.test.duration} earlyExit={earlyExit} />}
          </div>
          <TestQuestion testId={data.test.id} numberQuestions={data.test.numberQuestions} testQuestion={data.test.currentTestQuestion} refetch={refetch} />
        </div>
      </div>
    )
  }  
  
  if (data.test.completedAt) {
    return (
      <div>
        <div className='bg-black h-16 px-8 flex flex-row items-center justify-between'>
          <h1 className='text-white text-xl font-medium'>NAVLE Practice Test</h1>
          <button onClick={() => navigate('/tests')} className='bg-white rounded-sm px-4 py-1 font-medium hover:bg-gray-200'>Exit</button>
        </div>
        <div className='p-8 bg-gray-100 h-screen overflow-y-scroll'>
          <TestCompleted testId={data.test.id} />
        </div>
      </div>
    )
  }

 
}

export default TestPage