import { useEffect } from "react";
import { useAuthentication } from "../../contexts/authentication"
import { useParams } from "react-router-dom";
import { useQuery, useMutation } from "@apollo/client";
import { GET_ARTICLE_QUERY, GET_ARTICLE_AUDIO_QUERY, TOGGLE_ARTICLE_COMPLETION_MUTATION } from './queries'
import Markdown from 'react-markdown'
import { Link } from "react-router-dom";
import MainLayout from '../../components/main-layout'
import ArticleQuiz from "./article-quiz";
// import WaveformAudioPlayer from 'components/waveform-audio-player'

const AudioWidget = ({ articleId }) => {
  const { data, error } = useQuery(GET_ARTICLE_AUDIO_QUERY, {
    variables: {
      id: articleId,
    }
  })
  console.log('articleAudio error', error)
  console.log('articleAudio data', data)
  
  if (!data?.articleAudio) { return null; }
  
  console.log('articleAudio data.articleAudio', data.articleAudio)

  return (
    <div className='mt-8'>
      {/* <WaveformAudioPlayer audioUrl={data.articleAudio} /> */}
      <audio controls="controls">
        <source src={data.articleAudio} type="audio/mp3" />
      </audio>
    </div>
  )

}

const ArticlePage = () => {
  const { logUserOut, currentUser } = useAuthentication()
  const params = useParams();

  const { loading, error, data, refetch } = useQuery(GET_ARTICLE_QUERY, {
    variables: {
      category: params.category,
      subcategory: params.subcategory,
      slug: params.slug,
    }
  })

  useEffect(() => {
    if (data) {
      document.title = `Patch Education — ${data.article.category.title} - ${data.article.title}`;
    }
  }, [data]);


  const [toggleArticleCompletion, _] = useMutation(TOGGLE_ARTICLE_COMPLETION_MUTATION, {
    errorPolicy: "all",
    onCompleted: (data) => {
      refetch()
    }
  })


  if (error) { 
    console.log('error', error)
    return <p>Error: {error}</p>
  }
  if (!data) { return null; }

  return (
    <MainLayout>
      <div>
        {/* <p>Hi {currentUser?.fullName}</p> */}
        <div className='py-8 px-4 md:p-8'>
          <p>
            <Link className='text-sm text-blue-600 font-medium' to={`/course/${data.article.category.slug}`}>{data.article.category.title}</Link>
            <span className='text-sm font-bold px-2 text-slate-600'>/</span>
            <span className='text-sm text-slate-800 font-medium'>{data.article.subcategory.title}</span>
          </p>
          <div className='flex flex-col md:flex-row justify-start md:justify-between'>
            <Markdown components={{
              p(props) {
                const { node, ...rest } = props
                return <h1 className='text-2xl font-bold mb-4 md:mb-8' {...rest} />
              },
              em(props) {
                const { node, ...rest } = props
                return <i className='font-italic' {...rest} />
              }
            }}>
              {data.article.title}
            </Markdown>
            {
              data.article.isCompleted ? (
                <button onClick={() => toggleArticleCompletion({ variables: { articleId: data.article.id }})} className='text-black flex flex-row text-green-600 font-semibold'>
                  <svg className='h-6 pt-0.5 mr-2' fill="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" aria-hidden="true">
                    <path clip-rule="evenodd" fill-rule="evenodd" d="M2.25 12c0-5.385 4.365-9.75 9.75-9.75s9.75 4.365 9.75 9.75-4.365 9.75-9.75 9.75S2.25 17.385 2.25 12zm13.36-1.814a.75.75 0 10-1.22-.872l-3.236 4.53L9.53 12.22a.75.75 0 00-1.06 1.06l2.25 2.25a.75.75 0 001.14-.094l3.75-5.25z"></path>
                  </svg>
                  Complete
                </button>
                ) : (
                <button onClick={() => toggleArticleCompletion({ variables: { articleId: data.article.id }})} className='text-slate-500 flex flex-row'>
                  <svg className='h-6 pt-0.5 mr-2' fill="none" stroke="currentColor" strokeWidth="1.5" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" aria-hidden="true">
                    <path strokeLinecap="round" strokeLinejoin="round" d="M9 12.75L11.25 15 15 9.75M21 12a9 9 0 11-18 0 9 9 0 0118 0z"></path>
                  </svg>
                  Incomplete
                </button>
              )
            }
          </div>
          <AudioWidget articleId={data.article.id} />
          <div className='mt-8'>
            <div className='flex flex-col md:flex-row'>
              <div className='flex flex-col w-100 md:w-2/3 md:pr-12' >
                <Markdown components={{
                  // h3: 'H3Component',
                  p(props) {
                    const { node, ...rest } = props
                    return <p className='mb-4' {...rest} />
                  },
                  h2(props) {
                    const { node, ...rest } = props
                    return <h2 className='text-lg font-bold underline' {...rest} />
                  },
                  h3(props) {
                    const { node, ...rest } = props
                    return <h3 className='text-md font-bold' {...rest} />
                  },
                  h4(props) {
                    const { node, ...rest } = props
                    return <h4 className='font-medium underline' {...rest} />
                  },
                  em(props) {
                    const { node, ...rest } = props
                    return <i className='font-italic' {...rest} />
                  }
                }}>
                  {data.article.content}
                </Markdown>
              </div>
              <div className='w-100 md:w-1/3'>
                <ArticleQuiz articleId={data.article.id} />
              </div>
            </div>
          </div>
          {
            data.article.previousArticle &&
              (<p>
                <Link className='text-sm text-blue-600 font-medium' to={`/course/${data.article.previousArticle.path}`}>
                  &larr; Prev:&nbsp;
                  <Markdown components={{
                    p(props) {
                      const { node, ...rest } = props
                      return <span className='text-blue-600' {...rest} />
                    },
                    em(props) {
                      const { node, ...rest } = props
                      return <i className='font-italic' {...rest} />
                    }
                  }}>
                    {data.article.previousArticle.title}
                  </Markdown>
                </Link>
                </p>
              )
          }
          {data.article.subcategory.lastArticleOrderNumber == data.article.order && (
            <p>
              <Link className='text-sm text-blue-600 font-medium' to={`/course/${data.article.category.slug}/${data.article.subcategory.slug}/quiz`}>
                &rarr; Next: Quiz
              </Link>
            </p>
          )}
          {
            data.article.subcategory.lastArticleOrderNumber != data.article.order && data.article.nextArticle &&
            (<p>
              <Link className='text-sm text-blue-600 font-medium' to={`/course/${data.article.nextArticle.path}`}>
                &rarr; Next:&nbsp;
                <Markdown components={{
                  p(props) {
                    const { node, ...rest } = props
                    return <span className='text-blue-600' {...rest} />
                  },
                  em(props) {
                    const { node, ...rest } = props
                    return <i className='font-italic' {...rest} />
                  }
                }}>
                  {data.article.nextArticle.title}
                </Markdown>
              </Link>
            </p>
            )
          }
        </div>
      </div>
    </MainLayout>
  )
}

export default ArticlePage