import { gql } from "@apollo/client"

export const GET_CATEGORY_QUERY = gql`
  query GetCategory($slug: String!) {
    category(slug: $slug) {
      id
      title
      content
      slug

      subcategories {
        id
        title
        slug
        order
        quizComplete

        articles {
          id
          slug
          title
          isCompleted
        }
      }
    }
  }
`;