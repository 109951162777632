import { useEffect } from 'react';
import MainLayout from '../../components/main-layout'
import algoliasearch from 'algoliasearch/lite';
import { InstantSearch, SearchBox, Highlight, Hits} from 'react-instantsearch';
import Markdown from 'react-markdown'
import { Link } from 'react-router-dom';
import { useState, useRef } from 'react';
import { useInstantSearch, useSearchBox } from 'react-instantsearch';
const { REACT_APP_ENVIRONMENT } = process.env;

function CustomSearchBox(props) {
  const { query, refine } = useSearchBox(props);
  const { status } = useInstantSearch();
  const [inputValue, setInputValue] = useState(query);
  const inputRef = useRef(null);

  const isSearchStalled = status === 'stalled';

  function setQuery(newQuery) {
    setInputValue(newQuery);

    refine(newQuery);
  }

  useEffect(() => {
    document.title = `Patch Education — Search`;
  }, []);

  return (
    <div>
      <form
        action=""
        role="search"
        noValidate
        onSubmit={(event) => {
          event.preventDefault();
          event.stopPropagation();

          if (inputRef.current) {
            inputRef.current.blur();
          }
        }}
        onReset={(event) => {
          event.preventDefault();
          event.stopPropagation();

          setQuery('');

          if (inputRef.current) {
            inputRef.current.focus();
          }
        }}
      >
      <div>
        <div className="relative mt-2 rounded-md shadow-sm">
          <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3 text-slate-400">
              <svg className='text-slate-400 h-5 w-5' data-slot="icon" fill="none" stroke-width="1.5" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" aria-hidden="true">
                <path stroke-linecap="round" stroke-linejoin="round" d="m21 21-5.197-5.197m0 0A7.5 7.5 0 1 0 5.196 5.196a7.5 7.5 0 0 0 10.607 10.607Z"></path>
              </svg>
          </div>
          <input
            ref={inputRef}
            autoComplete="off"
            autoCorrect="off"
            autoCapitalize="off"
            autoFocus
            placeholder={'equine leptospirosis...'}
            spellCheck={false}
            maxLength={512}
            type="search"
            value={inputValue}
            onChange={(event) => {
              setQuery(event.currentTarget.value);
            }}
            className='autofocus block w-full rounded-md border-0 py-1.5 pl-10 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6'
          />
          {/* <button type="submit">Submit</button> */}
          {/* <button
            type="reset"
            hidden={inputValue.length === 0 || isSearchStalled}
          >
            Reset
          </button> */}
        </div>
      </div>
        <span hidden={!isSearchStalled}>Searching…</span>
      </form>
    </div>
  );
}

const searchClient = algoliasearch('MKPSWLYCOZ', 'fe2f6b34b5eca1381d93629bf79bd70d');

const Hit = ({ hit }) => {

  // const content = hit._highlightResult.algolia_content.value.replace('<mark>', '**').replace('</mark>', '**')

  return (
    <Link to={`/course/${hit.path}`}>
      <li className='py-8 border rounded-lg px-8 mt-8'>
        <p className='font-bold text-lg'><Highlight attribute="title" hit={hit} /></p>
        <p className='text-sm font-medium'>
          <Highlight attribute="category_title" hit={hit} /> / <Highlight attribute="subcategory_title" hit={hit} />
        </p>
        {/* <p>This is a hit</p> */}
        {/* <p>{JSON.stringify(hit)}</p> */}
        {/* <Markdown components={{
          p(props) {
            const { node, ...rest } = props
            return <span className='text-slate-600 text-sm' {...rest} />
          },
          em(props) {
            const { node, ...rest } = props
            return <i className='font-italic' {...rest} />
          },
          mark(props) {
            const { node, ...rest } = props
            return <i className='font-bold' {...rest} />
          }
        }}>
          {content}
        </Markdown> */}
        
        
        <p className='text-sm text-slate-800'><Highlight attribute="algolia_content" hit={hit} /></p>
      </li>
    </Link>
  )
}

const SearchPage = () => {

  return (
    <MainLayout>
      <div className='p-4 md:p-8'>
        <div className='flex flex-row justify-between items-center mb-12'>
          <h1 className='text-2xl font-bold text-slate-800'>Search</h1>
          {/* <button className='text-white bg-black px-4 py-2 rounded-md' onClick={() => navigate('/tests/new')}>Take a new test</button> */}
        </div>

        <InstantSearch searchClient={searchClient} indexName={REACT_APP_ENVIRONMENT == 'development' ? "Article_development" : "Article_production"}>
          {/* <SearchBox 
            placeholder="equine leptospirosis" 
            autoFocus={true}
          /> */}
          <CustomSearchBox />
          <ul className='divider-y divider-slate-200 mt-12'>
            <Hits
              // Optional props
              hitComponent={Hit}
              classNames={{}}
              // transformItems={(items) => items.map(item => ({
              //   ...item,
              //   content: item.content.replace('<mark>', '**').replace('</mark>', '**')
              // }))}
              // ...props={}
              />
          </ul>
        </InstantSearch>

      </div>
    </MainLayout>
  )
}

export default SearchPage