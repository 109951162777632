import { useState, useEffect } from 'react'
import { useMutation, useQuery } from "@apollo/client"
import MainLayout from '../../components/main-layout'
import Markdown from 'react-markdown'
import { GENERATE_ASSISTANT_RESPONSE, GENERATE_ASSISTANT_QUICK_RESPONSE, GET_ASSISTANT_QUERIES_QUERY } from './queries'

const AskPage = () => {

  const [question, setQuestion] = useState()
  const [assistantQuickResponse, setAssistantQuickResponse] = useState()
  const [assistantQuickResponseLoading, setAssistantQuickResponseLoading] = useState()
  const [assistantResponse, setAssistantResponse] = useState()
  const [assistantResponseLoading, setAssistantResponseLoading] = useState()

  const { data, loading, error, refetch } = useQuery(GET_ASSISTANT_QUERIES_QUERY)

  const [generateAssistantResponse, _] = useMutation(GENERATE_ASSISTANT_RESPONSE, {
    errorPolicy: "all",
    variables: {
      question: question,
    },
    onCompleted: (data) => {
      console.log('generateAssistantResponse data', data)
      setAssistantResponseLoading(false)
      setAssistantResponse(data.generateAssistantResponse.response)
      refetch()
    },
    onError: (error) => {
      console.log('generateAssistantResponse error',error)
    }
  })
  const [generateAssistantQuickResponse, _2] = useMutation(GENERATE_ASSISTANT_QUICK_RESPONSE, {
    errorPolicy: "all",
    variables: {
      question: question,
    },
    onCompleted: (data) => {
      console.log('generateAssistantResponse data', data)
      setAssistantQuickResponseLoading(false)
      setAssistantQuickResponse(data.generateAssistantQuickResponse.response)
      refetch()
    },
    onError: (error) => {
      console.log('generateAssistantResponse error',error)
    }
  })

  useEffect(() => {
    document.title = `Patch Education — Ask AI`;
  }, []);

  console.log('data?.assistantQueries', data)

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      submit()
    }
  }

  const submit = () => {
    setAssistantResponse(null)
    setAssistantQuickResponse(null)
    setAssistantQuickResponseLoading(true)
    setAssistantResponseLoading(true)
    generateAssistantResponse()
    generateAssistantQuickResponse()
  }

  const selectPreviousQuery = async (query) => {
    await setQuestion(query)
    submit()
  }

  return (
    <MainLayout>
      <div className='p-4 md:p-8'>
        <div className='flex flex-row justify-between items-center mb-12'>
          <h1 className='text-2xl font-bold text-slate-800'>Ask AI</h1>
          {/* <button className='text-white bg-black px-4 py-2 rounded-md' onClick={() => navigate('/tests/new')}>Take a new test</button> */}
        </div>
        <div className='flex md:flex-row flex-col'>
          <div className='flex flex-col w-full'>
            <div className='w-100 flex flex-col'>
              <p className='text-slate-800 font-medium text-sm mb-4'>Enter your question below:</p>
              <div className='flex flex-row justify-between'>
                <div className="relative rounded-md shadow-sm w-full">
                  <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3 text-slate-400">
                    <svg className='h-5 w-5 text-slate-400' data-slot="icon" fill="none" stroke-width="1.5" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" aria-hidden="true">
                      <path stroke-linecap="round" stroke-linejoin="round" d="M9.879 7.519c1.171-1.025 3.071-1.025 4.242 0 1.172 1.025 1.172 2.687 0 3.712-.203.179-.43.326-.67.442-.745.361-1.45.999-1.45 1.827v.75M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Zm-9 5.25h.008v.008H12v-.008Z"></path>
                    </svg>
                  </div>
                  <input value={question} onKeyDown={handleKeyDown} onChange={(e) => setQuestion(e.target.value)} rows={5} className='autofocus block w-full rounded-md border-0 py-1.5 pl-10 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6' placeholder="What is Cushing's Disease?" />
                </div>
                <div className='mt-0.5 ml-4'>
                  <button onClick={submit} className='bg-black text-sm text-white rounded-md hover:opacity-80 active:opacity-80 px-2 py-1'>Submit</button>
                </div>
              </div>
            </div>

            <div className='w-100 pt-8'>
              {/* <h2 className='font-bold text-lg mt-8'>Response</h2> */}
              {assistantQuickResponse && (<div className='bg-slate-100 px-4 py-4 rounded-md border mb-8'>
                <p><span className='font-bold'>Quick Response:&nbsp;</span>{assistantQuickResponse}</p>
              </div>)}
              <div>
                {assistantResponseLoading && <p>Generating in-depth answer...</p>}
                <Markdown components={{
                  h1(props) {
                    const { node, ...rest } = props
                    return <h1 className='text-2xl font-bold my-4' {...rest} />
                  },
                  h2(props) {
                    const { node, ...rest } = props
                    return <h2 className='text-xl font-semibold my-4' {...rest} />
                  },
                  h3(props) {
                    const { node, ...rest } = props
                    return <h3 className='text-lg font-semibold my-4' {...rest} />
                  },
                  h4(props) {
                    const { node, ...rest } = props
                    return <h3 className='text-lg font-medium my-4' {...rest} />
                  },
                  em(props) {
                    const { node, ...rest } = props
                    return <i className='font-italic' {...rest} />
                  },
                  b(props) {
                    const { node, ...rest } = props
                    return <i className='font-semibold' {...rest} />
                  },
                  ul(props) {
                    const { node, ...rest } = props
                    return <ul className='list-disc' {...rest} />
                  },
                  ol(props) {
                    const { node, ...rest } = props
                    return <ul className='list-decimal' {...rest} />
                  },
                  li(props) {
                    const { node, ...rest } = props
                    return <li className='ml-6' {...rest} />
                  },
                  p(props) {
                    const { node, ...rest } = props
                    return <p className='mt-4' {...rest} />
                  }
                }}>
                  {assistantResponse}
                </Markdown>
              </div>
            </div>

          </div>
          <div className='flex flex-col md:w-1/3 md:ml-12 mt-12 md:mt-0 bg-slate-100 p-4 rounded-sm'>
            <h3 className='font-semibold'>History</h3>
            <ul className='divide-y divide-slate-300'>
              {data?.assistantQueries.map(query => (
                <li className='text-sm cursor-pointer py-4 hover:font-medium' onClick={() => selectPreviousQuery(query.query)}>{query.query}</li>
              ))}
              {data?.assistantQueries.length === 0 && (
                <li className='text-slate-400'>No queries yet</li>
              )}
            </ul>
          </div>
        </div>
      </div>
    </MainLayout>
  )
}

export default AskPage