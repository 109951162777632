const CompleteQuiz = ({ quiz }) => {

  return (
    <div>
      <p className='mb-8'>SCORE: {quiz.numberCorrectAnswers} / {quiz.numberQuestions}</p>
      <ul>
        {quiz.questions.map((question, i1) => (
          <li key={question.id} className='mb-8'>
            <p className={`font-bold text-sm`}>{i1 + 1}. {question.question}</p>
            <div className=''>
              {question.answers.map((possibleAnswer, i2) => {
                console.log('possibleAnswer', possibleAnswer, i2)
                console.log('correctAnswer', question.correctAnswer, i2)
                console.log('possibleAnswer == correctAnswer', question.correctAnswer == possibleAnswer, i2)
                return (
                <div key={i2}>
                  <input
                    type="radio" key={possibleAnswer} value={possibleAnswer} name={question.id} id={question.id}
                    checked={possibleAnswer === quiz.answers[question.id]}
                    readOnly
                  />
                  <label
                    htmlFor={question.question}
                      className={`ml-2 text-sm ${question.correctAnswer == possibleAnswer ? 'text-green-600 font-semibold' : ''} ${possibleAnswer == quiz.answers[question.id] && question.correctAnswer != possibleAnswer ? 'text-red-600 font-semibold' : ''}`}
                  >
                    {i2 == 0 && 'A: '}
                    {i2 == 1 && 'B: '}
                    {i2 == 2 && 'C: '}
                    {i2 == 3 && 'D: '}
                    {possibleAnswer}
                  </label>
                </div>
              )})}
            </div>
          </li>
        ))}

      </ul>
    </div>
  )
}

export default CompleteQuiz