import { useEffect } from "react"
import MainLayout from "components/main-layout"
import Markdown from "react-markdown"
import { Link } from "react-router-dom"

import { DISEASES } from './diseases'

const NotifiableDiseasesPage = () => {

  useEffect(() => {
    document.title = `Patch Education — Reportable Diseases`;
  }, []);

  return (
    <MainLayout>
      <div>
        <div className='md:p-8 p-2 flex flex-col lg:flex-row'>
          <div className='w-full'>
            <h1 className='text-2xl font-bold mt-4'>Reportable/Notifiable Diseases</h1>
            
            
            <h2 className='text-lg font-semibold'>Animal Health Surveillance in the United States</h2>
            <p className='mb-4 font-medium'>Reference: <a target='_blank' className='text-blue-500 font-lg font-medium' href="https://www.aphis.usda.gov/aphis/ourfocus/animalhealth/animal-disease-information">Animal Health Surveillance</a></p>
            <p className='mb-4'>A comprehensive, coordinated, integrated surveillance system is the foundation for animal health, public health, food safety, and environmental health. Animal health surveillance is designed to detect threats to animal health in a timely manner. The U.S. Department of Agriculture (USDA) Animal and Plant Health Inspection Service (APHIS) Veterinary Services (VS) works with a vast network of partners to carry out surveillance activities in the United States. Together, these partners work to protect animal health, national economic viability, and the food supply. In addition, these surveillance programs and efforts assure international trading partners of the health of the Nation’s herd and safety of our livestock and livestock products. Our national surveillance system also provides the tools necessary to detect chemical or environmental agents that could affect animal health.</p>
            <p className='mb-4'>Surveillance helps rapidly detect foreign diseases and emerging issues, monitor and prove actionable information for endemic diseases, and measure regional prevalence of trade-significant diseases.</p>
            <p className='mb-4'>An accredited veterinarian shall immediately report to both the Veterinary Official (VO) and the State Animal Health Official all diagnosed or suspected cases of a communicable animal disease for which a APHIS has a control or eradication program in 9 CFR chapter I, and all diagnosed or suspected cases of any animal disease not known to exist in the United States as provided by §71.3(b) of this chapter.</p>
            <p className='mb-4'>Report any suspicious clinical or necropsy findings accompanied by a history of people or animals that have recently returned from a foreign country and report any disease of unknown etiology causing high mortality or morbidity. Most States provide a list of reportable diseases that should be used to supplement the list of reportable diseases that follows. Contact your SAHO for such a list.</p>
            <p className='mb-4'>You should be suspicious of the following:</p>
            <ul className='list-disc ml-6'>
              <li>High morbidity, high mortality;</li>
              <li>Severe abortion storms of unknown etiology;</li>
              <li>Severe respiratory conditions;</li>
              <li>Vesicular lesions;</li>
              <li>Pox or lumpy skin conditions;</li>
              <li>Poor or no response to treatment when response is expected;</li>
              <li>Atypical findings at necropsy;</li>
              <li>History of foreign travel, foreign visitors, or receipt of foreign parcels;</li>
              <li>Recent importation of animals, embryos, or semen;</li>
              <li>Undiagnosed encephalitic (CNS) conditions;</li>
              <li>Larvae (maggots) feeding on living tissue;</li>
              <li>Avian disease with acute deaths or CNS signs;</li>
              <li>Unusual myiasis or acariasis (exotic flies, mites, ticks, etc.); or</li>
              <li>Unusual or unexplained signs of illness.</li>
            </ul>

            <h3 className='font-semibold font-lg mt-10 mb-4'>Guidelines</h3>
            <p className='mb-4'>If you suspect a highly contagious foreign or reportable disease (e.g., foot-and-mouth disease, classical swine fever, highly pathogenic avian influenza), phone the VO and the State Animal Health Official directly from the farm or premises (see your Federal listing in appendix B and your State listing in appendix C). Have the following information available:</p>
            <ul className="list-disc ml-6">
              <li>Producer or owner name, address, county, and phone number;</li>
              <li>Directions to the farm or premises;</li>
              <li>Complete clinical history;</li>
              <li>Number and species of animals affected, and number and species of animals susceptible;</li>
              <li>Conditions you may have already ruled out;</li>
              <li>Any treatments given and response noted; and</li>
              <li>Contact information for you, including your name, address, and relevant phone numbers.</li>
            </ul>

            <div className="mt-20">
              {DISEASES.map(disease => (
                <div key={disease.title} className='py-8 border-t border-slate-200'>
                  <h3 className='text-xl font-bold text-black'>
                    {disease.title}
                  </h3>
                  {disease.links.map(link => (
                    <p><Link key={link.title} className='text-blue-600 text-sm font-medium' to={link.url}>View {link.title}</Link></p>
                  ))}
                  <p className='text-slate-800 mt-4'>{disease.content}</p>
                  <h5 className='font-semibold mt-8'>Actions to take if you suspect infection:</h5>
                  <p className='text-slate-800'>{disease.actions}</p>
                </div>
              ))}
            </div>
            <div>
            </div>
          </div>
        </div>
      </div>
    </MainLayout>
  )

}

export default NotifiableDiseasesPage