

const StudyguideTab = ({ test }) => {

  // testQuestions {
  //   id
  //   correct
  //   answer
  //       question {
  //     id
  //     question
  //     answers
  //     correctAnswer
  //   }
  // }

  return (
    <div className='mt-12'>
      <ul>
        {test.testQuestions.map((testQuestion, i1) => (
          <li key={testQuestion.id} className='mb-8'>
            <p className={`font-bold text-sm`}>{i1 + 1}. {testQuestion.question.question}</p>
            <div className=''>
              {testQuestion.question.answers.map((possibleAnswer, i2) => {
                return (
                  <div key={i2}>
                    <input
                      type="radio" key={possibleAnswer} value={possibleAnswer} name={testQuestion.question.id} id={testQuestion.question.id}
                      checked={possibleAnswer === testQuestion.answer}
                      readOnly
                    />
                    <label
                      htmlFor={testQuestion.question.question}
                      className={`ml-2 text-sm ${testQuestion.question.correctAnswer == possibleAnswer ? 'text-green-600 font-semibold' : ''} ${possibleAnswer == testQuestion.answer && testQuestion.question.correctAnswer != possibleAnswer ? 'text-red-600 font-semibold' : ''}`}
                    >
                      {i2 == 0 && 'A: '}
                      {i2 == 1 && 'B: '}
                      {i2 == 2 && 'C: '}
                      {i2 == 3 && 'D: '}
                      {possibleAnswer}
                    </label>
                  </div>
                )
              })}
            </div>
          </li>
        ))}
      </ul>
    </div>
  )
}

export default StudyguideTab;