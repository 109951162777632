import { gql } from "@apollo/client"

export const GET_ARTICLE_QUIZ_QUERY = gql`
  query GetArticleQuiz($articleId: ID!) {
    articleQuiz(articleId: $articleId) {
      id
      questions {
        id
        question
        answers
        correctAnswer
      }
      answers
      completedAt
      numberCorrectAnswers
      numberQuestions
    }
  }
`;
