import { ApolloProvider } from '@apollo/client';
import { useEffect } from 'react'
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import HomePage from 'pages/home';
import LoginPage from 'pages/login';
import ForgotPasswordPage from 'pages/forgot-password';
import ResetPasswordPage from 'pages/reset-password';
import SetPasswordPage from 'pages/set-password';
import OnboardingSurveyPage from 'pages/onboarding-survey';
import CoursePage from 'pages/course';
import TestPage from 'pages/test';
import NewTestPage from 'pages/new-test';
import AllTestsPage from 'pages/all-tests';
import ListenPage from 'pages/listen';
import ArticlePage from 'pages/article';
import SubcategoryPage from 'pages/subcategory';
import SubcategoryQuizPage from 'pages/subcategory-quiz';
import CategoryPage from 'pages/category';
import SearchPage from 'pages/search';
import AskPage from 'pages/ask';
import ReferencesPage from 'pages/references';
import NotifiableDiseasesPage from 'pages/notifiable-diseases';
import EthicsPage from 'pages/ethics';
import PharmacologyPage from 'pages/pharmacology';
import PracticeManagementPage from 'pages/practice-management';
import * as Sentry from "@sentry/react";
import client from './config/apollo'

import AuthenticationProvider, { AuthenticatedRoute } from './contexts/authentication'

// https://blog.saeloun.com/2022/02/17/how-to-integrate-react-app-with-google-analytics/
import ReactGA from 'react-ga';
const TRACKING_ID = "G-XJDNX2XC97"; // OUR_TRACKING_ID
ReactGA.initialize(TRACKING_ID);

const { REACT_APP_ENVIRONMENT } = process.env;

Sentry.init({
  environment: REACT_APP_ENVIRONMENT,
  dsn: "https://b962a744a3a9347d5c0855af7448a21e@o4506543658827776.ingest.sentry.io/4506543660597248",
  integrations: [
    new Sentry.BrowserTracing({
      // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
      tracePropagationTargets: ["localhost", /^https:\/\/patch-education-frontend\.onrender\.com\//],
    }),
    new Sentry.Replay({
      maskAllText: false,
      blockAllMedia: false,
    }),
  ],
  // Performance Monitoring
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

function App() {

  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, []);

  return (
    <ApolloProvider client={client}>
      <AuthenticationProvider>
          <BrowserRouter>
              <Routes>
                <Route 
                  index
                  path="/"
                  element={<HomePage />}
                />
                <Route 
                  path="/login"
                  element={<LoginPage />}
                />
                <Route 
                  path="/forgot-password"
                  element={<ForgotPasswordPage />}
                />
                <Route 
                  path="/reset-password"
                  element={<ResetPasswordPage />}
                />
                <Route 
                  path="/set-password"
                  element={<SetPasswordPage />}
                />
                <Route 
                  path="/onboarding-survey"
                  element={<OnboardingSurveyPage />}
                />
                <Route 
                  path="/course/notifiable-diseases"
                  element={<NotifiableDiseasesPage />}
                />
                <Route 
                  path="/course/ethics"
                  element={<EthicsPage />}
                />
                <Route 
                  path="/course/pharmacology"
                  element={<PharmacologyPage />}
                />
                <Route 
                  path="/course/practice-management"
                  element={<PracticeManagementPage />}
                />
                
                <Route 
                  path="/course/:category/:subcategory/quiz"
                  element={
                    <AuthenticatedRoute>
                      <SubcategoryQuizPage />
                    </AuthenticatedRoute>
                  }
                />
                <Route 
                  path="/course/:category/:subcategory/:slug"
                  element={
                    <AuthenticatedRoute>
                      <ArticlePage />
                    </AuthenticatedRoute>
                  }
                />
                <Route 
                  path="/course/:category/:subcategory"
                  element={
                    <AuthenticatedRoute>
                      <SubcategoryPage />
                    </AuthenticatedRoute>
                  }
                />
                <Route 
                  path="/course/:category"
                  element={
                    <AuthenticatedRoute>
                      <CategoryPage />
                    </AuthenticatedRoute>
                  }
                />
                <Route 
                  path="/course"
                  element={
                    <AuthenticatedRoute>
                      <CoursePage />
                    </AuthenticatedRoute>
                  }
                />
                <Route 
                  path="/search"
                  element={
                    <AuthenticatedRoute>
                      <SearchPage />
                    </AuthenticatedRoute>
                  }
                />
                <Route 
                  path="/ask"
                  element={
                    <AuthenticatedRoute>
                      <AskPage />
                    </AuthenticatedRoute>
                  }
                />
                <Route 
                  path="/references"
                  element={
                    <AuthenticatedRoute>
                      <ReferencesPage />
                    </AuthenticatedRoute>
                  }
                />
                <Route 
                  path="/listen"
                  element={
                    <AuthenticatedRoute>
                      <ListenPage />
                    </AuthenticatedRoute>
                  }
                />
                <Route 
                  path="/tests/:testId"
                  element={
                    <AuthenticatedRoute>
                      <TestPage />
                    </AuthenticatedRoute>
                  }
                />
                <Route 
                  path="/tests/new"
                  element={
                    <AuthenticatedRoute>
                      <NewTestPage />
                    </AuthenticatedRoute>
                  }
                />
                <Route 
                  path="/tests"
                  element={
                    <AuthenticatedRoute>
                      <AllTestsPage />
                    </AuthenticatedRoute>
                  }
                />
              </Routes>
          </BrowserRouter>
      </AuthenticationProvider>
    </ApolloProvider>
  );
}

export default App;
