import Header from '../header/index'
import { Link } from 'react-router-dom'
import { useState } from 'react'
import { useQuery, useMutation } from "@apollo/client";
import { gql } from "@apollo/client"

export const SUBMIT_FEEDBACK_MUTATION = gql`
  mutation SubmitFeedback($category: String, $content: String) {
    submitFeedback(content: $content, category: $category) {
      success
    }
  }
`

const Modal = ({ toggleModal }) => {

    const [category, setCategory] = useState(null)
    const [content, setContent] = useState(null)

    const [submitFeedback, _] = useMutation(SUBMIT_FEEDBACK_MUTATION, {
        errorPolicy: "all",
        variables: {
            category,
            content
        },
        onCompleted: (data) => {
            toggleModal()
        }
    })


    const onSetCategory = (e) => {
        setCategory(e.target.value)
    }
    
    const onSetContent = (e) => {
        setContent(e.target.value)
    }

    const onSubmit = () => {
        submitFeedback()
    }
    
    
    return (
    <>
        <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" onClick={toggleModal}></div>

        <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
            <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <div className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-sm sm:p-6">
                <div>
                {/* <div className="mx-auto flex h-12 w-12 items-center justify-center rounded-full bg-green-100">
                    <svg className="h-6 w-6 text-green-600" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" aria-hidden="true">
                    <path stroke-linecap="round" stroke-linejoin="round" d="M4.5 12.75l6 6 9-13.5" />
                    </svg>
                </div> */}
                <div className="mt-3 text-center sm:mt-5">
                    <h3 className="text-base font-semibold leading-6 text-gray-900" id="modal-title">Report an issue</h3>
                    <div className="mt-2">
                                    <p className="text-sm text-gray-500">Leave us feedback using the form below.</p>
                                    <p className="text-sm text-gray-500">Thank you!</p>
                    <div className='flex flex-start flex-col justify-start items-start mt-6'>
                        <label for="type" className="block text-sm font-medium leading-6 text-gray-900">Category</label>
                        <select 
                            value={category}
                            id="type" 
                            name="type" 
                            onChange={onSetCategory}
                            className="mt-2 block w-full rounded-md border-0 py-1.5 pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6">
                            <option disabled selected value> -- select an option -- </option>
                            <option selected={category == 'Inaccurate Information'}>Inaccurate Information</option>
                            <option selected={category == 'Website Bug / Error'}>Website Bug / Error</option>
                        </select>
                    </div>
                    <div className='flex flex-start flex-col justify-start items-start mt-6'>
                        <label for="type" className="block text-sm font-medium leading-6 text-gray-900">Content</label>
                        <textarea 
                            value={content}
                            id="content" 
                            name="content" 
                            rows={6}
                            onChange={onSetContent}
                            className="mt-2 block w-full rounded-md border-0 py-1.5 pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6"
                        />
                    </div>
                    
                    </div>
                </div>
                </div>
                <div className="mt-5 sm:mt-6">
                <button onClick={onSubmit} type="button" className="inline-flex w-full justify-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">Submit</button>
                </div>
            </div>
            </div>
        </div>
    </>
    )
}

const MainLayout = ({ children }) => {
    const [showReportModal, setShowReportModal] = useState(false)

    const toggleModal = () => {
        setShowReportModal(prev => !prev)
    }

    return (
        <div className="min-h-full bg-white relative">
            <Header />
            <div>
                {children}
            </div>
            <div className='h-8 text-sm border-t flex items-center justify-center px-8 hidden sm:flex'>
                <div>
                    <span className='mr-2'>&copy; Patch 2024</span>
                    &bull;
                    <span className='ml-2 mr-2'><Link className='hover:underline hover:text-slate-800' to='/references'>References</Link></span>
                    &bull;
                    <span className='ml-2'><span className='hover:underline hover:text-slate-800' onClick={toggleModal}>Report</span></span>
                </div>
            </div>
            {showReportModal && <Modal toggleModal={toggleModal} />}
        </div>
    )
}

export default MainLayout