import { useEffect } from "react";
import { useAuthentication } from "../../contexts/authentication"
import { useParams } from "react-router-dom";
import { useQuery } from "@apollo/client";
import { GET_CATEGORY_QUERY } from './queries'
import { Link } from "react-router-dom";
import Markdown from 'react-markdown'
import MainLayout from "../../components/main-layout";


const CategoryPage = () => {
  const { logUserOut, currentUser } = useAuthentication()
  const params = useParams();

  const { loading, error, data } = useQuery(GET_CATEGORY_QUERY, {
    variables: {
      slug: params.category 
    }
  })

  useEffect(() => {
    if (data) {
      document.title = `Patch Education — ${data.category.title}`;
    }
  }, [data]);

  if (error) { 
    console.log('error', error)
    return <p>Error: {error}</p>
  }
  if (!data) { return null; }

  return (
    <MainLayout>
    <div>
      <div className='md:p-8 p-2 flex flex-col lg:flex-row'>
        <div className='w-full'>
          <h1 className='text-2xl font-bold mb-8 mt-4'>{data.category.title}</h1>
          <div>
            {data.category.subcategories.map(subcategory => (
              <div key={subcategory.id}>
                <div className='border rounded-lg mb-12'>
                  <div className='font-semibold pt-4 pb-4 px-8 rounded-t-lg border-b-2 bg-slate-800 text-white text-md'>{subcategory.title}</div>
                  <ul role="list" className="divide-y divide-slate-100 px-8 pt-4 pb-2">
                    {subcategory.articles.map(article => (
                      <li className="flex flex-row flex-wrap items-center justify-between py-5 sm:flex-nowrap hover:font-medium text-xs md:text-base" key={article.id}>
                        <Link to={`/course/${data.category.slug}/${subcategory.slug}/${article.slug}`} className='text-wrap w-3/4 flex flex-row'>
                            <Markdown components={{
                              p(props) {
                                const { node, ...rest } = props
                                return <span className='text-slate-800' {...rest} />
                              },
                              em(props) {
                                const { node, ...rest } = props
                                return <i className='font-italic' {...rest} />
                              }
                            }}>
                              {article.title}
                            </Markdown>
                        </Link>
                            <div>
                              {article.isCompleted ? (
                                <div className='text-black flex flex-row text-green-600 font-semibold block'>
                                  <svg className='h-6 pt-0.5 mr-2' fill="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" aria-hidden="true">
                                    <path clip-rule="evenodd" fill-rule="evenodd" d="M2.25 12c0-5.385 4.365-9.75 9.75-9.75s9.75 4.365 9.75 9.75-4.365 9.75-9.75 9.75S2.25 17.385 2.25 12zm13.36-1.814a.75.75 0 10-1.22-.872l-3.236 4.53L9.53 12.22a.75.75 0 00-1.06 1.06l2.25 2.25a.75.75 0 001.14-.094l3.75-5.25z"></path>
                                  </svg>
                                  <span className='hidden md:inline-block'>Complete</span>
                                </div>
                              ) : (
                                <div className='text-slate-500 flex flex-row block'>
                                  <svg className='h-6 pt-0.5 mr-2' fill="none" stroke="currentColor" strokeWidth="1.5" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" aria-hidden="true">
                                    <path strokeLinecap="round" strokeLinejoin="round" d="M9 12.75L11.25 15 15 9.75M21 12a9 9 0 11-18 0 9 9 0 0118 0z"></path>
                                  </svg>
                                  <span className='hidden md:inline-block'>Incomplete</span>
                                </div>
                              )}
                            </div>
                        </li>
                    ))}
                  </ul>
                  <div className="flex flex-wrap items-center justify-between gap-x-6 gap-y-4 px-8 rounded-b-lg py-5 sm:flex-nowrap border-t-2 bg-slate-200">
                    <Link className='text-slate-800 font-semibold hover:font-extrabold' to={`/course/${data.category.slug}/${subcategory.slug}/quiz`}>Quiz</Link>
                    <div>
                      {subcategory.quizComplete ? (
                        <div className='text-black flex flex-row text-green-600 font-semibold block'>
                          <svg className='h-6 pt-0.5 mr-2' fill="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" aria-hidden="true">
                            <path clip-rule="evenodd" fill-rule="evenodd" d="M2.25 12c0-5.385 4.365-9.75 9.75-9.75s9.75 4.365 9.75 9.75-4.365 9.75-9.75 9.75S2.25 17.385 2.25 12zm13.36-1.814a.75.75 0 10-1.22-.872l-3.236 4.53L9.53 12.22a.75.75 0 00-1.06 1.06l2.25 2.25a.75.75 0 001.14-.094l3.75-5.25z"></path>
                          </svg>
                          <span className='hidden md:inline-block'>Complete</span>
                        </div>
                      ) : (
                        <div className='text-slate-500 flex flex-row block'>
                          <svg className='h-6 pt-0.5 mr-2' fill="none" stroke="currentColor" strokeWidth="1.5" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" aria-hidden="true">
                            <path strokeLinecap="round" strokeLinejoin="round" d="M9 12.75L11.25 15 15 9.75M21 12a9 9 0 11-18 0 9 9 0 0118 0z"></path>
                          </svg>
                          <span className='hidden md:inline-block'>Incomplete</span>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
    </MainLayout>
  )
}

export default CategoryPage