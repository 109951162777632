import { useQuery } from '@apollo/client'
import { GET_SPECIAL_CATEGORY_QUIZ_QUERY } from './queries'
import IncompleteQuiz from './incomplete-quiz'
import CompleteQuiz from './complete-quiz'

const SpecialCategoryQuiz = ({ categoryId }) => {

  const { loading, error, data, refetch } = useQuery(GET_SPECIAL_CATEGORY_QUIZ_QUERY, {
    variables: {
      categoryId
    }
  })

  console.log('specialCategoryQuiz', data)
  console.log('specialCategoryQuiz error', error)

  if (!data) { return null }

  return (
    <div className='w-100 h-full bg-gray-100 p-4 md:p-8 rounded-md border my-8 md:my-0'>
      <h2 className='text-lg font-bold mb-8'>Test Your Understanding</h2>
      {!data.specialCategoryQuiz.completedAt && <IncompleteQuiz quiz={data.specialCategoryQuiz} refetch={refetch} />}
      {data.specialCategoryQuiz.completedAt && <CompleteQuiz quiz={data.specialCategoryQuiz} />}
    </div>
  )

}

export default SpecialCategoryQuiz