import { useAuthentication } from "../../contexts/authentication"
import { useParams } from "react-router-dom";
import { useQuery } from "@apollo/client";
import { GET_SUBCATEGORY_QUERY } from './queries'
import { Link } from "react-router-dom";
import Markdown from 'react-markdown'
import MainLayout from "../../components/main-layout";
import Quiz from './quiz'

const SubcategoryQuizPage = () => {
  const { logUserOut, currentUser } = useAuthentication()
  const params = useParams();

  const { loading, error, data } = useQuery(GET_SUBCATEGORY_QUERY, {
    variables: {
      category: params.category,
      subcategory: params.subcategory
    }
  })

  if (error) { 
    console.log('error', error)
    return <p>Error: {error}</p>
  }
  if (!data) { return null; }

  return (
    <MainLayout>
    <div>
      <div className='p-8'>
        <p>
          <Link className='text-sm text-blue-500 font-medium' to={`/course/${data.subcategory.category.slug}`}>{data.subcategory.category.title}</Link>
        </p>
        <h1 className='text-2xl font-bold mb-8'>{data.subcategory.title} Quiz</h1>
        <Quiz subcategoryId={data.subcategory.id} />
      </div>
    </div>
    </MainLayout>
  )
}

export default SubcategoryQuizPage