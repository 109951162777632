import { useEffect, useMemo, useState } from "react";
import MainLayout from "components/main-layout"
import { Link } from "react-router-dom"
import { CONTENT } from './content'
import { useQuery } from "@apollo/client";
import { GET_CATEGORY_QUERY } from './queries'
import Markdown from "react-markdown";
import SpecialCategoryQuiz from "./special-category-quiz";

const PracticeManagementPage = () => {  

  const { loading, error, data, refetch } = useQuery(GET_CATEGORY_QUERY, {
    variables: {
      slug: 'practice-management',
    }
  })

  console.log('practice-management data', data)

  useEffect(() => {
    document.title = `Patch Education — Practice Management`;
  }, []);

  return (
    <MainLayout>
      <div>
        <div className='md:pt-8 p-2 flex flex-col'>
          <div className='w-full flex flex-col'>
            <h1 className='md:px-8 text-2xl font-bold mt-4'>Practice Management</h1>
          </div>
          <div className='mt-8 px-0 md:px-8'>
            <div className='flex flex-col md:flex-row'>
              <div className='flex flex-col w-100 md:w-2/3 md:pr-12' >
                <Markdown components={{
                  // h3: 'H3Component',
                  p(props) {
                    const { node, ...rest } = props
                    return <p className='mb-4' {...rest} />
                  },
                  h2(props) {
                    const { node, ...rest } = props
                    return <h2 className='text-lg font-bold underline' {...rest} />
                  },
                  h3(props) {
                    const { node, ...rest } = props
                    return <h3 className='text-md font-bold' {...rest} />
                  },
                  h4(props) {
                    const { node, ...rest } = props
                    return <h4 className='font-medium underline' {...rest} />
                  },
                  em(props) {
                    const { node, ...rest } = props
                    return <i className='font-italic' {...rest} />
                  }
                }}>
                  {CONTENT}
                </Markdown>
              </div>
              <div className='w-100 w-1/3'>
                {data?.category?.id && <SpecialCategoryQuiz categoryId={data.category.id} />}
              </div>
            </div>
          </div>
        </div>
      </div>
    </MainLayout>
  )

}

export default PracticeManagementPage