import { useState, useEffect } from "react"
// import MainLayout from "../../components/main-layout"
import { PROCEED_TO_NEXT_QUESTION_MUTATION, SHOW_TEST_QUESTION_ANSWER_MUTATION } from "./queries"
import { useQuery, useMutation } from "@apollo/client"
import { Link, useParams } from "react-router-dom"
// import IncompleteTest from './incomplete-test'
// import CompleteTest from './complete-test'
import { format } from "date-fns"


const TestQuestion = ({ testId, numberQuestions, testQuestion, refetch }) => {
  // const params = useParams()
  const [selectedAnswer, setSelectedAnswer] = useState()
  const [correct, setCorrect] = useState()
  const [correctAnswer, setCorrectAnswer] = useState()
  const [answeredAt, setAnsweredAt] = useState()

  // const { loading, error, data, refetch } = useQuery(GET_CURRENT_TEST_QUESTION_QUERY, {
  //   variables: {
  //     testId: testId
  //   },
  //   onCompleted: (data) => {
  //     console.log('test question data', data)
  //   }
  // })

  const [showTestQuestionAnswer, _] = useMutation(SHOW_TEST_QUESTION_ANSWER_MUTATION, {
    variables: {
      testId: testId,
      testQuestionId: testQuestion.id,
      submittedAnswer: selectedAnswer
    },
    errorPolicy: "all",
    onCompleted: (data) => {
      console.log('showTestQuestionAnswerMutation', data)
      // refetch()
      setCorrect(data.showTestQuestionAnswer.testQuestion.correct)
      setAnsweredAt(data.showTestQuestionAnswer.testQuestion.answeredAt)
      setCorrectAnswer(data.showTestQuestionAnswer.testQuestion.question.correctAnswer)
    }
  })

  const [proceedToNextItem, _2] = useMutation(PROCEED_TO_NEXT_QUESTION_MUTATION, {
    variables: {
      testId: testId,
      testQuestionId: testQuestion.id,
      submittedAnswer: selectedAnswer
    },
    errorPolicy: 'all',
    onCompleted: (data) => {
      console.log('proceed to next is done')
      refetch()
    }
  })

  const onSelectAnswer = (e) => {
    setSelectedAnswer(e.target.value)
  }

  const onShowTestQuestionAnswer = () => {
    if (!answeredAt) {
      showTestQuestionAnswer()
    }
  }

  useEffect(() => {
    setCorrect(null)
    setAnsweredAt(null)
    setCorrectAnswer(null)
  }, [testQuestion])


  // console.log('current test question', data)
  console.log('testQuestion', testQuestion)

  if (!testQuestion) { return null }

  return (
      <div>
      <p className={`mb-4`}>{testQuestion.order}. {testQuestion.question.question}</p>
        <div className='bg-white border px-4 py-4 border-[#004975] border-b-4'>
          {testQuestion.question.answers.map((answer, i) => (
            <div key={i}>
              <input
                type="radio" key={answer} value={answer} name={testQuestion.id} id={answer}
                onChange={onSelectAnswer}
                disabled={answeredAt}
              />
              <label htmlFor={answer} className={`ml-2 ${correctAnswer === answer ? 'text-green-600 font-medium' : ''} ${correctAnswer && correctAnswer !== answer && selectedAnswer === answer ? 'text-red-600 font-medium' : ''}`}>
                {i == 0 && 'A. '}
                {i == 1 && 'B. '}
                {i == 2 && 'C. '}
                {i == 3 && 'D. '}
                {answer}
              </label>
            </div>
          ))}
          <button onClick={onShowTestQuestionAnswer} className='text-black bg-gray-200 text-sm rounded-sm shadow-sm border border-black font-semibold px-4 py-1 mt-4'>Show Answer</button>
        </div>
        <div className='flex flex-row items-center justify-center mt-4'>
          <button onClick={proceedToNextItem} className='bg-[#004975] rounded-sm shadow-sm px-4 py-1 text-white'>{numberQuestions == testQuestion.order ? 'Complete Test' : 'Proceed to Next Item'}</button>
        </div>
      </div>
  )
}

export default TestQuestion