import { useEffect, useState } from "react"
import MainLayout from "../../components/main-layout"
import { GET_TESTS_QUERY } from "./queries"
import { useQuery } from "@apollo/client"
import { Link } from "react-router-dom"
import { format } from 'date-fns';
import { useNavigate } from "react-router-dom";

const AllTestPage = () => {
  const navigate = useNavigate()

  const [currentTab, setCurrentTab] = useState('tests')

  const { loading, error, data, refetch } = useQuery(GET_TESTS_QUERY)

  useEffect(() => {
    refetch()
  }, [])

  useEffect(() => {
    document.title = `Patch Education — Tests`;
  }, []);
  
  
  console.log('error',error)
  console.log('tests', data)

  if (!data) { return null }

  const options = { weekday: 'long', year: 'numeric', month: 'short', day: 'numeric' };



  return (
    <MainLayout>
      <div className='p-4 md:p-8'>
        <div className='flex flex-row justify-between items-center mb-12'>
          <h1 className='text-2xl font-bold text-slate-800'>Testing</h1>
          <button className='text-white bg-black px-4 py-2 rounded-md' onClick={() => navigate('/tests/new')}>Take a new test</button>
        </div>
        <p className='text-slate-800 mb-8'>Welcome to the testing center. Below you will see a list of past tests you've taken. Start a new test using the button to your right.</p>
        <div className="sm:block">
          <div className="border-b border-slate-200">
            <nav className="-mb-px flex space-x-8" aria-label="Tabs">
              {/* <!-- Current: "border-indigo-500 text-indigo-600", Default: "border-transparent text-slate-500 hover:border-slate-300 hover:text-slate-700" --> */}
              <a onClick={() => setCurrentTab('tests')} className={`${currentTab == 'tests' ? 'border-indigo-500 text-indigo-600' : 'border-transparent text-slate-500 hover:border-slate-300 hover:text-slate-700'} whitespace-nowrap border-b-2 py-4 px-1 text-sm font-medium cursor-pointer`}>Tests</a>
              <a onClick={() => setCurrentTab('studyguide')} className={`${currentTab == 'studyguide' ? 'border-indigo-500 text-indigo-600' : 'border-transparent text-slate-500 hover:border-slate-300 hover:text-slate-700'} whitespace-nowrap border-b-2 py-4 px-1 text-sm font-medium cursor-pointer`}>Studyguide</a>
            </nav>
          </div>
        </div>
        {currentTab == 'tests' && data.tests.length > 0 && (
          <ul className='divide-y divide-slate-100 border rounded-lg pt-2 px-4 mt-12'>
            {data.tests.map(test => (
              <li className='px-4 py-4 mb-2 flex flex-row justify-between items-center' key={test.id}>
                <Link to={`/tests/${test.id}`} className='hover:font-medium text-black'>
                  <span>{format(new Date(test.createdAt), "LLL dd, yyyy hh:mm a")}</span>
                </Link>
                <div>
                  <span>{test.completedAt && (`${test.scorePercent}%`)}</span>
                  {!test.completedAt && <span className='text-red-500'>Incomplete</span>}
                </div>
              </li>
            ))}
          </ul>
        )}
        {currentTab == 'studyguide' && data.missedTestQuestions.length > 0 && (
          <ul className='list-disc px-8 mt-12'>
            {data.missedTestQuestions.map(testQuestion => (
              <li className='px-4 py-2' key={testQuestion.id}>
                {testQuestion.question.studyHint}
              </li>
            ))}
          </ul>
        )}

        {data.tests.length == 0 && <p className='text-slate-500 mt-12'>No tests yet!</p>}
      </div>
    </MainLayout>
  )
}

export default AllTestPage