import { useEffect } from "react"
import { useAuthentication } from "../../contexts/authentication"
import MainLayout from "../../components/main-layout"
import { useQuery } from "@apollo/client"
import { GET_CATEGORIES_QUERY } from './queries'
import { Link } from "react-router-dom"

const CoursePage = () => {

  useEffect(() => {
    document.title = `Patch Education — Course`;
  }, []);

  // const { loading, error, data } = useQuery(GET_CATEGORIES_QUERY) 

  const categories = [
    { link: '/course/swine', title: 'Swine', photoUrl: 'https://patch-education-assets.s3.us-west-1.amazonaws.com/swine.png' },
    { link: '/course/equine', title: 'Equine', photoUrl: 'https://patch-education-assets.s3.us-west-1.amazonaws.com/equine.png' },
    { link: '/course/canine-feline', title: 'Canine/Feline', photoUrl: 'https://patch-education-assets.s3.us-west-1.amazonaws.com/canine-feline-2.png' },
    { link: '/course/bovine', title: 'Bovine', photoUrl: 'https://patch-education-assets.s3.us-west-1.amazonaws.com/bovine.png' },
    { link: '/course/farm-animals', title: 'Farm Animals', photoUrl: 'https://patch-education-assets.s3.us-west-1.amazonaws.com/farm-animals.png' },
    { link: '/course/exotics', title: 'Exotics', photoUrl: 'https://patch-education-assets.s3.us-west-1.amazonaws.com/exotic.png' },
    { link: '/course/cervids-camelids', title: 'Cervids/Camelids', photoUrl: 'https://patch-education-assets.s3.us-west-1.amazonaws.com/deer.png' },
    { link: '/course/notifiable-diseases', title: 'Reportable Diseases', photoUrl: 'https://patch-education-assets.s3.us-west-1.amazonaws.com/notifiable-diseases.png' },
    { link: '/course/ethics', title: 'Ethics', photoUrl: 'https://patch-education-assets.s3.us-west-1.amazonaws.com/ethics-2.png' },
    { link: '/course/pharmacology', title: 'Pharmacology', photoUrl: 'https://patch-education-assets.s3.us-west-1.amazonaws.com/pharmacology.png' },
    { link: '/course/practice-management', title: 'Practice Management', photoUrl: 'https://patch-education-assets.s3.us-west-1.amazonaws.com/practice-management.png' }
  ]
  

  return (
    <MainLayout>
      <div className='p-8'>
        <h1 className='text-2xl font-bold mb-12 text-slate-800'>Welcome to the course!</h1>
        <h3 className='text-slate-800 font-bold text-sm mb-4'>A Note From The Authors</h3>
        <p className='text-slate-800 text-sm leading-0.5 mb-4'>This manual was written primarily for veterinary students studying for the North American Veterinary Licensing Examination®. The format of this text is designed to provide veterinary students with a short and concise overview of the most important diseases they need to know to pass their licensing examination. The diseases discussed within this text are those most commonly encountered in previous exams and most likely to reappear in the future.</p>
        <p className='text-slate-800 text-sm leading-0.5 mb-4'>The amount of material students are forced to memorize can be overwhelming, and for this reason numerous diseases not commonly encountered in licensing examinations have been omitted from this text. By including only those diseases most likely to appear on the test, students may focus their time and energy in those areas that are most important. This manual is in no way a complete reference guide to all diseases, however, it does provide a solid foundation for the most common diseases in the world today and is a good manual for future reference or client education.</p>
        <p className='text-slate-800 text-sm leading-0.5 font-medium'>— Patch Veterinary Team</p>
        <ul className='grid xl:grid-cols-5 lg:grid-cols-4 md:grid-cols-3 sm:grid-cols-2 gap-12 mt-24'>
          {categories.map(category => (
            <Link to={category.link}>
              <li className='group rounded-md border px-8 py-4 text-center relative w-56 h-56' style={{ backgroundRepeat: 'no-repeat', backgroundSize: 'contain', backgroundImage: `url(${category.photoUrl})` }}>
                <div className='absolute inset bg-black right-0 left-0 top-0 bottom-0 group-hover:block hidden opacity-10'></div>
                <span className='absolute bg-slate-900 text-white left-0 right-0 bottom-0 opacity-80 h-12 flex flex-col items-center justify-center font-semibold group-hover:opacity-90 rounded-b-md'>{category.title}</span>
              </li>
            </Link>
          ))}
        </ul>
      </div>
    </MainLayout>
  )
}

export default CoursePage