export const DISEASES = [
  {
    title: 'African Horse Sickness',
    links: [{title: 'Equine', url: '/course/equine/infectious-diseases/african-horse-sickness'}],
    content: 'African horse sickness(AHS) is a non-contagious, vector-borne viral disease that affects all members of the Equidae family including horses, mules, donkeys, and zebras. The United States(U.S.) has never had a confirmed case of AHS; therefore, it is a foreign animal disease reportable at both the Federal and State levels. A variety of susceptible hosts, primarily equids, and competent vectors, including the biting “midge,” are present throughout North America.AHS does not affect human health.',
    actions: 'AHS is an internationally reportable disease. If you see an equid you believe have AHS, contact your state and federal veterinary health officials.'
  },
  {
    title: 'African Swine Fever',
    links: [{title: 'Swine', url: '/course/swine/polysystemic-diseases/african-swine-fever-virus'}],
    content: `African swine fever is a highly contagious and deadly viral disease affecting both domestic and feral swine of all ages. ASF is not a threat to human health and cannot be transmitted from pigs to humans.It is not a food safety issue. ASF is found in countries around the world and it has never been found in the United States.
    Anyone who works with pigs should be familiar with the signs of ASF:
    High fever, decreased appetite and weakness, ded, blotchy skin or skin lesions, diarrhea and vomiting, coughing and difficulty breathing.`,
    actions: 'Immediately report animals with any of these signs to state or federal animal health officials.'
  },
  {
    title: 'Highly Pathogenic Avian Influenza',
    links: [{title: 'Poultry', url: '/course/exotics/poultry-infectious-diseases/avian-influenza'}],
    content: 'Highly Pathogenic Avian Influenza(HPAI) is a serious disease and requires rapid response because it is highly contagious and often fatal to chickens.APHIS works with its federal, state, local and industry partners to quickly mobilize a response to any HPAI findings. The goal is to quickly contain and eradicate the disease, protecting our poultry industry, and in turn, the American consumer.',
    actions: 'Immediately report animals with any of these signs to state or federal animal health officials.'
  },
  {
    title: 'Bovine Spongiform Encephalopathy',
    links: [{title: 'Bovine', url: '/course/bovine/infectious-diseases/bovine-spongiform-encephalitis'}],
    content: `Bovine Spongiform Encephalopathy(BSE), widely referred to as “mad cow disease,” is a progressive and fatal neurologic disease of cattle. It is caused by an unconventional transmissible agent, an abnormal prion protein.BSE belongs to a family of diseases known as transmissible spongiform encephalopathies(TSEs) that includes scrapie in sheep and goats, chronic wasting disease in deer, elk and moose, and in humans, classic and variant Creutzfeldt - Jakob disease(CJD) among other syndromes.The prion agent is resistant to enzymatic breakdown and most disinfection treatments.Clinical signs in cattle include behavioral changes, coordination problems, weight loss and decreased milk production.
    BSE was first diagnosed in 1986 in the United Kingdom, which has had the vast majority of cases worldwide. However, the disease has been detected in many other countries, including six cases in the United States from 2003 to 2012. Of the six U.S.cases, the first was a case of classical BSE that was imported from Canada; the rest have been atypical BSE.
    BSE presents a public health concern because occurrences of variant CJD in humans have been linked to the consumption of food containing ingredients derived from BSE - infected cattle.`,
    actions: 'BSE is a reportable disease. Any suspicious cases should be reported to the APHIS - VS Area Office or the State animal health official as a suspected foreign animal disease(FAD).',
  },
  {
    title: 'Brucellosis',
    links: [{
      title: 'Goats',
      url: '/course/farm-animals/goats-infectious-diseases/brucellosis'
    }, {
      title: 'Bovine',
      url: '/course/bovine/infectious-diseases/brucellosis'
    }, {
      title: 'Swine',
      url: '/course/swine/reproductive-diseases/brucellosis'
    }, {
      title: 'Canine/Feline',
      url: '/course/canine-feline/infectious-diseases/brucellosis'
    }],
    content: "Brucellosis is a contagious, costly disease. While most often found in ruminant animals(e.g., cattle, bison and cervids) and swine, brucellosis(also known as contagious abortion or Bang's disease) can affect other animals and is transmissible to humans. The disease is caused by a group of bacteria known scientifically as the genus Brucella. Three species of Brucella cause the most concern: B. abortus, principally affecting cattle, bison and cervids; B. suis, principally affecting swine and reindeer but also cattle and bison; and B. melitensis, principally affecting goats but not present in the United States.",
    actions: 'If you suspect brucellosis in a herd due to either clinical signs such as abortion or weak calves, or if you have reason to believe your cattle have been exposed to brucellosis through wildlife, you must contact your state animal health official, or your local USDA - APHIS - VS office. Since brucellosis is infectious within a herd and can spread animal - to - animal or animal - to - human, you must call as soon as you suspect brucellosis.'
  },
  {
    title: 'Cattle Fever Tick',
    links: [{title: 'Bovine', url: '/course/bovine/infectious-diseases/babesiosis'}],
    content: 'Cattle Fever Ticks, known scientifically as Rhipicephalus(formerly Boophilus) annulatus and R.microplus, are a significant threat to the United States cattle industry. These ticks are capable of carrying the protozoa, or microscopic parasites, Babesia bovis or B.bigemina, commonly known as cattle fever.The Babesia organism attacks and destroys red blood cells, causing acute anemia, high fever, and enlargement of the spleen and liver, ultimately resulting in death for up to 90 percent of susceptible naive cattle. The fever tick has been a threat to American agriculture for generations.The disease caused enormous economic losses to the U.S.cattle industry in the late 1800s and early 1900s.Since that time, the Texas Animal Health Commission(TAHC) and the USDA - Animal and Plant Health Inspection Service - Veterinary Services works together to protect the state and nation from the pest and its repercussions.',
    actions: 'Fever Ticks are reportable to the TAHC.To report a suspected or confirmed fever ticks, call the TAHC or the USDA.'
  }, 
  {
    title: 'Chronic Wasting Disease',
    links: [{title: 'Goats', url: '/course/farm-animals/goats-infectious-diseases/chronic-wasting-disease-cwd'}],
    content: `Chronic Wasting Disease(CWD) is an infectious, degenerative disease of animals in the family cervidae(elk, deer, and moose, etc.) that causes brain cells to die, ultimately leading to the death of the affected animal. First recognized in Colorado in 1967, CWD was described as a clinical 'wasting' syndrome of unknown cause.It later became clear that CWD was a member of a group of diseases known as transmissible spongiform encephalopathies or TSEs. TSEs include a number of different diseases that affect animals or humans, including bovine spongiform encephalopathy(BSE or “mad cow”) in cattle, scrapie in sheep and goats, and Creutzfeldt - Jacob disease(CJD), variant CJD, Kuru, fatal familial insomnia, and Gerstmann - Straussler - Scheinker syndrome in humans.Unlike other infectious diseases, TSEs are not caused by bacteria or viruses, but rather by a naturally occurring protein, that when folded incorrectly, becomes both infectious and deadly.The prion protein in its normal state is thought to have a role in functions such as cell signaling and neuroprotection.It is still unclear what initially causes the normal shaped protein to misfold into the infectious form. Once misfolded, the infectious prion proteins continue to convert more and more normal prion proteins to the misfolded form.Misfolding of prion proteins in the brain leads to the death of neurons(brain cells) resulting in dysfunction in the body, ultimately causing death.The incubation period can be long(several months to years) depending on species and genetic factors, and infected animals are in good body condition until the end stages of the disease, making them difficult to distinguish from healthy animals.
    Unfortunately, animals infected with CWD can transmit the disease to other animals during the “silent” incubation period.
    In the final stages of disease, animals become thin, drink and urinate excessively, have poor balance and coordination, lack body fat, have drooping ears, and difficulty swallowing(which is responsible for the classic drooling associated with the disease). Inability to swallow leads to aspiration pneumonia and death.Currently there is no cure or preventative measure, such as a vaccine, available for CWD.Other disease may present in a similar manner so post mortem testing is required to verify disease.Since its discovery in 1967 CWD has been found in a number of states, Canadian provinces, Korea, and Norway, in both wild and farmed populations.`,
    actions: 'If the animal to be sampled is a wild deer or elk that is suspected of having CWD, accredited veterinarians should inform State and Federal authorities and work with their State wildlife management agency to find out how officials would like the sample collected and submitted.'
  }, 
  {
    title: 'Classical Swine Fever',
    links: [{title: 'Swine', url: '/course/swine/polysystemic-diseases/hog-cholera-classical-swine-fever'}],
    content: 'Classical swine fever(CSF) is a highly contagious and economically significant viral disease of pigs. The severity of the illness varies with the strain of the virus, the age of the pig, and the immune status of the herd.Acute infections, which are caused by highly virulent isolates and have a high mortality rate in naive herds, are more likely to be diagnosed rapidly. However, infections with less virulent isolates can be more difficult to recognize, particularly in older pigs. The range of clinical signs and its clinical similarity to other diseases can make classical swine fever challenging to diagnose.Although classical swine fever was once widespread, many countries have eradicated this disease from domesticated swine.CSF was eradicated from the U.S in 1978.',
    actions: 'Classical swine fever should be reported immediately upon diagnosis or suspicion of the disease. Reporting should be made to the Federal Area Veterinarian in Charge as well as the State Animal Health Officials.'
  },
  {
    title: 'Contagious Equine Metritis',
    links: [{title: 'Equine', url: '/course/equine/reproductive-diseases/contagious-equine-metritis'}],
    content: 'Contagious Equine Metritis(CEM) is a venereal disease caused by the bacteria, Taylorella equigenitalis affecting only the equine species.It can have significant impacts on reproduction. Treatment of CEM is usually successful.CEM has not been shown to be zoonotic.The United States is considered CEM free and therefore it is a Foreign Animal Disease reportable at both the Federal and State level. Horses imported to the United States must meet certain import criteria or undergo CEM quarantine procedures at an approved facility to demonstrate freedom of CEM.',
    actions: 'The United States is considered CEM free and therefore it is a Foreign Animal Disease reportable at both the Federal and State level.'
  },
  {
    title: 'Equine Herpesvirus',
    links: [{title: 'Equine', url: '/course/equine/reproductive-diseases/herpesvirus-type-1'}],
    content: 'Equine Herpesvirus(EHV), also known as Equine Rhinopneumonitis, is a highly infectious Alphaherpesviridae found virtually worldwide.There are currently 9 known EHVs.EHV - 1, EHV - 3 and EHV - 4 pose the highest disease risk in the U.S.horse population. EHV - 1 and EHV - 4 can cause upper respiratory disease, neurological disease, abortions, and / or neonatal death.EHV - 3 causes a venereal disease called coital exanthema. In recent years there has been an increase in the number of EHV - 1 neurologic cases, also referred to as Equine Herpes Myeloencephalopathy(EHM).The virus incubation period is highly variable and can be as long as 14 days. Prognosis is good for horses not affected with the neurologic form and varies for those with neurologic signs.EHV has not been shown to be zoonotic.',
    actions: 'Contact your local State Animal Health Official or APHIS - VS - Assistant Director.Collect nasal swabs and blood to send to NAHLN Laboratory for confirmation.Using a 1 to 10 bleach ratio will neutralize the virus.'
  },
  {
    title: 'Equine Infectious Anemia',
    links: [{title: 'Equine', url: '/course/equine/infectious-diseases/infectious-anemia'}],
    content: 'Equine Infectious Anemia(EIA) is a viral disease affecting only members of the equidae family(horses, ponies, zebras, mules, and donkeys). There is no vaccine or treatment for the disease.Infection is often inapparent and results in a lifelong carrier state if the horse survives the acute phase of the infection.EIA is found widely throughout the world. There is no evidence that EIA is a threat to human health.EIA is a reportable animal disease in all states.',
    actions: 'Contact your local State Animal Health Official or APHIS - VS - Assistant Director.'
  },
  {
    title: 'Equine Piroplasmosis',
    links: [{title: 'Equine', url: '/course/equine/infectious-diseases/babesiosis'}],
    content: 'Equine Piroplasmosis is a blood - borne protozoal infection of horses caused by Theileria(Babesia) equi and / or Babesia caballi. Equine Piroplasmosis is present in South and Central America, the Caribbean(including Puerto Rico), Africa, the Middle East, and Eastern and Southern Europe. Only the United States, Canada, Australia, Japan, and Iceland are not considered to be endemic areas. Mortality rates for infected horses can reach 50 %.Horses infected with EP can be enrolled in a USDA - APHIS - approved EP treatment program which is frequently successful at permanently eliminating the infection. EP has not been shown to be zoonotic.EP is considered a foreign animal disease in the United States and any detection must be reported to the State Veterinarian and / or the APHIS Veterinary Services Assistant Director.',
    actions: 'Contact your local State Animal Health Official or APHIS.'
  },
  {
    title: 'Equine Viral Arteritis',
    links: [{title: 'Equine', url: '/course/equine/reproductive-diseases/viral-arteritis'}],
    content: 'Equine Viral Arteritis(EVA) is a contagious viral disease in horses caused by Equine Arteritis Virus(EAV). Infection can go undetected by horse owners / breeders and in herds that were previously unexposed(naïve) abortion rates in pregnant mares can reach up to 70%. Most horses infected with EAV will show minimal if any clinical signs and recover without incident although stallions can become carriers and lifelong shedders of the virus. There is a vaccine available that has been shown to prevent infection with EAV. Vaccination should be performed at least 21 days prior to the start of breeding season to provide adequate levels of immunity.EVA has not been shown to be zoonotic.',
    actions: 'Contact your local State Animal Health Official or APHIS as EVA is not reportable in every state.'
  },
  {
    title: 'Foot and Mouth Disease',
    links: [{title: 'Swine', url: '/course/swine/polysystemic-diseases/foot-and-mouth-disease'}],
    content: `Foot-and-mouth disease(FMD) is a severe and highly contagious viral disease. The FMD virus causes illness in cows, pigs, sheep, goats, deer, and other animals with divided hooves.It does not affect horses, dogs, or cats.
    FMD is not a public health or food safety threat. It is also not related to hand, foot, and mouth disease, which is a common childhood illness caused by a different virus. FMD is a worldwide concern as it can spread quickly and cause significant economic losses. While many countries across the globe are dealing with FMD in their livestock populations, the United States eradicated the disease here in 1929. APHIS works hard to prevent FMD from reentering the country.
    FMD causes production losses and hardships for farmers and ranchers. It also has serious impacts on livestock trade—a single detection of FMD will likely stop international trade completely for a period of time. Since the disease can spread widely and rapidly and has grave economic consequences, FMD is one of the animal diseases livestock owners dread most.`,
    actions: "Contact your local State Animal Health Official or APHIS."
  },
  {
    title: "Johne's Disease",
    links: [{
      title: 'Goats',
      url: '/course/farm-animals/goats-infectious-diseases/johne-s-disease'
    }, {
      title: 'Bovine',
      url: '/course/bovine/infectious-diseases/johne-s-disease'
    }, {
      title: 'Sheep',
      url: '/course/farm-animals/sheep-infectious-diseases/johne-s-disease-paratuberculosis'
    }],
    content: "Johne's disease(pronounced 'yo-knees') is a contagious, chronic and usually fatal infection that affects primarily the small intestine of ruminants.All ruminants are susceptible to Johne's disease. Johne's disease is caused by Mycobacterium avium subspecies paratuberculosis, a hardy bacteria related to the agents of leprosy and tuberculosis.The disease is worldwide in distribution. Signs of Johne's disease include weight loss and diarrhea with a normal appetite.Several weeks after the onset of diarrhea, a soft swelling may occur under the jaw(bottle jaw).Bottle jaw or intermandibular edema is due to protein loss from the bloodstream into the digestive tract. Animals at this stage of the disease will not live very long, perhaps a few weeks at most.",
    actions: "Contact your local State Animal Health Official or APHIS."
  }, {
    title: 'Mycoplasma ovipneumoniae',
    links: [{title: 'Sheep', url: '/course/farm-animals/sheep-infectious-diseases/enzootic-pneumonia'}],
    content: `Mycoplasma ovipneumoniae is a respiratory bacterium associated with economically impactful pneumonia in domestic sheep and goats since 1972, when it was first described.
    Although M.ovipneumoniae was previously reported to affect species of Caprinae(sheep, goats, and muskoxen), recent studies have identified the bacterium in animals outside Caprinae, highlighting the knowledge gaps in the basic epidemiology of this bacterium. To date, M.ovipneumoniae has been reported in Beira antelope, bighorn sheep, caribou, Dall sheep, moose, mountain goats, mule deer, caribou, muskoxen, white - tailed deer, and domestic cattle, sheep, and goats via a variety of diagnostic methods.`,
    actions: 'Contact your local State Animal Health Official or APHIS.'
  },
  {
    title: 'Porcine Epidemic Diarrhea Virus',
    links: [{title: 'Swine', url: '/course/swine/polysystemic-diseases/african-swine-fever-virus'}],
    content: 'Porcine Epidemic Diarrhea Virus(PEDV) is a highly contagious viral disease that primarily affects pigs. The virus belongs to the Coronaviridae family and is characterized by causing severe diarrhea and dehydration, particularly in swine herds with no previous exposure.',
    actions: 'Contact your local State Animal Health Official or APHIS.'
  }, {
    title: 'Pseudorabies',
    links: [{
      title: 'Goats',
      url: '/course/farm-animals/goats-infectious-diseases/pseudorabies'
    }, {
      title: 'Bovine',
      url: '/course/bovine/infectious-diseases/pseudorabies'
    }, {
      title: 'Swine',
      url: '/course/swine/polysystemic-diseases/pseudorabies-virus'
    }],
    content: 'Pseudorabies is a disease of swine that can also affect cattle, dogs, cats, sheep, and goats. Pseudorabies virus(PRV) is a contagious herpesvirus that causes reproductive problems, (abortion, stillbirths), respiratory problems and occasional deaths in breeding and finishing hogs.Infected newborn pigs may exhibit central nervous system clinical signs.',
    actions: 'Pseudorabies is a reportable disease in all states.Contact your local State Animal Health Official or APHIS.'
  },
  {
    title: 'Rabbit Hemorrhagic Disease Virus',
    links: [{
      title: 'Rabbit',
      url: '/course/exotics/rabbits/rabbit-calicivirus-disease'
    }],
    content: 'Rabbit Hemorrhagic Disease Virus(RHDV) is a highly contagious and often fatal viral infection affecting rabbits. The virus is a member of the Caliciviridae family and has several variants, including RHDV1 and RHDV2. RHDV primarily targets the liver and causes acute hemorrhagic lesions, leading to significant morbidity and mortality in affected rabbit populations.',
    actions: 'RHDV is a reportable disease in all states.Contact your local State Animal Health Official or APHIS.'
  }, {
    title: 'Schmallenberg Virus',
    links: [{
      title: 'Sheep',
      url: '/course/farm-animals/sheep-infectious-diseases/schmallenberg-virus'
    }],
    content: 'Schmallenberg virus(SBV) is an Orthobunyavirus belonging to the Bunyaviridae family. It primarily affects ruminants, including cattle, sheep, and goats.SBV emerged in 2011 in Germany and has since been reported in various European countries. The virus is transmitted by Culicoides biting midges, making it an arthropod - borne virus with a significant impact on livestock health.',
    actions: 'SBV is a reportable disease in all states.Contact your local State Animal Health Official or APHIS.'
  },
  {
    title: 'Scrapie',
    links: [{title: 'Goats', url: 'course/farm-animals/goats-infectious-diseases/scrapie'}],
    content: 'Scrapie is a transmissible spongiform encephalopathy(TSE) affecting sheep and goats. It belongs to the family of prion diseases, characterized by the accumulation of misfolded prion proteins in the central nervous system. Scrapie is a notifiable disease, with significant implications for animal health and trade.',
    actions: 'Scarpie is reportable in every state.Contact your State Veterinarian or the USDA Veterinary Services Office if your sheep or goat, older than 12 months, exhibits clinical signs of scrapie. Testing clinical suspects is the most cost effective way to find scrapie infected animals.Submit samples or whole heads from sheep and goats over 18 months of age that die or are euthanized on your premises.'
  },
  {
    title: 'Influenza A Virus in Swine',
    links: [{title: 'Swine', url: '/course/swine/respiratory-diseases/swine-influenza'}],
    content: 'Influenza A virus in swine, commonly known as swine flu, is caused by influenza A viruses that infect pigs. These viruses belong to the Orthomyxoviridae family and can undergo genetic reassortment, leading to the emergence of new strains with the potential for interspecies transmission.',
    actions: 'Influenza is present at low levels in pigs throughout the world, and is monitored by the voluntary USDA Swine Influenza Surveillance Program, although it is not a reportable or regulated disease.'
  },
  {
    title: 'Tuberculosis',
    links: [{
      title: 'Equine',
      url: '/course/equine/infectious-diseases/tuberculosis'
    }, {
      title: 'Bovine',
      url: '/course/bovine/infectious-diseases/bovine-tuberculosis'
    }],
    content: 'Tuberculosis in animals is a chronic infectious disease caused by various species of mycobacteria, most notably Mycobacterium bovis. It affects a wide range of animals, including cattle, deer, badgers, goats, and several other species.Tuberculosis is zoonotic, posing a risk to both animal and human populations.Once the most prevalent infectious disease of cattle and swine in the United States, bovine TB caused more losses among U.S. farm animals in the early part of the 20th century than all other infectious diseases combined. While this disease has nearly been eradicated, sporadic cases are detected in livestock herds.',
    actions: 'Tuberculosis is a reportable disease and every suspected or diagnosed bovine TB case must be reported to the state veterinarian and the USDA assistant director.'
  },
  {
    title: 'Vesicular Stomatitis',
    links: [{
      title: 'Equine',
      url: '/course/equine/infectious-diseases/vesicular-stomatitis'
    }, {
      title: 'Swine',
      url: '/course/swine/polysystemic-diseases/vesicular-stomatitis'
    }],
    content: 'Vesicular stomatitis(VS) is a viral disease affecting various domestic and wild animals. Caused by vesiculoviruses, primarily Vesicular Stomatitis Virus(VSV), the disease is characterized by the formation of vesicles and erosions on the oral mucosa, teats, and coronary bands. Vesicular stomatitis is not endemic within the United States and is federally reportable. In the past decade, the southwestern and western United States experienced several vesicular stomatitis outbreaks introduced from Mexico. Outbreaks usually occur during the warmer months, often along waterways. In some years, only a few premises in a single State were affected. In other years, multiple States and many premises were involved.',
    actions: 'If you suspect an animal may have vesicular stomatitis or any other vesicular disease, immediately contact State and Federal animal health authorities. Samples must be tested at a facility approved by the U.S.Department of Agriculture’s(USDA) National Veterinary Services Laboratories.'
  },
  {
    title: 'West Nile Virus',
    links: [{title: 'Equine', url: '/course/equine/infectious-diseases/west-nile-virus'}],
    content: 'West Nile Virus(WNV) is a viral disease in the Flavivirus family that can result in fever and neurologic disease. The virus can infect horses, humans, and many different species of birds. Clinical signs of the disease usually present within about 15 days after a bite from an infected mosquito. The virus was introduced to the United States in 1999, since then over 27,000 horses have been infected and WNV is considered an endemic disease.',
    actions: 'Report any dead wild birds to the local department of health. If you suspect an animal may have west nile virus you must report it to the state veterinary health official immediately as practitioner testing is an important part of surveillance.'
  }
]