import { gql } from "@apollo/client"

export const GET_TESTS_QUERY = gql`
  query GetTests {
    tests {
      id
      completedAt
      createdAt
      numberCorrectAnswers
      numberQuestions
      scorePercent
    }

    missedTestQuestions {
      id
      question {
        id
        studyHint
      }
    }
  }
`;

export const CREATE_NEW_TEST_MUTATION = gql`
  mutation CreateNewTest {
    createNewTest {
      test {
        id
        completedAt
      }
    }
  }
`
