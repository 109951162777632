import { gql } from "@apollo/client";

export const GET_CURRENT_USER_QUERY = gql`
  query GetCurrentUser {
    currentUser {
      firstName
      lastName
      fullName
      authToken
      photoUrl
      email
      id
    }
  }
`;