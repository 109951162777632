import { ApolloClient, InMemoryCache, ApolloLink, HttpLink } from '@apollo/client';
import { getLocalStorageEntry, setLocalStorageEntry } from 'utils/local-storage'
const { REACT_APP_GRAPHQL_URL } = process.env;


const authLink = new ApolloLink((operation, forward) => {
    const userData = getLocalStorageEntry('_patch_education_user');

    operation.setContext(({ headers }) => ({
        headers: {
            authorization: `Bearer ${userData?.authToken}`,
            ...headers
        }
    }));
    return forward(operation);
});

const httpLink = new HttpLink({
    uri: REACT_APP_GRAPHQL_URL,
    credentials: 'omit',
});

const client = new ApolloClient({
    link: ApolloLink.from([authLink, httpLink]),
    cache: new InMemoryCache(),
});

export default client;