import { useState, useEffect } from 'react'
import { useMutation } from '@apollo/client'
import { ANSWER_USER_SURVEY_MUTATION } from './queries.js'

import PatchEducationLogo from '../../assets/patch-education-white.png'
import PatchLogoIcon from '../../assets/patch-logo-icon.png'
// https://github.com/OMikkel/tailwind-datepicker-react
import Datepicker from "tailwind-datepicker-react"

const { REACT_APP_ENVIRONMENT } = process.env;

const OnboardingSurveyPage = ({ filter }) => {

  const [showDatepicker, setShowDatepicker] = useState(false)
  const [surveyTestDate, setSurveyTestDate] = useState()
  const [surveyTakenBefore, setSurveyTakenBefore] = useState(null)
  const [surveyChallenge, setSurveyChallenge] = useState()
  const [error, setError] = useState()


  useEffect(() => {
    document.title = `Patch Education — OnboardingSurvey`;
  }, []);

  const [answerSurvey, _] = useMutation(ANSWER_USER_SURVEY_MUTATION, {
    errorPolicy: "all",
    onError: (e) => {
      console.log(e)
      setError('Something went wrong')
    },
    onCompleted: (data) => {
      setError(false)
      console.log('completed', data)
      window.location = '/course'
    },
    variables: {
      surveyTestDate,
      surveyTakenBefore,
      surveyChallenge
    }
  })

  const onSubmit = (e) => {
    e.preventDefault()
    answerSurvey()
  }

  const datepickerOptions = {
    defaultDate: null,
    minDate: new Date(), // today
    todayBtn: false,
    clearBtn: false,
  }

  return (
    <div className='h-screen w-full bg-slate-800'>
      <div className="flex min-h-full flex-col justify-center py-12 sm:px-6 lg:px-8">
        <div className="sm:mx-auto sm:w-full sm:max-w-md">
          <img className="mx-auto h-16 w-auto" src={PatchEducationLogo} alt="Patch Education" />
          {/* <h2 className="mt-6 text-center text-2xl font-bold leading-9 tracking-tight text-slate-100">Set a New Password</h2> */}
        </div>

        <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-[480px]">
          <div className="bg-white px-6 py-12 shadow sm:rounded-lg sm:px-12">
            <p className='text-sm mb-8 text-black font-semibold'>Answer a few quick questions so we can better help you prepare.</p>
            <form className="space-y-6" action="#" method="POST">
              <div>
                <label htmlFor="surveyTestDate" className="block text-sm font-medium leading-6 text-gray-900">What date is your test? (Leave blank if not scheduled)</label>
                <div className="mt-2 relative">
                  <Datepicker options={datepickerOptions} onChange={(date) => setSurveyTestDate(date.toDateString())} show={showDatepicker} setShow={setShowDatepicker} />
                  {/* <input value={surveyTestDate} onChange={(e) => setSurveyTestDate(e.target.value)} id="surveyTestDate" name="surveyTestDate" required className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-400 sm:text-sm sm:leading-6" /> */}
                </div>
              </div>
              <div>
                <label htmlFor="surveyTakenBefore" className="block text-sm font-medium leading-6 text-gray-900">Have you taken the test before?</label>
                <div class="space-y-4 sm:flex sm:items-center sm:space-x-10 sm:space-y-0 mt-2">
                  <div class="flex items-center">
                    <input checked={surveyTakenBefore === true} onChange={(e) => setSurveyTakenBefore(true)} id="surveyTakenBeforeYes" name="notification-method" type="radio" class="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600" />
                    <label for="surveyTakenBeforeYes" class="ml-3 block text-sm font-medium leading-6 text-gray-900">Yes</label>
                  </div>
                  <div class="flex items-center">
                    <input checked={surveyTakenBefore === false} onChange={(e) => setSurveyTakenBefore(false)} id="surveyTakenBeforeNo" name="notification-method" type="radio" class="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600" />
                    <label for="surveyTakenBeforeNo" class="ml-3 block text-sm font-medium leading-6 text-gray-900">No</label>
                  </div>
                </div>
              </div>
              {surveyTakenBefore && (
                <div>
                  <label htmlFor="surveyChallenge" className="block text-sm font-medium leading-6 text-gray-900">What did you find most challenging about the test?</label>
                  <div className="mt-2">
                    <textarea rows={3} value={surveyChallenge} onChange={(e) => setSurveyChallenge(e.target.value)} id="surveyChallenge" name="surveyChallenge" required className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-400 sm:text-sm sm:leading-6" />
                  </div>
                </div>
              )}
              <div>
                <button disabled={!(surveyTakenBefore != null)} onClick={onSubmit} type="submit" className={`flex w-full justify-center rounded-md bg-blue-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:opacity-80 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-[#FF3F44] ${!(surveyTakenBefore != null) && 'bg-gray-400 hover:bg-gray-400'}`}>Access The Course</button>
              </div>
              {error && (
                <div className='w-full flex flex-col items-center'>
                  <p className='text-red-500 text-sm'>{error}</p>
                </div>
              )}
            </form>
          </div>
        </div>
      </div>
    </div>
  )

}

export default OnboardingSurveyPage