import { gql } from "@apollo/client"

export const ANSWER_USER_SURVEY_MUTATION = gql`
  mutation AnswerUserSurvey(
    $surveyTestDate: String
    $surveyTakenBefore: Boolean
    $surveyChallenge: String
  ) {
    answerUserSurvey(
      surveyTestDate: $surveyTestDate
      surveyTakenBefore: $surveyTakenBefore
      surveyChallenge: $surveyChallenge
    ) {
      success
    }
  }
`
