import { gql } from "@apollo/client"

export const GET_SUBCATEGORY_QUERY = gql`
  query GetSubcategory($category: String!, $subcategory: String!) {
    subcategory(category: $category, subcategory: $subcategory) {
      id
      title
      slug

      category {
        id
        title
        slug
      }

      articles {
        id
        slug
        title
        isCompleted
      }
    }
  }
`;