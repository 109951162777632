import { Popover } from '@headlessui/react'

import HeaderDesktop from './header-desktop'

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

const Header = () => {
    // {/* When the mobile menu is open, add `overflow-hidden` to the `body` element to prevent double scrollbars */ }
    return (
        <Popover
            as="header"
            className= {({ open }) =>
        classNames(
            open ? 'fixed inset-0 z-40 overflow-y-auto' : '',
            'bg-white lg:static lg:overflow-y-visible'
        )
                            }
                        >
            {({ open }) => (
                <>
                    <HeaderDesktop open={open} />
                </>
            )}
    </Popover >
    )
}

export default Header;