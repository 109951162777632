import { useState, useEffect } from 'react'
import { useMutation } from '@apollo/client'
import { RESET_PASSWORD_MUTATION } from './queries.js'

import { useAuthentication } from '../../contexts/authentication'
import PatchEducationLogo from '../../assets/patch-education-white.png'
import PatchLogoIcon from '../../assets/patch-logo-icon.png'
import { useSearchParams } from 'react-router-dom'
import { useNavigate } from 'react-router-dom'

const { REACT_APP_ENVIRONMENT } = process.env;

const ResetPasswordPage = ({ filter }) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate()

  const token = searchParams.get('token')

  const { logUserIn, currentUser } = useAuthentication()

  const [error, setError] = useState()
  const [password, setPassword] = useState('')
  const [password2, setPassword2] = useState('')

  useEffect(() => {
    if (currentUser) {
      window.location = '/course'
    }
  })

  useEffect(() => {
    document.title = `Patch Education — Set Password`;
  }, []);

  const [resetPassword, _] = useMutation(RESET_PASSWORD_MUTATION, {
    errorPolicy: "all",
    onError: (e) => {
      console.log(e)
      setError('Invalid Reset Password Link')
    },
    onCompleted: (data) => {
      setError(false)
      console.log('completed', data.resetPassword)
      logUserIn(data.resetPassword)
      navigate('/onboarding-survey')
    },
    variables: {
      password,
      password2,
      token
    }
  })

  const onSubmit = (e) => {
    e.preventDefault()

    if (password !== password2) {
      setError('Passwords must match')
    } else {
      resetPassword()
    }
  }

  return (
    <div className='h-screen w-full bg-slate-800'>
      <div className="flex min-h-full flex-col justify-center py-12 sm:px-6 lg:px-8">
        <div className="sm:mx-auto sm:w-full sm:max-w-md">
          <img className="mx-auto h-16 w-auto" src={PatchEducationLogo} alt="Patch Education" />
          <h2 className="mt-6 text-center text-2xl font-bold leading-9 tracking-tight text-slate-100">Set a New Password</h2>
        </div>

        <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-[480px]">
          <div className="bg-white px-6 py-12 shadow sm:rounded-lg sm:px-12">
            <form className="space-y-6" action="#" method="POST">
              <div>
                <label htmlFor="password" className="block text-sm font-medium leading-6 text-gray-900">New Password</label>
                <div className="mt-2">
                  <input value={password} onChange={(e) => setPassword(e.target.value)} id="password" name="password" type="password" required className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-400 sm:text-sm sm:leading-6" />
                </div>
              </div>
              <div>
                <label htmlFor="password" className="block text-sm font-medium leading-6 text-gray-900">Confirm Password</label>
                <div className="mt-2">
                  <input value={password2} onChange={(e) => setPassword2(e.target.value)} id="password2" name="password2" type="password" required className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-400 sm:text-sm sm:leading-6" />
                </div>
              </div>
              <div>
                <button disabled={!(password && password2)} onClick={onSubmit} type="submit" className={`flex w-full justify-center rounded-md bg-blue-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:opacity-80 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-[#FF3F44] ${!(password && password2) && 'bg-gray-400 hover:bg-gray-400'}`}>Reset Password</button>
              </div>
              {error && (
                <div className='w-full flex flex-col items-center'>
                  <p className='text-red-500 text-sm'>{error}</p>
                </div>
              )}
            </form>
          </div>
        </div>
      </div>
    </div>
  )

}

export default ResetPasswordPage