import { useAuthentication } from "../../contexts/authentication"
import { useParams } from "react-router-dom";
import { useQuery } from "@apollo/client";
import { GET_SUBCATEGORY_QUERY } from './queries'
import { Link } from "react-router-dom";
import Markdown from 'react-markdown'
import MainLayout from "../../components/main-layout";

const SubcategoryPage = () => {
  const { logUserOut, currentUser } = useAuthentication()
  const params = useParams();

  const { loading, error, data } = useQuery(GET_SUBCATEGORY_QUERY, {
    variables: {
      category: params.category,
      subcategory: params.subcategory
    }
  })

  if (error) { 
    console.log('error', error)
    return <p>Error: {error}</p>
  }
  if (!data) { return null; }

  return (
    <MainLayout>
    <div>
      {/* <p>Hi {currentUser?.fullName}</p> */}
      <div className='p-8'>
        <p>
          <Link className='text-sm text-[#FF3F44] font-medium' to={`/course/${data.subcategory.category.slug}`}>{data.subcategory.category.title}</Link>
        </p>
        <h1 className='text-2xl font-bold mb-8'>{data.subcategory.title}</h1>

        <ul>
          {data.subcategory.articles.map(article => (
            <li>
              <Link to={`/course/${data.subcategory.category.slug}/${data.subcategory.slug}/${article.slug}`}>
                <Markdown components={{
                  p(props) {
                    const { node, ...rest } = props
                    return <span className='text-[#FF3F44]' {...rest} />
                  },
                  em(props) {
                    const { node, ...rest } = props
                    return <i className='font-italic' {...rest} />
                  }
                }}>
                  {article.title}
                </Markdown>
                {article.isCompleted && (
                  <svg className='h-4 inline text-green-600 ml-1 mb-1' fill="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" aria-hidden="true">
                    <path clipRule="evenodd" fillRule="evenodd" d="M2.25 12c0-5.385 4.365-9.75 9.75-9.75s9.75 4.365 9.75 9.75-4.365 9.75-9.75 9.75S2.25 17.385 2.25 12zm13.36-1.814a.75.75 0 10-1.22-.872l-3.236 4.53L9.53 12.22a.75.75 0 00-1.06 1.06l2.25 2.25a.75.75 0 001.14-.094l3.75-5.25z"></path>
                  </svg>
                )}
              </Link>
            </li>
          ))}
            <li><Link className='text-[#FF3F44] font-semibold' to={`/course/${data.subcategory.category.slug}/${data.subcategory.slug}/quiz`}>Quiz</Link></li>
        </ul>
      </div>
    </div>
    </MainLayout>
  )
}

export default SubcategoryPage