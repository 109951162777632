import { useState } from "react"
import { GET_TEST_QUERY, GET_CURRENT_TEST_QUESTION_QUERY } from "./queries"
import { useQuery, useMutation } from "@apollo/client"
import { minutesSeconds } from '../../../utils/time'
import StatsTab from './stats-tab'
import AnswersTab from './answers-tab'
import StudyguideTab from './studyguide-tab'


const TestCompleted = ({ testId }) => {

  const [currentTab, setCurrentTab] = useState('stats')

  const { loading, error, data, refetch } = useQuery(GET_TEST_QUERY, {
    variables: {
      testId: testId
    }
  })

  console.log('test completed', data)

  if (!data) { return null }

  // https://flowbite.com/docs/components/progress/


  console.log('completedTestStats', data.test.completedTestCategoryStats)
  console.log('scorePercent', data.test.scorePercent)

  return (
    <div>
      <h1 className='text-xl font-bold mb-16'>Test Completed</h1>
      <p><span className='font-semibold'>Time to Complete:</span> {minutesSeconds(data.test.finishedDuration)} / {minutesSeconds(data.test.duration * 60)}</p>
      {/* <p><span className='font-semibold'>Correct Answers:</span> </p> */}
      <div className='w-96 flex flex-row items-center mb-4 mt-4'>
        <div className="w-full h-6 bg-gray-200 rounded-full dark:bg-gray-700">
          <div className="h-6 bg-blue-600 rounded-full dark:bg-blue-500 text-sm text-white text-center pt-1 leading-none" style={{ width: `${data.test.scorePercent}%` }}>{data.test.scorePercent}%</div>
        </div>
        <p className='text-md font-semibold w-24 ml-2'>{data.test.numberCorrectAnswers} / {data.test.numberQuestions}</p>
      </div>
      <br />
      <div className="sm:block">
        <div className="border-b border-slate-200">
          <nav className="-mb-px flex space-x-8" aria-label="Tabs">
            {/* <!-- Current: "border-indigo-500 text-indigo-600", Default: "border-transparent text-slate-500 hover:border-slate-300 hover:text-slate-700" --> */}
            <a onClick={() => setCurrentTab('stats')} className={`${currentTab == 'stats' ? 'border-indigo-500 text-indigo-600' : 'border-transparent text-slate-500 hover:border-slate-300 hover:text-slate-700'} whitespace-nowrap border-b-2 py-4 px-1 text-sm font-medium cursor-pointer`}>Results</a>
            <a onClick={() => setCurrentTab('answers')} className={`${currentTab == 'answers' ? 'border-indigo-500 text-indigo-600' : 'border-transparent text-slate-500 hover:border-slate-300 hover:text-slate-700'} whitespace-nowrap border-b-2 py-4 px-1 text-sm font-medium cursor-pointer`}>Answers</a>
            <a onClick={() => setCurrentTab('studyguide')} className={`${currentTab == 'studyguide' ? 'border-indigo-500 text-indigo-600' : 'border-transparent text-slate-500 hover:border-slate-300 hover:text-slate-700'} whitespace-nowrap border-b-2 py-4 px-1 text-sm font-medium cursor-pointer`}>Studyguide</a>
          </nav>
        </div>
      </div>
      {currentTab == 'stats' && <StatsTab test={data.test} />}
      {currentTab == 'answers' && <AnswersTab test={data.test} />}
      {currentTab == 'studyguide' && <StudyguideTab test={data.test} />}
    </div>
  )
}

export default TestCompleted