import { gql } from "@apollo/client"

export const RESET_PASSWORD_MUTATION = gql`
  mutation ResetPassword(
    $password: String!
    $password2: String!
    $token: String!
  ) {
    resetPassword(
      password: $password
      password2: $password2
      token: $token
    ) {
      id
      authToken
    }
  }
`
