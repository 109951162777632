import { gql } from "@apollo/client"

export const CREATE_NEW_TEST_MUTATION = gql`
  mutation CreateNewTest($testVersion: String!) {
    createNewTest(testVersion: $testVersion) {
      test {
        id
        startedAt
        completedAt
      }
    }
  }
`
