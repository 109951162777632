
export const LARGE_ANIMAL_DRUGS = [
  {
    "name": "Amoxicillin",
    "class": "Antibiotic (Penicillin)",
    "side_effects": "Potential for gastrointestinal upset, rare instances of allergic reactions"
  },
  {
    "name": "Amikacin",
    "class": "Antibiotic (Aminoglycoside)",
    "side_effects": "Potential for nephrotoxicity, ototoxicity"
  },
  {
    "name": "Aspirin",
    "class": "Nonsteroidal Anti-Inflammatory Drug (NSAID)",
    "side_effects": "Potential for gastrointestinal ulceration, renal toxicity"
  },
  {
    "name": "Atropine",
    "class": "Antimuscarinic Agent",
    "side_effects": "Potential for tachycardia, dry mouth"
  },
  {
    "name": "Acepromazine",
    "class": "Phenothiazine Tranquilizer",
    "side_effects": "Potential for hypotension, sedation"
  },
  {
    "name": "Amitraz",
    "class": "Parasiticide (Formamidine)",
    "side_effects": "Potential for sedation, hypothermia"
  },
  {
    "name": "Anaplasmosis Vaccine",
    "class": "Vaccine",
    "side_effects": "Local swelling, potential for allergic reactions"
  },
  {
    "name": "Ascorbic Acid (Vitamin C)",
    "class": "Vitamin",
    "side_effects": "Generally well-tolerated, rare instances of gastrointestinal upset"
  },
  {
    "name": "Atipamezole",
    "class": "Alpha-2 Adrenergic Receptor Antagonist",
    "side_effects": "Potential for vomiting, transient hypertension"
  },
  {
    "name": "Autogenous Vaccines",
    "class": "Vaccine",
    "side_effects": "Local swelling, potential for allergic reactions"
  },
  {
    "name": "Avermectins (e.g., Ivermectin)",
    "class": "Antiparasitic (Macrocyclic Lactone)",
    "side_effects": "Potential for neurotoxicity in certain breeds, especially collies"
  },
  {
    "name": "Ammonium Chloride",
    "class": "Urinary Acidifier",
    "side_effects": "Potential for metabolic acidosis, gastrointestinal upset"
  },
  {
    "name": "Aspartic Acid",
    "class": "Amino Acid (Nutritional Supplement)",
    "side_effects": "Generally well-tolerated, rare instances of gastrointestinal upset"
  },
  {
    "name": "Artemisinins (e.g., Artemether)",
    "class": "Antiparasitic (Endoperoxide)",
    "side_effects": "Potential for gastrointestinal upset, neurotoxicity"
  },
  {
    "name": "Anabolic Steroids (e.g., Boldenone)",
    "class": "Steroid Hormone",
    "side_effects": "Potential for hormonal imbalances, masculinization in females"
  },
  {
    "name": "Alpha-Galactosidase",
    "class": "Enzyme (Digestive Aid)",
    "side_effects": "Generally well-tolerated, rare instances of gastrointestinal upset"
  },
  {
    "name": "Albendazole",
    "class": "Antiparasitic (Benzimidazole)",
    "side_effects": "Potential for gastrointestinal upset, rare instances of bone marrow suppression"
  },
  {
    "name": "Alprazolam",
    "class": "Benzodiazepine (Anxiolytic)",
    "side_effects": "Potential for sedation, ataxia"
  },
  {
    "name": "Artificial Insemination (AI) Protocols",
    "class": "Reproductive Protocol",
    "side_effects": "Variable, depending on specific drugs used"
  },
  {
    "name": "Aluminum Hydroxide",
    "class": "Antacid (Gastric Protectant)",
    "side_effects": "Generally well-tolerated, rare instances of constipation"
  },
  {
    "name": "Ampicillin",
    "class": "Antibiotic (Penicillin)",
    "side_effects": "Potential for gastrointestinal upset, rare instances of allergic reactions"
  },
  {
    "name": "Apramycin",
    "class": "Antibiotic (Aminoglycoside)",
    "side_effects": "Potential for nephrotoxicity, ototoxicity"
  },
  {
    "name": "Alfaxalone",
    "class": "Injectable Anesthetic (Neurosteroid)",
    "side_effects": "Potential for respiratory depression, dose-dependent effects"
  },
  {
    "name": "Ammonium Molybdate",
    "class": "Trace Element (Nutritional Supplement)",
    "side_effects": "Generally well-tolerated, rare instances of gastrointestinal upset"
  },
  {
    "name": "Anagrelide",
    "class": "Platelet-Reducing Agent (Thrombopoietin Inhibitor)",
    "side_effects": "Potential for gastrointestinal upset, cardiovascular effects"
  },
  {
    "name": "Banamine (Flunixin Meglumine)",
    "class": "Nonsteroidal Anti-Inflammatory Drug (NSAID)",
    "side_effects": "Potential for gastrointestinal ulceration, renal toxicity"
  },
  {
    "name": "Baytril (Enrofloxacin)",
    "class": "Fluoroquinolone Antibiotic",
    "side_effects": "Potential for gastrointestinal upset, rare instances of musculoskeletal effects"
  },
  {
    "name": "Bovine Viral Diarrhea (BVD) Vaccine",
    "class": "Vaccine",
    "side_effects": "Local swelling, potential for allergic reactions"
  },
  {
    "name": "Butorphanol",
    "class": "Opioid Analgesic (Agonist-Antagonist)",
    "side_effects": "Potential for sedation, respiratory depression"
  },
  {
    "name": "Bovine Respiratory Syncytial Virus (BRSV) Vaccine",
    "class": "Vaccine",
    "side_effects": "Local swelling, potential for allergic reactions"
  },
  {
    "name": "Bismuth Subsalicylate",
    "class": "Antidiarrheal Agent",
    "side_effects": "Generally well-tolerated, potential for constipation"
  },
  {
    "name": "Benzathine Penicillin",
    "class": "Antibiotic (Penicillin)",
    "side_effects": "Potential for allergic reactions, rare instances of neurotoxicity"
  },
  {
    "name": "BloatGuard (Poloxalene)",
    "class": "Anti-Bloat Agent",
    "side_effects": "Generally well-tolerated, rare instances of gastrointestinal upset"
  },
  {
    "name": "Bacitracin",
    "class": "Antibiotic (Polypeptide)",
    "side_effects": "Potential for allergic reactions, rare instances of nephrotoxicity"
  },
  {
    "name": "Bunamidine",
    "class": "Anthelmintic (Tetrahydropyrimidine)",
    "side_effects": "Potential for gastrointestinal upset, rare instances of neurotoxicity"
  },
  {
    "name": "Bromhexine",
    "class": "Mucolytic Agent",
    "side_effects": "Potential for gastrointestinal upset, rare instances of allergic reactions"
  },
  {
    "name": "Bovine Rumen Magnet",
    "class": "Rumen Magnet",
    "side_effects": "Generally well-tolerated, potential for displacement if not placed correctly"
  },
  {
    "name": "Bovine Pasteurella Vaccine",
    "class": "Vaccine",
    "side_effects": "Local swelling, potential for allergic reactions"
  },
  {
    "name": "Bovine Blue Tongue Virus Vaccine",
    "class": "Vaccine",
    "side_effects": "Local swelling, potential for allergic reactions"
  },
  {
    "name": "Ceftiofur",
    "class": "Antibiotic (Cephalosporin)",
    "side_effects": "Potential for gastrointestinal upset, rare instances of allergic reactions"
  },
  {
    "name": "Clenbuterol",
    "class": "Beta-2 Adrenergic Agonist",
    "side_effects": "Potential for tachycardia, muscle tremors"
  },
  {
    "name": "Cyclosporine",
    "class": "Immunosuppressant (Calcineurin Inhibitor)",
    "side_effects": "Potential for gastrointestinal upset, increased risk of infections"
  },
  {
    "name": "Cephalexin",
    "class": "Antibiotic (Cephalosporin)",
    "side_effects": "Potential for gastrointestinal upset, rare instances of allergic reactions"
  },
  {
    "name": "Calcium Gluconate",
    "class": "Calcium Supplement",
    "side_effects": "Generally well-tolerated, potential for hypercalcemia with high doses"
  },
  {
    "name": "Colistin",
    "class": "Antibiotic (Polypeptide)",
    "side_effects": "Potential for nephrotoxicity, neurotoxicity"
  },
  {
    "name": "Carprofen",
    "class": "Nonsteroidal Anti-Inflammatory Drug (NSAID)",
    "side_effects": "Potential for gastrointestinal upset, renal toxicity"
  },
  {
    "name": "Ciprofloxacin",
    "class": "Fluoroquinolone Antibiotic",
    "side_effects": "Potential for gastrointestinal upset, rare instances of musculoskeletal effects"
  },
  {
    "name": "Ceftazidime",
    "class": "Antibiotic (Cephalosporin)",
    "side_effects": "Potential for gastrointestinal upset, rare instances of allergic reactions"
  },
  {
    "name": "Chloramphenicol",
    "class": "Antibiotic (Amphenicol)",
    "side_effects": "Potential for bone marrow suppression, rare instances of aplastic anemia"
  },
  {
    "name": "Corticotropin",
    "class": "Adrenocorticotropic Hormone (ACTH)",
    "side_effects": "Potential for adrenal gland suppression, increased risk of infections"
  },
  {
    "name": "Ceftizoxime",
    "class": "Antibiotic (Cephalosporin)",
    "side_effects": "Potential for gastrointestinal upset, rare instances of allergic reactions"
  },
  {
    "name": "Chorionic Gonadotropin",
    "class": "Gonadotropin",
    "side_effects": "Potential for ovarian hyperstimulation, increased risk of infections"
  },
  {
    "name": "Chlortetracycline",
    "class": "Antibiotic (Tetracycline)",
    "side_effects": "Potential for gastrointestinal upset, photosensitivity"
  },
  {
    "name": "Ceftiofur Sodium",
    "class": "Antibiotic (Cephalosporin)",
    "side_effects": "Potential for gastrointestinal upset, rare instances of allergic reactions"
  },
  {
    "name": "Cyanocobalamin",
    "class": "Vitamin B12 Supplement",
    "side_effects": "Generally well-tolerated, rare instances of hypersensitivity reactions"
  },
  {
    "name": "Cimetidine",
    "class": "Histamine H2 Receptor Antagonist",
    "side_effects": "Potential for gastrointestinal upset, rare instances of CNS effects"
  },
  {
    "name": "Copper Sulfate",
    "class": "Trace Element Supplement",
    "side_effects": "Potential for copper toxicity at high doses"
  },
  {
    "name": "Cefquinome",
    "class": "Antibiotic (Cephalosporin)",
    "side_effects": "Potential for gastrointestinal upset, rare instances of allergic reactions"
  },
  {
    "name": "Cyromazine",
    "class": "Insect Growth Regulator",
    "side_effects": "Generally well-tolerated, potential for respiratory irritation"
  },
  {
    "name": "Dexamethasone",
    "class": "Glucocorticoid",
    "side_effects": "Potential for immunosuppression, increased risk of infections, altered glucose metabolism"
  },
  {
    "name": "Dinoprost Tromethamine",
    "class": "Prostaglandin F2α Analog",
    "side_effects": "Uterine contractions, potential for respiratory distress, transient hyperthermia"
  },
  {
    "name": "Doxycline",
    "class": "Antibiotic (Tetracycline)",
    "side_effects": "Potential for gastrointestinal upset, photosensitivity"
  },
  {
    "name": "Detomidine",
    "class": "Alpha-2 Adrenergic Agonist",
    "side_effects": "Potential for sedation, bradycardia, respiratory depression"
  },
  {
    "name": "Dipyrone",
    "class": "Nonsteroidal Anti-Inflammatory Drug (NSAID)",
    "side_effects": "Potential for gastrointestinal upset, rare instances of bone marrow suppression"
  },
  {
    "name": "Deltamethrin",
    "class": "Pyrethroid Insecticide",
    "side_effects": "Potential for skin irritation, neurotoxicity"
  },
  {
    "name": "Doxycycline",
    "class": "Antibiotic (Tetracycline)",
    "side_effects": "Potential for gastrointestinal upset, photosensitivity"
  },
  {
    "name": "Diatrizoate Meglumine",
    "class": "Radiographic Contrast Agent",
    "side_effects": "Potential for allergic reactions, renal toxicity"
  },
  {
    "name": "Dichlorvos",
    "class": "Organophosphate Insecticide",
    "side_effects": "Potential for neurotoxicity, respiratory distress"
  },
  {
    "name": "Diminazene Aceturate",
    "class": "Antiprotozoal Agent (Trypanocidal)",
    "side_effects": "Potential for gastrointestinal upset, rare instances of renal toxicity"
  },
  {
    "name": "Deslorelin",
    "class": "Gonadotropin-Releasing Hormone (GnRH) Analog",
    "side_effects": "Potential for reproductive suppression, transient hyperthermia"
  },
  {
    "name": "Doramectin",
    "class": "Macrocyclic Lactone Anthelmintic",
    "side_effects": "Potential for gastrointestinal upset, neurotoxicity in high doses"
  },
  {
    "name": "Danofloxacin",
    "class": "Fluoroquinolone Antibiotic",
    "side_effects": "Potential for gastrointestinal upset, rare instances of musculoskeletal effects"
  },
  {
    "name": "Dinotefuran",
    "class": "Neonicotinoid Insecticide",
    "side_effects": "Generally well-tolerated, potential for neurotoxicity"
  },
  {
    "name": "Dimetridazole",
    "class": "Antiprotozoal Agent (Trichomonacidal)",
    "side_effects": "Potential for gastrointestinal upset, rare instances of neurotoxicity"
  },
  {
    "name": "Diatomaceous Earth",
    "class": "Insecticide",
    "side_effects": "Generally well-tolerated, potential for respiratory irritation"
  },
  {
    "name": "Dexamethasone Sodium Phosphate",
    "class": "Glucocorticoid",
    "side_effects": "Potential for immunosuppression, increased risk of infections, altered glucose metabolism"
  },
  {
    "name": "Diazepam",
    "class": "Benzodiazepine",
    "side_effects": "Potential for sedation, ataxia, respiratory depression"
  },
  {
    "name": "Dyclonine Hydrochloride",
    "class": "Local Anesthetic",
    "side_effects": "Generally well-tolerated, potential for local irritation"
  },
  {
    "name": "Difloxacin",
    "class": "Fluoroquinolone Antibiotic",
    "side_effects": "Potential for gastrointestinal upset, rare instances of musculoskeletal effects"
  },
  {
    "name": "Enrofloxacin",
    "class": "Fluoroquinolone Antibiotic",
    "side_effects": "Potential for gastrointestinal upset, rare instances of musculoskeletal effects"
  },
  {
    "name": "Estradiol",
    "class": "Estrogen",
    "side_effects": "Potential for reproductive changes, behavioral alterations"
  },
  {
    "name": "Ephedrine",
    "class": "Alpha- and Beta-Adrenergic Agonist",
    "side_effects": "Potential for increased heart rate, hypertension"
  },
  {
    "name": "Eprinomectin",
    "class": "Macrocyclic Lactone Anthelmintic",
    "side_effects": "Potential for gastrointestinal upset, neurotoxicity in high doses"
  },
  {
    "name": "Estrus Synchronization Agents",
    "class": "Hormones",
    "side_effects": "Variable, depending on the specific agent; potential for reproductive changes"
  },
  {
    "name": "Epinephrine",
    "class": "Alpha- and Beta-Adrenergic Agonist",
    "side_effects": "Potential for increased heart rate, hypertension"
  },
  {
    "name": "Estradiol Benzoate",
    "class": "Estrogen",
    "side_effects": "Potential for reproductive changes, behavioral alterations"
  },
  {
    "name": "Ergotamine",
    "class": "Ergot Alkaloid",
    "side_effects": "Potential for vasoconstriction, respiratory distress"
  },
  {
    "name": "Erythromycin",
    "class": "Antibiotic (Macrolide)",
    "side_effects": "Potential for gastrointestinal upset, rare instances of allergic reactions"
  },
  {
    "name": "Ergonovine",
    "class": "Ergot Alkaloid",
    "side_effects": "Potential for vasoconstriction, respiratory distress"
  },
  {
    "name": "Epinephrine Hydrochloride",
    "class": "Alpha- and Beta-Adrenergic Agonist",
    "side_effects": "Potential for increased heart rate, hypertension"
  },
  {
    "name": "Estradiol Cypionate",
    "class": "Estrogen",
    "side_effects": "Potential for reproductive changes, behavioral alterations"
  },
  {
    "name": "Ethylenediamine Dihydroiodide",
    "class": "Iodine Supplement",
    "side_effects": "Generally well-tolerated, potential for iodine toxicity at high doses"
  },
  {
    "name": "Estradiol Valerate",
    "class": "Estrogen",
    "side_effects": "Potential for reproductive changes, behavioral alterations"
  },
  {
    "name": "Estrone",
    "class": "Estrogen",
    "side_effects": "Potential for reproductive changes, behavioral alterations"
  },
  {
    "name": "Enilconazole",
    "class": "Antifungal Agent (Imidazole)",
    "side_effects": "Potential for local irritation, rare instances of allergic reactions"
  },
  {
    "name": "Esomeprazole",
    "class": "Proton Pump Inhibitor",
    "side_effects": "Potential for gastrointestinal upset, rare instances of liver dysfunction"
  },
  {
    "name": "Ethopabate",
    "class": "Coccidiostat",
    "side_effects": "Generally well-tolerated, potential for gastrointestinal upset"
  },
  {
    "name": "Ethyl Alcohol (Ethanol)",
    "class": "Antiseptic, Anesthetic",
    "side_effects": "Potential for local irritation, systemic effects with excessive use"
  },
  {
    "name": "Ethylenediaminetetraacetic Acid (EDTA)",
    "class": "Chelating Agent",
    "side_effects": "Generally well-tolerated, potential for hypocalcemia with high doses"
  },
  {
    "name": "Flunixin Meglumine",
    "class": "Nonsteroidal Anti-Inflammatory Drug (NSAID)",
    "side_effects": "Potential for gastrointestinal upset, renal toxicity, and ulceration"
  },
  {
    "name": "Florfenicol",
    "class": "Antibiotic (Phenicol)",
    "side_effects": "Potential for gastrointestinal upset, rare instances of bone marrow suppression"
  },
  {
    "name": "Furosemide",
    "class": "Loop Diuretic",
    "side_effects": "Potential for electrolyte imbalances, dehydration"
  },
  {
    "name": "Florbetaben F 18",
    "class": "Radiopharmaceutical Agent",
    "side_effects": "Generally well-tolerated, potential for allergic reactions"
  },
  {
    "name": "Famotidine",
    "class": "Histamine H2 Receptor Antagonist",
    "side_effects": "Potential for gastrointestinal upset, rare instances of liver dysfunction"
  },
  {
    "name": "Fenthion",
    "class": "Organophosphate Insecticide",
    "side_effects": "Potential for neurotoxicity, respiratory distress"
  },
  {
    "name": "Fluphenazine",
    "class": "Phenothiazine Tranquilizer",
    "side_effects": "Potential for sedation, hypotension, and extrapyramidal effects"
  },
  {
    "name": "Follicle-Stimulating Hormone (FSH)",
    "class": "Hormone",
    "side_effects": "Variable, depending on the purpose of use; potential for reproductive changes"
  },
  {
    "name": "Florfenicol/Flunixin Meglumine",
    "class": "Antibiotic/NSAID Combination",
    "side_effects": "Combined effects of both components"
  },
  {
    "name": "Firocoxib",
    "class": "Nonsteroidal Anti-Inflammatory Drug (NSAID)",
    "side_effects": "Potential for gastrointestinal upset, renal toxicity, and ulceration"
  },
  {
    "name": "Fenbendazole",
    "class": "Benzimidazole Anthelmintic",
    "side_effects": "Potential for gastrointestinal upset, rare instances of bone marrow suppression"
  },
  {
    "name": "Fluazuron",
    "class": "Chitin Synthesis Inhibitor (Insect Growth Regulator)",
    "side_effects": "Generally well-tolerated, potential for skin irritation"
  },
  {
    "name": "Fentanyl",
    "class": "Opioid Analgesic",
    "side_effects": "Potential for respiratory depression, sedation"
  },
  {
    "name": "Fluralaner",
    "class": "Isoxazoline Insecticide",
    "side_effects": "Potential for gastrointestinal upset, neurological signs"
  },
  {
    "name": "Fenpropathrin",
    "class": "Pyrethroid Insecticide",
    "side_effects": "Potential for skin irritation, neurotoxicity"
  },
  {
    "name": "Fluorouracil (5-FU)",
    "class": "Antimetabolite Chemotherapy Agent",
    "side_effects": "Potential for bone marrow suppression, gastrointestinal upset"
  },
  {
    "name": "Fluoxetine",
    "class": "Selective Serotonin Reuptake Inhibitor (SSRI)",
    "side_effects": "Potential for gastrointestinal upset, behavioral changes"
  },
  {
    "name": "Famciclovir",
    "class": "Antiviral Agent",
    "side_effects": "Potential for gastrointestinal upset, rare instances of bone marrow suppression"
  },
  {
    "name": "Gentamicin",
    "class": "Aminoglycoside Antibiotic",
    "side_effects": "Potential for nephrotoxicity, ototoxicity, and allergic reactions"
  },
  {
    "name": "Guaifenesin",
    "class": "Skeletal Muscle Relaxant",
    "side_effects": "Generally well-tolerated, potential for mild sedation"
  },
  {
    "name": "Glucagon",
    "class": "Pancreatic Hormone",
    "side_effects": "Potential for gastrointestinal upset, hypotension, and hyperglycemia"
  },
  {
    "name": "Glycerol",
    "class": "Osmotic Diuretic",
    "side_effects": "Potential for gastrointestinal upset, dehydration"
  },
  {
    "name": "Gonadotropin-Releasing Hormone (GnRH)",
    "class": "Hormone",
    "side_effects": "Variable, depending on the purpose of use; potential for reproductive changes"
  },
  {
    "name": "Glycopyrrolate",
    "class": "Antimuscarinic Agent",
    "side_effects": "Potential for tachycardia, dry mouth, and decreased gastrointestinal motility"
  },
  {
    "name": "Griseofulvin",
    "class": "Antifungal Agent",
    "side_effects": "Potential for gastrointestinal upset, hepatotoxicity, and skin reactions"
  },
  {
    "name": "Gonadorelin",
    "class": "Hormone",
    "side_effects": "Variable, depending on the purpose of use; potential for reproductive changes"
  },
  {
    "name": "Glucosamine",
    "class": "Nutraceutical",
    "side_effects": "Generally well-tolerated, potential for mild gastrointestinal upset"
  },
  {
    "name": "Gonadotropins (e.g., PMSG)",
    "class": "Hormones",
    "side_effects": "Variable, depending on the purpose of use; potential for reproductive changes"
  },
  {
    "name": "Gentamicin/Betamethasone",
    "class": "Antibiotic/Corticosteroid Combination",
    "side_effects": "Combined effects of both components"
  },
  {
    "name": "Glycopyrrolate/Atropine",
    "class": "Antimuscarinic Combination",
    "side_effects": "Combined effects of both components"
  },
  {
    "name": "Glycogen",
    "class": "Carbohydrate",
    "side_effects": "Generally well-tolerated, essential for energy storage"
  },
  {
    "name": "Gossypol",
    "class": "Antispermatogenic Agent",
    "side_effects": "Potential for reproductive changes, including temporary infertility"
  },
  {
    "name": "Gamma Oryzanol",
    "class": "Nutraceutical",
    "side_effects": "Generally well-tolerated, potential for mild gastrointestinal upset"
  },
  {
    "name": "Glycine",
    "class": "Amino Acid",
    "side_effects": "Generally well-tolerated, potential for electrolyte imbalances in high doses"
  },
  {
    "name": "Granulocyte-Colony Stimulating Factor (G-CSF)",
    "class": "Cytokine",
    "side_effects": "Variable, depending on the purpose of use; potential for bone pain, flu-like symptoms"
  },
  {
    "name": "Gonadotropin-Releasing Hormone (GnRH) Agonists (e.g., Leuprolide)",
    "class": "Hormone",
    "side_effects": "Variable, depending on the purpose of use; potential for reproductive changes"
  },
  {
    "name": "Glycogenolytic Agents (e.g., Glucagon)",
    "class": "Pancreatic Hormone",
    "side_effects": "Potential for gastrointestinal upset, hypotension, and hyperglycemia"
  },
  {
    "name": "Guaifenesin/Ketamine",
    "class": "Skeletal Muscle Relaxant/General Anesthetic Combination",
    "side_effects": "Combined effects of both components"
  },
  {
    "name": "Heparin",
    "class": "Anticoagulant",
    "side_effects": "Potential for bleeding, thrombocytopenia"
  },
  {
    "name": "Hydrocortisone",
    "class": "Corticosteroid",
    "side_effects": "Potential for immunosuppression, gastrointestinal upset, and adrenal suppression"
  },
  {
    "name": "Hydroxyzine",
    "class": "Antihistamine",
    "side_effects": "Potential for sedation, gastrointestinal upset"
  },
  {
    "name": "Hyaluronic Acid",
    "class": "Joint Supplement",
    "side_effects": "Generally well-tolerated, potential for local reactions at the injection site"
  },
  {
    "name": "Halothane",
    "class": "Inhalation Anesthetic",
    "side_effects": "Potential for respiratory depression, cardiovascular depression"
  },
  {
    "name": "Hormones (e.g., HCG)",
    "class": "Hormone",
    "side_effects": "Variable, depending on the purpose of use; potential for reproductive changes"
  },
  {
    "name": "Hydrocortisone/Sulfacetamide Sodium",
    "class": "Corticosteroid/Antibiotic Combination",
    "side_effects": "Combined effects of both components"
  },
  {
    "name": "Hydromorphone",
    "class": "Opioid Analgesic",
    "side_effects": "Potential for respiratory depression, sedation, and constipation"
  },
  {
    "name": "Hyaluronidase",
    "class": "Enzyme",
    "side_effects": "Potential for allergic reactions, local reactions at the injection site"
  },
  {
    "name": "Homatropine",
    "class": "Antimuscarinic Agent",
    "side_effects": "Potential for tachycardia, dry mouth, and decreased gastrointestinal motility"
  },
  {
    "name": "Hyaluronic Acid/Polyvinylpyrrolidone",
    "class": "Joint Supplement",
    "side_effects": "Combined effects of both components"
  },
  {
    "name": "Hawthorn",
    "class": "Herbal Supplement",
    "side_effects": "Generally well-tolerated, potential for gastrointestinal upset"
  },
  {
    "name": "Hydroxyzine/Prednisolone",
    "class": "Antihistamine/Corticosteroid Combination",
    "side_effects": "Combined effects of both components"
  },
  {
    "name": "Heparin/Lidocaine",
    "class": "Anticoagulant/Local Anesthetic Combination",
    "side_effects": "Combined effects of both components"
  },
  {
    "name": "Hydrocodone",
    "class": "Opioid Analgesic",
    "side_effects": "Potential for respiratory depression, sedation, and constipation"
  },
  {
    "name": "Hemostatic Agents (e.g., Hemocoagulase)",
    "class": "Hemostatic Agent",
    "side_effects": "Potential for allergic reactions, local reactions at the injection site"
  },
  {
    "name": "Histidine",
    "class": "Amino Acid",
    "side_effects": "Generally well-tolerated, essential for protein synthesis"
  },
  {
    "name": "Hydromorphone/Bupivacaine",
    "class": "Opioid Analgesic/Local Anesthetic Combination",
    "side_effects": "Combined effects of both components"
  },
  {
    "name": "Hypertonic Saline",
    "class": "Osmotic Agent",
    "side_effects": "Potential for electrolyte imbalances, dehydration"
  },
  {
    "name": "Hydrocortisone/Chlorhexidine",
    "class": "Corticosteroid/Antiseptic Combination",
    "side_effects": "Combined effects of both components"
  },
  {
    "name": "Ibuprofen",
    "class": "Nonsteroidal Anti-Inflammatory Drug (NSAID)",
    "side_effects": "Potential for gastrointestinal ulceration, renal toxicity, and decreased platelet aggregation"
  },
  {
    "name": "Imidocarb",
    "class": "Antiprotozoal Agent",
    "side_effects": "Potential for injection site reactions, gastrointestinal upset, and transient anemia"
  },
  {
    "name": "Ivermectin",
    "class": "Antiparasitic Agent",
    "side_effects": "Potential for neurotoxicity in certain breeds, especially Collies and related breeds"
  },
  {
    "name": "Isoflurane",
    "class": "Inhalation Anesthetic",
    "side_effects": "Potential for respiratory depression, cardiovascular depression"
  },
  {
    "name": "Insulin",
    "class": "Hormone",
    "side_effects": "Potential for hypoglycemia, insulin resistance, and allergic reactions"
  },
  {
    "name": "Isoprenaline",
    "class": "Beta-Adrenergic Agonist",
    "side_effects": "Potential for tachycardia, arrhythmias, and hyperglycemia"
  },
  {
    "name": "Intravenous Immunoglobulin (IVIG)",
    "class": "Immunomodulator",
    "side_effects": "Potential for hypersensitivity reactions, renal dysfunction, and thrombotic events"
  },
  {
    "name": "Ibuprofen/Prednisolone",
    "class": "NSAID/Corticosteroid Combination",
    "side_effects": "Combined effects of both components"
  },
  {
    "name": "Imidocarb Dipropionate",
    "class": "Antiprotozoal Agent",
    "side_effects": "Potential for injection site reactions, gastrointestinal upset, and transient anemia"
  },
  {
    "name": "Iodoform",
    "class": "Antiseptic",
    "side_effects": "Potential for local irritation, allergic reactions, and systemic toxicity"
  },
  {
    "name": "Isoflupredone Acetate",
    "class": "Corticosteroid",
    "side_effects": "Potential for immunosuppression, gastrointestinal upset, and adrenal suppression"
  },
  {
    "name": "Interferon Alfa",
    "class": "Immunomodulator",
    "side_effects": "Potential for flu-like symptoms, myelosuppression, and hepatotoxicity"
  },
  {
    "name": "Ivermectin/Praziquantel",
    "class": "Antiparasitic Agent",
    "side_effects": "Combined effects of both components"
  },
  {
    "name": "Iodine Compounds",
    "class": "Antiseptic",
    "side_effects": "Potential for local irritation, allergic reactions, and systemic toxicity"
  },
  {
    "name": "Immunosuppressive Agents",
    "class": "Immunosuppressant",
    "side_effects": "Variable, depending on the specific agent; potential for increased susceptibility to infections, renal dysfunction, and gastrointestinal upset"
  },
  {
    "name": "Juglans Nigra (Black Walnut)",
    "class": "Herbal Supplement",
    "side_effects": "Potential for laminitis in horses due to exposure to shavings or hulls."
  },
  {
    "name": "J-X Injection (Butafosfan and Cyanocobalamin)",
    "class": "Nutritional Supplement",
    "side_effects": "Generally well-tolerated, may cause transient discomfort at the injection site."
  },
  {
    "name": "Joint Supplements",
    "class": "Nutritional Supplement",
    "side_effects": "Generally well-tolerated, potential for gastrointestinal upset."
  },
  {
    "name": "J-Tect (Tulathromycin)",
    "class": "Antibiotic (Macrolide)",
    "side_effects": "Potential for injection site reactions, gastrointestinal upset."
  },
  {
    "name": "Jurocyl (Oxytetracycline)",
    "class": "Antibiotic (Tetracycline)",
    "side_effects": "Potential for gastrointestinal upset, photosensitivity."
  },
  {
    "name": "JointTact (Hyaluronic Acid)",
    "class": "Joint Supplement",
    "side_effects": "Generally well-tolerated, potential for local reactions at the injection site."
  },
  {
    "name": "Ketoprofen",
    "class": "Non-Steroidal Anti-Inflammatory Drug (NSAID)",
    "side_effects": "Gastrointestinal upset, renal toxicity, potential for ulcers."
  },
  {
    "name": "Ketamine",
    "class": "General Anesthetic (Dissociative)",
    "side_effects": "Hallucinations, increased heart rate, respiratory depression."
  },
  {
    "name": "Ketofen (Ketoprofen)",
    "class": "Non-Steroidal Anti-Inflammatory Drug (NSAID)",
    "side_effects": "Gastrointestinal upset, renal toxicity, potential for ulcers."
  },
  {
    "name": "Ketolac (Ketoprofen)",
    "class": "Non-Steroidal Anti-Inflammatory Drug (NSAID)",
    "side_effects": "Gastrointestinal upset, renal toxicity, potential for ulcers."
  },
  {
    "name": "Kexxtone (Monensin)",
    "class": "Ionophore Antibiotic",
    "side_effects": "Potential for ionophore toxicity, cardiac issues, respiratory distress."
  },
  {
    "name": "Kopertox (Copper Naphthenate)",
    "class": "Topical Antiseptic",
    "side_effects": "Potential for local irritation, use caution on sensitive skin."
  },
  {
    "name": "Kaolin-Pectin",
    "class": "Gastrointestinal Protectant",
    "side_effects": "Generally well-tolerated, potential for constipation."
  },
  {
    "name": "Ketamine HCl",
    "class": "General Anesthetic (Dissociative)",
    "side_effects": "Hallucinations, increased heart rate, respiratory depression."
  },
  {
    "name": "Kaolin",
    "class": "Gastrointestinal Protectant",
    "side_effects": "Generally well-tolerated, potential for constipation."
  },
  {
    "name": "Lidocaine",
    "class": "Local Anesthetic",
    "side_effects": "Localized reactions, systemic toxicity (rare), central nervous system effects."
  },
  {
    "name": "Lincocin (Lincomycin)",
    "class": "Antibiotic (Lincosamide)",
    "side_effects": "Gastrointestinal upset, allergic reactions, potential for antibiotic-associated diarrhea."
  },
  {
    "name": "Lactated Ringer's Injection",
    "class": "Crystalloid Fluid",
    "side_effects": "Generally well-tolerated, potential for fluid overload in excessive amounts."
  },
  {
    "name": "Lutalyse (Dinoprost Tromethamine)",
    "class": "Prostaglandin F2α Analog",
    "side_effects": "Uterine contractions, potential for abortion, increased respiratory rate."
  },
  {
    "name": "Lincospectin (Lincomycin/Spectinomycin)",
    "class": "Antibiotic (Lincosamide/Aminoglycoside)",
    "side_effects": "Gastrointestinal upset, allergic reactions, potential for antibiotic-associated diarrhea."
  },
  {
    "name": "Lidocaine Hydrochloride",
    "class": "Local Anesthetic",
    "side_effects": "Localized reactions, systemic toxicity (rare), central nervous system effects."
  },
  {
    "name": "Lincomix (Lincomycin)",
    "class": "Antibiotic (Lincosamide)",
    "side_effects": "Gastrointestinal upset, allergic reactions, potential for antibiotic-associated diarrhea."
  },
  {
    "name": "Liquamycin (Oxytetracycline)",
    "class": "Antibiotic (Tetracycline)",
    "side_effects": "Gastrointestinal upset, potential for photosensitivity, tooth discoloration."
  },
  {
    "name": "Lipotropic 1000",
    "class": "Nutritional Supplement (Vitamins and Amino Acids)",
    "side_effects": "Generally well-tolerated when used as a supplement."
  },
  {
    "name": "Lixotinic (Multivitamins)",
    "class": "Nutritional Supplement (Vitamins and Minerals)",
    "side_effects": "Generally well-tolerated when used as a supplement."
  },
  {
    "name": "Loperamide",
    "class": "Antidiarrheal",
    "side_effects": "Constipation, abdominal discomfort, potential for toxic effects in some species."
  },
  {
    "name": "Lubrisyn HA (Hyaluronic Acid)",
    "class": "Joint Supplement",
    "side_effects": "Generally well-tolerated, potential for localized reactions."
  },
  {
    "name": "Mannitol",
    "class": "Osmotic Diuretic",
    "side_effects": "Dehydration, electrolyte imbalance, potential for increased intracranial pressure."
  },
  {
    "name": "Masti-Clear (Oxytetracycline)",
    "class": "Antibiotic (Tetracycline)",
    "side_effects": "Gastrointestinal upset, potential for photosensitivity, tooth discoloration."
  },
  {
    "name": "Metacam (Meloxicam)",
    "class": "Non-Steroidal Anti-Inflammatory Drug (NSAID)",
    "side_effects": "Gastrointestinal ulceration, renal dysfunction, decreased platelet aggregation."
  },
  {
    "name": "Methergine (Methylergonovine)",
    "class": "Uterine Stimulant",
    "side_effects": "Increased blood pressure, uterine cramping, potential for cardiovascular effects."
  },
  {
    "name": "Metoclopramide",
    "class": "Gastrointestinal Prokinetic",
    "side_effects": "Gastrointestinal upset, restlessness, potential for neurological effects."
  },
  {
    "name": "Micotil (Tilmicosin)",
    "class": "Antibiotic (Macrolide)",
    "side_effects": "Injection site reactions, potential for cardiovascular effects."
  },
  {
    "name": "Mineral Oil",
    "class": "Laxative",
    "side_effects": "Diarrhea, interference with nutrient absorption, potential for aspiration."
  },
  {
    "name": "Moxidectin",
    "class": "Antiparasitic (Macrocyclic Lactone)",
    "side_effects": "Neurological signs, potential for toxicity in certain species."
  },
  {
    "name": "Mycostatin (Nystatin)",
    "class": "Antifungal (Polyene)",
    "side_effects": "Gastrointestinal upset, potential for allergic reactions."
  },
  {
    "name": "Mycotic II (Nystatin/Neomycin/Thiostrepton/Triamcinolone)",
    "class": "Antifungal/Antibiotic/Corticosteroid",
    "side_effects": "Gastrointestinal upset, potential for allergic reactions, corticosteroid-related effects."
  },
  {
    "name": "Mycovet (Nystatin/Neomycin/Thiostrepton/Triamcinolone)",
    "class": "Antifungal/Antibiotic/Corticosteroid",
    "side_effects": "Gastrointestinal upset, potential for allergic reactions, corticosteroid-related effects."
  },
  {
    "name": "Marbocyl (Marbofloxacin)",
    "class": "Antibiotic (Fluoroquinolone)",
    "side_effects": "Gastrointestinal upset, potential for joint cartilage damage in young animals."
  },
  {
    "name": "Multi-Min (Trace Minerals)",
    "class": "Nutritional Supplement (Trace Minerals)",
    "side_effects": "Generally well-tolerated when used as a supplement."
  },
  {
    "name": "Multimin 90 (Trace Minerals)",
    "class": "Nutritional Supplement (Trace Minerals)",
    "side_effects": "Generally well-tolerated when used as a supplement."
  },
  {
    "name": "Naxcel (Ceftiofur)",
    "class": "Antibiotic (Cephalosporin)",
    "side_effects": "Gastrointestinal upset, allergic reactions, potential for bacterial resistance."
  },
  {
    "name": "Neobiotic (Neomycin)",
    "class": "Antibiotic (Aminoglycoside)",
    "side_effects": "Nephrotoxicity, ototoxicity, potential for bacterial resistance."
  },
  {
    "name": "Neomycin",
    "class": "Antibiotic (Aminoglycoside)",
    "side_effects": "Nephrotoxicity, ototoxicity, potential for bacterial resistance."
  },
  {
    "name": "Noromycin (Oxytetracycline)",
    "class": "Antibiotic (Tetracycline)",
    "side_effects": "Gastrointestinal upset, potential for photosensitivity, tooth discoloration."
  },
  {
    "name": "Oxytetracycline",
    "class": "Antibiotic (Tetracycline)",
    "side_effects": "Gastrointestinal upset, photosensitivity, potential for microbial resistance."
  },
  {
    "name": "Oxytocin",
    "class": "Hormone (Oxytocic)",
    "side_effects": "Uterine contractions, increased heart rate, potential for uterine rupture if overdosed."
  },
  {
    "name": "Orbifloxacin",
    "class": "Antibiotic (Fluoroquinolone)",
    "side_effects": "Gastrointestinal upset, potential for joint damage in young animals."
  },
  {
    "name": "Omeprazole",
    "class": "Proton Pump Inhibitor",
    "side_effects": "Rare, but can include diarrhea, colic, or changes in liver enzymes."
  },
  {
    "name": "Osphos (Clodronate)",
    "class": "Bisphosphonate",
    "side_effects": "Generally well-tolerated, potential for gastrointestinal upset."
  },
  {
    "name": "Ondansetron",
    "class": "Antiemetic (5-HT3 receptor antagonist)",
    "side_effects": "Rare, but can include constipation or diarrhea."
  },
  {
    "name": "Oxyglobin (Hemoglobin-based oxygen carrier)",
    "class": "Blood Substitute",
    "side_effects": "Potential for hypersensitivity reactions."
  },
  {
    "name": "Oxymorphone",
    "class": "Opioid Analgesic",
    "side_effects": "Respiratory depression, sedation, potential for addiction."
  },
  {
    "name": "Oseltamivir",
    "class": "Antiviral (Neuraminidase Inhibitor)",
    "side_effects": "Generally well-tolerated, potential for gastrointestinal upset."
  },
  {
    "name": "Onsior (Robenacoxib)",
    "class": "NSAID (COX-2 Inhibitor)",
    "side_effects": "Gastrointestinal upset, renal toxicity, potential for ulcers."
  },
  {
    "name": "Penicillin G",
    "class": "Antibiotic (Beta-lactam)",
    "side_effects": "Hypersensitivity reactions, potential for microbial resistance."
  },
  {
    "name": "Procaine Penicillin",
    "class": "Antibiotic (Beta-lactam)",
    "side_effects": "Hypersensitivity reactions, potential for microbial resistance."
  },
  {
    "name": "Polymyxin B",
    "class": "Antibiotic (Polypeptide)",
    "side_effects": "Nephrotoxicity, neurotoxicity."
  },
  {
    "name": "Prednisolone",
    "class": "Corticosteroid",
    "side_effects": "Increased thirst and urination, potential for gastrointestinal upset."
  },
  {
    "name": "Phenylbutazone",
    "class": "NSAID (Non-Selective COX Inhibitor)",
    "side_effects": "Gastrointestinal upset, potential for ulcers, renal toxicity."
  },
  {
    "name": "Pyrantel Pamoate",
    "class": "Anthelmintic (Depolarizing neuromuscular blocker)",
    "side_effects": "Generally well-tolerated, potential for mild gastrointestinal upset."
  },
  {
    "name": "Prostaglandin F2alpha (PGF2α)",
    "class": "Hormone (Luteolytic agent)",
    "side_effects": "Uterine contractions, potential for transient colic."
  },
  {
    "name": "Praziquantel",
    "class": "Anthelmintic (Trematodicidal)",
    "side_effects": "Generally well-tolerated, potential for mild gastrointestinal upset."
  },
  {
    "name": "Propofol",
    "class": "Anesthetic (General)",
    "side_effects": "Respiratory depression, hypotension."
  },
  {
    "name": "Propantheline",
    "class": "Antimuscarinic",
    "side_effects": "Dry mouth, increased heart rate."
  },
  {
    "name": "Pimobendan",
    "class": "Inodilator (Phosphodiesterase III Inhibitor)",
    "side_effects": "Increased heart rate, potential for arrhythmias."
  },
  {
    "name": "Pentoxifylline",
    "class": "Hemorheologic Agent",
    "side_effects": "Gastrointestinal upset, potential for bleeding disorders."
  },
  {
    "name": "Progesterone",
    "class": "Hormone (Reproductive)",
    "side_effects": "Mammary gland development, potential for uterine infections."
  },
  {
    "name": "Poldine Methosulfate",
    "class": "Antimuscarinic",
    "side_effects": "Dry mouth, increased heart rate."
  },
  {
    "name": "Poloxalene",
    "class": "Antifoaming Agent (For bloat prevention)",
    "side_effects": "Generally well-tolerated, potential for mild gastrointestinal upset."
  },
  {
    "name": "Ponazuril",
    "class": "Antiprotozoal (Coccidiostat)",
    "side_effects": "Generally well-tolerated, potential for mild gastrointestinal upset."
  },
  {
    "name": "Quinidine",
    "class": "Antiarrhythmic",
    "side_effects": "Diarrhea, gastrointestinal upset, potential for arrhythmias."
  },
  {
    "name": "Quinupristin-Dalfopristin",
    "class": "Antibiotic (Streptogramin)",
    "side_effects": "Injection site reactions, potential for gastrointestinal upset."
  },
  {
    "name": "Quinine",
    "class": "Antiprotozoal (Antimalarial)",
    "side_effects": "Sweating, nausea, potential for allergic reactions."
  },
  {
    "name": "Quazepam",
    "class": "Sedative-Hypnotic",
    "side_effects": "Sedation, ataxia."
  },
  {
    "name": "Quetiapine",
    "class": "Antipsychotic",
    "side_effects": "Sedation, potential for weight gain."
  },
  {
    "name": "Quinaldine",
    "class": "Anesthetic",
    "side_effects": "Respiratory depression, potential for cardiovascular effects."
  },
  {
    "name": "Quadritop Ointment",
    "class": "Topical Ointment (Antibiotic and Steroid Combination)",
    "side_effects": "Potential for skin irritation, allergic reactions."
  },
  {
    "name": "Quinacrine",
    "class": "Antiprotozoal",
    "side_effects": "Gastrointestinal upset, potential for allergic reactions."
  },
  {
    "name": "Rifampin",
    "class": "Antibiotic (Rifamycin)",
    "side_effects": "Gastrointestinal upset, potential for liver enzyme elevation."
  },
  {
    "name": "Reserpine",
    "class": "Antihypertensive",
    "side_effects": "Sedation, potential for hypotension."
  },
  {
    "name": "Rompun (Xylazine)",
    "class": "Sedative, Analgesic",
    "side_effects": "Sedation, bradycardia, potential for respiratory depression."
  },
  {
    "name": "Rimadyl (Carprofen)",
    "class": "Non-Steroidal Anti-Inflammatory Drug (NSAID)",
    "side_effects": "Gastrointestinal upset, potential for renal and hepatic effects."
  },
  {
    "name": "Revolution (Selamectin)",
    "class": "Parasiticide (Macrocyclic Lactone)",
    "side_effects": "Potential for skin irritation, neurological signs in sensitive individuals."
  },
  {
    "name": "Rifampicin",
    "class": "Antibiotic (Rifamycin)",
    "side_effects": "Gastrointestinal upset, potential for liver enzyme elevation."
  },
  {
    "name": "Ranitidine",
    "class": "H2 Receptor Antagonist",
    "side_effects": "Rare, may include gastrointestinal upset."
  },
  {
    "name": "Regu-Mate (Altrenogest)",
    "class": "Hormonal Reproductive Agent",
    "side_effects": "Variable, may include changes in behavior and appetite."
  },
  {
    "name": "Ralgro (Zeranol)",
    "class": "Growth Promoter",
    "side_effects": "Potential for local tissue reactions at implant site."
  },
  {
    "name": "Revalor (Trenbolone Acetate and Estradiol)",
    "class": "Growth Promoter",
    "side_effects": "Potential for local tissue reactions at implant site."
  },
  {
    "name": "Rabon (Tetrachlorvinphos)",
    "class": "Insecticide",
    "side_effects": "Potential for skin and eye irritation."
  },
  {
    "name": "Ryzen (Altrenogest)",
    "class": "Hormonal Reproductive Agent",
    "side_effects": "Variable, may include changes in behavior and appetite."
  },
  {
    "name": "Sulfamethazine",
    "class": "Antibiotic (Sulfonamide)",
    "side_effects": "Gastrointestinal upset, potential for allergic reactions."
  },
  {
    "name": "Sulfadimethoxine",
    "class": "Antibiotic (Sulfonamide)",
    "side_effects": "Gastrointestinal upset, potential for allergic reactions."
  },
  {
    "name": "Sulfasalazine",
    "class": "Anti-inflammatory (Sulfonamide)",
    "side_effects": "Gastrointestinal upset, potential for blood disorders."
  },
  {
    "name": "Streptomycin",
    "class": "Antibiotic (Aminoglycoside)",
    "side_effects": "Nephrotoxicity, potential for ototoxicity."
  },
  {
    "name": "Salicylates (Aspirin)",
    "class": "Non-Steroidal Anti-Inflammatory Drug (NSAID)",
    "side_effects": "Gastrointestinal upset, potential for ulceration, and bleeding."
  },
  {
    "name": "Synovex (Estradiol and Testosterone)",
    "class": "Hormonal Growth Promoter",
    "side_effects": "Potential for local tissue reactions at implant site."
  },
  {
    "name": "Sedivet (Romifidine)",
    "class": "Sedative, Analgesic",
    "side_effects": "Sedation, potential for ataxia, bradycardia."
  },
  {
    "name": "Surolan (Miconazole, Polymyxin B, and Prednisolone)",
    "class": "Antifungal, Antibacterial, Corticosteroid",
    "side_effects": "Local irritation, potential for systemic effects with prolonged use."
  },
  {
    "name": "Spectramast DC (Cephapirin)",
    "class": "Antibiotic (Cephalosporin)",
    "side_effects": "Potential for local reactions at infusion site."
  },
  {
    "name": "Sedazine (Detomidine)",
    "class": "Sedative, Analgesic",
    "side_effects": "Sedation, potential for ataxia, bradycardia."
  },
  {
    "name": "Saraflox (Sarafloxacin)",
    "class": "Antibiotic (Fluoroquinolone)",
    "side_effects": "Gastrointestinal upset, potential for cartilage abnormalities in young animals."
  },
  {
    "name": "Synulox (Amoxicillin and Clavulanic Acid)",
    "class": "Antibiotic (Penicillin)",
    "side_effects": "Gastrointestinal upset, potential for allergic reactions."
  },
  {
    "name": "Soloxine (Levothyroxine)",
    "class": "Thyroid Hormone",
    "side_effects": "Overdose may lead to hyperthyroidism-like symptoms."
  },
  {
    "name": "Selenium and Vitamin E (Bo-Se)",
    "class": "Nutritional Supplement",
    "side_effects": "Potential for selenium toxicity if overdosed."
  },
  {
    "name": "Scour Halt (Neomycin, Oxymycin, Sulfabromomethazine)",
    "class": "Antibiotic, Antiprotozoal",
    "side_effects": "Gastrointestinal upset, potential for allergic reactions."
  },
  {
    "name": "Selenium Sulfide (Seleen)",
    "class": "Topical Antifungal",
    "side_effects": "Potential for skin irritation."
  },
  {
    "name": "Surpass (Diclofenac)",
    "class": "Non-Steroidal Anti-Inflammatory Drug (NSAID)",
    "side_effects": "Gastrointestinal upset, potential for ulceration, and bleeding."
  },
  {
    "name": "Spectramast LC (Lactating Cow formulation)",
    "class": "Antibiotic (Cephalosporin)",
    "side_effects": "Potential for local reactions at infusion site."
  },
  {
    "name": "Tetrahydrogestrinone (THG)",
    "class": "Hormonal Growth Promoter",
    "side_effects": "Potential for hormonal imbalances."
  },
  {
    "name": "Tildren (Tiludronate)",
    "class": "Bisphosphonate",
    "side_effects": "Potential for gastrointestinal upset."
  },
  {
    "name": "Tetanus Antitoxin",
    "class": "Antitoxin",
    "side_effects": "Potential for allergic reactions."
  },
  {
    "name": "Tylosin",
    "class": "Antibiotic (Macrolide)",
    "side_effects": "Gastrointestinal upset, potential for allergic reactions."
  },
  {
    "name": "Tulathromycin (Draxxin)",
    "class": "Antibiotic (Macrolide)",
    "side_effects": "Potential for injection site reactions."
  },
  {
    "name": "Triamcinolone",
    "class": "Corticosteroid",
    "side_effects": "Potential for immunosuppression, laminitis in horses."
  },
  {
    "name": "Testosterone",
    "class": "Hormone",
    "side_effects": "Potential for hormonal imbalances."
  },
  {
    "name": "Tiamulin",
    "class": "Antibiotic (Tiamulin Fumarate)",
    "side_effects": "Gastrointestinal upset, potential for allergic reactions."
  },
  {
    "name": "Tranquilizers (e.g., Acepromazine)",
    "class": "Sedative, Tranquilizer",
    "side_effects": "Sedation, potential for low blood pressure, penis extrusion in horses."
  },
  {
    "name": "Tolazoline",
    "class": "Alpha-Adrenergic Blocker",
    "side_effects": "Potential for cardiovascular effects."
  },
  {
    "name": "Tetracycline",
    "class": "Antibiotic (Tetracycline)",
    "side_effects": "Gastrointestinal upset, potential for photosensitivity."
  },
  {
    "name": "Trichlorfon (Neguvon)",
    "class": "Parasiticide",
    "side_effects": "Potential for toxicity if not used properly."
  },
  {
    "name": "Theophylline",
    "class": "Bronchodilator",
    "side_effects": "Potential for gastrointestinal upset, increased heart rate."
  },
  {
    "name": "Thiamine (Vitamin B1)",
    "class": "Nutritional Supplement",
    "side_effects": "Generally safe when used as a supplement."
  },
  {
    "name": "Thiabendazole",
    "class": "Anthelmintic",
    "side_effects": "Potential for gastrointestinal upset."
  },
  {
    "name": "Tylosin Tartrate and Sulfamethazine (Tylan Sulfa G)",
    "class": "Antibiotic Combination",
    "side_effects": "Gastrointestinal upset, potential for allergic reactions."
  },
  {
    "name": "Trilostane (Vetoryl)",
    "class": "Adrenal Steroidogenesis Inhibitor",
    "side_effects": "Potential for gastrointestinal upset, lethargy."
  },
  {
    "name": "Thyro-L (Levothyroxine)",
    "class": "Thyroid Hormone",
    "side_effects": "Overdose may lead to hyperthyroidism-like symptoms."
  },
  {
    "name": "Tolfenamic Acid",
    "class": "Non-Steroidal Anti-Inflammatory Drug (NSAID)",
    "side_effects": "Gastrointestinal upset, potential for ulceration, and bleeding."
  },
  {
    "name": "Torbugesic (Butorphanol)",
    "class": "Opioid Analgesic",
    "side_effects": "Potential for sedation, respiratory depression."
  },
  {
    "name": "Urea",
    "class": "Nutritional Supplement",
    "use": "Urea is used as a non-protein nitrogen source in ruminant diets.",
    "side_effects": "Overconsumption can lead to toxicity, causing metabolic imbalances."
  },
  {
    "name": "Ultrabac (Ultrabac 7)",
    "class": "Vaccine",
    "use": "Ultrabac is a vaccine used for the prevention of respiratory diseases in cattle, including brucellosis.",
    "side_effects": "Typically mild, local reactions at the injection site."
  },
  {
    "name": "Ubrogepant (Ubrelvy)",
    "class": "Nonsteroidal Anti-Inflammatory Drug (NSAID)",
    "use": "Ubrogepant is used in cattle for the treatment of pain and inflammation associated with musculoskeletal disorders.",
    "side_effects": "Gastrointestinal upset, potential for kidney and liver issues with prolonged use."
  },
  {
    "name": "Ursodiol (Actigall, Urso)",
    "class": "Gallstone Dissolution Agent",
    "use": "Ursodiol is used in large animals to dissolve gallstones and prevent their formation.",
    "side_effects": "Generally safe when used as directed."
  },
  {
    "name": "Udder Balm (Udder Butter)",
    "class": "Topical Emollient",
    "use": "Udder balm is used for soothing and moisturizing udders in dairy cows.",
    "side_effects": "Generally safe when used topically."
  },
  {
    "name": "Urea Molasses Mineral Block",
    "class": "Nutritional Supplement",
    "use": "Blocks containing urea and molasses are provided to cattle as a nutritional supplement to meet their dietary requirements.",
    "side_effects": "Overconsumption can lead to urea toxicity."
  },
  {
    "name": "Uridine Triacetate (Vistogard)",
    "class": "Antidote",
    "use": "Uridine triacetate is used in large animals as an antidote for 5-fluorouracil toxicity.",
    "side_effects": "Generally safe when used under veterinary supervision."
  },
  {
    "name": "Uterine Bolus (Calcium, Magnesium, Phosphorus Bolus)",
    "class": "Nutritional Supplement",
    "use": "Uterine boluses are used to provide essential minerals to support uterine health in postpartum dairy cows.",
    "side_effects": "Generally safe when used as directed."
  },
  {
    "name": "Urecholine (Bethanechol)",
    "class": "Cholinergic Agent",
    "use": "Urecholine may be used in large animals to stimulate bladder emptying or improve gastrointestinal motility.",
    "side_effects": "Potential for gastrointestinal upset, salivation."
  },
  {
    "name": "U-7 Gastric Aid",
    "class": "Digestive Supplement",
    "use": "U-7 Gastric Aid is used to support gastric health and prevent ulcers in horses.",
    "side_effects": "Generally safe when used as directed."
  },
  {
    "name": "Urocit-K (Potassium Citrate)",
    "class": "Urinary Alkalinizing Agent",
    "use": "Urocit-K is used in large animals to prevent the formation of urinary stones.",
    "side_effects": "Generally safe when used as directed."
  },
  {
    "name": "Uniprim (Trimethoprim and Sulfadiazine)",
    "class": "Antibiotic Combination",
    "use": "Uniprim is used for the treatment of respiratory and urinary tract infections in large animals.",
    "side_effects": "Potential for gastrointestinal upset, allergic reactions."
  },
  {
    "name": "Vitamin A (Retinol)",
    "class": "Vitamin",
    "use": "Essential for vision, immune function, and reproductive health in cattle.",
    "side_effects": "Excessive intake can lead to toxicity, causing skeletal issues and liver damage."
  },
  {
    "name": "Vitamin B Complex (B Vitamins)",
    "class": "Vitamin",
    "use": "Supports metabolism, energy production, and overall health in large animals.",
    "side_effects": "Generally safe when used as directed."
  },
  {
    "name": "Vitamin C (Ascorbic Acid)",
    "class": "Vitamin",
    "use": "Used to supplement vitamin C levels in cases of deficiency or stress in large animals.",
    "side_effects": "Generally safe when used as directed."
  },
  {
    "name": "Vitamin D (Cholecalciferol)",
    "class": "Vitamin",
    "use": "Essential for calcium and phosphorus metabolism, promoting bone health in cattle.",
    "side_effects": "Excessive intake can lead to vitamin D toxicity, causing hypercalcemia."
  },
  {
    "name": "Vitamin E (Tocopherol)",
    "class": "Vitamin",
    "use": "Antioxidant that supports muscle development and immune function in large animals.",
    "side_effects": "Generally safe when used as directed."
  },
  {
    "name": "Valbazen (Albendazole)",
    "class": "Anthelmintic (Dewormer)",
    "use": "Used to control and treat various internal parasites in cattle, sheep, and goats.",
    "side_effects": "May cause mild gastrointestinal upset in some cases."
  },
  {
    "name": "Vimco (Flunixin Meglumine)",
    "class": "Nonsteroidal Anti-Inflammatory Drug (NSAID)",
    "use": "Used for pain relief and inflammation control in large animals.",
    "side_effects": "Potential for gastrointestinal ulcers, kidney issues with prolonged use."
  },
  {
    "name": "Ventipulmin (Clenbuterol)",
    "class": "Beta-2 Adrenergic Agonist",
    "use": "Used for bronchodilation in cases of respiratory conditions in horses and cattle.",
    "side_effects": "Potential for tremors, increased heart rate."
  },
  {
    "name": "Vetalog (Trilostane)",
    "class": "Corticosteroid",
    "use": "Used in cases of hyperadrenocorticism (Cushing's disease) in dogs and horses.",
    "side_effects": "Potential for adrenal insufficiency, gastrointestinal upset."
  },
  {
    "name": "Vetrimycin (Oxytetracycline)",
    "class": "Antibiotic (Tetracycline)",
    "use": "Broad-spectrum antibiotic used for the treatment of bacterial infections in large animals.",
    "side_effects": "Potential for gastrointestinal upset, photosensitivity."
  },
  {
    "name": "Vitamin K1 (Phytonadione)",
    "class": "Vitamin",
    "use": "Used in cases of vitamin K deficiency or to counteract certain rodenticide toxicities in large animals.",
    "side_effects": "Generally safe when used as directed."
  },
  {
    "name": "Vivotif (Typhoid Vaccine Live Oral Ty21a)",
    "class": "Vaccine",
    "use": "Used in horses for the prevention of equine typhoid.",
    "side_effects": "Generally mild, local reactions at the injection site."
  },
  {
    "name": "Vivonex (Enteral Nutrition)",
    "class": "Nutritional Supplement",
    "use": "Provides nutritional support in cases of malabsorption or gastrointestinal issues in large animals.",
    "side_effects": "Generally safe when used as directed."
  },
  {
    "name": "Vivitonin (Propentofylline)",
    "class": "Vasodilator",
    "use": "Used in horses for the improvement of peripheral blood flow.",
    "side_effects": "Potential for gastrointestinal upset, restlessness."
  },
  {
    "name": "Vetrazin (Azamethiphos)",
    "class": "Insecticide",
    "use": "Used for the control of flies and other insects in and around livestock.",
    "side_effects": "Generally safe when used as directed."
  },
  {
    "name": "Wazine (Piperazine)",
    "class": "Anthelmintic (Dewormer)",
    "use": "Used to control and treat roundworm infections in poultry and swine.",
    "side_effects": "Generally safe when used as directed, mild gastrointestinal upset possible."
  },
  {
    "name": "Weldamine (Mebendazole)",
    "class": "Anthelmintic (Dewormer)",
    "use": "Used to treat various gastrointestinal parasites in cattle, sheep, and swine.",
    "side_effects": "Generally safe when used as directed, mild gastrointestinal upset possible."
  },
  {
    "name": "Wintix (Tetramisole)",
    "class": "Anthelmintic (Dewormer)",
    "use": "Used to control and treat gastrointestinal parasites in cattle, sheep, and swine.",
    "side_effects": "Generally safe when used as directed, may cause hypersensitivity reactions."
  },
  {
    "name": "White E Oil (Mineral Oil)",
    "class": "Laxative",
    "use": "Used as a lubricant laxative in cases of constipation in large animals.",
    "side_effects": "Generally safe when used as directed, aspiration risk if given orally."
  },
  {
    "name": "Wound-Kote (Aluminum Powder, Gentian Violet)",
    "class": "Topical Antiseptic",
    "use": "Used for the treatment of minor wounds and abrasions in large animals.",
    "side_effects": "Generally safe when used topically, avoid ingestion."
  },
  {
    "name": "Water Soluble Vitamins (Various)",
    "class": "Vitamin Supplement",
    "use": "Used to supplement essential water-soluble vitamins in large animals.",
    "side_effects": "Generally safe when used as directed."
  },
  {
    "name": "Wondaloop (Propantheline)",
    "class": "Anticholinergic",
    "use": "Used to reduce intestinal spasms and control diarrhea in large animals.",
    "side_effects": "Potential for dry mouth, constipation."
  },
  {
    "name": "Wound Honey (Medihoney)",
    "class": "Topical Wound Dressing",
    "use": "Used in wound care for its antibacterial properties in large animals.",
    "side_effects": "Generally safe when used topically, avoid ingestion."
  },
  {
    "name": "Worm-X (Fenbendazole)",
    "class": "Anthelmintic (Dewormer)",
    "use": "Used to control and treat various gastrointestinal parasites in large animals.",
    "side_effects": "Generally safe when used as directed, mild gastrointestinal upset possible."
  },
  {
    "name": "W-D Suspension (Carbidopa and Levodopa)",
    "class": "Dopaminergic Agent",
    "use": "Used in horses for the management of Parkinson's disease.",
    "side_effects": "Potential for gastrointestinal upset, neurologic effects."
  },
  {
    "name": "Winstrol V (Stanozolol)",
    "class": "Anabolic Steroid",
    "use": "Used in horses for weight gain and management of certain medical conditions.",
    "side_effects": "Potential for liver toxicity, behavioral changes."
  },
  {
    "name": "Warfarin (Coumadin)",
    "class": "Anticoagulant",
    "use": "Used in horses for the prevention and treatment of blood clotting disorders.",
    "side_effects": "Risk of bleeding, requires careful monitoring."
  },
  {
    "name": "Wellness Formula (Multiple Ingredients)",
    "class": "Nutritional Supplement",
    "use": "Used to support overall health and immune function in large animals.",
    "side_effects": "Generally safe when used as directed."
  },
  {
    "name": "Water Pill (Furosemide)",
    "class": "Loop Diuretic",
    "use": "Used in horses for the treatment of edema associated with congestive heart failure.",
    "side_effects": "Electrolyte imbalances, dehydration."
  },
  {
    "name": "Wipe (Cetrimide)",
    "class": "Topical Antiseptic",
    "use": "Used for cleaning and disinfecting wounds in large animals.",
    "side_effects": "Generally safe when used topically, avoid ingestion."
  },
  {
    "name": "Xylazine (Xylazine Hydrochloride)",
    "class": "Alpha-2 Adrenergic Agonist",
    "use": "Sedative, analgesic, and muscle relaxant used in large animals for restraint and minor surgical procedures.",
    "side_effects": "Bradycardia, respiratory depression, hypotension."
  },
  {
    "name": "Xenazine (Tetrabenazine)",
    "class": "Neurological Agent",
    "use": "Used in horses to manage hyperkinetic disorders.",
    "side_effects": "Sedation, gastrointestinal upset."
  },
  {
    "name": "Yohimbine",
    "class": "Alpha-2 Adrenergic Antagonist",
    "use": "Used in large animals to reverse the sedative and analgesic effects of xylazine.",
    "side_effects": "Increased heart rate, hypertension."
  },
  {
    "name": "Zinc Bacitracin",
    "class": "Antibiotic",
    "use": "Used in large animals for the prevention and treatment of bacterial infections.",
    "side_effects": "Limited absorption, potential for allergic reactions."
  }
]