

const AnswersTab = ({ test }) => {

  const wrongQuestions = test.testQuestions.filter(tq => !tq.correct).filter(tq => !!tq.question.studyHint)

  return (
    <div className='mt-12'>
      <ul className='px-4 list-disc'>
        {wrongQuestions.map((testQuestion, i1) => (
          <li key={testQuestion.id} className='mb-4 bullet'>
            {testQuestion.question.studyHint}
          </li>
        ))}
      </ul>
    </div>
  )
}

export default AnswersTab;