import { useState } from 'react'
import { SUBMIT_QUIZ_MUTATION } from './queries';
import { useMutation } from '@apollo/client';
// import { shuffle } from "underscore"

const IncompleteQuiz = ({ quiz, refetch }) => {


  const [answers, setAnswers] = useState({})
  const [errors, setErrors] = useState([])
  const [errorMessage, setErrorMessage] = useState()

  const onSelectAnswer = (e) => {
    // console.log('onselectanswer value', e.target.value)
    let newAnswers = { ...answers }
    newAnswers[e.target.id] = e.target.value
    setAnswers(newAnswers)
  }

  const [submitTest, _] = useMutation(SUBMIT_QUIZ_MUTATION, {
    errorPolicy: "all",
    onCompleted: (data) => {
      // window.location = window.location
      refetch()
      console.log('submitTest', data)
    },
    onError: (err) => {
      console.log('err', err)
    }
  })


  const onSubmitTest = () => {
    const questions_without_answers = quiz.questions.filter(q => !(q.id in answers))
    console.log('onsubmit')
    if (questions_without_answers.length > 0) {
      setErrors(questions_without_answers.map(q => q.id))
      setErrorMessage('Please answer all questions')
    } else {
      setErrors([])
      setErrorMessage(null)
      submitTest({
        variables: {
          quizId: quiz.id,
          answers: answers
        }
      })
    }
  }

  return (
    <div>
      <ul>
        {quiz.questions.map((question, i1) => (
          <li key={question.id} className='mb-8'>
            <p className={`font-bold text-sm ${errors.includes(question.id) ? 'text-red-500' : ''}`}>{i1 + 1}. {question.question}</p>
            <div className=''>
              {question.answers.map((answer, i2) => (
                <div key={i2}>
                  <input
                    type="radio" key={answer} value={answer} name={question.id} id={question.id}
                    onChange={onSelectAnswer}
                  />
                  <label htmlFor={question.question} className='ml-2 text-sm'>
                    {i2 == 0 && 'A: '}
                    {i2 == 1 && 'B: '}
                    {i2 == 2 && 'C: '}
                    {i2 == 3 && 'D: '}
                    {answer}
                  </label>
                </div>
              ))}
            </div>
          </li>
        ))}

      </ul>
      <button className='text-white bg-black px-4 py-2 rounded-md' onClick={onSubmitTest}>Submit Quiz</button>
      {errorMessage && <p className='text-red-500'>{errorMessage}</p>}
    </div>
  )
}

export default IncompleteQuiz