import { useState, useEffect } from "react"
import { useNavigate } from "react-router-dom"
import { CREATE_NEW_TEST_MUTATION } from "./queries"
import { useMutation } from "@apollo/client"

const NewTestPage = () => {
  const navigate = useNavigate()

  const [testVersion, setTestVersion] = useState(null)

  const onExit = () => {
    navigate('/tests')
  }

  useEffect(() => {
    document.title = `Patch Education — New Test`;
  }, []);

  const [createNewTest, _] = useMutation(CREATE_NEW_TEST_MUTATION, {
    variables: {
      testVersion: testVersion
    },
    errorPolicy: "all",
    onCompleted: (data) => {
      console.log('createNewTest', data)
      navigate(`/tests/${data.createNewTest.test.id}`)
    },
    onError: (err) => {
      console.log('err', err)
    }
  })

  const onSelectAnswer = (e) => {
    setTestVersion(e.target.value)
  }

  return (
    <div>
      <div className='bg-black h-16 px-8 flex flex-row items-center justify-between'>
        <h1 className='text-white text-xl font-medium'>NAVLE Practice Test</h1>
        <button onClick={onExit} className='bg-white rounded-sm px-4 py-1 font-medium hover:bg-gray-200'>Exit</button>
      </div>
      <div className='p-8 bg-gray-100 h-screen'>
        <p>Welcome to the testing center!</p>
        <p>This is built to look like the real NAVLE exam and get you comfortable with test taking.</p>
        <p className="font-semibold mt-8">Choose a length of exam:</p>
        <div className='bg-white border px-4 py-4 border-[#004975] border-b-4'>
          <div>
            <input
              type="radio" key={'test-15'} value={15} name={'testVersion'} id={'test-15'}
              onChange={onSelectAnswer}
            />
            <label htmlFor={'test-15'} className='ml-2'>
              15 minutes, 15 questions
            </label>
          </div>
          <div>
            <input
              type="radio" key={'test-30'} value={30} name={'testVersion'} id={'test-30'}
              onChange={onSelectAnswer}
            />
            <label htmlFor={'test-30'} className='ml-2'>
              30 minutes, 30 questions
            </label>
          </div>
          <div>
            <input
              type="radio" key={'test-60'} value={60} name={'testVersion'} id={'test-60'}
              onChange={onSelectAnswer}
            />
            <label htmlFor={'test-60'} className='ml-2'>
              60 minutes, 60 questions (True NAVLE Exam Section)
            </label>
          </div>
        </div>
        <div className='flex flex-row items-center justify-center mt-12'>
          {testVersion && <button onClick={createNewTest} className='bg-[#004975] rounded-sm shadow-sm px-4 py-1 text-white'>Start Test</button>}
          {!testVersion && <button disabled className='bg-gray-600 rounded-sm shadow-sm px-4 py-1 text-white'>Start Test</button>}
        </div>
      </div>
    </div>
  )
}

export default NewTestPage