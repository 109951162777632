import { gql } from "@apollo/client"

export const GET_ARTICLE_QUERY = gql`
  query GetArticle($category: String!, $subcategory: String!, $slug: String!) {
    article(category: $category, subcategory: $subcategory, slug: $slug) {
      id
      title
      content
      audio
      isCompleted
      order

      subcategory {
        id
        title
        slug
        lastArticleOrderNumber
      }

      category {
        id
        title
        slug
      }

      previousArticle {
        id
        title
        path
      }
      nextArticle {
        id
        title
        path
      }
    }
  }
`;

export const GET_ARTICLE_AUDIO_QUERY = gql`
  query GetArticleAudio($id: ID!) {
    articleAudio(id: $id)
  }
`;

export const TOGGLE_ARTICLE_COMPLETION_MUTATION = gql`
  mutation ToggleArticleCompletion(
    $articleId: ID!
  ) {
    toggleArticleCompletion(
      articleId: $articleId
    ) {
      success
    }
  }
`
